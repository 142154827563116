import Resource from './Resource';
import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';
import { prospectingTracker, accountTracker } from 'App/babel/helpers/Tracker';
import RequestBuilder from './RequestBuilder';
import logError from '../helpers/logError';
import { getCountryFromProspectingId } from 'App/services/prospectingService';

class Prospecting extends Resource {
	constructor() {
		super('prospecting', null, { notificationTitle: 'default.account' });

		this.notifications = {
			save: () => {
				return {
					title: 'savedTitle.prospecting',
					style: STYLE.SUCCESS,
					icon: 'save',
					type: TYPE.BODY
				};
			},
			saveBulk: () => {
				return {
					title: 'savedTitle.prospectingMulti',
					body: 'saved.prospectingMulti',
					style: STYLE.SUCCESS,
					icon: 'save',
					type: TYPE.BODY,
					customBody: {
						extraSeconds: 2000
					}
				};
			},
			saveError: ({ error }) => {
				return {
					title: 'default.error',
					body: error.translated || 'saveError.prospecting',
					style: STYLE.ERROR,
					icon: 'save',
					type: TYPE.BODY
				};
			},
			saveBulkError: ({ error }) => {
				return {
					title: 'default.error',
					body: error.translated || 'saveError.prospectingMulti',
					style: STYLE.ERROR,
					icon: 'save',
					type: TYPE.BODY
				};
			},
			findError: ({ error }) => {
				return {
					title: 'default.error',
					body: error.translated || error.msg,
					style: STYLE.ERROR,
					icon: 'times',
					type: TYPE.BODY
				};
			}
		};
	}

	getCountryFromProspectingId(prospectingId) {
		return getCountryFromProspectingId(prospectingId);
	}

	lookup(field, params = {}) {
		return this._getRequest(`lookup/${field}`, { methodName: 'lookup', params }).then(response => response.data);
	}

	async getClient(prospectingId, country) {
		const fields = ['name', 'prospectingId', 'groupSize', 'dunsNo'];
		const prospectingRB = new RequestBuilder();
		prospectingRB.addFilter({ field: 'prospectingId' }, RequestBuilder.comparisonTypes.Equals, prospectingId);
		prospectingRB.extraParams.push({ key: 'country', value: country });
		prospectingRB.fields = fields;

		const res = await this.find(prospectingRB.build());
		return res.data?.length > 0 ? res.data[0] : {};
	}

	findGroupstructure(params = {}) {
		return this._getRequest('', {
			methodName: 'findGroupstructure',
			_url: 'prospectinggroupstructure',
			params
		}).then(response => response.data);
	}

	getCompaniesFromGroup(params = {}) {
		return this._getRequest('', {
			methodName: 'getCompaniesFromGroup',
			_url: 'getCompaniesFromGroup',
			params
		}).then(response => response.data);
	}

	findDirectors(data = {}) {
		return this._postRequest('findDirectors', data).then(response => response.data);
	}

	async getCompanyGroup(prospectingId, fetchClient = false) {
		let client;
		const country = this.getCountryFromProspectingId(prospectingId);
		if (fetchClient) {
			client = await this.getClient(prospectingId, country);
		}

		let total = 0;

		const fixMatchInUpsales = item => {
			total++;
			if (item.matchInUpsales) {
				Object.assign(item, item.matchInUpsales, { existing: item.matchInUpsales });
			}
			for (const branch of item.branches) {
				total++;
				if (branch.matchInUpsales) {
					Object.assign(branch, branch.matchInUpsales, { existing: branch.matchInUpsales });
				}
			}
			for (let child of item.children) {
				child = fixMatchInUpsales(child, total);
			}

			return item;
		};

		const getBranches = item => {
			let allBranches = item.branches;
			for (const child of item.children) {
				const childBranches = getBranches(child);
				allBranches = allBranches.concat(childBranches);
			}
			return allBranches;
		};
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'prospectingId' }, RequestBuilder.comparisonTypes.Equals, prospectingId);
		rb.extraParams.push({ key: 'country', value: country });

		try {
			const group = await this.findGroupstructure(rb.build());

			if (group.data && group.data.length) {
				let tree = group.data[0];
				tree = fixMatchInUpsales(tree);
				const unknowns = tree ? getBranches(tree) : null;

				const data = {
					client: client || {},
					unknownTotal: unknowns.length,
					total: total,
					tree: tree,
					unknowns: unknowns
				};

				return data;
			}

			if (client) {
				return {
					client,
					unknownTotal: 0,
					total: 0,
					tree: null,
					unknowns: []
				};
			}
		} catch (err) {
			logError(err, 'failed getting group structure');
			return undefined;
		}
	}

	async save(data, opts) {
		const result = await super.save(data, opts);
		const event = data.id ? accountTracker.events.UPDATE : accountTracker.events.CREATE;
		accountTracker.track(event, { module: 'Account & Contacts' });
		return result;
	}

	async saveBulk(data, opts = {}) {
		const result = await this._postRequest('', data, { ...opts, _url: 'prospectingbulk', methodName: 'saveBulk' });
		prospectingTracker.track(prospectingTracker.events.BULK_SAVE);
		return result.data;
	}

	async matchAll(opts = {}) {
		const result = await this._putRequest(
			'',
			{},
			{ ...opts, _url: 'prospectingmatcher/all', methodName: 'matchAll' }
		);
		return result.data;
	}

	getSuggestionBoxes(filters, opts = {}) {
		return this._getRequest('', {
			params: filters,
			...opts,
			_url: 'prospectingsuggestion',
			methodName: 'getSuggestionBoxes'
		}).then(response => response.data);
	}

	findSuggestionBoxes(id, filters = {}, opts = {}) {
		return this._getRequest('', {
			params: filters,
			...opts,
			_url: `prospectingsuggestion/${id}`,
			methodName: 'getSuggestionBoxes'
		}).then(response => response.data);
	}

	runActionsSuggestions(id, data, opts = {}) {
		return this._postRequest('', data, {
			...opts,
			_url: `prospectingsuggestion/${id}`,
			methodName: 'runActionsSuggestions'
		}).then(response => response.data);
	}

	getPromoData(type, params = {}, opts = {}) {
		return this._getRequest(`promo/${type}`, {
			...opts,
			params,
			methodName: 'getPromoData'
		}).then(response => response.data);
	}

	getGroupStatement(prospectingId, params = {}, opts = {}) {
		return this._getRequest(`groupStatement/${prospectingId}`, {
			...opts,
			params,
			methodName: 'getGroupStatement'
		}).then(response => response.data);
	}

	ceoOnBoard({ limit, offset, sort, country, ...opts } = {}) {
		return this._getRequest('ceoOnBoard', {
			params: { limit, offset, sort, country },
			...opts,
			methodName: 'ceoOnBoard'
		}).then(response => response.data);
	}

	insideCompanyGroups({ limit, offset, sort, country, ...opts } = {}) {
		return this._getRequest('insideCompanyGroups', {
			params: { limit, offset, sort, country },
			...opts,
			methodName: 'insideCompanyGroups'
		}).then(response => response.data);
	}
}

export default new Prospecting();
