import React, { ForwardedRef, PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react';
import { MultiSelect } from '../ListView/ListViewRenderHelpers';
import { MultiAction } from '../ListViewActions';

type Props = {};

const getCountAndSelectedIds = (selectedIdMap: { [id: number]: boolean }) => {
	return Object.keys(selectedIdMap)
		.map(Number)
		.reduce(
			(res, id) => {
				if (selectedIdMap[id]) {
					res.selected += 1;
					res.selectedIds.push(id);
				}
				return res;
			},
			{ selected: 0, selectedIds: [] as number[] }
		);
};

export type MultiSelectContext = {
	active: boolean;
	allSelected: boolean;
	selected: number;
	selectedIds: number[];
	selectedIdMap: Record<number, boolean>;
	multiActions: MultiAction[];
	total: number;
	setTotal: (total: number) => void;
	selectAllResult: () => Promise<void>;
	setSelectedIds: (selectedIds: MultiSelect['selectedIds']) => Promise<void>;
	toggleSelected: (id: number, checked?: boolean) => void;
	setMultiActions: (actions: MultiAction[]) => void;
	selectNone: () => void;
	reset: () => void;
};

const defaultFnUsedWarning = (fnName: string) => () => {
	console.error(`MultiselectProvider: ${fnName} was used outside of context`);
	return Promise.resolve();
};

const MultiselectContext = createContext<MultiSelectContext>({
	active: false,
	allSelected: false,
	selected: 0,
	selectedIds: [],
	multiActions: [],
	selectedIdMap: {},
	total: 0,
	setTotal: defaultFnUsedWarning('setTotal'),
	selectAllResult: defaultFnUsedWarning('selectAllResult'),
	setSelectedIds: defaultFnUsedWarning('setSelectedIds'),
	toggleSelected: defaultFnUsedWarning('toggleSelected'),
	setMultiActions: defaultFnUsedWarning('setMultiActions'),
	selectNone: defaultFnUsedWarning('selectNone'),
	reset: defaultFnUsedWarning('reset')
});

export const useMultiselect = () => useContext(MultiselectContext);

const useMultiselectData = (): MultiSelectContext => {
	const [selected, setSelected] = useState<MultiSelectContext['selected']>(0);
	const [total, setTotal] = useState<MultiSelectContext['selected']>(0);
	const [selectedIds, setSelectedIds] = useState<MultiSelectContext['selectedIds']>([]);
	const [selectedIdMap, setSelectedIdMap] = useState<MultiSelectContext['selectedIdMap']>({});
	const [multiActions, setMultiActions] = useState<MultiSelectContext['multiActions']>([]);
	const [selectAll, setSelectAll] = useState<MultiSelectContext['allSelected']>(false);
	const cbRef = useRef<(value?: any) => void>();

	const selectNone = () => {
		setSelected(0);
		setSelectedIds([]);
		setSelectedIdMap({});
		setSelectAll(false);
	};

	useEffect(() => {
		cbRef.current?.();
		cbRef.current = undefined;
	}, [selected]);

	return {
		multiActions,
		active: !!multiActions.length,
		allSelected: selectAll,
		selected,
		selectedIds,
		selectedIdMap,
		total,
		setMultiActions,
		// setSelectedIdMap should never be exposed to the outside world. Setting setSelectedIds will generate the map
		setSelectedIds: ids => {
			return new Promise(resolve => {
				cbRef.current = resolve;
				const currentSelectedIds = Array.from(new Set([...selectedIds, ...ids]));
				setSelected(currentSelectedIds.length);
				setSelectedIds(currentSelectedIds);
				setSelectedIdMap(currentSelectedIds.reduce((res, id) => ({ ...res, [id]: true }), {}));
				setSelectAll(false);
			});
		},
		setTotal: total => {
			if (selectAll) {
				setSelected(total);
			}
			setTotal(total);
		},
		toggleSelected: (id, checked) => {
			const map = {
				...selectedIdMap,
				[id]: checked ?? !selectedIdMap[id] // if checked was not provided we toggle current value
			};
			const { selected, selectedIds } = getCountAndSelectedIds(map);

			setSelected(selected);
			setSelectedIds(selectedIds);
			setSelectedIdMap(map);
			setSelectAll(false);
		},
		selectAllResult: () => {
			return new Promise(resolve => {
				if (total === selected) {
					return resolve();
				}

				cbRef.current = resolve;
				setSelected(total);
				setSelectedIds([]);
				setSelectedIdMap({});
				setSelectAll(true);
			});
		},
		selectNone,
		reset: () => {
			selectNone();
			setTotal(0);
			setSelectAll(false);
		}
	};
};

const MultiselectProvider = ({ children }: PropsWithChildren<Props>) => {
	const data = useMultiselectData();
	return <MultiselectContext.Provider value={data}>{children}</MultiselectContext.Provider>;
};

export const withMultiselect = <P extends {}>(Component: React.ComponentType<P>) => {
	return (props: P) => {
		const data = useMultiselectData();
		return (
			<MultiselectContext.Provider value={data}>
				<Component {...props} multiselect={data} />
			</MultiselectContext.Provider>
		);
	};
};

export const withMultiselectForwardRef = <P extends {}>(Component: React.ComponentType<P>) => {
	const WithMultiselect = withMultiselect<P & { forwardedRef: ForwardedRef<unknown> }>(
		({ forwardedRef, ...props }) => (
			// eslint-disable-next-line react/jsx-key
			<Component ref={forwardedRef} {...(props as P)} />
		)
	);

	return React.forwardRef<unknown, P>((props, ref) => <WithMultiselect {...props} forwardedRef={ref} />);
};

export default MultiselectProvider;
