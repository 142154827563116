import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { FilterPreset, generateFilter, getLoggedInUserFilter } from '../filterGenerators';
import ProjectPlanPriorityResource from 'App/resources/ProjectPlanPriority';
import ProjectPlanStatusResource from 'App/resources/ProjectPlanStatus';
import ProjectPlanTypeResource from 'App/resources/ProjectPlanType';
import ProjectPlanTemplateResource from 'App/babel/resources/ProjectPlanTemplate';
import T from 'Components/Helpers/translate';
import UserRoleTree from 'Components/Helpers/UserRoleTree';

export default (): { [key: string]: FilterConfig } => ({
	Name: generateFilter(FilterPreset.Text, {
		field: 'name',
		title: 'default.name'
	}),
	User: generateFilter(
		FilterPreset.User,
		{
			field: 'user.id',
			title: 'defaultView.assignee',
			dataPromise: async () => ({
				data: [
					getLoggedInUserFilter(),
					{
						id: null,
						name: T('default.unassigned')
					},
					...UserRoleTree({ accessType: null })
				]
			})
		},
		null
	),
	StartDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'startDate',
			title: 'default.startDate'
		},
		'standard'
	),
	EndDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'endDate',
			title: 'default.dueDate'
		},
		'future'
	),
	Client: generateFilter(
		FilterPreset.ResourceLazyList,
		{
			field: 'client.id',
			title: 'default.account'
		},
		'client'
	),
	Contact: generateFilter(
		FilterPreset.ResourceLazyList,
		{
			field: 'contact.id',
			title: 'default.contact'
		},
		'contact'
	),
	ProjectPlanTemplate: generateFilter(FilterPreset.List, {
		field: 'projectPlanTemplate.id',
		title: 'default.projectPlanTemplate',
		inputType: 'select',
		dataPromise: () => ProjectPlanTemplateResource.find({ f: ['id', 'name'] })
	}),
	ProjectPlanStage: generateFilter(FilterPreset.List, {
		field: 'projectPlanStage.id',
		title: 'projectPlan.projectStage',
		inputType: 'select',
		dataPromise: async () => {
			return {
				data: [
					{ id: 1, title: 'To Do', name: 'To Do' },
					{ id: 2, title: 'In progress', name: 'In progress' },
					{ id: 3, title: 'Done', name: 'Done' }
				]
			};
		}
	}),
	ProjectPlanPriority: generateFilter(FilterPreset.List, {
		field: 'projectPlanPriority.id',
		title: 'default.priority',
		inputType: 'select',
		dataPromise: () => ProjectPlanPriorityResource.find({ f: ['id', 'name'] })
	}),
	ProjectPlanStatus: generateFilter(FilterPreset.List, {
		field: 'projectPlanStatus.id',
		title: 'default.status',
		inputType: 'select',
		dataPromise: () => ProjectPlanStatusResource.find({ f: ['id', 'name'] })
	}),
	ProjectPlanType: generateFilter(FilterPreset.List, {
		field: 'projectPlanType.id',
		title: 'default.type',
		inputType: 'select',
		dataPromise: () => ProjectPlanTypeResource.find({ f: ['id', 'name'] })
	}),
	ProjectPlanTypeCategory: generateFilter(FilterPreset.List, {
		field: 'projectPlanType.category',
		title: 'default.category',
		inputType: 'select',
		dataPromise: async () => {
			return {
				data: [
					{ name: T('projectPlanType.project'), id: 'PROJECT' },
					{ name: T('projectPlanType.service'), id: 'SERVICE' }
				]
			};
		}
	}),
	OpenTasks: generateFilter(FilterPreset.Range, {
		title: 'default.openTasks',
		field: 'openTasks'
	}),
	FinishedTasks: generateFilter(FilterPreset.Range, {
		title: 'default.finishedTasks',
		field: 'finishedTasks'
	})
});
