import {
	Block,
	Button,
	Flex,
	FullscreenModal,
	Help,
	Icon,
	Input,
	Label,
	Link,
	ModalContent,
	ModalHeader,
	NumberInput,
	Text,
	Toggle,
	Tooltip
} from '@upsales/components';
import FormObserver, {
	getCustomFieldModel,
	mapCustomValuesToArray,
	mapCustomValuesToObject
} from 'App/components/FormObserver';
import { removeItem } from 'App/babel/store/helpers/array';
import { SlideFade } from '@upsales/components/animations';
import { TaskTemplate } from 'App/resources/Model/ProjectPlanTemplate';
import { useCustomFields } from 'App/components/hooks/appHooks';
import { useSoftDeployAccess } from 'App/components/hooks/featureHelper';
import { v4 as uuidv4 } from 'uuid';
import BemClass from '@upsales/components/Utils/bemClass';
import CustomFields from 'App/components/CustomFields';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import ModalTagList from 'App/babel/components/Modals/ModalTagList';
import React, { useMemo, useState } from 'react';
import T from 'Components/Helpers/translate';
import UserRoleList from 'Components/Inputs/UserRoleList';

import './EditTaskTemplate.scss';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';

type Props = {
	className: string;
	close: (taskTemplate: TaskTemplate | null) => void;
	onClose: (taskTemplate: TaskTemplate | null) => void;
	isService: boolean;
	taskTemplate: TaskTemplate | null;
	type?: 'TODO' | 'PHONE_CALL';
	offsetEndDate: boolean;
};

function getNewTaskTemplate() {
	return {
		tempId: uuidv4(),
		name: '',
		active: true,
		users: [],
		roles: [],
		startDateOffset: 0,
		priority: false,
		custom: []
	} as unknown as TaskTemplate;
}

const TooltipHelpIcon = ({ tooltip }: { tooltip: string }) => (
	<Tooltip title={tooltip}>
		<Icon space="mlm" name="question-circle" />
	</Tooltip>
);

const EditTaskTemplate = (props: Props) => {
	const { className, close, offsetEndDate } = props;
	const type = props.taskTemplate?.type ?? props.type ?? 'TODO';
	const classNames = new BemClass('EditTaskTemplate', className);
	const hasRecurringTaskFeature = useSoftDeployAccess('RECURRING_TASKS');
	const hasNewProjectFields = useSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS');
	const isPhonecall = type === 'PHONE_CALL';

	const lang = useMemo(
		() => ({
			addTask: isPhonecall ? T('default.addPhonecall') : T('default.addTodo'),
			backToTaskList: T('admin.projectPlan.taskSettings.backToTaskList'),
			cancel: T('default.cancel'),
			editTask: isPhonecall ? T('default.editPhonecall') : T('default.editTodo'),
			markAsPriorised: T('activity.markAsImportant'),
			markAsRecurring: isPhonecall ? T('default.recurringPhonecall') : T('default.recurringTodo'),
			next: T('default.next'),
			offSetDueDate: T('admin.projectPlan.taskSettings.offSetDueDate'),
			offSetDueDateTooltip: T('admin.projectPlan.taskSettings.offSetDueDateTooltip'),
			pickATitleToSave: T('admin.newSalesProcess.pickATitleToSave'),
			priorityTooltip: T('admin.projectPlan.taskSettings.priorityTooltip'),
			recurringTaskInfo: T('admin.projectPlan.taskSettings.recurringTaskInfo'),
			recurringTaskInfoDueDate: T('admin.projectPlan.taskSettings.recurringTaskInfoDueDate'),
			pickAUserRoleToSave: T('admin.projectTaskTemplate.pickAUserRoleToSave'),
			save: T('default.save'),
			taskDueDays: isPhonecall
				? T('admin.projectPlan.taskSettings.phonecallDueDays')
				: T('admin.projectPlan.taskSettings.todoDueDays'),
			taskDueDaysStart: T('admin.projectPlan.taskSettings.taskDueDaysStart'),
			taskDueDaysEnd: T('admin.projectPlan.taskSettings.taskDueDaysEnd'),
			taskDueDaysRecurring: isPhonecall
				? T('admin.projectPlan.taskSettings.phonecallDueDaysRecurring')
				: T('admin.projectPlan.taskSettings.todoDueDaysRecurring'),
			taskDueDaysRecurring2: T('admin.projectPlan.taskSettings.taskDueDaysRecurring2'),
			taskRecurring: isPhonecall
				? T('admin.projectPlan.taskSettings.phonecallRecurring')
				: T('admin.projectPlan.taskSettings.todoRecurring'),
			taskRecurring2: T('admin.projectPlan.taskSettings.taskRecurring2'),
			taskTitle: T('default.title'),
			taskUserRoleInformation: T('admin.projectPlan.taskSettings.taskUserRoleInformation'),
			taskUserRoleInformationLink: T('admin.projectPlan.taskSettings.taskUserRoleInformationLink'),
			taskUserRoleSelection: isPhonecall
				? T('admin.projectPlan.taskSettings.phonecallUserRoleSelection')
				: T('admin.projectPlan.taskSettings.todoUserRoleSelection'),
			titlePlaceholder: T('admin.projectPlan.taskSettings.taskTitlePlaceholder'),
			showAvailableTags: T('admin.documentTemplate.standardTemplatesModal.showTags')
		}),
		[type]
	);

	const [tagListVisible, setTagListVisible] = useState(false);

	const [taskTemplate, setTaskTemplate] = useState(
		props.taskTemplate ? structuredClone(props.taskTemplate) : getNewTaskTemplate()
	);

	const [assignmentType, setAssignmentType] = useState<'users' | 'roles'>(
		taskTemplate.users.length ? 'users' : 'roles'
	);

	const setIsRecurring = (recurring: boolean) => {
		setTaskTemplate({
			...taskTemplate,
			isRecurring: recurring,
			recurringInterval: taskTemplate.recurringInterval ?? 14
		});
	};

	const setRecurringInterval = (recurringInterval: number) => {
		setTaskTemplate({ ...taskTemplate, recurringInterval: recurringInterval });
	};

	const changeName = (name: string) => {
		setTaskTemplate({ ...taskTemplate, name });
	};

	const changeDate = (numberOfDays: number) => {
		setTaskTemplate({ ...taskTemplate, startDateOffset: numberOfDays });
	};

	const changePriority = (priority: boolean) => {
		setTaskTemplate({ ...taskTemplate, priority });
	};

	const changeCustom = (custom: TaskTemplate['custom']) => {
		setTaskTemplate({ ...taskTemplate, custom });
	};

	const changeDescription = (description: string) => {
		setTaskTemplate({ ...taskTemplate, description: description.trimStart() });
	};

	const setSelectedUsersRoles = (objects: TaskTemplate['users'] | TaskTemplate['roles']) => {
		if (assignmentType === 'users') {
			setTaskTemplate({ ...taskTemplate, users: objects as TaskTemplate['users'], roles: [] });
		} else {
			setTaskTemplate({ ...taskTemplate, roles: objects as TaskTemplate['roles'], users: [] });
		}
	};
	const setToggledUsersRoles = (object: TaskTemplate['users'][0] | TaskTemplate['roles'][0]) => {
		if (assignmentType === 'users') {
			const typedObject = object as TaskTemplate['users'][0];
			const index = taskTemplate.users.findIndex(user => user.id === typedObject.id);
			if (index === -1) {
				setTaskTemplate({ ...taskTemplate, users: [...taskTemplate.users, typedObject], roles: [] });
			} else {
				setTaskTemplate({ ...taskTemplate, users: removeItem(taskTemplate.users, index), roles: [] });
			}
		} else {
			const typedObject = object as TaskTemplate['roles'][0];
			const index = taskTemplate.roles.findIndex(user => user.id === typedObject.id);
			if (index === -1) {
				setTaskTemplate({ ...taskTemplate, roles: [...taskTemplate.roles, typedObject], users: [] });
			} else {
				setTaskTemplate({ ...taskTemplate, roles: removeItem(taskTemplate.roles, index), users: [] });
			}
		}
	};

	const customFieldType = isPhonecall ? 'activity' : 'todo';
	const customFields = useCustomFields(customFieldType);

	const validatonModel = {
		custom: getCustomFieldModel(customFields, undefined, 'todo', true)
	};
	const initialCustomValues = {
		custom: mapCustomValuesToObject(taskTemplate.custom ?? [], customFields)
	};

	return (
		<FullscreenModal className={classNames.mod({ tagListVisible }).b()} headerAtTop>
			<ModalHeader title={props.taskTemplate === null ? lang.addTask : lang.editTask}>
				<EditorHeaderButton
					title={lang.cancel}
					onClick={() => close(null)}
					supertitle={undefined}
					className={undefined}
					noIcon={true}
					next={false}
				/>
				<Tooltip
					title={taskTemplate.name ? lang.pickAUserRoleToSave : lang.pickATitleToSave}
					disabled={!!taskTemplate.name}
				>
					<EditorHeaderButton
						title={lang.save}
						supertitle={lang.backToTaskList}
						className={undefined}
						disabled={!taskTemplate.name}
						onClick={() => close({ ...taskTemplate, type })}
						next={true}
						noIcon={false}
					/>
				</Tooltip>
			</ModalHeader>
			<ModalContent>
				<Block className={classNames.elem('taskInformation').b()}>
					<Block space="mtxl">
						<Label
							required
							maxLength={100}
							value={taskTemplate.name}
							maxLengthReachedText={T('default.characterLimitReached')}
						>
							{lang.taskTitle}
						</Label>
						<Input
							maxLength={100}
							placeholder={lang.titlePlaceholder}
							data-testid="taskTitle"
							onChange={event => changeName(event.target.value)}
							value={taskTemplate.name}
						/>
					</Block>
					{hasRecurringTaskFeature && !props.isService ? (
						<Flex space="mtxl">
							<Block>
								<Toggle
									checked={taskTemplate.isRecurring}
									onChange={() => setIsRecurring(!taskTemplate.isRecurring)}
								/>
							</Block>
							<Flex inline alignItems="baseline">
								<Text space="mlm">{lang.markAsRecurring}</Text>
								<TooltipHelpIcon tooltip={lang.recurringTaskInfo} />
							</Flex>
						</Flex>
					) : null}
					{!props.isService ? (
						<>
							<SlideFade
								visible={hasRecurringTaskFeature && taskTemplate.isRecurring}
								height
								maxHeight={40}
							>
								<Flex space="mtl" alignItems="baseline">
									<Text color="grey-11">{lang.taskRecurring}</Text>
									<NumberInput
										max={365}
										min={1}
										onChange={recurringInterval => setRecurringInterval(recurringInterval ?? 0)}
										value={taskTemplate.recurringInterval}
									/>
									<Text color="grey-11">{lang.taskRecurring2}</Text>
								</Flex>
							</SlideFade>
							<Block space="mtxl">
								<Flex inline alignItems="baseline">
									<Label>{lang.offSetDueDate}</Label>
									<TooltipHelpIcon
										tooltip={
											taskTemplate.isRecurring && hasRecurringTaskFeature
												? lang.recurringTaskInfoDueDate
												: lang.offSetDueDateTooltip
										}
									/>
								</Flex>
								<Flex alignItems="baseline">
									<Text color="grey-11">
										{taskTemplate.isRecurring && hasRecurringTaskFeature
											? lang.taskDueDaysRecurring
											: lang.taskDueDays}
									</Text>
									<NumberInput
										max={365}
										min={-365}
										data-testid="taskDueDays"
										onChange={startDateOffset => changeDate(startDateOffset ?? 0)}
										value={taskTemplate.startDateOffset}
									/>
									<Text color="grey-11">
										{taskTemplate.isRecurring && hasRecurringTaskFeature
											? lang.taskDueDaysRecurring2
											: offsetEndDate
											? lang.taskDueDaysEnd
											: lang.taskDueDaysStart}
									</Text>
								</Flex>
							</Block>
						</>
					) : null}
					<Flex space="mtxl">
						<Block>
							<Toggle
								checked={taskTemplate.priority}
								onChange={() => changePriority(!taskTemplate.priority)}
							/>
						</Block>
						<Flex inline alignItems="baseline">
							<Text space="mlm">{lang.markAsPriorised}</Text>
							<TooltipHelpIcon tooltip={lang.priorityTooltip} />
						</Flex>
					</Flex>
					{!props.isService ? (
						<Block space="mtxl">
							<Label>{lang.taskUserRoleSelection}</Label>
							<UserRoleList
								asIds={false}
								selectedUsersRoles={
									assignmentType === 'users' ? taskTemplate.users : taskTemplate.roles
								}
								setSelectedUsersRoles={setSelectedUsersRoles}
								toggleUserRole={setToggledUsersRoles}
								assignmentType={assignmentType}
								changeAssignmentType={setAssignmentType}
								userTypes="service"
							/>
							<Text space="mtm" color="grey-11">
								{lang.taskUserRoleInformation}
								<Help articleId={1505} sidebar>
									<Link>{lang.taskUserRoleInformationLink}</Link>
								</Help>
							</Text>
						</Block>
					) : null}
					{hasNewProjectFields ? (
						<Block space="mtxl">
							<NotesWithSignature value={taskTemplate.description ?? ''} onChange={changeDescription} />
						</Block>
					) : null}
				</Block>
				<Block space="mtxl">
					<FormObserver<{ custom: { [key: string]: string | null } }>
						validateOnMount={false}
						onChange={values => {
							const mappedCustom = mapCustomValuesToArray(values.custom);
							changeCustom(mappedCustom);
						}}
						model={validatonModel}
						initialValues={initialCustomValues}
					>
						{({ onFormChange, inputProps }) => (
							<CustomFields
								type={customFieldType}
								inputProps={inputProps}
								onChange={(id: number, value: string) => onFormChange(`custom.Custom_${id}`, value)}
								showFormGroupName={true}
								allFieldsOptional={true}
								allFieldsEditable={true}
							/>
						)}
					</FormObserver>
				</Block>
			</ModalContent>
			<Button
				className={classNames.elem('ModalTagListButton').b()}
				color="grey-13"
				onClick={() => setTagListVisible(true)}
				text={lang.showAvailableTags}
			/>
			<Button
				className={classNames.elem('ModalTagListButton').b()}
				color="grey-13"
				onClick={() => setTagListVisible(true)}
				text={lang.showAvailableTags}
			/>
			<ModalTagList entity={'taskTemplate'} onClose={() => setTagListVisible(false)} />
		</FullscreenModal>
	);
};

export default EditTaskTemplate;
