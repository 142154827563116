import ActivityResource from 'Resources/Activity';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

import Order from 'App/resources/Model/Order';
import OrderResource from 'App/resources/Order';
import OrderAttributes from 'App/babel/attributes/Order';
import Activity from 'App/resources/Model/Activity';
import Appointment from 'App/resources/Model/Appointment';
import ProjectPlanResource from 'Resources/ProjectPlan';
import type ProjectPlan from 'App/resources/Model/ProjectPlan';
import Ticket from 'App/resources/Model/Ticket';
import { AvailableRelationConfig } from 'App/resources/Model/EntityRelation';
import { default as TicketResource } from 'App/resources/Ticket';
import Agreement from 'App/resources/Model/Agreement';
import { default as AgreementResource } from 'Resources/Agreement';
import attributes from 'App/babel/attributes/Agreement';
import ComparisonTypes from 'Resources/ComparisonTypes';

const LIMIT = 10;

export type FetchPromises = [
	Promise<Order[]>,
	Promise<Appointment[]>,
	Promise<Activity[]>,
	Promise<ProjectPlan[]>,
	Promise<Ticket[]>,
	Promise<Agreement[]>
];
export type RelationPromises = [
	Promise<Order> | null,
	Promise<Appointment> | null,
	Promise<Activity> | null,
	Promise<ProjectPlan> | null,
	Promise<Ticket> | null,
	Promise<Agreement> | null
];

export const fetchOpportunities = async (
	search: string,
	userId: number,
	clientId: number,
	searchForOrders: boolean,
	searchForOpportunities: boolean
) => {
	const opportunityFilter = new RequestBuilder();
	if (searchForOpportunities && !searchForOrders) {
		opportunityFilter.addFilter(OrderAttributes.probability, comparisonTypes.LessThan, 100);
		opportunityFilter.addFilter(OrderAttributes.probability, comparisonTypes.GreaterThan, 0);
	} else if (!searchForOpportunities && searchForOrders) {
		opportunityFilter.addFilter(OrderAttributes.probability, comparisonTypes.Equals, [0, 100]);
	}
	if (search) {
		opportunityFilter.addFilter({ field: 'description' }, comparisonTypes.Search, search);
	}
	opportunityFilter.addFilter({ field: 'user.id' }, comparisonTypes.Equals, userId);
	opportunityFilter.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	opportunityFilter.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
	opportunityFilter.limit = LIMIT;

	const { data } = await OrderResource.find(opportunityFilter.build());
	return data;
};

export const fetchAppointments = async (search: string, userId: number, clientId: number) => {
	const { Appointment, AppService } = Tools;
	const rb = new RequestBuilder();
	if (search) {
		rb.addFilter({ field: 'description' }, comparisonTypes.Search, search);
	}
	rb.addFilter({ field: 'user.id' }, comparisonTypes.Equals, userId);
	rb.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);

	rb.limit = LIMIT;

	const { data } = await Appointment.customer(AppService.getCustomerId()).find(rb.build());
	return data;
};
export const fetchActivities = async (search: string, userId: number, clientId: number) => {
	const rb = new RequestBuilder();
	if (search) {
		rb.addFilter({ field: 'description' }, comparisonTypes.Search, search);
	}
	rb.addFilter({ field: 'user.id' }, comparisonTypes.Equals, userId);
	rb.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);

	rb.limit = LIMIT;

	const { data } = await ActivityResource.find(rb.build());
	return data;
};
export const fetchTickets = async (search: string, clientId: number) => {
	const rb = new RequestBuilder();
	if (search) {
		const orBuilder = rb.orBuilder();
		orBuilder.next();
		orBuilder.addFilter({ field: 'id' }, comparisonTypes.Search, search);
		orBuilder.next();
		orBuilder.addFilter({ field: 'title' }, comparisonTypes.Wildcard, search);
		orBuilder.done();
	}
	rb.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);

	rb.limit = LIMIT;

	const { data } = await TicketResource.find(rb.build());
	return data;
};

export const fetchProjectPlans = async (search: string, userId: number, clientId: number) => {
	const rb = new RequestBuilder();
	if (search) {
		const orBuilder = rb.orBuilder();
		orBuilder.next();
		orBuilder.addFilter({ field: 'name' }, comparisonTypes.Wildcard, search);
		orBuilder.next();
		orBuilder.addFilter({ field: 'client.name' }, comparisonTypes.Search, search);
		orBuilder.next();
		orBuilder.addFilter({ field: 'contact.name' }, comparisonTypes.Search, search);
		orBuilder.next();
		orBuilder.addFilter({ field: 'id' }, comparisonTypes.Search, search);
		orBuilder.done();
	}
	rb.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);

	rb.limit = LIMIT;

	const { data } = await ProjectPlanResource.find(rb.build());
	return data;
};

export const fetchAgreements = async (search: string, clientId: number) => {
	const rb = new RequestBuilder();
	if (search) {
		rb.addFilter({ field: 'description' }, comparisonTypes.Search, search);
	}
	rb.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
	rb.addFilter({ field: 'metadata.activeVersionId' }, comparisonTypes.Equals, null);

	// filter for active agreements only
	const orGroup = rb.orBuilder();
	orGroup.next();
	orGroup.addFilter(attributes.metadata.attr.agreementEnddate, ComparisonTypes.Equals, null);
	orGroup.next();
	orGroup.addFilter(attributes.metadata.attr.agreementEnddate, ComparisonTypes.GreaterThan, new Date());
	orGroup.done();
	rb.addFilter(attributes.metadata.attr.agreementStartdate, ComparisonTypes.LessThanEquals, new Date());

	rb.limit = LIMIT;

	const { data } = await AgreementResource.find(rb.build());
	return data;
};

export const fetch = (
	search: string,
	userId: number,
	clientId: number | null,
	availableEntities: AvailableRelationConfig = {
		order: false,
		opportunity: true,
		activity: true,
		appointment: true,
		projectPlan: true,
		ticket: true,
		agreement: true
	}
) => {
	const promises: FetchPromises = !clientId
		? [
				Promise.resolve([]),
				Promise.resolve([]),
				Promise.resolve([]),
				Promise.resolve([]),
				Promise.resolve([]),
				Promise.resolve([])
		  ]
		: [
				availableEntities.opportunity || availableEntities.order
					? fetchOpportunities(
							search,
							userId,
							clientId,
							availableEntities.order,
							availableEntities.opportunity
					  )
					: Promise.resolve([]),
				availableEntities.appointment ? fetchAppointments(search, userId, clientId) : Promise.resolve([]),
				availableEntities.activity ? fetchActivities(search, userId, clientId) : Promise.resolve([]),
				availableEntities.projectPlan ? fetchProjectPlans(search, userId, clientId) : Promise.resolve([]),
				Tools.FeatureHelper.hasSoftDeployAccess('TICKETS_RELATE_TASKS_AND_TICKETS') && availableEntities.ticket
					? fetchTickets(search, clientId)
					: Promise.resolve([]),
				Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_RELATE_TO_TASK_SUPPORT') &&
				availableEntities.agreement
					? fetchAgreements(search, clientId)
					: Promise.resolve([])
		  ];
	return makeCancelable(Promise.all(promises));
};

export const getOpportunity = async (opportunityId: number) => {
	const { data } = await OrderResource.get(opportunityId);
	return data;
};

export const getAppointment = async (appointmentId: number) => {
	const { Appointment, AppService } = Tools;
	const { data } = await Appointment.customer(AppService.getCustomerId()).get(appointmentId);
	return data;
};

export const getActivity = async (activityId: number) => {
	const { data } = await ActivityResource.get(activityId);
	return data;
};

export const getProjectPlan = async (projectPlanId: number) => {
	const { data } = await ProjectPlanResource.get(projectPlanId);
	return data;
};
export const getTicket = async (ticketId: number) => {
	const { data } = await TicketResource.get(ticketId);
	return data;
};

export const getAgreement = async (agreementId: number) => {
	const { data } = await AgreementResource.get(agreementId);
	return data;
};

export const getRelations = (
	opportunityId?: number | null,
	appointmentId?: number | null,
	activityId?: number | null,
	projectPlanId?: number | null,
	ticketId?: number | null,
	agreementId?: number | null
): CancelablePromise => {
	const promises: RelationPromises = [
		opportunityId ? getOpportunity(opportunityId) : null,
		appointmentId ? getAppointment(appointmentId) : null,
		activityId ? getActivity(activityId) : null,
		projectPlanId ? getProjectPlan(projectPlanId) : null,
		ticketId ? getTicket(ticketId) : null,
		agreementId ? getAgreement(agreementId) : null
	];
	return makeCancelable(Promise.all(promises));
};
