import { type StructuredAddress, type AddressWithLatLong } from './Model/Geocode';
import Resource from 'Resources/Resource';

class Geocode extends Resource {
	constructor() {
		super('geocode');
	}

	lookupAddress(address: StructuredAddress): Promise<{ data: AddressWithLatLong }> {
		return super.get('address', { params: address });
	}

	lookupLatLong(latitude: number, longitude: number): Promise<{ data: AddressWithLatLong }> {
		return super.get('latLong', { params: { latitude, longitude } });
	}
}

const resource = new Geocode();

export default resource;
