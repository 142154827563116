import type CustomerPortfolioPotential from 'App/resources/Model/CustomerPortfolioPotential';
import ResourceTyped from './ResourceTyped';

export interface SimilarCustomer {
	id: number;
	name: string;
	sniBucket: number;
	sniCode: string;
	sniTwoDigitBucket: number;
	takeRate: number;
	totalSales: number;
	turnoverBucket: number;
}

export interface PotentialUpsellTableResponse {
	data: {
		companies: SimilarCustomer[];
		industryStats: {
			industryAverageTotalSales: number;
		};
		metadata: {
			total: number;
			limit: number;
			offset: number;
		};
	};
}

class CustomerPortfolioPotentialResource extends ResourceTyped<CustomerPortfolioPotential> {
	constructor() {
		super('customerPortfolioPotential');
	}

	findSimilarUpsellCustomers(
		clientId: number,
		takeRatePotentialPercentage: number,
		valueType: string
	): Promise<PotentialUpsellTableResponse> {
		return this._getRequest(
			`${clientId}?takeRatePotentialPercentage=${takeRatePotentialPercentage}&valueType=${valueType}`
		);
	}
}

const resource = new CustomerPortfolioPotentialResource();

export default resource;
