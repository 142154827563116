import React from 'react';
import PropTypes from 'prop-types';
import Button from '@upsales/components/Button';

export default class GroupSizeColumn extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			viewGroup: t('account.showGroup'),
			openingGroup: t('account.openingGroup'),
			commercialDescription: t('account.relations.groupSize.commercialDescription2')
		};

		this.openGroup = this.openGroup.bind(this);
	}

	openGroup(e) {
		e.stopPropagation();
		this.props.actions.openGroup(this.props.account, this.props.dataSourceId);
	}

	render() {
		if (this.props.account.merging) {
			return <td />;
		}

		const hasGroupTree =
			this.props.dataSourceId === 'prospecting'
				? Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO)
				: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.GROUP_TREE);

		const isOpening =
			this.props.companyGroup &&
			this.props.companyGroup.openingGroup &&
			this.props.companyGroup.groupAccount[this.props.idField] === this.props.account[this.props.idField];

		if(this.props.account.groupSize === null) {
			return (
				<td className="DataSourceColumn group-size-column">
					<span className="grey-text">
						{'-'}
					</span>
				</td>
			);
		}

		return (
			<td className="DataSourceColumn group-size-column">
				{isOpening && (
					<span className="grey-text">
						<i className="fa fa-spinner fa-spin" /> {this.lang.openingGroup}
					</span>
				)}
				{!hasGroupTree &&
					this.props.account.groupSize > 1 &&
					ReactTemplates.TOOLS.withTooltip(
						<span>
							<i className="fa fa-sitemap" /> {this.props.account.groupSize}
						</span>,
						this.lang.commercialDescription,
						{ placement: 'left', distance: 20 }
					)}
				{!isOpening && (hasGroupTree || this.props.account.groupSize <= 1) && (
					<span className={this.props.account.groupSize > 1 ? 'hide-on-row-hover' : ''}>
						<i className="fa fa-sitemap" /> {this.props.account.groupSize}
					</span>
				)}
				{hasGroupTree && !isOpening && this.props.account.groupSize > 1 && (
					<span className="show-on-row-hover">
						<i className="fa fa-sitemap" />{' '}
						<Button className="btn-inline" type="link" color="bright-blue" onClick={this.openGroup}>
							{this.lang.viewGroup}
						</Button>
					</span>
				)}
			</td>
		);
	}
}

GroupSizeColumn.propTypes = {
	actions: PropTypes.object,
	account: PropTypes.object,
	companyGroup: PropTypes.object,
	idField: PropTypes.string,
	dataSourceId: PropTypes.string
};
