import React, { useMemo } from 'react';
import moment from 'moment-business-days';
import { Block, Flex, Help, Icon, Link, ModalContent, ModalControls, Text, Title } from '@upsales/components';
import { useEditProjectPlanContext } from 'Components/EditProjectPlan/Context';
import { DefaultButton, PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import t from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import OutsideClick from '@upsales/components/OutsideClick';

type PropsUser = {
	setUser: (user: { id: number; name: string; title: string; keepTasks?: boolean }) => void;
};

type PropsDate = {
	setStartDate: (startDate: Date) => void;
	setEndDate: (endDate: Date) => void;
};

type Props = {
	show: boolean;
	className?: string;
	confirm: () => void;
	altConfirm?: () => void;
	cancel: () => void;
	title: string;
	buttonText: string;
	altButtonText?: string;
	child?: React.ReactNode;
	helpText?: string;
	articleId?: number;
};

export const AfterSelectPopup = ({
	show,
	className,
	confirm,
	altConfirm,
	cancel,
	child,
	title,
	buttonText,
	altButtonText,
	helpText,
	articleId
}: Props) => {
	if (!show) {
		return null;
	}
	const classes = new BemClass('AfterSelectPopup', className);

	return (
		<OutsideClick
			outsideClick={() => {
				cancel();
			}}
			targetClass={'AfterSelectPopup'}
		>
			<ModalContent className={classes.mod({ altConfirm: !!altButtonText }).b()}>
				<Title>{title}</Title>
				{child ?? null}
				{helpText && articleId && (
					<Block space="mtxl">
						<Help articleId={articleId} sidebar>
							<Link>
								<Icon space="mrs" name="question-circle" />
								{helpText}
							</Link>
						</Help>
					</Block>
				)}
				<ModalControls>
					<PrimaryButton onClick={confirm}>{buttonText}</PrimaryButton>
					{altConfirm && altButtonText ? (
						<DefaultButton onClick={altConfirm} className={classes.elem('alt-button').b()}>
							{altButtonText}
						</DefaultButton>
					) : null}
					<ThirdButton onClick={cancel} className={classes.elem('cancel-button').b()}>
						{t('default.cancel')}
					</ThirdButton>
				</ModalControls>
			</ModalContent>
		</OutsideClick>
	);
};

export const AfterSelectDatePopup = ({ setStartDate, setEndDate }: PropsDate) => {
	const { state, setTempStartDate } = useEditProjectPlanContext();
	const classes = new BemClass('AfterSelectPopup');

	const projectPlan = state.projectPlan!;
	const tasks = state.tasks;
	const tempStartDate = state.tempStartDate!;

	const newStart = moment(tempStartDate);
	const oldStart = moment(projectPlan.startDate);
	const businessDaysDiff = newStart.businessDiff(oldStart);
	let postponedStartDate = '-';
	let newEndDate: Date;

	if (newStart.isAfter(moment(projectPlan.startDate))) {
		postponedStartDate = '+';
		newEndDate = moment(projectPlan.endDate).businessAdd(businessDaysDiff, 'days').toDate();
	} else {
		newEndDate = moment(projectPlan.endDate).businessSubtract(businessDaysDiff, 'days').toDate();
	}

	const confirm = () => {
		setStartDate(tempStartDate);
		setTempStartDate(null);
		setTimeout(() => setEndDate(newEndDate), 0);
	};

	const cancel = () => {
		setTempStartDate(null);
	};

	const openTodos = tasks.filter(task => task.closeDate === null);

	const lang = useMemo(
		() => ({
			cancel: t('default.cancel'),
			moveProjectStartDateText: t('projectPlan.moveProjectStartDateText'),
			buttonText: t('projectPlan.moveProjectPlanChangeDateBtn'),
			moveProjectEndDateText: t('projectPlan.moveProjectEndDateText'),
			moveProjectTodosText: t('projectPlan.moveProjectTodosText'),
			helpText: t('projectPlan.moveProjectPlanHelp'),
			title: t('projectPlan.moveProjectPlanTitle')
		}),
		[]
	);

	const childText = () => {
		return (
			<Block space="mtl">
				<Flex space="mtm">
					<Text space="mrs" color="grey-11" bold>
						{lang.moveProjectStartDateText}
					</Text>
					<Text color="grey-11">
						{t('projectPlan.moveProjectStartDateData', {
							newStartDate: moment(tempStartDate).format('L'),
							postponedStartDate,
							businessDaysDiff
						})}
					</Text>
				</Flex>
				<Flex space="mtm">
					<Text space="mrs" color="grey-11" bold>
						{lang.moveProjectEndDateText}
					</Text>
					<Text color="grey-11">
						{t('projectPlan.moveProjectEndDateData', {
							newEndDate: moment(newEndDate).format('L'),
							postponedStartDate,
							businessDaysDiff
						})}
					</Text>
				</Flex>
				<Flex space="mtm">
					<Text space="mrs" color="grey-11" bold>
						{openTodos.length} {lang.moveProjectTodosText}
					</Text>
					<Text color="grey-11">
						{postponedStartDate}
						{t('projectPlan.moveProjectTodosData', { businessDaysDiff })}
					</Text>
				</Flex>
			</Block>
		);
	};

	return (
		<AfterSelectPopup
			show={tempStartDate !== null}
			className={classes.mod({ StartDate: true }).b()}
			confirm={confirm}
			cancel={cancel}
			child={childText()}
			title={lang.title}
			buttonText={lang.buttonText}
			helpText={lang.helpText}
			articleId={1503}
		/>
	);
};

export const AfterSelectEndDatePopup = ({ setStartDate, setEndDate }: PropsDate) => {
	const { state, setTempEndDate } = useEditProjectPlanContext();
	const classes = new BemClass('AfterSelectPopup');

	const projectPlan = state.projectPlan!;
	const tasks = state.tasks;
	const tempEndDate = state.tempEndDate!;

	const newEnd = moment(tempEndDate);
	const oldEnd = moment(projectPlan.endDate);
	const businessDaysDiff = newEnd.businessDiff(oldEnd);
	let postponedEndDate = '-';

	if (newEnd.isAfter(moment(projectPlan.endDate))) {
		postponedEndDate = '+';
	}

	const confirm = () => {
		setEndDate(tempEndDate);
		setTempEndDate(null);
	};

	const cancel = () => {
		setTempEndDate(null);
	};

	const openTodos = tasks.filter(task => task.closeDate === null);

	const lang = useMemo(
		() => ({
			cancel: t('default.cancel'),
			moveProjectStartDateText: t('projectPlan.moveProjectStartDateText'),
			buttonText: t('projectPlan.moveProjectPlanChangeDateBtn'),
			moveProjectEndDateText: t('projectPlan.moveProjectEndDateText'),
			moveProjectTodosText: t('projectPlan.moveProjectTodosText'),
			helpText: t('projectPlan.moveProjectPlanHelp'),
			title: t('projectPlan.moveProjectPlanEndDateTitle.noStartDate')
		}),
		[]
	);

	const childText = () => {
		return (
			<Block space="mtl">
				<Flex space="mtm">
					<Text space="mrs" color="grey-11" bold>
						{lang.moveProjectEndDateText}
					</Text>
					<Text color="grey-11">
						{t('projectPlan.moveProjectEndDateData', {
							newEndDate: moment(tempEndDate).format('L'),
							postponedStartDate: postponedEndDate,
							businessDaysDiff
						})}
					</Text>
				</Flex>
				<Flex space="mtm">
					<Text space="mrs" color="grey-11" bold>
						{openTodos.length} {lang.moveProjectTodosText}
					</Text>
					<Text color="grey-11">
						{postponedEndDate}
						{t('projectPlan.moveProjectTodosData', { businessDaysDiff })}
					</Text>
				</Flex>
			</Block>
		);
	};

	return (
		<AfterSelectPopup
			show={tempEndDate !== null}
			className={classes.mod({ StartDate: true }).b()}
			confirm={confirm}
			cancel={cancel}
			child={childText()}
			title={lang.title}
			buttonText={lang.buttonText}
			helpText={lang.helpText}
			articleId={1503}
		/>
	);
};

export const AfterSelectUserPopup = ({ setUser }: PropsUser) => {
	const { state, setTempUser } = useEditProjectPlanContext();
	const classes = new BemClass('AfterSelectPopup');

	const projectPlan = state.projectPlan!;
	const tempUser = state.tempUser!;

	const confirm = (keepTasks?: boolean) => {
		setUser({ ...tempUser, keepTasks });
		setTempUser(null);
	};

	const cancel = () => {
		setTempUser(null);
	};

	const lang = useMemo(
		() => ({
			buttonText: t('projectPlan.moveTasksToUserConfirm'),
			altButtonText: t('projectPlan.keepTasks'),
			title: t('projectPlan.moveTasksToUser', {
				toUser: tempUser?.name,
				fromUser: projectPlan.user?.name
			})
		}),
		[]
	);

	return (
		<AfterSelectPopup
			show={tempUser !== null}
			className={classes.mod({ User: true }).b()}
			confirm={() => {
				confirm(false);
			}}
			altConfirm={() => {
				confirm(true);
			}}
			altButtonText={lang.altButtonText}
			cancel={cancel}
			title={lang.title}
			buttonText={lang.buttonText}
		/>
	);
};
