import {
	PERFORMANCE_STATUS,
	getChartConfig,
	getCompetitorColor,
	PERFORMANCE_LEVELS,
	getRandomTitle,
	getLoadingChartConfig,
	formatCurrency
} from '../Helpers/Helpers';
import MarketShareTable, { CompanyMarketShare } from '../MarketShareTable/MarketShareTable';
import { AssistChip, Flex, Icon, Text, Title, Tooltip, Select, Help } from '@upsales/components';
import { DefaultNoDataAsTable } from 'App/components/ListView/DefaultNoData';
import React, { useEffect, useRef, useState } from 'react';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import KeyFiguresTable from '../KeyFiguresTable/KeyFiguresTable';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import ClientIndustryResource from 'Resources/ClientIndustry';
import BemClass from '@upsales/components/Utils/bemClass';
import { useIndustryInsights } from '../Context/Context';
import Highcharts, { SeriesOptions } from 'highcharts';
import Client from 'App/resources/Model/Client';
import EmptyData from '../EmptyData/EmptyData';
import T from 'Components/Helpers/translate';
import './IndustryInsightsAccount.scss';
import logError from 'Helpers/logError';

interface TimeSeriesData {
	dateFrom: string;
	dateTo: string;
	ruleOfForty: number;
	ruleOfFortyMedian: number;
	ruleOfFortyUpperQuartile: number;
	ruleOfFortyLowerQuartile: number;
	bucketRanges: {
		minRev: number;
		maxRev: number;
	};
}

interface ClientIndustryResponse {
	importDate: string;
	similarCompaniesCount: number;
	timeSeries: TimeSeriesData[];
}

interface CompetitorMetrics {
	name: string;
	data: {
		dateFrom: string;
		dateTo: string;
		ruleOfForty: number;
	}[];
}

interface KeyFiguresMetrics {
	client: {
		name: string;
		metrics: {
			growth: number;
			operatingMargin: number;
			netDebtEbitdaRatio: number;
			netDebt: number;
			ebitda: number;
			employmentRate: number;
			netSalesPerEmployee: number;
		};
	};
	industry: {
		metrics: {
			growth: number;
			operatingMargin: number;
			netDebt: number;
			employmentRate: number;
			netSalesPerEmployee: number;
		};
		outliers: {
			count: number;
			totalSamples: number;
			highestValue: number;
			lowestValue: number;
		};
	};
}

interface MarketCompetitor {
	id: number;
	journeyStep: string | undefined;
	prospectingId: string | null;
	name: string;
	revenue: number;
	marketShare: number;
	marketShareChange: number;
}
interface MarketShareMetrics {
	clientRevenue: number;
	clientMarketShare: number;
	clientMarketShareChange: number;
	competitors: MarketCompetitor[];
}

type Props = {
	client: Client;
	code: string;
	setImportDate: (date: string) => void;
	close: () => void;
};

type PerformanceStatusWithLabel = (typeof PERFORMANCE_STATUS)[keyof typeof PERFORMANCE_STATUS] & {
	label: string;
};

const IndustryInsightsAccount = ({ client, code, setImportDate, close }: Props) => {
	const classes = new BemClass('IndustryInsightsAccount');
	const anchor = '.Modals';
	const { bucketRange, setBucketRange, description } = useIndustryInsights();

	const [loading, setLoading] = useState<boolean>(true);
	const [performanceTitle, setPerformanceTitle] = useState<string>('');
	const [similarCompaniesCount, setSimilarCompaniesCount] = useState<number>(0);
	const [timeSeriesData, setTimeSeriesData] = useState<TimeSeriesData[]>([]);
	const lang = {
		highestPerformer: T('ai.industryInsights.highestPerformer'),
		aboveAveragePerformer: T('ai.industryInsights.aboveAveragePerformer'),
		averagePerformer: T('ai.industryInsights.averagePerformer'),
		underperformer: T('ai.industryInsights.underperformer'),
		highestPerformerGraph: T('ai.industryInsights.highestPerformerGraph'),
		averagePerformerGraph: T('ai.industryInsights.averagePerformerGraph'),
		underperformerGraph: T('ai.industryInsights.underperformerGraph'),
		performerGraphTitle: T('ai.industryInsights.performerGraphTitle'),
		companyGrowthComparison: T('ai.industryInsights.companyGrowthComparison'),
		similarCompaniesCount: T('default.similarCompaniesCount', { count: similarCompaniesCount }),
		inIndustry: T('default.inIndustry'),
		similarCompaniesTooltip: T('ai.industryInsights.similarCompaniesTooltip', {
			value: bucketRange ? `${formatCurrency(bucketRange.minRev)} - ${formatCurrency(bucketRange.maxRev)}` : '',
			industry: description
		}),
		lastThreeYears: T('date.prev3Year'),
		lastFiveYears: T('date.prev5Year'),
		lastTenYears: T('date.prev10Year'),
		allYears: T('date.prevAvailableYear'),
		howItWorks: T('ai.industryInsights.howItWorks')
	};

	const YEAR_RANGE_OPTIONS = [
		{ years: 3, key: 'lastThreeYears' as keyof typeof lang },
		{ years: 5, key: 'lastFiveYears' as keyof typeof lang },
		{ years: 10, key: 'lastTenYears' as keyof typeof lang }
	];

	const [yearRange, setYearRange] = useState(YEAR_RANGE_OPTIONS[1].years);
	const [availableYears, setAvailableYears] = useState<number[]>([]);
	const [selectedYearOption, setSelectedYearOption] = useState<SelectItem>({
		id: 5,
		title: lang.lastFiveYears
	});

	const getYearRangeOptions = (totalYears: number): SelectItem[] => {
		const options: SelectItem[] = [];

		for (const option of YEAR_RANGE_OPTIONS) {
			if (totalYears > option.years) {
				options.push({ id: option.years, title: lang[option.key] });
			} else if (totalYears === option.years) {
				options.push({ id: totalYears, title: lang.allYears });
			}
		}

		// Add "all years" option if total years exceeds max defined range
		const maxDefinedYears = Math.max(...YEAR_RANGE_OPTIONS.map(opt => opt.years));
		if (totalYears > maxDefinedYears) {
			options.push({ id: totalYears, title: lang.allYears });
		}

		return options;
	};

	const [performance, setPerformance] = useState<PerformanceStatusWithLabel>({
		...PERFORMANCE_STATUS.AVERAGE,
		label: lang.averagePerformer
	});

	const chartRef = useRef<HTMLDivElement>(null);
	const companyDataRequest = useRef<null | CancelablePromise<{ data: ClientIndustryResponse }>>(null);
	const competitorDataRequest = useRef<null | CancelablePromise<{ data: CompetitorMetrics[] }>>(null);
	const marketShareRequest = useRef<null | CancelablePromise<{ data: any }>>(null);
	const keyFiguresRequest = useRef<null | CancelablePromise<{ data: any }>>(null);

	const [marketData, setMarketData] = useState<{
		client: CompanyMarketShare;
		competitors: CompanyMarketShare[];
	}>({
		client: {
			id: client.id,
			journeyStep: getJourneyStep(client?.journeyStep)?.name,
			prospectingId: client.prospectingId ?? null,
			name: client.name,
			revenue: 0,
			marketShare: 0,
			marketShareChange: 0
		},
		competitors: []
	});

	const [keyFigures, setKeyFigures] = useState<{
		client: { metrics: any };
		industry: { metrics: any; outliers: any };
	}>({
		client: { metrics: {} },
		industry: { metrics: {}, outliers: {} }
	});

	const getPerformanceWithLabel = (status: (typeof PERFORMANCE_STATUS)[keyof typeof PERFORMANCE_STATUS]) => ({
		...status,
		label:
			status === PERFORMANCE_STATUS.TOP_PERFORMER
				? lang.highestPerformer
				: status === PERFORMANCE_STATUS.ABOVE_AVERAGE
				? lang.aboveAveragePerformer
				: status === PERFORMANCE_STATUS.AVERAGE
				? lang.averagePerformer
				: lang.underperformer
	});

	const filterDataByYearRange = (data: any[], years: number) => {
		if (!data.length) return data;
		return data.slice(-years);
	};

	const processYearlyData = (data: any[], years: string[]) => {
		// Return array of values aligned with years array
		// If a year has no data but there's data before and after, use null to allow connecting
		// If there's no data before or after, use undefined to prevent connecting
		const result = years.map(year => {
			const dataPoint = data.find(d => new Date(d.dateFrom).getFullYear().toString() === year);
			if (dataPoint) {
				return dataPoint.ruleOfForty;
			}
			return null;
		});
		return result;
	};

	const processData = (
		companyDataResponse: { data: ClientIndustryResponse },
		competitorResponse: { data: CompetitorMetrics[] },
		keyFiguresResponse: { data: KeyFiguresMetrics },
		marketShareResponse: { data: MarketShareMetrics }
	) => {
		const timeSeries = companyDataResponse?.data?.timeSeries || [];
		setTimeSeriesData(timeSeries);

		// Process time series data
		if (timeSeries && competitorResponse?.data && chartRef.current) {
			const { importDate, similarCompaniesCount: companiesCount } = companyDataResponse.data;
			const years = timeSeries.map(d => new Date(d.dateFrom).getFullYear());
			setAvailableYears(years);

			const filteredData = filterDataByYearRange(timeSeries, yearRange);
			const filteredYears = filteredData.map(d => new Date(d.dateFrom).getFullYear().toString());
			const yearOption = {
				id: yearRange,
				title: YEAR_RANGE_OPTIONS.find(opt => opt.years === yearRange)?.key
					? lang[YEAR_RANGE_OPTIONS.find(opt => opt.years === yearRange)!.key]
					: lang.allYears
			};
			const latestData = filteredData[filteredData.length - 1];
			const latestPerformanceYear = new Date(latestData.dateFrom).getFullYear();
			const currentValue = latestData.ruleOfForty;
			const upperQuartile = latestData.ruleOfFortyUpperQuartile;
			const median = latestData.ruleOfFortyMedian;
			const lowerQuartile = latestData.ruleOfFortyLowerQuartile;
			const top3Competitors = competitorResponse?.data?.length
				? competitorResponse.data.map((competitor, index) => {
						const yearData = processYearlyData(competitor.data, filteredYears);

						return {
							name: competitor.name,
							type: 'spline',
							data: yearData,
							color: getCompetitorColor(index),
							visible: false,
							zIndex: 4 + index,
							marker: {
								symbol: 'diamond'
							}
						} as SeriesOptions;
				  })
				: [];
			const performanceSeries = PERFORMANCE_LEVELS.map(
				level =>
					({
						name: lang[level.name],
						type: 'areaspline',
						data: filteredData.map(d => {
							const hasAllValues =
								d.ruleOfFortyUpperQuartile != null &&
								d.ruleOfFortyMedian != null &&
								d.ruleOfFortyLowerQuartile != null;
							return hasAllValues ? d[level.dataKey] : null;
						}),
						color: level.color,
						zIndex: level.zIndex,
						threshold: null,
						fillColor: {
							linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
							stops: [
								[0, `${level.color}80`],
								[1, `${level.color}33`]
							]
						}
					} as unknown as SeriesOptions) // threshold: null is apparently causing TS error, hence the unknown cast
			);
			const options = getChartConfig();
			(options.xAxis as Highcharts.AxisOptions).categories = filteredYears;

			// Set performance status and title
			if (currentValue >= upperQuartile) {
				const newPerformance = getPerformanceWithLabel(PERFORMANCE_STATUS.TOP_PERFORMER);
				setPerformance(newPerformance);
				setPerformanceTitle(
					T(getRandomTitle('TOP_PERFORMER'), { client: client.name, year: latestPerformanceYear })
				);
			} else if (currentValue >= median) {
				const newPerformance = getPerformanceWithLabel(PERFORMANCE_STATUS.ABOVE_AVERAGE);
				setPerformance(newPerformance);
				setPerformanceTitle(
					T(getRandomTitle('ABOVE_AVERAGE'), { client: client.name, year: latestPerformanceYear })
				);
			} else if (currentValue >= lowerQuartile) {
				const newPerformance = getPerformanceWithLabel(PERFORMANCE_STATUS.AVERAGE);
				setPerformance(newPerformance);
				setPerformanceTitle(T(getRandomTitle('AVERAGE'), { client: client.name, year: latestPerformanceYear }));
			} else {
				const newPerformance = getPerformanceWithLabel(PERFORMANCE_STATUS.UNDERPERFORMER);
				setPerformance(newPerformance);
				setPerformanceTitle(
					T(getRandomTitle('UNDERPERFORMER'), {
						client: client.name,
						year: latestPerformanceYear
					})
				);
			}

			options.series = [
				...performanceSeries,
				{
					name: client.name,
					type: 'spline',
					data: filteredData.map(d => d.ruleOfForty || null),
					color: '#000000',
					zIndex: 4,
					marker: {
						symbol: 'circle'
					}
				} as SeriesOptions,
				...top3Competitors
			];

			Highcharts.chart(chartRef.current, options);
			setImportDate(importDate);
			setBucketRange(latestData.bucketRange);
			setSimilarCompaniesCount(companiesCount);
			setSelectedYearOption(yearOption);
		}

		if (keyFiguresResponse?.data) {
			// Check if we have any non-null metrics data
			const hasClientMetrics = Object.values(keyFiguresResponse.data.client?.metrics || {}).some(
				value => value !== null
			);
			const hasIndustryMetrics = Object.values(keyFiguresResponse.data.industry?.metrics || {}).some(
				value => value !== null
			);

			if (hasClientMetrics || hasIndustryMetrics) {
				setKeyFigures(keyFiguresResponse.data);
			}
		}

		if (marketShareResponse?.data) {
			setMarketData({
				client: {
					id: client.id,
					journeyStep: getJourneyStep(client?.journeyStep)?.name,
					prospectingId: client.prospectingId ?? null,
					name: client.name,
					revenue: marketShareResponse.data.clientRevenue,
					marketShare: marketShareResponse.data.clientMarketShare,
					marketShareChange: marketShareResponse.data.clientMarketShareChange
				},
				competitors: marketShareResponse.data.competitors.map((comp: MarketCompetitor) => ({
					id: comp.id,
					journeyStep: comp.id ? getJourneyStep(comp?.journeyStep)?.name : undefined,
					prospectingId: comp.prospectingId,
					name: comp.name,
					revenue: comp.revenue,
					marketShare: comp.marketShare,
					marketShareChange: comp.marketShareChange
				}))
			});
		}
	};

	// TODO: This together with the other resource calls we do should probably be moved to their own separate component,
	// this will be done when we add this data to the company financials page in client card
	const refreshMarketData = () => {
		const useSNI = Tools.FeatureHelper.hasSoftDeployAccess('AI_CLIENT_INDUSTRY_SNI');
		const group = !useSNI ? code.substring(0, 3) : undefined;

		const request = makeCancelable(ClientIndustryResource.getMarketShareData(client.id, group));
		marketShareRequest.current = request;

		request.promise
			.then(marketShare => {
				if (marketShare?.data) {
					setMarketData({
						client: {
							id: client.id,
							journeyStep: getJourneyStep(client?.journeyStep)?.name,
							prospectingId: client.prospectingId ?? null,
							name: client.name,
							revenue: marketShare.data.clientRevenue,
							marketShare: marketShare.data.clientMarketShare,
							marketShareChange: marketShare.data.clientMarketShareChange
						},
						competitors: marketShare.data.competitors.map((comp: MarketCompetitor) => ({
							id: comp.id,
							journeyStep: comp.id ? getJourneyStep(comp?.journeyStep)?.name : undefined,
							prospectingId: comp.prospectingId,
							name: comp.name,
							revenue: comp.revenue,
							marketShare: comp.marketShare,
							marketShareChange: comp.marketShareChange
						}))
					});
				}
			})
			.catch((err: Error) => {
				logError(err, 'Failed to refresh market data');
			});
	};

	useEffect(() => {
		const shouldSkipDataFetch = !client?.id || !client?.prospectingId;
		if (shouldSkipDataFetch) {
			setLoading(false);
			return;
		}

		const useSNI = Tools.FeatureHelper.hasSoftDeployAccess('AI_CLIENT_INDUSTRY_SNI');
		const group = !useSNI ? code.substring(0, 3) : undefined;

		if (loading && chartRef.current) {
			const loadingOptions = getLoadingChartConfig({
				clientName: client.name,
				lang,
				yearRange
			});
			Highcharts.chart(chartRef.current, loadingOptions);
		}

		const requests = {
			companyData: makeCancelable(ClientIndustryResource.getClientIndustryStatistics(client.id, group)),
			competitors: makeCancelable(ClientIndustryResource.getCompetitorRuleOfForty(client.id, group)),
			keyFigures: makeCancelable(ClientIndustryResource.getKeyFigures(client.id, group)),
			marketShare: makeCancelable(ClientIndustryResource.getMarketShareData(client.id, group))
		};

		companyDataRequest.current = requests.companyData;
		competitorDataRequest.current = requests.competitors;
		keyFiguresRequest.current = requests.keyFigures;
		marketShareRequest.current = requests.marketShare;

		Promise.all([
			requests.companyData.promise,
			requests.competitors.promise,
			requests.keyFigures.promise,
			requests.marketShare.promise
		])
			.then(([companyData, competitors, keyFigures, marketShare]) => {
				processData(companyData, competitors, keyFigures, marketShare);
			})
			.catch((err: Error) => {
				logError(err, 'Failed to load data');
			})
			.finally(() => {
				setLoading(false);
			});

		return () => {
			Object.values(requests).forEach(request => request.cancel());
		};
	}, [client.prospectingId, client.name, client.id, yearRange]);

	const hasTimeSeriesData = timeSeriesData.some(data => {
		const { ruleOfFortyMedian, ruleOfFortyUpperQuartile, ruleOfFortyLowerQuartile } = data;
		return [ruleOfFortyMedian, ruleOfFortyUpperQuartile, ruleOfFortyLowerQuartile].every(value => value != null);
	});

	const hasKeyFiguresData = Object.keys(keyFigures?.client?.metrics || {}).length > 0;
	const hasMarketShareData = marketData?.competitors?.length > 0;

	if (!loading && !hasTimeSeriesData && !hasKeyFiguresData && !hasMarketShareData) {
		return <DefaultNoDataAsTable subtitle={T('noMatches.reason')} formatNoData={() => T('default.noResults')} />;
	}

	const renderChart = () => {
		if (loading) {
			return (
				<Flex className={classes.elem('chartWidgetContainer').b()} gap="u2">
					<Flex
						className={classes.elem('chartWidget').b()}
						direction="column"
						space="ptl prl pbl pll"
						gap="u4"
					>
						<Flex justifyContent="space-between" alignItems="center">
							<Flex alignItems="center" justifyContent="space-between" flex={1}>
								<Flex gap="u2" alignItems="center">
									<Text loading={loading} bold>
										{lang.companyGrowthComparison} {lang.similarCompaniesCount}{' '}
										{lang.inIndustry.toLowerCase()}
									</Text>
									<Tooltip title={lang.similarCompaniesTooltip}>
										<Icon name="info-circle" loading={loading} />
									</Tooltip>
								</Flex>
								<Text loading={loading} size="sm" />
							</Flex>
						</Flex>
						<Flex flex={1} ref={chartRef} className={classes.elem('chart').b()} />
					</Flex>
				</Flex>
			);
		}

		if (!hasTimeSeriesData) {
			return (
				<Flex className={classes.elem('chartWidgetContainer').b()} gap="u2">
					<Flex
						className={classes.elem('chartWidget').b()}
						direction="column"
						space="ptl prl pbl pll"
						gap="u4"
					>
						<EmptyData />
					</Flex>
				</Flex>
			);
		}

		return (
			<Flex className={classes.elem('chartWidgetContainer').b()} gap="u2">
				<Flex className={classes.elem('chartWidget').b()} direction="column" space="ptl prl pbl pll" gap="u4">
					<Flex alignContent="space-between" justifyContent="center" direction="column">
						<Flex alignItems="center" justifyContent="space-between" flex={1}>
							<Flex gap="u2" alignItems="center">
								<Text loading={loading} bold>
									{lang.companyGrowthComparison} {lang.similarCompaniesCount}{' '}
									{lang.inIndustry.toLowerCase()}
								</Text>
								<Tooltip title={lang.similarCompaniesTooltip}>
									<Icon name="info-circle" loading={loading} />
								</Tooltip>
							</Flex>
							<Select
								className={classes.elem('yearSelect').b()}
								anchor={anchor}
								size="sm"
								showSearch={false}
								value={selectedYearOption}
								onChange={option => {
									setSelectedYearOption(option);
									setYearRange(Number(option.id));
								}}
								options={getYearRangeOptions(availableYears.length)}
							/>
						</Flex>
						<Flex>
							<Help articleId={1557}>
								<Text size="sm" color="bright-blue">
									{lang.howItWorks}
								</Text>
							</Help>
						</Flex>
					</Flex>
					<Flex flex={1} ref={chartRef} className={classes.elem('chart').b()} />
				</Flex>
			</Flex>
		);
	};

	return (
		<Flex direction="column" gap="u4" className={classes.b()}>
			<Title bold size="lg" loading={loading}>
				{hasTimeSeriesData
					? T(performanceTitle, { client: client.name, year: new Date().getFullYear() })
					: T('default.noResultsForClientWithType', { client: client.name, type: T('default.e40') })}
			</Title>
			<Flex className={classes.elem('pendoWrapper').b()} justifyContent="space-between">
				<Tooltip title={lang.performerGraphTitle}>
					<AssistChip
						title={
							hasTimeSeriesData
								? performance.label
								: loading
								? T('default.loadingEllipsis')
								: T('default.noResults')
						}
						type={hasTimeSeriesData ? performance.type : 'default'}
						icon={hasTimeSeriesData ? performance.icon : undefined}
					/>
				</Tooltip>
			</Flex>
			{renderChart()}
			<KeyFiguresTable
				hasKeyFiguresData={hasKeyFiguresData}
				clientName={client.name}
				loading={loading}
				metrics={keyFigures.client.metrics}
				industryMetrics={keyFigures.industry.metrics}
				outliers={keyFigures.industry.outliers}
			/>
			<MarketShareTable
				hasMarketShareData={hasMarketShareData}
				clientData={marketData.client}
				competitors={marketData.competitors}
				loading={loading}
				close={close}
				onRefresh={refreshMarketData}
			/>
		</Flex>
	);
};

export default IndustryInsightsAccount;
