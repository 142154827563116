import {
	Table,
	TableRow,
	TableColumn,
	Text,
	TableHeader,
	Flex,
	AssistChip,
	Tooltip,
	Icon,
	TableHeaderColumn
} from '@upsales/components';
import { roundNumber } from 'App/pages/CompanyGroupCard/Sidebar/UpsellWidget/UpsellWidget';
import { DisplayValueType } from 'App/pages/CustomerPortfolio/CustomerPortfolio';
import { NumberFormat } from 'App/babel/utils/numberFormat';
import { formatCurrency, formatTakeRate } from './Helpers';
import BemClass from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Model/Client';
import { useMetadata } from '../hooks/appHooks';
import T from 'Components/Helpers/translate';
import React from 'react';
import './ComparisonTable.scss';

interface Props {
	clientName: string;
	loading: boolean;
	valueType: DisplayValueType;
	industryStats: {
		industryAverageTotalSales: number;
	};
	clientData: {
		client: Client;
		growth: number | undefined;
		takeRatePotentialPercentage: number;
		takeRatePotential: number;
		takeRate: number;
		minTurnover: number;
		maxTurnover: number;
		upsellPotential: number;
	};
}

interface TableRowData {
	value: string | React.ReactElement;
	color?: 'danger-4' | 'success-6';
}

interface TableRowInterface {
	label: string;
	clientValue: TableRowData;
	similarValue: TableRowData;
	highlight?: boolean;
}

const ComparisonTable = ({ clientName, loading, valueType, clientData, industryStats }: Props) => {
	const classes = new BemClass('ComparisonTable');
	const metadata = useMetadata();
	const currency = metadata?.defaultCurrency || { iso: 'SEK', rate: 1 } || 'SEK';

	const mapValueTypeToGrowthKeys = () => {
		switch (valueType) {
			case 'annualValue':
			case 'monthlyValue':
				return 'arr';
			case 'contributionMargin':
				return 'cmLast12Months';
			default:
				return 'salesLast12Months';
		}
	};

	const growthValue = clientData.client?.growth ? clientData.client.growth[mapValueTypeToGrowthKeys()] : 0;
	const potential = roundNumber(clientData.upsellPotential) * currency.rate;
	const potentialToGrowthRatio = (potential / growthValue) * 100;
	const numberFormat = new NumberFormat();

	function round(num: number) {
		return Math.round(num / 10) * 10;
	}

	const potentialPercent = numberFormat.default(round(potentialToGrowthRatio));

	const lang = {
		similarCustomers: T('default.similarCustomers'),
		shareOfWallet: T('default.shareOfWallet'),
		potentialUpsell: T('default.potentialUpsell'),
		revenue: T('account.revenue'),
		accountGrowthArr: T('accountGrowth.column.arr'),
		accountGrowthMrr: T('accountGrowth.column.mrr'),
		accountGrowthCm: T('accountGrowth.column.cmLast12months'),
		accountGrowthSales: T('accountGrowth.column.salesLast12months'),
		similarCustomersTooltip: T('ai.industryInsights.similarCompaniesTooltip', {
			value:
				clientData.minTurnover && clientData.maxTurnover
					? `${formatCurrency(clientData.minTurnover)} - ${formatCurrency(clientData.maxTurnover)}`
					: '',
			industry: T(`default.sniCode.${clientData.client.sniCode}`)
		})
	};

	const rows = [
		{
			label:
				valueType === 'annualValue'
					? lang.accountGrowthArr
					: valueType === 'monthlyValue'
					? lang.accountGrowthMrr
					: valueType === 'contributionMargin'
					? lang.accountGrowthCm
					: lang.accountGrowthSales,
			clientValue: {
				value: formatCurrency(clientData?.growth || 0)
			},
			similarValue: {
				value: formatCurrency(industryStats.industryAverageTotalSales)
			}
		},
		{
			label: lang.revenue,
			clientValue: {
				value: formatCurrency(clientData?.client.turnover || 0)
			},
			similarValue: {
				value: `${formatCurrency(clientData.minTurnover)} - ${formatCurrency(clientData.maxTurnover)}`
			}
		},
		{
			label: lang.shareOfWallet,
			clientValue: {
				value: formatTakeRate(clientData?.takeRate || 0),
				color: 'danger-4'
			},
			similarValue: {
				value: formatTakeRate(clientData.takeRatePotentialPercentage)
			}
		},
		{
			label: lang.potentialUpsell,
			clientValue: {
				value: (
					<Flex gap="u2">
						{formatCurrency(clientData.upsellPotential) +
							(valueType === 'annualValue' || valueType === 'monthlyValue'
								? ` (${lang.accountGrowthArr}) `
								: '')}
						{growthValue > 0 ? <AssistChip type="success" title={'+' + potentialPercent + '%'} /> : null}
					</Flex>
				),
				color: 'success-6'
			},
			similarValue: {
				value: ''
			}
		}
	] satisfies TableRowInterface[];

	if (loading) {
		return (
			<Table className={classes.elem('table').b()}>
				{[...Array(3)].map((_, i) => (
					<TableRow key={`loading-${i}`}>
						{[...Array(3)].map((_, j) => (
							<TableColumn key={`loading-${i}-${j}`}>
								<Text loading size="sm" />
							</TableColumn>
						))}
					</TableRow>
				))}
			</Table>
		);
	}

	return (
		<Flex className={classes.b()}>
			<Table>
				<TableHeader>
					<TableHeaderColumn>
						<Text></Text>
					</TableHeaderColumn>
					<TableHeaderColumn>
						<Text size="sm" color="grey-11">
							{clientName}
						</Text>
					</TableHeaderColumn>
					<TableHeaderColumn>
						<Flex gap="u1" alignItems="center">
							<Text size="sm" color="grey-11">
								{lang.similarCustomers}
							</Text>
							<Tooltip title={lang.similarCustomersTooltip}>
								<Icon name="question-circle" loading={loading} />
							</Tooltip>
						</Flex>
					</TableHeaderColumn>
				</TableHeader>
				{rows.map((row, index) => (
					<TableRow className={classes.mod(`comparison-row-${index}`).b()} key={`comparison-row-${index}`}>
						<TableColumn>
							<Text>{row.label}</Text>
						</TableColumn>
						<TableColumn>
							<Text color={row.clientValue.color}>{row.clientValue.value}</Text>
						</TableColumn>
						<TableColumn>
							<Text>{row.similarValue.value}</Text>
						</TableColumn>
					</TableRow>
				))}
			</Table>
		</Flex>
	);
};

export default ComparisonTable;
