import { useFieldTranslations } from 'App/components/FieldTranslations/FieldTranslationsContext';
import { Button, Card, DropDownMenu, Icon, Text, Tooltip } from '@upsales/components';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import { useOrderRelationActive } from 'App/components/hooks/appHooks';
import { getClientRelationName } from 'Store/selectors/AppSelectors';
import AgreementGroup from 'App/resources/Model/AgreementGroup';
import AgreementGroupResource from 'Resources/AgreementGroup';
import { OldOrNewAgreement } from '../../SubscriptionCards';
import BemClass from '@upsales/components/Utils/bemClass';
import Client, { ClientIdName } from 'App/resources/Model/Client';
import { DocumentTemplate } from 'App/resources/AllIWant';
import { Feature } from 'Store/actions/FeatureHelperActions';
import Agreement from 'App/resources/Model/Agreement';
import ToolTipOrderRows from '../../ToolTipOrderRows';
import React, { useEffect, useState } from 'react';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import { useSelector } from 'react-redux';
import DeleteGroup from '../DeleteGroup';
import logError from 'Helpers/logError';
import { RootState } from 'Store/index';
import moment from 'moment';

import './CardActions.scss';

type Props = {
	createDocument: (documentTemplates: DocumentTemplate[], agreementId: number, clientId: number) => void;
	setOpen: (cb: (prev: boolean) => boolean) => void;
	documentTemplates: DocumentTemplate[];
	agreementGroup: OldOrNewAgreement;
	hasDropDown: boolean;
	hideActions?: boolean;
	client: ClientIdName | Client;
	hasMRR: boolean;
	open: boolean;
};

const CardActions = ({
	agreementGroup,
	documentTemplates,
	hasMRR,
	setOpen,
	createDocument,
	open,
	hasDropDown,
	hideActions,
	client
}: Props) => {
	const [deleting, setDeleting] = useState(false);
	const metadata = useSelector(({ App }: RootState) => App.metadata);
	const orderRelationActive = useOrderRelationActive();

	let deletePromise: CancelablePromise<Awaited<ReturnType<typeof AgreementGroupResource.delete>>> | undefined;
	const removeSubscription = async (
		agreement: Agreement,
		agreementGroupId?: number,
		agreementGroup?: OldOrNewAgreement
	) => {
		setDeleting(true);
		try {
			if (agreementGroupId) {
				deletePromise = makeCancelable(AgreementGroupResource.delete(agreementGroupId, agreementGroup));
			} else {
				deletePromise = makeCancelable(
					Tools.Agreement.customer(Tools.AppService.getCustomerId()).delete(agreement)
				);
			}
			await deletePromise.promise;
		} catch (e) {
			logError(e, 'Faild to delete agreement');
		} finally {
			setDeleting(false);
		}
	};

	useEffect(() => {
		return () => deletePromise?.cancel();
	}, []);
	const classes = new BemClass('CardActions');

	const currentAgreement = agreementGroup.currentAgreement;
	const hasSubaccountsV1 = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2');
	const hasSubaccountsFeature = useFeatureAvailable(Feature.SUB_ACCOUNTS) && hasSubaccountsV2;
	const isSubAccount = client.id !== currentAgreement.client.id && currentAgreement.client.operationalAccount;

	const isRelated = !isSubAccount && client.id !== currentAgreement.client.id && orderRelationActive;

	let clientRelationName = '';
	const clientOrderRelationTranslations = useFieldTranslations('clientorderrelation');
	if (isRelated) {
		clientRelationName = getClientRelationName(
			metadata,
			currentAgreement?.client,
			// connectedClients does not exist on ClientIdName but that is okay typescript
			(client as Client).connectedClients,
			clientOrderRelationTranslations,
			true
		);
	}
	const canCreateCopy = Tools.FeatureHelper.hasSoftDeployAccess('AGREEMENT_GROUP_CARDS_COPY');
	const dontWait = Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS');

	let orderRows = currentAgreement.orderRow;
	if (currentAgreement.children?.length) {
		const currentChild = [...currentAgreement.children]
			.reverse()
			.find(child => moment().isSameOrAfter(moment(child.startDate)));
		if (currentChild) {
			orderRows = currentChild.orderRow;
		}
	}

	return (
		<div className={classes.mod({ hideActions }).b()}>
			{!hideActions ? (
				<>
					{(isSubAccount && hasSubaccountsV1 && !hasSubaccountsFeature) || isRelated ? (
						<Tooltip
							title={T(
								isSubAccount
									? 'subscription.card.subaccount.tooltip'
									: 'default.clientConnection.tooltip',
								{
									company: currentAgreement.client.name,
									relation: clientRelationName || T('default.clientConnection').toLowerCase()
								}
							)}
						>
							<div className={classes.elem('relation').b()} data-testid="relation-icon">
								<Icon color="grey-11" name={isSubAccount ? 'subaccounts' : 'sitemap'} />
							</div>
						</Tooltip>
					) : null}
					{canCreateCopy || documentTemplates.length ? (
						<DropDownMenu
							className={classes.elem('action', classes.elem('dropdownMenuActions').b()).b()}
							align="right"
							renderTrigger={(expanded, setExpanded) => (
								<Button type="link" onClick={setExpanded}>
									<Icon name="ellipsis-h" />
								</Button>
							)}
							useAnimation
							noMinWidth
						>
							{close => (
								<Card className={classes.elem('dropdownActions').b()}>
									{canCreateCopy ? (
										<Button
											type="link"
											onClick={e => {
												e.stopPropagation();
												openModal('EditSubscription', {
													agreementGroupId: agreementGroup.id,
													agreement: agreementGroup.id ? undefined : currentAgreement,
													agreementId: currentAgreement.id,
													createCopy: true,
													dontWait
												});
												close();
											}}
										>
											<Icon color="grey-11" name="copy" space="mrm" />
											<Text>{T('default.copy')}</Text>
										</Button>
									) : null}
									<Button
										type="link"
										onClick={e => {
											e.stopPropagation();
											openModal('MoveSubscription', {
												agreementGroup: agreementGroup as AgreementGroup,
												show: true,
												animate: true
											});
											close();
										}}
									>
										<Icon color="grey-11" name="arrow-right" space="mrm" />
										<Text>{T('subscription.card.move')}</Text>
									</Button>
									{documentTemplates.length ? (
										<Button
											type="link"
											onClick={e => {
												e.stopPropagation();
												const {
													id: agreementId,
													client: { id: clientId }
												} = currentAgreement;
												createDocument(documentTemplates, agreementId, clientId);
												close();
											}}
										>
											<Icon color="grey-11" name="file" space="mrm" />

											<Text>{T('default.createDocument')}</Text>
										</Button>
									) : null}
								</Card>
							)}
						</DropDownMenu>
					) : null}

					<ToolTipOrderRows
						hasMRR={hasMRR}
						interval={currentAgreement.metadata.agreementIntervalPeriod}
						orderRows={orderRows}
						currency={currentAgreement.currency}
						currencyRate={currentAgreement.currencyRate}
					>
						<div className={classes.elem('action').b()}>
							<Icon color="grey-11" name="cubes" />
						</div>
					</ToolTipOrderRows>
					{currentAgreement.userRemovable ? (
						<Tooltip title={T('default.delete')}>
							<div className={classes.elem('action').b()} onClick={e => e.stopPropagation()}>
								<DeleteGroup
									removeSubscription={removeSubscription}
									agreementGroup={agreementGroup}
									deleting={deleting}
								/>
							</div>
						</Tooltip>
					) : null}
				</>
			) : null}
			<div className={classes.elem('divider').b()} />
			<div
				onClick={
					hasDropDown
						? e => {
								e.stopPropagation();
								setOpen(prev => !prev);
						  }
						: undefined
				}
				className={classes.elem('action').mod({ disabled: !hasDropDown }).b()}
			>
				<Icon
					name="chevron-down"
					className={classes.elem('dropDown').mod({ rotate: open }).b()}
					color="grey-11"
				/>
			</div>
		</div>
	);
};

export default CardActions;
