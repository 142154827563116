import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import FilterService from 'App/services/FilterService';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { ListView, ListViewFilter } from 'App/resources/AllIWant';
import CustomField from 'App/resources/Model/CustomField';
import ProjectBoard from 'App/resources/Model/ProjectBoard';

type Props = {
	onVisibleChange: (visible: boolean) => void;
	filterConfigs: { [name: string]: FilterConfig };
	activeFilters: { [filterName: string]: ListViewFilter };
	opts?: { noTabs?: boolean; alwaysOpen?: boolean };
	onChange: (filters: { [filterName: string]: ListViewFilter }) => void;
	hiddenFilters?: string[];
	hasChanged: boolean;
	listType?: string;
	customFields?: CustomField[];
	relatedEntities?: string[];
	selectedView?: ListView | ProjectBoard | null;
	onViewChange?: (view: ListView | ProjectBoard, opts?: { fromSave: boolean }) => void;
};

const getVisibleFilters = (filterConfigs: Props['filterConfigs'], hiddenFilters: string[]) => {
	return Object.keys(filterConfigs).reduce((res, key) => {
		if (!hiddenFilters.includes(key)) {
			res[key] = filterConfigs[key];
		}
		return res;
	}, {} as Props['filterConfigs']);
};

const getHiddenFiltersValue = (activeFilters: Props['activeFilters'], hiddenFilters: string[]) => {
	return Object.keys(activeFilters).reduce((res, key) => {
		if (hiddenFilters.includes(key)) {
			res[key] = activeFilters[key];
		}
		return res;
	}, {} as Props['activeFilters']);
};

const getVisibleFiltersValue = (activeFilters: Props['activeFilters'], hiddenFilters: string[]) => {
	return Object.keys(activeFilters).reduce((res, key) => {
		if (!hiddenFilters.includes(key)) {
			res[key] = activeFilters[key];
		}
		return res;
	}, {} as Props['activeFilters']);
};

export default ({
	onVisibleChange,
	filterConfigs,
	activeFilters,
	onChange,
	hasChanged,
	hiddenFilters = ['Labels'],
	customFields = [],
	listType,
	relatedEntities,
	selectedView,
	onViewChange,
	opts
}: Props) => {
	const { customerId } = useSelector(({ App }: RootState) => App);
	const filterService = useMemo(
		() =>
			new FilterService(
				customerId,
				getVisibleFilters(filterConfigs, hiddenFilters),
				customFields,
				relatedEntities
			),
		[filterConfigs, hiddenFilters, customFields, relatedEntities]
	);
	if (!Object.keys(filterService.filterConfigs).length && !opts?.alwaysOpen) {
		return null;
	}
	return (
		<div id="up-list-filters">
			<ReactTemplates.upFilters.filterMenu
				onVisibleChange={onVisibleChange}
				opts={opts}
				tableType={listType}
				activeFilters={getVisibleFiltersValue(activeFilters, hiddenFilters)}
				filterService={filterService}
				onChange={(filter: ListViewFilter, options: { action?: string }) => {
					if (options.action === 'remove' && activeFilters[filter.filterName]) {
						const filteredFilters = { ...activeFilters };
						delete filteredFilters[filter.filterName];
						onChange(filteredFilters);
					} else {
						onChange({ ...activeFilters, [filter.filterName]: filter });
					}
				}}
				onClear={() => {
					// Make sure to not clear hidden filters
					onChange(getHiddenFiltersValue(activeFilters, hiddenFilters));
				}}
				hasChanged={hasChanged}
				onViewChange={onViewChange}
				selectedView={selectedView}
			/>
		</div>
	);
};
