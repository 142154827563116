import React from 'react';
import ProjectMapUserFilter from './ProjectMapUserFilter';
import ProjectMapDateFilter from './ProjectMapDateFilter';

const ProjectMapQuickFilters = () => {
	return (
		<>
			<ProjectMapDateFilter />
			<ProjectMapUserFilter />
		</>
	);
};

export default ProjectMapQuickFilters;
