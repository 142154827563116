import React from 'react';
import type ProjectPlan from 'App/resources/Model/ProjectPlan';
import { AssistChip, Tooltip } from '@upsales/components';
import t from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import './ProjectPlanChip.scss';

type SupportedProjectPlanAttributes = 'status' | 'priority' | 'stage' | 'type' | 'tasks';

type ProjectPlanInBoard = Collect<
	Pick<ProjectPlan, 'projectPlanPriority' | 'projectPlanStatus' | 'openTasks' | 'finishedTasks'>
>;

type SupportedProjectPlan = ProjectPlan | ProjectPlanInBoard;

const mapToChipType = (projectPlan: SupportedProjectPlan, type: SupportedProjectPlanAttributes) => {
	if (!projectPlan) {
		return 'default';
	}
	if (type === 'status') {
		switch (projectPlan.projectPlanStatus?.category) {
			case 'ON_TRACK':
				return 'success';
			case 'NEEDS_ATTENTION':
				return 'alert';
			case 'AT_RISK':
				return 'danger';
			default:
				return 'default';
		}
	} else if (type === 'priority') {
		switch (projectPlan.projectPlanPriority?.category) {
			case 'LOW':
				return 'default';
			case 'MEDIUM':
				return 'alert';
			case 'HIGH':
				return 'danger';
			default:
				return 'default';
		}
	} else if (type === 'tasks') {
		return projectPlan.openTasks ? 'default' : 'success';
	} else if (type === 'type' && 'projectPlanType' in projectPlan) {
		switch (projectPlan.projectPlanType?.category) {
			case 'SERVICE':
				return 'info';
			default:
				return 'default';
		}
	}
	return 'default';
};

const mapToChipTitle = (projectPlan: SupportedProjectPlan, type: SupportedProjectPlanAttributes) => {
	if (!projectPlan) {
		return '';
	}
	if (type === 'status') {
		return projectPlan.projectPlanStatus.name ?? '';
	}
	if (type === 'tasks') {
		return `${projectPlan.finishedTasks}/${projectPlan.finishedTasks + projectPlan.openTasks}`;
	}
	if (type === 'priority') {
		if (projectPlan?.projectPlanPriority?.isDefault) {
			return t(projectPlan.projectPlanPriority.name);
		}
		return projectPlan.projectPlanPriority.name ?? '';
	}
	if (type === 'stage' && 'projectPlanStage' in projectPlan) {
		return t(projectPlan.projectPlanStage?.name ?? '');
	}
	if (type === 'type' && 'projectPlanType' in projectPlan) {
		return projectPlan.projectPlanType?.name ?? '';
	}
	return '';
};

type ProjectPlanChipProps = {
	projectPlan: ProjectPlanInBoard;
	attribute: SupportedProjectPlanAttributes;
	tooltip?: string;
};
const ProjectPlanChip = ({ projectPlan, attribute, tooltip }: ProjectPlanChipProps) => {
	const classes = new BemClass('ProjectPlanChip');
	if (!projectPlan) {
		return null;
	}
	return (
		<Tooltip title={tooltip || t(`default.${attribute}`)}>
			<AssistChip
				className={classes.mod({ [attribute]: true }).b()}
				title={mapToChipTitle(projectPlan, attribute)}
				type={mapToChipType(projectPlan, attribute)}
			/>
		</Tooltip>
	);
};

export default ProjectPlanChip;
