import { useFeatureAvailable, useSoftDeployAccess } from '../hooks';
import { useTranslation } from 'Components/Helpers/translate';
import { Feature } from 'Store/actions/FeatureHelperActions';
import SignalsBar from './components/SignalsBar/SignalsBar';
import BemClass from '@upsales/components/Utils/bemClass';
import AISignalsResource from 'App/resources/AISignals';
import AISignals from 'App/resources/Model/AISignals';
import Contact from 'App/resources/Model/Contact';
import { Flex, Text } from '@upsales/components';
import Client from 'App/resources/Model/Client';
import { openDrawer } from 'Services/Drawer';
import React, { useEffect } from 'react';

import './RelationsSignalWidget.scss';

type RelationsSignalWidgetProps = {
	clientId: number;
	client?: Client;
	contactId?: number;
	contact?: Contact;
};

const SignalsWidget = ({ clientId, contactId, contact, client }: RelationsSignalWidgetProps) => {
	const classes = new BemClass('RelationsSignalWidget');
	const [signalScore, setSignalScore] = React.useState<AISignals | null>(null);
	const { t } = useTranslation();

	useEffect(() => {
		const fetchSignalScore = async () => {
			const reqContactId = contactId || contact?.id;
			const { data } = await AISignalsResource.getEntityTotalSignals(
				reqContactId ? 'contact' : 'client',
				reqContactId || clientId
			);
			setSignalScore(data);
		};

		fetchSignalScore();
	}, [clientId, contactId]);

	const hasSignals = signalScore && (signalScore.positive || signalScore.negative);

	return !hasSignals ? null : (
		<Flex
			className={classes.b()}
			gap="u4"
			justifyContent="space-between"
			alignItems="center"
			onClick={() => {
				openDrawer('RelationsSignalDrawer', { clientId, contactId: contactId || contact?.id });
			}}
		>
			<Text className={classes.elem('relationText').b()} bold>
				{contactId ? t('default.contactRelation') : t('default.clientRelation')}
			</Text>
			<div className={classes.elem('signals').b()}>
				<SignalsBar signals={signalScore} periodText={`(${t('default.lastNMonths', { months: 6 })})`} />
			</div>
		</Flex>
	);
};

const RelationsSignalWidget = ({ clientId, contactId, contact, client }: RelationsSignalWidgetProps) => {
	const hasAISignalsWidget = useSoftDeployAccess('AI_SIGNALS_WIDGET');
	const hasAI = useFeatureAvailable(Feature.AI);
	return !(hasAISignalsWidget && hasAI) ? null : (
		<SignalsWidget clientId={clientId} contactId={contactId} contact={contact} client={client} />
	);
};

export default RelationsSignalWidget;
