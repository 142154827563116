import CustomerPortfolioPotential from 'App/resources/Model/CustomerPortfolioPotential';
import CustomerPortfolioPotentialResource from 'Resources/CustomerPortfolioPotential';
import { DisplayValueType } from 'App/pages/CustomerPortfolio/CustomerPortfolio';
import { Drawer, DrawerHeader, Flex, Icon, Text, Title, Tooltip } from '@upsales/components';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import ComparisonTable from './ComparisonTable';
import { DefaultNoDataAsTable } from '../ListView/DefaultNoData';
import React, { useState, useRef, useEffect } from 'react';
import { formatCurrency, formatTakeRate } from './Helpers';
import ToplistTable, { ToplistData } from './ToplistTable';
import { ModalProps } from 'App/components/Modals/Modals';
import BemClass from '@upsales/components/Utils/bemClass';
import { CancelablePromise } from 'Helpers/promise';
import './CustomerPortfolioPotentialDrawer.scss';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';

type Props = {
	upsellClientData: CustomerPortfolioPotential;
	upsellPotential: number;
	valueType: DisplayValueType;
	salesModel: string;
} & ModalProps;

const CustomerPortfolioPotentialDrawer = ({
	className,
	close,
	upsellClientData,
	upsellPotential,
	valueType,
	salesModel
}: Props) => {
	const client = upsellClientData.client as Client;
	const classes = new BemClass('CustomerPortfolioPotentialDrawer', className);
	const [loading, setLoading] = useState(true);
	const [toplistData, setToplistData] = useState<ToplistData[]>([]);
	const [industryStats, setIndustryStats] = useState({
		industryAverageTotalSales: 0
	});
	const getPotentialsPromise = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof CustomerPortfolioPotentialResource.findSimilarUpsellCustomers>>
	>>(null);

	const getGrowth = (valueType: DisplayValueType) => {
		switch (valueType) {
			case 'annualValue':
				return client.growth?.arr;
			case 'monthlyValue':
				return client.growth?.mrr;
			case 'contributionMargin':
				return client.growth?.cmLast12Months;
			default:
				return client.growth?.salesLast12Months;
		}
	};
	const clientData = {
		client,
		growth: getGrowth(valueType),
		takeRatePotentialPercentage: upsellClientData.takeRatePotentialPercentage,
		takeRatePotential: upsellClientData.takeRatePotential,
		takeRate: upsellClientData.clientTakeRate,
		minTurnover: upsellClientData.minTurnover,
		maxTurnover: upsellClientData.maxTurnover,
		upsellPotential
	};

	const getMetricInfo = (valueType: DisplayValueType): { metric: string } => {
		switch (valueType) {
			case 'annualValue':
				return { metric: T('default.annualValue') };
			case 'monthlyValue':
				return { metric: T('default.monthlyValue') };
			case 'contributionMargin':
				return { metric: T('default.contributionMargin').toLowerCase() };
			default:
				return { metric: T('default.sales').toLowerCase() };
		}
	};

	const { metric } = getMetricInfo(valueType);
	const lang = {
		upsellPotentialHeaderTitle: T('default.upsellPotentialHeaderTitle', { name: client.name }),
		upsellPotentialContentTitle: T('default.upsellPotentialContentTitle', {
			metric,
			value: formatCurrency(upsellPotential)
		}),
		upsellPotentialContentDescription: T('default.upsellPotentialContentDescription', {
			value: formatTakeRate(upsellClientData.takeRatePotentialPercentage)
		}),
		potentialUpsell: T('default.potentialUpsell'),
		potentialUpsellTableTitle: T('default.potentialUpsellTableTitle'),
		similarCustomersTooltip: T('ai.industryInsights.similarCompaniesTooltip', {
			value:
				clientData.minTurnover && clientData.maxTurnover
					? `${formatCurrency(clientData.minTurnover)} - ${formatCurrency(clientData.maxTurnover)}`
					: '',
			industry: T(`default.sniCode.${clientData.client.sniCode}`)
		})
	};

	useEffect(() => {
		setLoading(true);
		const promise = makeCancelable(
			CustomerPortfolioPotentialResource.findSimilarUpsellCustomers(
				client.id,
				upsellClientData.takeRatePotentialPercentage,
				valueType
			)
		);
		getPotentialsPromise.current = promise;

		promise.promise
			.then(response => {
				if (!response?.data?.companies?.length) {
					setToplistData([]);
					setIndustryStats({
						industryAverageTotalSales: 0
					});
				}
				setToplistData(response.data.companies);
				setIndustryStats(response.data.industryStats);
			})
			.catch(err => {
				if (!err.isCanceled) {
					logError('Failed to fetch potentials:', err);
					setToplistData([]);
				}
			})
			.finally(() => {
				setLoading(false);
			});

		return () => {
			getPotentialsPromise.current?.cancel();
		};
	}, [client.id]);

	return (
		<Drawer className={classes.b()} show>
			<DrawerHeader
				className={classes.elem('header').b()}
				onHide={() => {
					close();
				}}
				title={lang.upsellPotentialHeaderTitle}
			/>
			{toplistData.length === 0 && !loading ? (
				<DefaultNoDataAsTable formatNoData={() => T('default.noResults')} />
			) : (
				<Flex className={classes.elem('contentWrapper').b()} direction="column">
					<Flex
						className={classes.elem('spacedContent').b()}
						direction="column"
						gap="u2"
						flex={[0, 0, 'auto']}
					>
						<Title loading={loading} bold size="lg">
							{lang.upsellPotentialContentTitle}
						</Title>
						<Text loading={loading} color="grey-11">
							{lang.upsellPotentialContentDescription}
						</Text>
						<Flex className={classes.elem('clientTable').b()} direction="column">
							<ComparisonTable
								clientName={client.name}
								loading={loading}
								valueType={valueType}
								clientData={clientData}
								industryStats={industryStats}
							/>
						</Flex>
					</Flex>
					<Flex direction="column" flex={[1, 1, 'auto']}>
						<Flex gap="u2" space="pll prl ptxl pbxl">
							<Text loading={loading} bold>
								{lang.potentialUpsellTableTitle}
							</Text>
							<Tooltip title={lang.similarCustomersTooltip}>
								<Icon name="question-circle" loading={loading} />
							</Tooltip>
						</Flex>
						<ToplistTable
							toplistData={toplistData}
							loading={loading}
							close={close}
							valueType={valueType}
							salesModel={salesModel}
						/>
					</Flex>
				</Flex>
			)}
		</Drawer>
	);
};

export default CustomerPortfolioPotentialDrawer;
