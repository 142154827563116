import React from 'react';
import { connect } from 'react-redux';

import T from '../../Helpers/translate';
import Bem from '@upsales/components/Utils/bemClass';
import { Title, Label, Button, Textarea, Progress, Icon, Tooltip } from '@upsales/components';

import { createViewChanged, showRegularView, saveView, deleteView } from 'Store/reducers/SharedViewsReducer';
import FocusableInput from '../../Helpers/FocusableInput';
import openModal from 'App/services/Modal';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';

import './SaveView.scss';
import '../SharedViewsDropdown.scss';

const mapStateToProps = ({ SharedViews, App }) => ({
	createView: SharedViews.createView,
	isSaveView: SharedViews.isSaveView,
	selectedView: SharedViews.selectedView,
	type: SharedViews.type,
	list: App[SharedViews.isReport ? 'reportViews' : 'listViews'][SharedViews.type] ?? [],
	isSaving: SharedViews.isSaving
});

const mapDispatchToProp = {
	createViewChanged,
	showRegularView,
	saveView,
	deleteView
};

const dicMapper = {
	title: 'default.name',
	description: 'default.description',
	userlist: 'sharedViews.shareWithRolesAndUsers'
};

function SaveView({
	changeView,
	createView,
	createViewChanged,
	showRegularView,
	isSaveView,
	saveView,
	selectedView,
	deleteView,
	list,
	isSaving,
	type,
	titleMaxLength = 50,
	descriptionMaxLength = 150
}) {
	const SaveClass = new Bem('SaveView');

	function convertRoleObject(id) {
		const allRoles = [].concat(Tools.AppService.getRoles());
		const foundRole = _.find(allRoles, role => {
			return role.id === parseInt(id.replace('role-', ''));
		});

		return foundRole;
	}

	function convertUserObject(id) {
		const allUsers = [].concat(Tools.AppService.getUsers());
		const foundUser = _.find(allUsers, user => {
			return user.id === parseInt(id);
		});

		foundUser.id = foundUser.id.toString();
		return foundUser;
	}

	function renderRow(key) {
		if (key === 'userlist') {
			const defaultValue = createView[key];
			const userRoleTreeConfig = {
				includeInactive: true,
				selectableRoles: true,
				noParentRef: true,
				roleIdPrefix: true,
				startDepthOn: 1,
				includeLoggedInUser: false,
				userTypes: 'all'
			};
			if (type === 'ticket') {
				userRoleTreeConfig.userTypes = ['support', 'administrator'];
				userRoleTreeConfig.excludeGhosts = true;
			}
			if (type === 'projectPlan') {
				userRoleTreeConfig.userTypes = ['service', 'administrator'];
				userRoleTreeConfig.excludeGhosts = true;
			}

			const userRoles = window.UserRoleTree(userRoleTreeConfig);
			const selectData = userRoles.length
				? [
						{
							name: T('sharedViews.sharedUsers'),
							$depth: 0,
							isSpecialRow: true,
							children: userRoles
						}
				  ]
				: [];
			return (
				<div key={`input-row-${key}`} className="SaveView__InputGroup">
					<Label>{T(dicMapper[key])}</Label>
					<ReactTemplates.INPUTS.upUsers
						className="SaveView__InputGroup--Select"
						dropdownCssClass="SaveView__InputGroup--Dropdown"
						data={selectData}
						getId={obj => {
							return obj.id;
						}}
						formatResult={(item, container, query, escape) => {
							const { isRole, name, children, isSpecialRow } = item;

							if (isSpecialRow) {
								return `${escape(name)}<span class='NumberChildren'>${
									Tools.AppService.getActiveUsers().length
								}</span>`;
							}

							if (isRole) {
								return `${escape(name)}<span class='NumberChildren'>${children.length}</span>`;
							}

							return escape(item.name);
						}}
						formatSelection={(item, container, escape) => {
							if (typeof item === 'object') {
								return escape(item.name);
							}

							if (item.indexOf('role') === 0) {
								return escape(convertRoleObject(item).name);
							}

							return escape(convertUserObject(item).name);
						}}
						multiple={true}
						value={defaultValue}
						onChange={selectedItem => {
							createViewChanged(key, selectedItem);
						}}
						tabIndex={isSaveView ? 0 : -1}
					/>
				</div>
			);
		}

		if (key === 'description') {
			return (
				<div key={`input-row-${key}`} className="SaveView__InputGroup">
					<Label>
						{T(dicMapper[key])}
						<Progress
							hideText
							strokeWidth={10}
							percentage={createView[key] ? (createView[key].length / 150) * 100 : 1}
						/>
					</Label>
					<Textarea
						state={createView[key] && createView[key].length > 150 ? 'error' : null}
						value={createView[key]}
						maxLength={descriptionMaxLength}
						tabIndex={isSaveView ? 0 : -1}
						onChange={event => createViewChanged(key, event.target.value)}
					/>
				</div>
			);
		}

		return (
			<div key={`input-row-${key}`} className="SaveView__InputGroup">
				<Label required={key === 'title'}>{T(dicMapper[key])}</Label>
				<FocusableInput
					value={createView[key]}
					tabIndex={isSaveView ? 0 : -1}
					onChange={event => createViewChanged(key, event.target.value)}
					maxLength={titleMaxLength}
				/>
			</div>
		);
	}

	const extraSaveButtonProps = {};
	if (createView['description'] && createView['description'].length > 150) {
		extraSaveButtonProps.disabled = true;
	}

	if (isSaving) {
		extraSaveButtonProps.disabled = true;
	}

	if (!createView.title) {
		extraSaveButtonProps.disabled = true;
	}

	const removeConfirmOptions = {
		type: 'confirm',
		reactive: true,
		dialog: AlertConfirm,
		id: 'confirm-delete-listview',
		body: T('sharedViews.confirmDeleteBody'),
		title: T('admin.modal.confirmDeletion'),
		confirmButtonText: T('admin.modal.confirmDeletion'),
		confirmButtonSize: 'md'
	};

	const showDeleteButton = !!parseInt(createView.id) === true;

	const onDelete = () => {
		deleteView(createView);

		const removedList = list.filter(v => v.id !== createView.id);
		// Set default or first view
		let selectedView = null;
		if (removedList.length) {
			// find default view
			selectedView = removedList.find(v => v.default) || removedList[0];
		}
		changeView(selectedView);
	};

	return (
		<div className={SaveClass}>
			<Title className={SaveClass.elem('Title')}>{T('sharedViews.saveAndShare')}</Title>
			{['title', 'description', 'userlist'].map((key, index) => {
				return renderRow(key, index);
			})}

			<div className={SaveClass.elem('Footer')}>
				{showDeleteButton ? (
					<Button
						onClick={() => {
							if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
								openModal('Alert', {
									...removeConfirmOptions,
									onClose: confirmed => {
										if (confirmed) {
											onDelete();
										}
									}
								});
							} else {
								// eslint-disable-next-line promise/catch-or-return
								Tools.$upModal.open('alert', removeConfirmOptions).then(() => {
									onDelete();
								});
							}
						}}
						size="sm"
						type="link"
						color="grey"
						style={{ marginRight: 'auto' }}
					>
						<Icon name="trash" style={{ marginRight: '5px' }} />
						{T('default.delete')}
					</Button>
				) : null}
				<Tooltip
					title={T('sharedViews.tip.nameRequired')}
					disabled={!extraSaveButtonProps.disabled || isSaving}
				>
					<Button
						{...extraSaveButtonProps}
						onClick={async () => {
							const resetView = true;
							const returnView = true;
							if (!createView.hasOwnProperty('id')) {
								createView.id = 'SAVE_AS_TEMPORARY_ID';
							}

							const newView = await saveView(createView, selectedView, resetView, returnView);
							changeView(newView);
						}}
						size="sm"
						shadow="none"
					>
						{T('default.save')}
					</Button>
				</Tooltip>
				<Button onClick={() => showRegularView()} size="sm" type="link">
					{T('cancel')}
				</Button>
			</div>
		</div>
	);
}

export const detached = SaveView;
export default connect(mapStateToProps, mapDispatchToProp)(SaveView);
