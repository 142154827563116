import React, { useMemo, useRef, useState } from 'react';
import { type DropResult } from 'react-beautiful-dnd';
import BemClass from '@upsales/components/Utils/bemClass';
import { ProjectPlanType } from 'App/resources/Model/ProjectPlan';
import ProjectPlanTypeResource from 'App/resources/ProjectPlanType';
import {
	CardHeader,
	Checkbox,
	ColorSwitcher,
	EllipsisTooltip,
	Flex,
	Input,
	Label,
	ModalContent,
	ModalHeader,
	Select,
	Tab,
	Tabs,
	Text,
	Title,
	Toggle
} from '@upsales/components';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import { useTranslation } from 'Components/Helpers/translate';
import { circle } from 'Components/Helpers/styleHelper';
import './EditProjectPlanType.scss';
import logError from 'Helpers/logError';
import { useProjectPlanStages } from 'App/components/hooks/appHooks';
import ColorChip from 'App/components/ColorChip';
import AdminCard from 'App/pages/Admin/AdminCard';
import ProjectBoardPreview from './ProjectBoardPreview';
import DragDropTable, { type DragDropTableProps } from 'App/components/DragDropTable';

type Props = {
	className?: string;
	projectPlanType: ProjectPlanType | null;
	close: () => void;
	onSave?: () => void;
};

const Steps = {
	DETAILS: 'details',
	STAGES: 'stages'
};

const truncate = (str: string, length: number) => {
	if (str.length <= length) {
		return str;
	}
	return str.slice(0, length) + '...';
};

const getNewProjectPlanType = () =>
	({
		name: '',
		category: 'PROJECT',
		isDefault: false,
		stages: []
	} as Omit<ProjectPlanType, 'id'>);

const EditProjectPlanType = ({ className, projectPlanType, onSave, close }: Props) => {
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const classes = new BemClass('EditProjectPlanType', className);
	classes.add('FullScreenModal');

	const [currentStep, setCurrentStep] = useState(Steps.DETAILS);
	const [editObj, setEditObj] = useState(projectPlanType ?? getNewProjectPlanType());

	const defaultProjectPlanStages = useProjectPlanStages();
	const projectPlanStages = defaultProjectPlanStages
		.map(projectPlanStage => {
			return {
				...projectPlanStage,
				active: editObj.stages.some(({ stage }) => stage.id === projectPlanStage.id),
				sortId: editObj.stages.find(({ stage }) => stage.id === projectPlanStage.id)?.sortId ?? 99999
			};
		})
		.sort((a, b) => a.sortId - b.sortId);

	const [saving, setSaving] = useState(false);
	const { t } = useTranslation();

	const hasNextStep = currentStep === Steps.DETAILS;
	const isValid = useMemo(() => editObj.name.trim().length > 0 && editObj.category, [editObj.name, editObj.category]);

	const save = () => {
		if (isValid && !saving) {
			setSaving(true);

			ProjectPlanTypeResource.save(editObj)
				.then(() => {
					onSave?.();
					close();
				})
				.catch(err => {
					logError(err, 'Failed to save project plan type');
				})
				.finally(() => {
					setSaving(false);
				});
		}
	};

	const tabs = [
		{
			id: Steps.DETAILS,
			title: t('order.details')
		},
		{
			id: Steps.STAGES,
			title: t('filter.history.selectStage')
		}
	];

	const categoryOptions = [
		{ id: 'PROJECT', title: t('projectPlan.type.category.project') },
		{ id: 'SERVICE', title: t('projectPlan.type.category.service') }
	];

	const onDragEnd = (result: DropResult) => {
		const { destination, source } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		setEditObj(prev => {
			const newStages = [...prev.stages];
			const [removed] = newStages.splice(source.index, 1);
			newStages.splice(destination.index, 0, removed);
			const stages = newStages.map((stage, index) => ({
				...stage,
				sortId: index + 1
			}));
			return { ...prev, stages };
		});
	};

	const tableColumns: DragDropTableProps['columns'] = [
		{ key: 'name', title: t('default.name'), render: name => <Text>{name}</Text> },
		{
			key: 'color',
			title: t('default.color'),
			render: color => <ColorChip color={color} />
		},
		{
			key: 'category',
			title: t('default.category'),
			render: category => <Text>{t('projectPlan.stage.category.' + category.toLowerCase())}</Text>
		},
		{
			key: 'id',
			title: t('default.select'),
			render: id => (
				<Checkbox
					checked={editObj.stages.some(({ stage }) => stage.id === id)}
					onChange={checked => {
						if (checked) {
							setEditObj(prev => {
								const selected = projectPlanStages.find(stage => stage.id === id);
								if (!selected) {
									return prev;
								}

								const { active, sortId, ...stage } = selected;
								const stages = [
									...prev.stages,
									{
										sortId: prev.stages.length + 1,
										stage: stage
									}
								];
								return { ...prev, stages };
							});
						} else {
							setEditObj(prev => {
								const stages = prev.stages
									.filter(({ stage }) => stage.id !== id)
									.map((stage, index) => ({
										...stage,
										sortId: index + 1
									}));
								return { ...prev, stages };
							});
						}
					}}
				/>
			)
		}
	];

	return (
		<div className={classes.b()} ref={wrapperRef}>
			<ModalHeader>
				<Flex className={classes.elem('header-content').b()} justifyContent="space-between" alignItems="center">
					<Flex alignItems="center" space="pll" gap="u5">
						<Flex className={classes.elem('title-wrapper').b()}>
							<EllipsisTooltip title={editObj.name}>
								<Title>{editObj.name || t('default.createCustomType')}</Title>
							</EllipsisTooltip>
						</Flex>
						<Tabs selected={currentStep} onChange={setCurrentStep}>
							{tabs.map((tab, i) => (
								<Tab key={i} id={tab.id} disabled={tab.id === Steps.STAGES && !isValid}>
									<Flex>
										<ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>
										<Text space="mlm" color="inherit" bold={currentStep === tab.id}>
											{tab.title}
										</Text>
									</Flex>
								</Tab>
							))}
						</Tabs>
					</Flex>
					<Flex>
						<EditorHeaderButton
							title={t('default.cancel')}
							onClick={close}
							noIcon
							supertitle={undefined}
							className={undefined}
							next={false}
						/>
						<EditorHeaderButton
							title={hasNextStep ? t('filter.history.selectStage') : t('default.save')}
							supertitle={hasNextStep ? t('default.nextStep') : undefined}
							onClick={hasNextStep ? () => setCurrentStep(Steps.STAGES) : save}
							icon={hasNextStep ? 'chevron-right' : 'check'}
							next
							className={undefined}
							noIcon={false}
							disabled={!isValid || saving}
						/>
					</Flex>
				</Flex>
			</ModalHeader>
			<ModalContent>
				{currentStep === Steps.DETAILS ? (
					<Flex className={classes.elem('content').b()} alignItems="center" direction="column">
						<Flex direction="column" gap="u4">
							<Flex direction="column">
								<Label required>{t('default.name')}</Label>
								<Input
									required
									value={editObj.name}
									onChange={e => setEditObj({ ...editObj, name: e.target.value })}
									placeholder={t('default.enterName')}
									maxLength={128}
								/>
							</Flex>
							{wrapperRef.current ? (
								<Flex direction="column">
									<Label required>{t('default.category')}</Label>
									<Select
										anchor={wrapperRef.current}
										options={categoryOptions}
										value={categoryOptions.find(c => c.id === editObj?.category) || null}
										onChange={value =>
											setEditObj(prev => ({ ...prev, category: value.id } as ProjectPlanType))
										}
									/>
								</Flex>
							) : null}

							<Flex gap="u2">
								<Toggle
									checked={editObj.isDefault}
									onChange={checked => setEditObj({ ...editObj, isDefault: checked })}
									disabled={projectPlanType?.isDefault}
								/>
								<Text>{t('default.setTypeAsDefault')}</Text>
							</Flex>
						</Flex>
					</Flex>
				) : currentStep === Steps.STAGES ? (
					<Flex className={classes.elem('content').b()} alignItems="center" direction="column" gap="u4">
						<ProjectBoardPreview stages={editObj.stages} name={editObj.name} />
						<AdminCard className={classes.elem('stages-card').b()}>
							<CardHeader
								title={t('projectPlan.type.selectStages.title', { name: truncate(editObj.name, 50) })}
								subtitle={t('projectPlan.type.selectStages.subtitle')}
							/>
							<DragDropTable onDragEnd={onDragEnd} columns={tableColumns} rows={projectPlanStages} />
						</AdminCard>
					</Flex>
				) : null}
			</ModalContent>
		</div>
	);
};

export default EditProjectPlanType;
