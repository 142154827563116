import {
	Block,
	Flex,
	Icon,
	Table,
	TableHeader,
	Title,
	TableRow,
	TableColumn,
	NumberInput,
	Text,
	Label,
	Input,
	Button,
	Tooltip,
	EllipsisTooltip
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState } from 'react';

import './TemplateProductLists.scss';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'react-i18next';
import ProductCategorySelect from 'App/components/OrderRows/OrderRow/ProductCategorySelect';
import { useSelector } from 'App/components/hooks';
import { RootState } from 'Store/index';
import Product from 'App/resources/Model/Product';
import { getTierFromQuantity } from 'Helpers/order';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';
import {
	addNewProductToProductLists,
	addSelectedProductToProductLists,
	createNewProduct,
	editProductLists,
	removeProductFromProductLists,
	updateQuantityOnProductLists
} from 'Components/EditProjectPlan/Helpers/ProductListHelpers';

type Props = {
	projectPlanTemplate: ProjectPlanTemplate;
	onProductListsChange: (productLists: ProjectPlanTemplate['productLists']) => void;
};

const TemplateProductLists = ({ projectPlanTemplate, onProductListsChange }: Props) => {
	const classes = new BemClass('TemplateProductLists');
	const { t } = useTranslation();
	const {
		totals: { products: productTotal }
	} = useSelector((state: RootState) => state.App);

	const [isCreatingProductList, setIsCreatingProductList] = useState(false);
	const [editingProductListName, setEditingProductListName] = useState<number | null>(null);
	const [productListName, setProductListName] = useState('');

	const onCreateProductList = async () => {
		const newProductList = { name: productListName, products: [createNewProduct()] };
		onProductListsChange([newProductList, ...projectPlanTemplate.productLists]);

		setProductListName('');
		setIsCreatingProductList(false);
	};

	const onEditProductList = async (productListIndex: number) => {
		onProductListsChange(editProductLists(projectPlanTemplate.productLists, productListIndex, productListName));

		setProductListName('');
		setEditingProductListName(null);
	};

	const onAddProduct = (productListIndex: number) => {
		onProductListsChange(addNewProductToProductLists(projectPlanTemplate.productLists, productListIndex));
	};

	const onSetProduct = (newProduct: Product, productListIndex: number, productIndex: number) => {
		onProductListsChange(
			addSelectedProductToProductLists(
				projectPlanTemplate.productLists,
				newProduct,
				productListIndex,
				productIndex
			)
		);
	};

	const onSetQuantity = (quantity: number | undefined, productListIndex: number, productIndex: number) => {
		onProductListsChange(
			updateQuantityOnProductLists(projectPlanTemplate.productLists, quantity, productListIndex, productIndex)
		);
	};

	const onRemoveProduct = (productListIndex: number, productIndex: number) => {
		onProductListsChange(
			removeProductFromProductLists(projectPlanTemplate.productLists, productListIndex, productIndex)
		);
	};

	return (
		<Block className={classes.b()} space="mrl pll ptxl prxl pbl">
			<Block className={classes.elem('productList-wrapper').b()}>
				<Flex direction="row" justifyContent="space-between" alignItems="center">
					<Flex direction="column" gap="u1" space="ptl pbl">
						<Title>{t('editProjectPlan.productList.productLists')}</Title>
						<Text color="grey-11">{t('editProjectPlan.productList.productLists.toolTip')}</Text>
					</Flex>
					{!isCreatingProductList ? (
						<PrimaryButton
							icon="plus"
							text={t('default.add')}
							onClick={() => {
								setIsCreatingProductList(true);
								setEditingProductListName(null);
								setProductListName('');
							}}
						/>
					) : (
						<Text
							className={classes.elem('clickable').b()}
							onClick={() => {
								setIsCreatingProductList(false);
								setProductListName('');
							}}
						>
							{t('default.cancel')}
						</Text>
					)}
				</Flex>
				{isCreatingProductList ? (
					<Block space="mtxl mbxl">
						<Label
							maxLength={128}
							value={productListName}
							maxLengthReachedText={t('default.characterLimitReached')}
							className={classes.elem('productList-input').b()}
						>
							{t('default.name')}
						</Label>
						<Flex gap="u1">
							<Input
								className={classes.elem('productList-input').b()}
								placeholder={t('default.enterName')}
								maxLength={128}
								value={productListName}
								onChange={e => setProductListName(e.target.value)}
								onKeyPress={e => {
									if (e.key === 'Enter') {
										onCreateProductList();
									} else if (e.key === 'Escape') {
										setIsCreatingProductList(false);
										setProductListName('');
									}
								}}
								autofocus
							/>
							<Button
								text={t('default.create')}
								onClick={onCreateProductList}
								disabled={!productListName.trim()}
							/>
						</Flex>
					</Block>
				) : null}
			</Block>
			{projectPlanTemplate.productLists.map((productList, productListIndex) => {
				return (
					<Block key={productListIndex} className={classes.elem('productList-wrapper').b()} space="mtxl">
						<Flex direction="row" justifyContent="space-between" alignItems="center">
							{editingProductListName !== productListIndex ? (
								<Flex gap="u4" alignItems="center" space="mrxl">
									<EllipsisTooltip title={productList.name}>
										<Title bold>{productList.name}</Title>
									</EllipsisTooltip>
									<Tooltip title={t('default.editName')}>
										<Icon
											name="edit"
											color="grey-8"
											className={classes.elem('clickable').b()}
											onClick={() => {
												setEditingProductListName(productListIndex);
												setProductListName(productList.name);
												setIsCreatingProductList(false);
											}}
										/>
									</Tooltip>
								</Flex>
							) : (
								<Flex gap="u2" alignItems="center" flex={1} space="mrl">
									<Input
										className={classes.elem('edit-productList-input').b()}
										placeholder={t('default.enterName')}
										value={productListName}
										onChange={e => setProductListName(e.target.value)}
										autofocus
										maxLength={128}
										onKeyPress={e => {
											if (e.key === 'Enter') {
												onEditProductList(productListIndex);
											} else if (e.key === 'Escape') {
												setEditingProductListName(null);
												setProductListName('');
											}
										}}
									/>
									<Button
										icon="check"
										color="green"
										className={classes.elem('icon-button').b()}
										onClick={() => onEditProductList(productListIndex)}
										disabled={!productListName.trim()}
									/>
									<Button
										icon="times"
										color="white"
										shadow="none"
										className={classes.elem('icon-button').b()}
										onClick={() => {
											setEditingProductListName(null);
											setProductListName('');
										}}
									/>
								</Flex>
							)}
							<Flex gap="u4" alignItems="center">
								<DefaultButton
									icon="plus"
									text={t('admin.products.addProduct')}
									onClick={() => onAddProduct(productListIndex)}
								/>
								<InlineConfirm
									key={productListIndex}
									show
									onConfirm={() => {
										onProductListsChange(
											projectPlanTemplate.productLists.filter(
												(_, index) => index !== productListIndex
											)
										);
									}}
									entity="editProjectPlan.productList"
									keepTabPosition
									noResolve
								>
									<Icon className={classes.elem('clickable').b()} name="trash" />
								</InlineConfirm>
							</Flex>
						</Flex>
						{!productList.products.length ? (
							<Block space="mtl mbl">
								<Text color="grey-11" italic>
									{t('editProjectPlan.productList.noProducts')}
								</Text>
							</Block>
						) : (
							<Block className={classes.elem('table-wrapper').b()} space="mtl mbl">
								<Table>
									<TableHeader
										columns={[
											{ title: t('default.product') },
											{ title: t('default.quantity') },
											{ title: '' }
										]}
									/>
									{productList.products.map((product, productIndex) => {
										let tierObject = null;
										if (product.product.tiers?.length) {
											tierObject = getTierFromQuantity(product.product.tiers, product.quantity);
										}
										return (
											<TableRow key={productIndex}>
												<TableColumn className={classes.elem('product-column').b()}>
													<ProductCategorySelect
														className={classes.elem('product-select').b()}
														tierObject={tierObject}
														orderRow={{ product: product.product } as any}
														useDiscount={false}
														productTotal={productTotal}
														virtualized={productTotal <= 4000}
														setProduct={product =>
															onSetProduct(product, productListIndex, productIndex)
														}
														anchor={'.EditProjectPlanTemplate'}
													/>
												</TableColumn>
												<TableColumn className={classes.elem('quantity-column').b()}>
													<NumberInput
														value={product.quantity}
														min={0}
														align="center"
														onChange={quantity =>
															onSetQuantity(quantity, productListIndex, productIndex)
														}
														decimals={16}
													/>
												</TableColumn>
												<TableColumn align="right">
													<Icon
														className={classes.elem('remove-row').b()}
														name="times"
														onClick={() => onRemoveProduct(productListIndex, productIndex)}
													/>
												</TableColumn>
											</TableRow>
										);
									})}
								</Table>
							</Block>
						)}
					</Block>
				);
			})}
		</Block>
	);
};

export default TemplateProductLists;
