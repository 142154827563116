import { useMemo } from 'react';
import { useSelector } from 'App/components/hooks';
import { ProjectPlanType } from 'App/resources/Model/ProjectPlan';

type UseSelectedProjectBoardViewProps = {
	selectedId: string | null;
	projectPlanTypeCategory?: ProjectPlanType['category'];
};

const useSelectedProjectBoardView = ({ selectedId, projectPlanTypeCategory }: UseSelectedProjectBoardViewProps) => {
	const listViews = useSelector(({ App }) => App.listViews.projectBoard || []);

	const defaultView =
		listViews.find(v => {
			if (projectPlanTypeCategory) {
				return v.standard && v.projectPlanType.category === projectPlanTypeCategory;
			}
			return v.default && !v.standard;
		}) ?? listViews[0];

	const selectedView = useMemo(() => {
		return listViews.find(v => v.id + '' === selectedId) || defaultView;
	}, [selectedId, listViews]);

	return selectedView;
};

export default useSelectedProjectBoardView;
