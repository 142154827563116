import React from 'react';
import { useProjectBoardContext } from 'App/pages/ProjectBoard/ProjectBoardContext';
import ReportcenterFilterUser, {
	Props
} from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterUser';
import { comparisonTypes } from 'Resources/RequestBuilder';
import { ListViewFilter } from 'App/resources/AllIWant';

const ProjectMapUserFilter = () => {
	const { state, setFilters } = useProjectBoardContext();
	const { filters } = state;
	const selectedUsers = (filters.User?.value as (number | null)[]) ?? [];

	const onUserChange: Props['onChange'] = (type, userIds, comparisonType) => {
		const listFilter: ListViewFilter = {
			filterName: 'User',
			comparisonType: comparisonTypes.NotEquals === comparisonType ? 'NotEquals' : 'Equals',
			inactive: false,
			value: userIds.map(id => (id === 'null' ? null : id))
		};

		setFilters({
			...filters,
			User: listFilter
		});
	};

	const updateFilterHeight = () => {};

	const values: Props['values'] = {
		User: {
			value: selectedUsers.map(id => (id === null ? 'null' : id)),
			comparison:
				filters.User?.comparisonType === 'NotEquals' ? comparisonTypes.NotEquals : comparisonTypes.Equals
		}
	};

	return (
		<ReportcenterFilterUser
			onChange={onUserChange}
			values={values}
			updateFilterHeight={updateFilterHeight}
			onlyUsers
			listFilterMode
		/>
	);
};

export default ProjectMapUserFilter;
