import { Drawer, DrawerHeader, Flex } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import ContactSignals from '../ContactSignals/ContactSignals';
import ClientSignals from '../ClientSignals/ClientSignals';
import BemClass from '@upsales/components/Utils/bemClass';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import React, { useEffect } from 'react';

import './RelationsSignalDrawer.scss';

type RelationsSignalDrawerProps = {
	clientId: number;
	contactId?: number;
	contact?: Contact;
	client?: Client;
	close: (a?: any) => void;
};

const RelationsSignalDrawer = ({ clientId, contactId, close }: RelationsSignalDrawerProps) => {
	const classes = new BemClass('RelationsSignalDrawer');
	const { t } = useTranslation();
	const [contactIdentifier, setContactIdentifier] = React.useState<number | null | undefined>(contactId);

	const onContactChange = (newContactId: number) => {
		setContactIdentifier(newContactId);
	};

	useEffect(() => {
		setContactIdentifier(contactId);
	}, [contactId]);

	return (
		<Drawer className={classes.b()} show>
			<DrawerHeader
				onHide={() => {
					close(true);
				}}
			>
				<Flex space="mll" alignItems="center">
					{contactId ? t('default.contactRelation') : t('default.clientRelation')}
				</Flex>
			</DrawerHeader>
			<Flex direction="column" gap="u8" space="prl pll ptl pbl">
				{contactIdentifier ? <ContactSignals contactId={contactIdentifier} /> : null}
				{clientId ? (
					<ClientSignals
						clientId={clientId}
						onlyClient={!contactIdentifier}
						onContactChange={onContactChange}
					/>
				) : null}
			</Flex>
		</Drawer>
	);
};

export default RelationsSignalDrawer;
