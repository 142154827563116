import formatWidgetValue from 'App/pages/Reportcenter/ReportcenterWidget/formatWidgetValue';
import colorMappings from '@upsales/components/Utils/colorMappings';
import { SeriesOptions } from 'highcharts';

interface LoadingChartLang {
	highestPerformerGraph: string;
	averagePerformerGraph: string;
	underperformerGraph: string;
}

export enum IndustryInsightsTabs {
	ACCOUNT_VS_INDUSTRY = 'accountVsIndustry',
	ABOUT_INDUSTRY = 'aboutIndustry'
}

export const PERFORMANCE_STATUS = {
	UNDERPERFORMER: {
		type: 'danger' as const,
		icon: 'warning'
	},
	AVERAGE: {
		type: 'alert' as const,
		icon: 'exclamation-circle'
	},
	ABOVE_AVERAGE: {
		type: 'success' as const,
		icon: 'check'
	},
	TOP_PERFORMER: {
		type: 'green' as const,
		icon: 'star'
	}
} as const;

export type PerformanceStatusType = keyof typeof PERFORMANCE_STATUS;

const CHART_COLORS = {
	HIGHEST_PERFORMER: colorMappings.get('success-4'),
	AVERAGE_PERFORMER: colorMappings.get('bright-orange'),
	UNDERPERFORMER: colorMappings.get('danger-5'),
	LOADING: colorMappings.get('grey-3'),
	GRID_LINE: colorMappings.get('grey-6')
} as const;

export const PERFORMANCE_LEVELS = [
	{
		name: 'highestPerformerGraph',
		dataKey: 'ruleOfFortyUpperQuartile',
		color: CHART_COLORS.HIGHEST_PERFORMER,
		zIndex: 1
	},
	{
		name: 'averagePerformerGraph',
		dataKey: 'ruleOfFortyMedian',
		color: CHART_COLORS.AVERAGE_PERFORMER,
		zIndex: 2
	},
	{
		name: 'underperformerGraph',
		dataKey: 'ruleOfFortyLowerQuartile',
		color: CHART_COLORS.UNDERPERFORMER,
		zIndex: 3
	}
] as const;

export const getChartConfig = () => ({
	chart: {
		borderRadius: 8,
		spacingLeft: 0,
		spacingRight: 16
	},
	title: {
		text: ''
	},
	xAxis: {
		categories: [],
		title: { text: '' },
		tickmarkPlacement: 'on',
		gridLineWidth: 1,
		gridLineColor: CHART_COLORS.GRID_LINE
	},
	yAxis: {
		title: { text: 'E40' },
		gridLineWidth: 1,
		gridLineColor: CHART_COLORS.GRID_LINE
	},
	tooltip: {
		shared: true
	},
	plotOptions: {
		areaspline: {
			pointPlacement: 'on',
			lineWidth: 1,
			softThreshold: true,
			animation: {
				duration: 1500
			},
			marker: {
				enabled: false,
				states: {
					hover: {
						enabled: true,
						radius: 4,
						symbol: 'circle'
					}
				}
			},
			states: {
				hover: {
					lineWidth: 2
				}
			},
			connectNulls: true
		},
		spline: {
			pointPlacement: 'on',
			smoothing: 0.7,
			lineWidth: 2,
			marker: {
				enabled: false,
				states: {
					hover: {
						enabled: true,
						radius: 4,
						symbol: 'circle'
					}
				}
			},
			states: {
				hover: {
					lineWidth: 3
				}
			},
			connectNulls: true
		}
	},
	legend: {
		enabled: true,
		itemStyle: {
			fontWeight: 'normal'
		},
		symbolRadius: 4,
		symbolWidth: 8,
		symbolHeight: 8,
		symbolPadding: 6
	},
	credits: {
		enabled: false
	},
	series: [] as SeriesOptions[]
});

interface LoadingChartOptions {
	clientName: string;
	lang: LoadingChartLang;
	yearRange?: number;
}

const generateRandomTimeSeries = (points: number = 5): number[] => {
	const baseValue = 50;
	const variance = 30;
	return Array(points)
		.fill(0)
		.map((_, i) => {
			const wave = Math.sin(i * (Math.PI / 2)) * variance;
			const noise = (Math.random() - 0.5) * variance;
			return baseValue + wave + noise;
		});
};

export const getLoadingChartConfig = ({ clientName, lang, yearRange = 5 }: LoadingChartOptions) => ({
	...getChartConfig(),
	xAxis: {
		...getChartConfig().xAxis,
		categories: Array(yearRange)
			.fill(0)
			.map((_, i) => (new Date().getFullYear() - (yearRange - 1) + i).toString())
	},
	series: [
		{
			name: lang.highestPerformerGraph,
			type: 'areaspline',
			data: generateRandomTimeSeries(yearRange),
			color: CHART_COLORS.LOADING,
			zIndex: 1,
			fillColor: {
				linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
				stops: [
					[0, `${CHART_COLORS.LOADING}33`],
					[1, `${CHART_COLORS.LOADING}00`]
				]
			}
		},
		{
			name: lang.averagePerformerGraph,
			type: 'areaspline',
			data: generateRandomTimeSeries(yearRange),
			color: CHART_COLORS.LOADING,
			zIndex: 2,
			fillColor: {
				linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
				stops: [
					[0, `${CHART_COLORS.LOADING}33`],
					[1, `${CHART_COLORS.LOADING}00`]
				]
			}
		},
		{
			name: lang.underperformerGraph,
			type: 'areaspline',
			data: generateRandomTimeSeries(yearRange),
			color: CHART_COLORS.LOADING,
			zIndex: 3,
			fillColor: {
				linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
				stops: [
					[0, `${CHART_COLORS.LOADING}33`],
					[1, `${CHART_COLORS.LOADING}00`]
				]
			}
		},
		{
			name: clientName,
			type: 'spline',
			data: generateRandomTimeSeries(yearRange),
			color: CHART_COLORS.LOADING,
			zIndex: 4
		}
	],
	plotOptions: {
		series: {
			enableMouseTracking: false,
			animation: {
				duration: 1500
			}
		},
		areaspline: {
			pointPlacement: 'on',
			lineWidth: 1,
			softThreshold: true,
			animation: {
				duration: 1500
			},
			marker: {
				enabled: false
			},
			connectNulls: true
		},
		spline: {
			pointPlacement: 'on',
			smoothing: 0.7,
			lineWidth: 2,
			marker: {
				enabled: false
			},
			connectNulls: true
		}
	},
	tooltip: {
		enabled: false
	}
});

export const COMPANY_COLORS = {
	CLIENT: 'black',
	COMPETITORS: [
		'medium-purple',
		'orange',
		'bright-blue',
		'bright-turcose',
		'rose-4',
		'dark-turcose',
		'bright-green',
		'rose-6',
		'grey-9'
	] as const,
	OTHERS: 'grey-7'
};

// Follows COMPANY_COLORS.COMPETITORS order
export const getCompetitorColor = (index: number): string => {
	const colorName = COMPANY_COLORS.COMPETITORS[index % COMPANY_COLORS.COMPETITORS.length];
	return colorMappings.get(colorName);
};

interface TitleVariants {
	[key: string]: string[];
}

export const PERFORMANCE_TITLES: TitleVariants = {
	TOP_PERFORMER: [
		'ai.industryInsights.highestPerformerTitle.1',
		'ai.industryInsights.highestPerformerTitle.2',
		'ai.industryInsights.highestPerformerTitle.3'
	],
	ABOVE_AVERAGE: [
		'ai.industryInsights.aboveAveragePerformerTitle.1',
		'ai.industryInsights.aboveAveragePerformerTitle.2',
		'ai.industryInsights.aboveAveragePerformerTitle.3'
	],
	AVERAGE: [
		'ai.industryInsights.averagePerformerTitle.1',
		'ai.industryInsights.averagePerformerTitle.2',
		'ai.industryInsights.averagePerformerTitle.3'
	],
	UNDERPERFORMER: [
		'ai.industryInsights.underperformerTitle.1',
		'ai.industryInsights.underperformerTitle.2',
		'ai.industryInsights.underperformerTitle.3'
	]
};

export const getRandomTitle = (performanceStatus: PerformanceStatusType): string => {
	const titles = PERFORMANCE_TITLES[performanceStatus];
	const randomIndex = Math.floor(Math.random() * titles.length);
	const titleKey = titles[randomIndex];
	return titleKey;
};

export const FORMAT_LOCALE = {
	SV: 'sv-SE',
	EN: 'en-US'
} as const;

interface FormatNumberOptions {
	decimals?: number;
	compact?: boolean;
	locale?: 'SV' | 'EN';
}

const getLocaleString = (locale: FormatNumberOptions['locale'] = 'SV'): string => {
	return FORMAT_LOCALE[locale];
};

export const formatPercentage = (value: number | undefined, options: FormatNumberOptions = {}) => {
	if (value === undefined || isNaN(value)) return '-';

	const { decimals = 2, locale = 'SV' } = options;

	const formatter = new Intl.NumberFormat(getLocaleString(locale), {
		style: 'percent',
		maximumFractionDigits: decimals,
		minimumFractionDigits: decimals
	});

	return formatter.format(value / 100);
};

export const getMasterCurrency = (): string => {
	const currencies = Tools.AppService.getMetadata()?.customerCurrencies ?? [];
	return currencies.find(currency => currency.masterCurrency)?.iso ?? 'SEK';
};

export const formatCurrency = (value: number | undefined, compact = true): string => {
	if (value === undefined) return '-';
	const masterCurrency = getMasterCurrency();
	return formatWidgetValue(value, 'currency', masterCurrency, compact);
};

export const formatValue = (value: number | undefined, format: 'percentage' | 'multiplier' | 'currency'): string => {
	if (value === undefined) return '-';

	switch (format) {
		case 'percentage':
			return formatPercentage(value);
		case 'multiplier':
			return `${value.toFixed(2)}x`;
		case 'currency':
			return formatCurrency(value);
	}
};
