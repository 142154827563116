import { Dispatch } from 'react';
import Actions from './Actions';

export type State = {
	bucketRange: {
		minRev: number;
		maxRev: number;
	};
	description: string;
	loading: boolean;
};

export const getInitialState = (description: string): State => ({
	bucketRange: {
		minRev: 0,
		maxRev: 0
	},
	description,
	loading: false
});

export const setBucketRange = (dispatch: Dispatch<any>, state: State) => (bucketRange: [number, number]) => {
	dispatch({ type: Actions.SET_BUCKET_RANGE, bucketRange });
};

export const setDescription = (dispatch: Dispatch<any>, state: State) => (description: string) => {
	dispatch({ type: Actions.SET_DESCRIPTION, description });
};
