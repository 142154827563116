module.exports = {
	ENV: 'BETA',
	URL: '/api/',
	API: 'v2/',
	SOCKET: 'https://sandbox.upsales.com',
	PUBLICPATH: 'https://img-sandbox.upsales.com/',
	FORMPATH: 'https://resource-sandbox.upsales.com',
	LANDINGPAGEPATH: 'https://pages-sandbox.upsales.com',
	CLASSIC_URL: 'https://sandboxclassic.upsales.com/professional/application/',
	STATIC_MAPS_KEY: 'AIzaSyBz8DCi-zXpKspt_-LTIlhu-0vbLYMTNOA',
	GOOGLE_FONTS_KEY: 'AIzaSyDRwD9bJV8XncXBSWG9SQxM0sHHPso5rbw',
	STATUSPAGE: {
		pageId: 'xgxqnwgpzcyz',
		key: '91c46437-14a8-4b0b-b7f8-8e3df6ded05f',
		componentId: '1gr032v9zz1j'
	},
	PUSHER: {
		key: '0dae677b9a6736e36932',
		cluster: 'mt1'
	},
	SENTRY: {
		enable: false,
		env: 'sandbox',
		dsn: 'https://47d0330c417c4f02b0da97429871724c@sentry.upsales.com/3'
	},
	CHECKIN_URL: 'https://sandboxcheckin.upsales.com/',
	CUSTOM_DOMAIN_CNAME: 'sandboxdomain.upsales.com',
	STATIC_URL: 'https://static-sandbox.upsales.com/**',
	SPLIT_KEY: 'rm8te0f9kmkncbb2ideqotgprv1l0rhpt9cv',
	chatEnabled: false,
	GOOGLE_MAPS_KEY: 'AIzaSyBz8DCi-zXpKspt_-LTIlhu-0vbLYMTNOA'
};
