import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import RelationSelect from 'App/components/RelationSelect';
import BemClass from '@upsales/components/Utils/bemClass';
import { Flex, Icon, Label, Tooltip } from '@upsales/components';
import { useEditProjectPlanContext } from 'Components/EditProjectPlan/Context';
import { useSelf } from 'App/components/hooks/appHooks';

const ProjectPlanRelations = ({ onChange }: { onChange: (name: string, value: any, cb?: () => void) => void }) => {
	const classes = new BemClass('ProjectPlanRelations');
	const {
		state: { projectPlan, savingProjectPlan, order, agreement },
		setAgreement,
		setOrder
	} = useEditProjectPlanContext();

	const {
		id,
		client,
		contact,
		activityId,
		appointmentId,
		relatedOrderId,
		ticketId,
		createdFromTicketId,
		agreementId
	} = projectPlan!;
	const self = useSelf();

	const { t } = useTranslation();

	return (
		<Flex direction="column" space="mbxl" className={classes.b()}>
			<Flex direction="row" gap="u1">
				<Label>{t('default.relateActivities')}</Label>
				<Tooltip position="right" title={t('default.relations.role')}>
					<Icon name="question-circle" />
				</Tooltip>
			</Flex>
			<RelationSelect
				availableEntities={{
					activity: true,
					opportunity: order?.id ? false : true,
					appointment: true,
					order: order?.id ? false : true,
					projectPlan: false,
					ticket: createdFromTicketId ? false : true,
					agreement: agreement?.id ? false : true
				}}
				staticRelations={{
					opportunity: order?.id ? true : false,
					agreement: agreement?.id ? true : false,
					ticket: createdFromTicketId ? true : false
				}}
				onChange={async v => {
					if (v.activityId !== activityId) {
						if (!v.activityId) {
							onChange('activityId', null);
							return;
						}
						onChange('activityId', v.activityId);
					}
					if (v.appointmentId !== appointmentId) {
						if (!v.appointmentId) {
							onChange('appointmentId', null);
							return;
						}
						onChange('appointmentId', v.appointmentId);
					}
					if (v.opportunityId !== (order?.id ?? relatedOrderId)) {
						if (!v.opportunityId) {
							// For when order itself was deleted
							if (order) {
								setOrder(null);
								if (agreement) {
									setAgreement(null);
								}
							}
							onChange(`${relatedOrderId ? 'relatedOrderId' : 'order'}`, null);
							return;
						}
						onChange('relatedOrderId', v.opportunityId);
					}
					if (v.ticketId !== (createdFromTicketId ?? ticketId)) {
						if (!v.ticketId) {
							onChange(`${ticketId ? 'ticketId' : 'createdFromTicketId'}`, null);
							return;
						}
						onChange('ticketId', v.ticketId);
					}
					if (v.agreementId !== (agreement?.id ?? agreementId)) {
						if (!v.agreementId) {
							if (agreement) {
								setAgreement(null);
							} else {
								onChange('agreementId', null);
							}
							return;
						}
						onChange('agreementId', v.agreementId);
					}
				}}
				opportunityId={order?.id ?? relatedOrderId}
				appointmentId={appointmentId}
				activityId={activityId}
				ticketId={createdFromTicketId ?? ticketId}
				agreementId={agreement?.id ?? agreementId}
				client={client}
				contact={contact}
				userId={self!.id}
				disabled={savingProjectPlan}
				opportunity={order ?? undefined}
				agreement={agreement ?? undefined}
				filterOutRelations={[{ type: 'projectPlan', id: id }]}
				showNoRelationsFound
			/>
		</Flex>
	);
};

export default ProjectPlanRelations;
