import {
	Table,
	TableRow,
	TableColumn,
	Text,
	TableHeader,
	TableHeaderColumn,
	Flex,
	Icon,
	EllipsisTooltip,
	Tooltip,
	Block
} from '@upsales/components';
import DistributionBar from 'App/pages/Reportcenter/ReportcenterWidget/Components/DistributionBar';
import { formatPercentage, formatCurrency } from '../Helpers/Helpers';
import RequiredFields from 'Components/Modals/CreateNewAccount/RequiredFields';
import { openGenericModal } from 'App/components/GenericModal/GenericModal';
import { useCustomFields } from 'App/components/hooks/appHooks';
import { DefaultButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import CustomField from 'App/resources/Model/CustomField';
import { useIndustryInsights } from '../Context/Context';
import { COMPANY_COLORS } from '../Helpers/Helpers';
import Prospecting from 'Resources/Prospecting';
import EmptyData from '../EmptyData/EmptyData';
import T from 'Components/Helpers/translate';
import React, { useState } from 'react';
import './MarketShareTable.scss';
import Error from 'App/resources/Model/Error';
import logError from 'Helpers/logError';

export type CompanyMarketShare = {
	id: number;
	journeyStep: string | undefined;
	prospectingId: string | null;
	name: string;
	revenue: number;
	marketShare: number;
	marketShareChange: number;
	originalIndex?: number;
};

interface Props {
	clientData: CompanyMarketShare;
	competitors: CompanyMarketShare[];
	loading?: boolean;
	hasMarketShareData: boolean;
	close: () => void;
	onRefresh?: () => void;
}

const MarketShareTable = ({ hasMarketShareData, clientData, competitors, loading, close, onRefresh }: Props) => {
	const { bucketRange, description } = useIndustryInsights();
	const hasNewClientCard = Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD');
	const customFields = useCustomFields('account');
	const classes = new BemClass('MarketShareTable');
	const lang = {
		marketShare: T('default.marketShare'),
		marketShareChange: T('default.marketShareChange'),
		revenue: T('account.revenue'),
		similarCompaniesTooltip: T('ai.industryInsights.similarCompaniesTooltip', {
			value: bucketRange ? `${formatCurrency(bucketRange.minRev)} - ${formatCurrency(bucketRange.maxRev)}` : '',
			industry: description
		})
	};

	const goToAccount = (id: number, hasNewClientCard: boolean) => {
		const route = hasNewClientCard ? 'react-root-clientCard' : 'account.dashboard';

		Tools.$state.go(
			route,
			{
				customerId: Tools.AppService.getCustomerId(),
				id,
				page: 'overview'
			},
			{
				inherit: false
			}
		);
	};

	const formatPerc = (value: number | undefined) => {
		if (value === undefined) return '-';
		return formatPercentage(value);
	};

	const formatRev = (value: number | undefined) => {
		return formatCurrency(value);
	};

	const allClients = [
		{ ...clientData, originalIndex: 0 },
		...competitors.map((comp, idx) => ({
			...comp,
			originalIndex: idx + 1
		}))
	].sort((a, b) => b.revenue - a.revenue);

	const getClientColor = (client: CompanyMarketShare) => {
		if (client.id === clientData.id) return COMPANY_COLORS.CLIENT;
		return COMPANY_COLORS.COMPETITORS[(client.originalIndex! - 1) % COMPANY_COLORS.COMPETITORS.length];
	};

	const distributionData = allClients.map(client => ({
		id: `${client.id}-${client.originalIndex}`,
		label: `${client.name}${client.revenue ? ` (${formatRev(client.revenue)})` : ''}`,
		value: client.marketShare || 0,
		color: getClientColor(client),
		disableTooltip: client.marketShare === undefined
	}));

	const getChangeColor = (value: number | undefined) => {
		if (value === undefined) return undefined;
		return value < 0 ? 'danger-4' : value > 0 ? 'success-5' : undefined;
	};

	const [sorting, setSorting] = useState<{ field: string; asc: boolean }>({
		field: 'revenue',
		asc: false
	});

	const handleSort = (sort: { field: string; asc: boolean }) => {
		setSorting(sort);
	};

	const getSortedClients = () => {
		const sorted = [...allClients].sort((a, b) => {
			const aValue =
				a[sorting.field as keyof Pick<CompanyMarketShare, 'revenue' | 'marketShare' | 'marketShareChange'>];
			const bValue =
				b[sorting.field as keyof Pick<CompanyMarketShare, 'revenue' | 'marketShare' | 'marketShareChange'>];

			if (aValue < bValue) return sorting.asc ? -1 : 1;
			if (aValue > bValue) return sorting.asc ? 1 : -1;
			return 0;
		});
		return sorted;
	};

	const showAccountCreationModal = (client: CompanyMarketShare) => {
		const requiredFields = customFields.filter(value => {
			return value.obligatoryField && value.$hasAccess && value.editable && value.alias !== 'ORG_NO';
		});

		const doAddAccount = async (customValues: CustomField[] = [], modalClose?: () => void) => {
			return Prospecting.save({
				prospectingId: client.prospectingId,
				customValues
			})
				.then(({ data }) => {
					modalClose?.();
					onRefresh?.();
				})
				.catch((err: Error) => {
					logError(err);
				});
		};

		if (!requiredFields.length) {
			return doAddAccount();
		}

		openGenericModal({
			Component: (props: any) => (
				<Block key={client.id} id="create-new-account-modal">
					<RequiredFields key={client.id} {...props} />
				</Block>
			),
			requiredFields: requiredFields,
			fullscreen: true,
			actions: {
				addAccount: (addingAccount: any, properties: any, purchaseType: any, modalClose: () => void) =>
					doAddAccount(properties, modalClose)
			}
		});
	};

	const sortedClients = getSortedClients();
	const columns = [
		{ title: '' },
		{ title: lang.revenue, sort: 'revenue', sortType: 'numeric' },
		{ title: lang.marketShare + ' (%)', sort: 'marketShare', sortType: 'numeric' },
		{ title: lang.marketShareChange, sort: 'marketShareChange', sortType: 'numeric' }
	];

	// Adds cool loading state to look like a table
	if (loading) {
		return (
			<Flex direction="column" className={classes.b()} space="pll pbl prl ptl" gap="u2">
				<Text loading size="sm" />
				<Table className={classes.elem('table').b()}>
					{[...Array(8)].map((_, i) => (
						<TableRow key={`loading-${i}`}>
							{[...Array(4)].map((_, j) => (
								<TableColumn key={`loading-${i}-${j}`}>
									<Text loading size="sm" />
								</TableColumn>
							))}
						</TableRow>
					))}
				</Table>
			</Flex>
		);
	}

	return (
		<Flex direction="column" className={classes.b()} space="pbs ptl" gap="u2">
			{hasMarketShareData ? (
				<>
					<Flex gap="u2" alignItems="center" space="pll prl">
						<Text bold>
							{T(`ai.industryInsights.clientSizeComparison`, {
								client: clientData.name
							})}
						</Text>
						<Tooltip title={lang.similarCompaniesTooltip}>
							<Icon name="info-circle" loading={loading} />
						</Tooltip>
					</Flex>
					<Block space="pll prl">
						<DistributionBar
							distribution={distributionData}
							datatype="percent"
							thick={true}
							drilldown={false}
						/>
					</Block>
					<Table className={classes.elem('table').b()}>
						<TableHeader className={classes.elem('header').b()}>
							{columns.map((column, index) => (
								<TableHeaderColumn
									key={index}
									column={column}
									sorting={sorting.field}
									asc={sorting.asc}
									onSortChange={handleSort}
									className={classes.elem('header-column').b()}
								>
									<Text size="sm">{column.title}</Text>
								</TableHeaderColumn>
							))}
						</TableHeader>
						{sortedClients.map(client => (
							<TableRow className={classes.elem('row').b()} key={client.originalIndex}>
								<TableColumn className={classes.elem('larger-col').b()}>
									<Flex alignItems="center" gap="u3">
										<Icon name="circle" color={getClientColor(client)} />
										<Flex direction="column" gap="u1" className={classes.elem('larger-col').b()}>
											<EllipsisTooltip title={client.name}>
												<Text
													bold={client.id === clientData.id}
													color={client.id ? 'bright-blue' : undefined}
													onClick={e => {
														e.stopPropagation();
														close();
														goToAccount(client.id, hasNewClientCard);
													}}
													className={client.id ? 'clickable' : undefined}
												>
													{client.name}
												</Text>
											</EllipsisTooltip>
											{client.journeyStep ? (
												<Text size="sm" color="grey-10">
													{client.journeyStep}
												</Text>
											) : null}
										</Flex>
									</Flex>
								</TableColumn>
								<TableColumn className={classes.elem('larger-col').b()}>
									<Text>{formatRev(client.revenue)}</Text>
								</TableColumn>
								<TableColumn className={classes.elem('smaller-col').b()}>
									<Text>{formatPerc(client.marketShare)}</Text>
								</TableColumn>
								<TableColumn className={classes.elem('larger-col').b()}>
									<Flex gap="u1" justifyContent="space-between" alignItems="center">
										<Text color={getChangeColor(client.marketShareChange)}>
											{formatPerc(client.marketShareChange)}
										</Text>
										{!client.id ? (
											<Tooltip title={T('default.addExternal')} position="top" distance={20}>
												<DefaultButton
													className={classes.elem('addButton').b()}
													onClick={() => showAccountCreationModal(client)}
													size="sm"
												>
													<Icon name="plus" /> {T('default.add')}
												</DefaultButton>
											</Tooltip>
										) : null}
									</Flex>
								</TableColumn>
							</TableRow>
						))}
					</Table>
				</>
			) : (
				<EmptyData />
			)}
		</Flex>
	);
};

export default MarketShareTable;
