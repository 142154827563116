import Attribute, { DisplayType, Type } from './Attribute';

const createAttributes = (isCallList: boolean) => ({
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	startDate: Attribute({
		title: 'default.startDate',
		field: 'startDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		groupable: false,
		sortable: 'startDate',
		selectableColumn: !isCallList,
		filterable: false
	}),
	endDate: Attribute({
		title: 'default.endDate',
		field: 'endDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		groupable: false,
		sortable: 'endDate',
		selectableColumn: !isCallList,
		filterable: false
	}),
	active: Attribute({
		title: 'default.active',
		field: 'active',
		type: Type.Boolean,
		displayType: DisplayType.Boolean,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	name: Attribute({
		title: 'default.name',
		field: 'name',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: 'name',
		selectableColumn: true,
		filterable: false
	}),
	users: Attribute({
		title: 'default.users',
		type: Type.Array,
		displayType: DisplayType.Users,
		field: 'user.id',
		inputType: 'select',
		displayAttr: 'name',
		parent: 'users',
		attr: {
			id: Attribute({
				field: 'users.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'users.name',
				type: Type.String
			})
		},
		groupable: false,
		// NOT POSSIBLE!!
		// sortable: 'users.name',
		selectableColumn: true,
		filterable: false
	}),
	notes: Attribute({
		title: 'default.notes',
		field: 'notes',
		type: Type.String
	}),
	quota: Attribute({
		title: 'campaign.quota',
		field: 'quota',
		sortable: 'quota',
		selectableColumn: !isCallList,
		displayType: DisplayType.Number,
		placeholder: '0',
		type: Type.Number
	}),
	statsActivity: Attribute({
		title: 'default.activities',
		field: 'stats.activity',
		displayType: DisplayType.Number,
		displayKey: 'stats.activity',
		placeholder: '0',
		type: Type.Number,
		groupable: false,
		selectableColumn: !isCallList,
		filterable: false
	}),
	statsAppointment: Attribute({
		title: 'default.appointments',
		field: 'stats.appointment',
		displayType: DisplayType.Number,
		displayKey: 'stats.appointment',
		placeholder: '0',
		type: Type.Number,
		groupable: false,
		selectableColumn: !isCallList,
		filterable: false
	}),
	statsClient: Attribute({
		title: 'default.accounts',
		field: 'stats.client',
		displayType: DisplayType.Number,
		displayKey: 'stats.client',
		placeholder: '0',
		type: Type.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	statsContact: Attribute({
		title: 'default.contacts',
		field: 'stats.contact',
		displayType: DisplayType.Number,
		displayKey: 'stats.contact',
		placeholder: '0',
		type: Type.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	statsOrder: Attribute({
		title: 'default.orders',
		field: 'stats.order',
		displayType: DisplayType.Number,
		displayKey: 'stats.order',
		placeholder: '0',
		type: Type.Number,
		groupable: false,
		selectableColumn: !isCallList,
		filterable: false
	}),
	statsOpportunity: Attribute({
		title: 'default.opportunities',
		field: 'stats.opportunity',
		displayType: DisplayType.Number,
		displayKey: 'stats.opportunity',
		placeholder: '0',
		type: Type.Number,
		groupable: false,
		selectableColumn: !isCallList,
		filterable: false
	}),
	isCallList: Attribute({
		field: 'isCallList',
		type: Type.Boolean
	}),
	stats: Attribute({
		type: Type.Object,
		field: 'stats',
		parent: 'stats',
		attr: {
			activity: Attribute({ field: 'users.activity', type: Type.Number }),
			appointment: Attribute({ field: 'users.appointment', type: Type.Number }),
			client: Attribute({ field: 'users.client', type: Type.Number }),
			contact: Attribute({ field: 'users.contact', type: Type.Number }),
			opportunity: Attribute({ field: 'users.opportunity', type: Type.Number }),
			order: Attribute({ field: 'users.order', type: Type.Number })
		}
	}),
	custom: Attribute({
		type: Type.Array,
		field: 'custom'
	})
});

const ProjectAttributes = createAttributes(false);
const CallListAttributes = createAttributes(true);

export { CallListAttributes };
export default ProjectAttributes;
