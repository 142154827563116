import React, { useEffect, useMemo, useReducer, useState, useCallback } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './ProductSearch.scss';
import Header from './Header';
import CategoryBar from './CategoryBar';
import ProductList from './ProductList';
import ProductSearchSettingsResource from 'Resources/ProductSearchSettings';
import _, { debounce } from 'lodash';
import T from 'Components/Helpers/translate';

import type Order from 'App/resources/Model/Order';
import type Product from 'App/resources/Model/Product';
import type ProductCategory from 'App/resources/Model/ProductCategory';
import CustomField, { ProductCustomField } from 'App/resources/Model/CustomField';
import ListViewFilters from 'App/components/ListView/ListViewFilters';
import { ListViewFilter } from 'App/resources/AllIWant';
import ProductResource from 'Resources/Product';
import { getCFFieldIdFromFilterName, getCustomFieldConfig, parseFilters } from 'App/helpers/filterHelper';
import ProductAttributes from 'App/babel/attributes/ProductAttributes';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import { CustomFieldFilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import Report, { ReportEntity } from 'App/resources/Report';
import { comparisonTypes as ComparisonTypes, AggregationTypes, BuildFilters } from 'App/babel/resources/RequestBuilder';
import { useSoftDeployAccess } from 'App/components/hooks';
import OrderRow from 'App/resources/Model/OrderRow';
import { Paginator } from '@upsales/components';

// Knowing where this is called from (EditOrder) we are aware of that there is a state where you can have an order row without a product.
type OrderBeingEdited = Optional<Omit<Order, 'orderRow'>, 'id'> & {
	orderRow: Array<Optional<Omit<OrderRow, 'product'>, 'id'> & { product: Product | null }>;
};

type Props = {
	close: () => void;
	order: OrderBeingEdited;
	addOrderRow: (product: Product) => void;
	className?: string;
};

export type ProductSearchSettingsCategoryColumn = {
	fieldId: number;
	sortId: number;
};

export type ProductSearchSettings = {
	categories: {
		categoryId: number;
		sortId: number;
		columns: ProductSearchSettingsCategoryColumn[];
	}[];
};

type ActiveFilters = {
	[filterName: string]: ListViewFilter;
};

type Category = (ProductCategory | Pick<ProductCategory, 'id' | 'name'>) & {
	sortId?: number;
	columns?: ProductSearchSettingsCategoryColumn[];
	customFields?: ProductCustomField[];
	count?: number;
};

export type ProductSearchState = {
	activeFilters: ActiveFilters;
	addedProducts: number[];
	categories: Category[];
	products: Product[];
	searchStr: string;
	selectedCategory: number;
	selectedCategoryCount: number;
	settings: ProductSearchSettings;
	settingsFetched: boolean;
	loadingCategories: boolean;
	loadingProducts: boolean;
	settingsChanged: boolean;
	offset: number;
};

const initialState: ProductSearchState = {
	activeFilters: {},
	addedProducts: [],
	categories: [],
	products: [],
	searchStr: '',
	selectedCategory: 0,
	selectedCategoryCount: 0,
	settings: { categories: [] },
	settingsFetched: false,
	loadingCategories: true,
	loadingProducts: true,
	settingsChanged: false,
	offset: 0
};

export const MAX_COLUMNS = 3;
const MAX_ROWS = 100;

function getCategorySettings(categoryId: ProductCategory['id'], settings: ProductSearchSettings) {
	return settings.categories?.find(categorySetting => categorySetting.categoryId === categoryId);
}

function getUpdatedCategoryColumns(
	categoryColumns: ProductSearchSettingsCategoryColumn[],
	customFieldIdSet: Set<number>,
	categorySettings?: ProductSearchSettings['categories'][0]
) {
	if (categorySettings?.columns) {
		return categorySettings.columns
			.filter(col => customFieldIdSet.has(col.fieldId))
			.sort((a, b) => a.sortId - b.sortId);
	}
	return categoryColumns;
}

function getFilterConfigs(customFields: ProductCustomField[]) {
	const filterConfigs = customFields.reduce<{ [filterName: string]: CustomFieldFilterConfig }>((res, customField) => {
		const filterName = `product.Custom_${customField.id}`;
		res[filterName] = getCustomFieldConfig({
			entityType: 'product',
			name: filterName,
			field: customField,
			fieldId: customField.id
		});
		return res;
	}, {});

	const lookupValues = async (fieldId: number, searchString: string, filters: ActiveFilters) => {
		const requestBuilder = parseFilters(filters, 'product', null, null, {
			getConfig: filterName => filterConfigs[filterName]
		});

		requestBuilder.addExtraParam('useEntityAccess', true);

		const aggregationBuilder = requestBuilder.aggregationBuilder();
		aggregationBuilder.aggregationName('lookup');
		const path = 'custom';
		aggregationBuilder.addAggregation(AggregationTypes.Terms, { field: `${path}.value` });
		aggregationBuilder.addFilter({ field: `${path}.fieldId` }, ComparisonTypes.Equals, fieldId);
		aggregationBuilder.aggregationSize(MAX_ROWS);
		aggregationBuilder.done();

		if (searchString) {
			requestBuilder.addFilter({ field: `${path}.value` }, ComparisonTypes.WildcardEnd, searchString);
		}

		const result = (await Report.find(ReportEntity.PRODUCT, requestBuilder.build())) as {
			data: { lookup: { buckets: { key: string }[] } };
		};

		// Sort the options by their order in the custom field settings
		// Options that have been removed are put at the end
		const fieldOptions = customFields.find(cf => cf.id === fieldId)?.default as string[] | undefined;
		result.data.lookup.buckets = result.data.lookup.buckets
			.map(bucket => {
				let sortId = Infinity;
				if (fieldOptions) {
					const index = fieldOptions.findIndex(option => option.toLowerCase() === bucket.key);
					if (index !== -1) {
						sortId = index;
					}
				}
				return {
					...bucket,
					sortId
				};
			})
			.sort((a, b) => a.sortId - b.sortId);

		return result.data.lookup.buckets;
	};

	for (const [filterName, config] of Object.entries(filterConfigs)) {
		const customField = config.$field!;

		if (['MultiSelect', 'Select'].includes(customField.datatype)) {
			config.searchFn =
				() =>
				async (term, fields, offset, limit, sort, activeFilters = {}) => {
					const { [filterName]: omitted, ...otherFilters } = activeFilters;
					const [allValues, filterValues] = await Promise.all([
						lookupValues(customField.id, term, {}),
						lookupValues(customField.id, term, otherFilters)
					]);

					const filterValuesData = filterValues.map(({ key }) => {
						const name =
							(customField?.default as string[])?.find(value => value.toLowerCase() === key) ?? key;
						return { id: key, name };
					});

					const allValuesData = allValues
						.filter(({ key }) => !filterValuesData.some(({ id }) => id === key))
						.map(({ key }) => {
							const name =
								(customField?.default as string[])?.find(value => value.toLowerCase() === key) ?? key;
							return { id: key, name };
						});

					const data = [];

					if (filterValuesData.length) {
						data.push({
							id: 'filterValuesData',
							title: T('productSearch.optionsWithProducts'),
							isGroup: true
						});
						data.push(...filterValuesData);
					}

					if (allValuesData.length) {
						data.push({
							id: 'allValuesData',
							title: T('productSearch.optionsWithoutProducts'),
							isGroup: true
						});
						data.push(...allValuesData);
					}

					return {
						data: data.slice(offset, offset + limit),
						metadata: { total: data.length, offset, limit }
					};
				};
		}
	}

	return filterConfigs;
}

const ProductSearch = ({ close, order, addOrderRow, className }: Props) => {
	const [customFields, customFieldIdSet] = useMemo(() => {
		const customFields = Tools.AppService.getCustomFields('product').filter(
			(cf: CustomField) => cf.$hasAccess
		) as ProductCustomField[];
		const customFieldIdSet = new Set(customFields.map(cf => cf.id));
		return [customFields, customFieldIdSet];
	}, []);
	const [showConfirmDialogue, setShowConfirmDialogue] = useState(false);
	const hasSoftDeployAccess = useSoftDeployAccess('PRODIUCT_PATTERN_SEARCH');
	const hasImprovedProductSearch = useSoftDeployAccess('ORDER_PRODUCTS_IMPROVED_SEARCH');
	const classes = new BemClass('ProductSearch', className);
	classes.add('FullScreenModal');

	const shouldFetchProducts = Tools.AppService.getTotals('products') > 4000;

	const handleClose = (state: ProductSearchState) => {
		if (state.settingsChanged && Tools.AppService.getSelf().administrator) {
			setShowConfirmDialogue(true);
		} else {
			close();
		}
	};

	const init = (state: ProductSearchState) => {
		state.products = Tools.AppService.getProducts(true, false, true);
		state.loadingProducts = false;
		state.categories = _.cloneDeep(Tools.AppService.getProductCategories());
		state.categories.forEach(category => {
			category.customFields = customFields.filter((customField: ProductCustomField) =>
				customField.categories.some(c => c.id === category.id)
			);
			category.columns = (
				category.customFields
					?.slice(0, MAX_COLUMNS)
					.map(customField => ({ fieldId: customField.id, sortId: 0 })) || []
			).sort((a, b) => a.sortId - b.sortId);
		});

		state.categories.unshift({
			id: 0,
			name: T('default.all'),
			customFields: customFields,
			columns:
				customFields
					.slice(0, MAX_COLUMNS)
					.map((customField: ProductCustomField) => ({ fieldId: customField.id, sortId: 0 })) || [],
			count: Tools.AppService.getTotals('activeProducts')
		});

		state.selectedCategory = state.categories[0].id;
		state.selectedCategoryCount = state.categories[0].count!;

		state.addedProducts = order.orderRow.reduce((res, row) => {
			if (row.product?.id) {
				res.push(row.product.id);
			}
			return res;
		}, [] as ProductSearchState['addedProducts']);

		return state;
	};

	const getProducts = useCallback(
		debounce(async (filters: any, dispatch: React.Dispatch<Partial<ProductSearchState>>) => {
			const products = await ProductResource.find({ ...filters, usePriceLists: true });
			dispatch({ products: products.data });
		}, 50),
		[]
	);

	const getProductCountsForCategories = useCallback(
		debounce(
			async (
				filters: BuildFilters,
				categories: Category[],
				dispatch: React.Dispatch<Partial<ProductSearchState>>
			) => {
				filters.aggs = [
					{
						type: 'terms',
						field: ProductAttributes.categoryId.field,
						aggName: 'categories'
					}
				];
				await Report.find(ReportEntity.PRODUCT, filters).then((result: any) => {
					for (let i = 1; i < categories.length; i++) {
						const data = result.data.categories.buckets.find(
							(bucket: any) => bucket.key === categories[i].id
						);
						categories[i].count = data ? data.doc_count : 0;
					}
					categories[0].count = result.data.categories.doc_count;
					dispatch({ categories });
				});
			},
			50
		),
		[]
	);

	const [state, dispatch] = useReducer(
		(prev: ProductSearchState, next: Partial<ProductSearchState>) => {
			const updatedState = { ...prev, ...next };

			if (next.settings && next.settings !== prev.settings) {
				if (!prev.settingsFetched) {
					// Add settings to categories when settings data is loaded
					updatedState.categories = updatedState.categories
						.map(category => {
							const settings = getCategorySettings(category.id, updatedState.settings);

							category.columns = getUpdatedCategoryColumns(category.columns!, customFieldIdSet, settings);

							category.sortId = settings?.sortId || Infinity;
							if (category.id === 0) category.sortId = -1;

							return category;
						})
						.sort((a, b) => a.sortId! - b.sortId!);
				} else {
					updatedState.settingsChanged = true;
					// When settings are updated by editColumns and it's only for one category
					const updatedCategory = updatedState.categories.find(
						category => category.id === updatedState.selectedCategory
					)!;

					const settings = getCategorySettings(updatedCategory.id, updatedState.settings);
					updatedCategory.columns = getUpdatedCategoryColumns(
						updatedCategory.columns!,
						customFieldIdSet,
						settings
					);
				}
			}

			if (next.products) {
				updatedState.loadingProducts = false;
			}

			if (next.selectedCategory) {
				const selectedCategoryObj = updatedState.categories.find(
					category => category.id === next.selectedCategory
				);

				const updatedActiveFilters: ActiveFilters = {};

				if (selectedCategoryObj?.customFields) {
					for (const filterName in updatedState.activeFilters) {
						const customFieldId = getCFFieldIdFromFilterName(filterName);

						if (selectedCategoryObj.customFields.some(cf => cf.id === customFieldId)) {
							updatedActiveFilters[filterName] = updatedState.activeFilters[filterName];
						}
					}
				}
				updatedState.activeFilters = updatedActiveFilters;
			}

			const selectionChanged =
				next.activeFilters ||
				updatedState.searchStr !== prev.searchStr ||
				updatedState.selectedCategory !== prev.selectedCategory;

			const updateProducts = shouldFetchProducts && (updatedState.offset !== prev.offset || selectionChanged);

			if (updateProducts) {
				if (selectionChanged) {
					updatedState.offset = 0;
				}

				const rb = parseFilters(updatedState.activeFilters, 'product', null, null, {
					getConfig: (filterName, type) => {
						const fieldId = getCFFieldIdFromFilterName(filterName) ?? undefined;
						const field = Tools.AppService.getCustomFields('product').find(cf => cf.id === fieldId);
						return getCustomFieldConfig({ name: filterName, fieldId, entityType: type, field });
					}
				});

				if (updatedState.searchStr.length) {
					if (hasSoftDeployAccess) {
						const groupBuilder = rb.groupBuilder();
						updatedState.searchStr.split(' ').forEach(searchStr => {
							const orBuilder = groupBuilder.orBuilder();
							orBuilder.addFilter(ProductAttributes.name, 'wc', searchStr);
							orBuilder.next();
							orBuilder.addFilter(ProductAttributes.articleNo, 'wc', searchStr);
							orBuilder.done();
						});
						groupBuilder.done();
					} else {
						const orBuilder = rb.orBuilder();
						orBuilder.addFilter(ProductAttributes.name, 'wc', updatedState.searchStr);
						orBuilder.next();
						orBuilder.addFilter(ProductAttributes.articleNo, 'wc', updatedState.searchStr);
						orBuilder.done();
					}
					if (hasImprovedProductSearch) {
						rb.addScore({ field: 'custom.value' }, 'eq', updatedState.searchStr);
					}
				}

				rb.addFilter(ProductAttributes.active, 'eq', true);

				// TODO: add sort to filter parsing
				rb.addSort(ProductAttributes.sortId, true);
				rb.offset = updatedState.offset;
				rb.limit = MAX_ROWS;

				updatedState.loadingProducts = true;

				if (dispatch) {
					getProductCountsForCategories(rb.build(), state.categories, dispatch);
					if (updatedState.selectedCategory) {
						rb.addFilter(ProductAttributes.categoryId, 'eq', updatedState.selectedCategory);
					}
					getProducts(rb.build(), dispatch);
				}
			}

			updatedState.selectedCategoryCount = updatedState.categories.find(
				(c: Category) => c.id === updatedState.selectedCategory
			)!.count!;

			return updatedState;
		},
		initialState,
		init
	);

	useEffect(() => {
		const getSettings = async () => {
			// Not sure how this will work just yet and you can only config settings via API so we just pick the first result for now
			const settings = await ProductSearchSettingsResource.find();
			if (settings.data?.length) {
				dispatch({ settings: settings.data[0] });
			}
			dispatch({ settingsFetched: true });
		};
		const getProducts = async () => {
			dispatch({ loadingProducts: true });
			const products = await ProductResource.find({ usePriceLists: true, active: true, limit: MAX_ROWS });
			dispatch({ products: products.data });
		};
		const getProductCountsForCategories = async () => {
			const promises = state.categories.slice(1).map((category: Category) => {
				return ProductResource.find({
					q: [{ a: ProductAttributes.categoryId.field, c: 'eq', v: category.id }],
					active: true,
					limit: 0
				});
			});
			const results = await Promise.all(promises);
			const categories: Category[] = results
				.map((result, i) => {
					return { ...state.categories[i + 1], count: result.metadata.total };
				})
				.sort((a, b) => a.sortId! - b.sortId!);
			categories.unshift(state.categories[0]);
			dispatch({ categories, loadingCategories: false });
		};

		getSettings();
		if (shouldFetchProducts || !state.products.length) {
			getProducts();
			getProductCountsForCategories();
		} else {
			dispatch({ loadingCategories: false });
		}
	}, []);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				handleClose(state);
			}
		};
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [state.settingsChanged]);

	const filterConfigs = useMemo(() => {
		const categoryCustomFields = customFields
			.filter((cf: ProductCustomField) =>
				state.selectedCategory ? cf.categories.some((c: Category) => c.id === state.selectedCategory) : true
			)
			.sort((a: ProductCustomField, b: ProductCustomField) => a.sortId - b.sortId);
		return getFilterConfigs(categoryCustomFields);
	}, [state.selectedCategory]);

	return (
		<div className={classes.b()}>
			<div className={classes.elem('list').b()}>
				<Header
					className={classes.elem('header').b()}
					description={order.description}
					state={state}
					dispatch={dispatch}
					close={() => handleClose(state)}
				/>
				{!state.loadingCategories && state.settingsFetched ? (
					<CategoryBar className={classes.elem('categoryBar').b()} state={state} dispatch={dispatch} />
				) : null}
				<ProductList
					className={classes.elem('products').b()}
					state={state}
					dispatch={dispatch}
					addOrderRow={addOrderRow}
					serverFilteredProducts={shouldFetchProducts}
				/>
				{state.selectedCategoryCount > MAX_ROWS && (
					<div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
						<Paginator
							limit={MAX_ROWS}
							offset={state.offset}
							total={state.selectedCategoryCount}
							paddButtons={4}
							align="center"
							onChange={(offset: number) => dispatch({ offset: offset })}
						/>
					</div>
				)}
			</div>
			<div className={classes.elem('filters').b()}>
				<ListViewFilters
					activeFilters={state.activeFilters}
					filterConfigs={filterConfigs}
					onChange={activeFilters => dispatch({ activeFilters })}
					onVisibleChange={() => {}}
					hasChanged={false}
					listType="product"
					opts={{ alwaysOpen: true }}
				/>
			</div>
			{showConfirmDialogue ? (
				<InlineAction
					toggleInlineAction={() => setShowConfirmDialogue(!showConfirmDialogue)}
					onConfirm={() => {
						ProductSearchSettingsResource.save(state.settings);
						close();
					}}
					onReject={close}
					showTop
					showLeft
					showRight={false}
				/>
			) : null}
		</div>
	);
};

export default ProductSearch;
