import { Flex, Title, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import React from 'react';

const EmptyData = () => (
	<Flex space="ptxl pbxl" alignItems="center" direction="column">
		<img alt="upsales briefcase" src="img/empty-briefcase.svg" />
		<Flex space="ptl pbl" gap="u2" direction="column">
			<Title align="center" color="grey-10">
				{T('default.noResults')}
			</Title>
			<Text align="center" color="grey-10">
				{T('noMatches.reason')}
			</Text>
		</Flex>
	</Flex>
);

export default EmptyData;
