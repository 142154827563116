import { IconName } from '@upsales/components';

export enum Type {
	String = 'String',
	Boolean = 'Boolean',
	Number = 'Number',
	Float = 'Float',
	Email = 'Email',
	Date = 'Date',
	Time = 'Time',
	DateTime = 'DateTime',
	DateStringOrDateTime = 'DateStringOrDateTime',
	StringifiedObject = 'StringifiedObject',
	Object = 'Object',
	Array = 'Array'
}

export enum DisplayType {
	Array = 'array',
	Category = 'category',
	Text = 'text',
	SubtitleText = 'subtitletext',
	Date = 'date',
	DateTime = 'datetime',
	DateUser = 'dateuser',
	Score = 'score',
	User = 'user',
	Users = 'users',
	UserText = 'userText',
	Custom = 'custom',
	Number = 'number',
	Boolean = 'boolean',
	PhoneNumber = 'phone',
	Email = 'email',
	Currency = 'currency',
	CurrencyChange = 'currencyChange',
	Journey = 'journey',
	Percent = 'percent',
	Stage = 'stage',
	StaticValue = 'static',
	URL = 'url',
	ProjectLink = 'projectLink',
	ClientLink = 'clientLink',
	MainAccount = 'mainAccount',
	ClientHistory = 'clientHistory',
	ClientLocation = 'clientLocation',
	Location = 'location',
	ContactHistory = 'contactHistory',
	Activity = 'activity',
	Year = 'year',
	Assigned = 'assigned',
	LeadSource = 'leadSource',
	CreditRating = 'creditRating',
	ContactLink = 'contactLink',
	Contact = 'contact',
	Contacts = 'contacts',
	ContactsText = 'contactsText',
	ContactTitle = 'contactTitle',
	MailTemplate = 'mailTemplate',
	MailStatus = 'mailStatus',
	ARRChange = 'arrChange',
	FileSize = 'fileSize',
	FileRelation = 'fileRelation',
	FileType = 'fileType',
	EsignOpportunity = 'esignOpportunity',
	Opportunity = 'opportunity',
	Order = 'order',
	EsignStatus = 'esignStatus',
	EsignInvolved = 'esignInvolved',
	EllipsisText = 'ellipsisText',
	Phone = 'phone'
}

export type Attr = {
	title: string;
	field: string;
	fieldPath?: string;
	type: Type;
	attr: {
		[key: string]: Attr;
	};
	size?: number;
	selectableColumn: boolean;
	sortable: string | false;
	displayType?: DisplayType;
	parent?: string | null;
	placeholder: string | null;
	displayKey: string | string[] | null;
	displayKeyMap?: { [key: string]: string } | null;
	currencyFallback?: string | null;
	elevioId?: number | null;
	unreleasedFeature: 'NEW_FIELDS' | null;
	locked: boolean;
	colorize?: boolean;
	icon?: IconName | null;
	tooltip?: string | null;
};

type AttributeType = {
	type: Type; // Datatype of the attribute
	title?: string; // title, mostly used in list views
	field: string; // field to point to when filtering the attribute
	fieldPath?: string;
	attr?: {}; // subattributes
	size?: number;
	groupable?: boolean;
	sortable?: string | false; // used in listviews if set then attribute can be sorted on. Value decides what field to use
	selectableColumn?: boolean; // if attribute should be selectable in listviews or not
	filterable?: boolean;
	inputType?: string;
	displayAttr?: string;
	parent?: string;
	link?: string;
	displayType?: Exclude<DisplayType, DisplayType.Currency>; // decides on how to display the attribute in listviews
	displayKey?: string | string[]; // if attribute key differs from the key we want to read from the entity and show in the list view
	placeholder?: string; // used to placehold nullish values
	displayKeyMap?: { [key: string]: string };
	currencyFallback?: undefined;
	elevioId?: number;
	icon?: IconName; //used in listviews to show icon and tooltip instead of title
	unreleasedFeature?: 'NEW_FIELDS';
	locked?: boolean;
	tooltip?: string | null;
	colorize?: boolean;
};

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
type CurrencyAttribute = Overwrite<
	AttributeType,
	{
		displayType: DisplayType.Currency;
		currencyFallback?: string;
	}
>;

type Props = AttributeType | CurrencyAttribute;

function Attribute(props: Props): Attr {
	// Validate props here
	if (props.type && !Type[props.type]) {
		throw new Error(`"${props.type}" is not a valid property type`);
	}

	const attribute: Attr = {
		type: props.type || Type.String,
		title: props.title || '',
		field: props.field,
		fieldPath: props.fieldPath,
		attr: props.attr || {},
		selectableColumn: !!props.selectableColumn,
		sortable: props.sortable ?? false,
		displayType: props.displayType || DisplayType.Text,
		displayKey: props.displayKey || null,
		parent: props.parent || null,
		displayKeyMap: props.displayKeyMap || null,
		currencyFallback: props.currencyFallback || null,
		placeholder: props.placeholder || null,
		elevioId: props.elevioId || null,
		icon: props.icon || null,
		unreleasedFeature: props.unreleasedFeature ?? null,
		locked: props.locked ?? false,
		tooltip: props.tooltip ?? null,
		colorize: props.colorize
	};

	if (props.size) {
		attribute.size = props.size;
	}

	return attribute;
}

Attribute.types = Type;

export default Attribute;
