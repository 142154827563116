import React from 'react';
import CustomerPortfolioWidget from '../CustomerPortfolioWidget';
import { useTranslation } from 'Components/Helpers/translate';

import BadDataWarning from 'App/components/BadDataWarning/BadDataWarning';
import { type RCWidgetData } from 'Resources/ReportWidget';
import useSelector from 'App/components/hooks/useSelector';
import { DisplayValueType, WidgetFilter } from '../CustomerPortfolio';
import { RootState } from 'Store/index';
import { hasSoliditetMatcher } from 'App/helpers/matcherHelper';

type Props = {
	valueType: DisplayValueType;
	filters?: WidgetFilter[];
};

const BiggestIndustriesWidget = ({ valueType, filters = [] }: Props) => {
	const { t } = useTranslation();
	const { self, accountSelf, customerId } = useSelector(({ App }: RootState) => ({
		self: App.self,
		accountSelf: App.accountSelf,
		customerId: App.customerId
	}));

	const [occurances, setOccurances] = React.useState<null | number>(null);

	const onDataFetched = (data: RCWidgetData) => {
		if (data.total?.special?.missingSNIAmount != null) setOccurances(data.total.special.missingSNIAmount);
	};

	const linkTarget = Tools.$state.href('matcher', {
		customerId
	});

	return (
		<CustomerPortfolioWidget
			valueType={valueType}
			config={{
				displayType: 'table',
				groupBy: ['industry', 'client'],
				type: 'BIGGEST_INDUSTRIES',
				goal: false,
				filters,
				skipTotal: true
			}}
			isBig
			extraHeaderInfo={
				hasSoliditetMatcher(self, accountSelf) && occurances ? (
					<BadDataWarning
						linkTarget={linkTarget}
						tooltipTitle={t('default.sniCode.clean', { occurances: occurances })}
						occurances={occurances}
					/>
				) : null
			}
			onDataFetched={onDataFetched}
		/>
	);
};

export default BiggestIndustriesWidget;
