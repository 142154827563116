import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './ReportcenterFilter.scss';
import { Text, Icon, DropDownMenu, Block, Tooltip, IconName } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import _ from 'lodash';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import { RootState } from 'App/babel/store';

export type CustomValue = { [k: string]: { [k: number]: (number | string)[] } };
export type Value =
	| number
	| string
	| (string | number)[]
	| CustomValue
	| { [k: string]: { [k: number]: { value: (number | string)[] } } | (number | null) }
	| null;

export interface Props {
	className: string;
	renderSelected: () => string;
	icon: IconName;
	placeholder: string;
	value: Value;
	resetFilter: () => void;
	getSelectedNames?: () => string[];
	children?: React.ReactNode;
	values: RCDashboardFilter;
	updateFilterHeight?: () => void;
	dashboard?: RootState['Reportcenter']['selectedDashboard'];
	renderCustomDropdown?: () => React.ReactNode;
	isDescription?: boolean;
	customOnClick?: (e: any, values: string) => void;
	removeFilter?: (values: string) => void;
	disableFutureDates?: boolean;
	product?: boolean;
	listFilterMode?: boolean;
}

const formatSelectedNamesTooltip = (selectedNames: string[]) => {
	const count = selectedNames.length;
	if (count > 8) {
		return selectedNames.slice(0, 8).join(', ') + ` +${count - 8} more`;
	}
	return selectedNames.join(', ');
};

const ReportcenterFilter = ({
	className,
	icon,
	placeholder,
	children,
	value,
	isDescription,
	renderSelected,
	resetFilter,
	getSelectedNames,
	updateFilterHeight,
	renderCustomDropdown,
	customOnClick,
	removeFilter,
	disableFutureDates,
	listFilterMode,
	...props
}: Props) => {
	const classes = isDescription
		? new bemClass('ReportcenterDescriptionFilter', className)
		: new bemClass('ReportcenterFilter', className);
	const isEmpty =
		!value ||
		(Array.isArray(value) && !value.length) ||
		(typeof value === 'object' && Object.keys(value).length === 0);
	const [alignContent, setAlignContent] = useState<'left' | 'right'>('left');
	const buttonRef = useRef<HTMLButtonElement>(null);
	const values = props.values;
	const updateAlignment = () => {
		const element = buttonRef.current;
		if (element) {
			const rect = element.getBoundingClientRect();
			const distanceToEdge = rect.right / window.innerWidth;
			const shouldAlignRight = distanceToEdge > 0.75;
			setAlignContent(shouldAlignRight ? 'right' : 'left');
		}
	};

	useEffect(() => {
		updateAlignment();
		const handleResize = _.debounce(updateAlignment, 200);
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [values]);
	return (
		<div className={classes.b()}>
			<DropDownMenu
				align={alignContent}
				onOpen={updateAlignment}
				onClose={updateFilterHeight}
				renderTrigger={(isExpanded, setExpanded) => (
					<button
						ref={buttonRef}
						onClick={customOnClick ? e => customOnClick(e, className) : e => setExpanded(e)}
						className={classes.elem('trigger').mod({ active: isExpanded, selected: !isEmpty }).b()}
					>
						<Text size="sm" color={isDescription || isEmpty || isExpanded ? 'grey-10' : 'green'}>
							{icon ? (
								<Icon name={icon} color={!isEmpty && !isExpanded ? 'green' : 'grey-10'} space="mrs" />
							) : null}

							{!isEmpty ? (
								getSelectedNames ? (
									<Tooltip
										distance={30}
										position="bottom"
										title={formatSelectedNamesTooltip(getSelectedNames())}
									>
										{renderSelected()}
									</Tooltip>
								) : (
									renderSelected()
								)
							) : (
								placeholder
							)}
							{isDescription ? (
								<Icon
									name={isExpanded ? 'chevron-up' : 'chevron-down'}
									color={'grey-10'}
									className={classes.elem('editIcon').b()}
								/>
							) : !isEmpty && !isExpanded ? (
								<Tooltip style={{ marginLeft: '10px' }} title={T('reportcenter.filter.clearFilter')}>
									<Icon
										className={classes.elem('reset-filter').b()}
										name="times"
										color="green"
										onClick={(e: React.MouseEvent<HTMLElement>) => {
											e.stopPropagation();
											e.preventDefault();
											resetFilter();
										}}
									/>
								</Tooltip>
							) : null}
							{removeFilter && isEmpty ? (
								<Icon
									className={classes.elem('reset-filter').b()}
									name="times"
									color="green"
									onClick={(e: React.MouseEvent<HTMLElement>) => {
										e.stopPropagation();
										e.preventDefault();
										removeFilter(className);
									}}
									style={{ marginLeft: '10px' }}
								/>
							) : null}
						</Text>
					</button>
				)}
			>
				{() => {
					if (renderCustomDropdown) {
						return renderCustomDropdown();
					} else if (listFilterMode) {
						return <div>{children}</div>;
					} else {
						return (
							<div className={classes.elem('dropdown').b()}>
								{children}
								<Block
									space={`${props.product ? 'pts' : 'ptl'} prl pbl pll`}
									backgroundColor="white"
									className={classes.elem('info').b()}
									border="ts"
									borderColor="grey-2"
								>
									<Text size="sm" color="grey-10">
										{T('reportcenter.dbFilterOverridesWidget')}
									</Text>
								</Block>
							</div>
						);
					}
				}}
			</DropDownMenu>
		</div>
	);
};

ReportcenterFilter.propTypes = {
	icon: PropTypes.string,
	placeholder: PropTypes.string,
	renderSelected: PropTypes.func.isRequired,
	resetFilter: PropTypes.func.isRequired,
	getSelectedNames: PropTypes.func
};

export default ReportcenterFilter;
