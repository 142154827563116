import CustomFieldInput from './CustomFieldInput';
import HiddenProductInput from './HiddenProductInput';
import MaxDiscountInfo from 'App/components/OrderRows/OrderRow/MaxDiscountInfo';
import NumberInput from './NumberInput';
import PriceInput from './PriceInput';
import PurchaseCostInput from './PurchaseCostInput';
import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import Tooltip from './Tooltip';
import _ from 'lodash';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import getSpeedTracker from 'App/helpers/speedTracker';
import logError from 'App/babel/helpers/logError';
import { ProductDescriptionTooltip } from 'App/components/OrderRows/OrderRow/ProductCategorySelect/ProductDescriptionTooltip';
import { currencyFormat, numberFormat } from 'Components/Filters/Currencies';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';
import { getCalculatingFieldTooltip } from 'App/helpers/formulaText';
import { getTierFromOrderRow, isTieredTotalOrderRow } from 'App/babel/helpers/order';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import classNames from 'classnames';

import type OrderRow from 'App/resources/Model/OrderRow';
import type { Actions } from '../hooks/useEditOrderLogic';
import type { EditOrderOrderRow, EditOrderCustomField, Form, ModifiedEvent } from '../types';
import type { ProvidedProps } from './OrderRows';

export type Props = {
	OrderForm: Form;
	copyRow: Actions['copyOrderRow'];
	currency: string;
	customFieldChange: Actions['orderRowCustomFieldChange'];
	decimalsPrice: number;
	deleteRow: Actions['deleteOrderRow'];
	discountChange: Actions['calc']['discountChange'];
	discountPercentChange: Actions['calc']['discountPercentChange'];
	fieldsDescription: { [key: string]: string };
	fieldsSyncDir: { [key: string]: string };
	hasContributionMargin: boolean;
	hasCustom: boolean;
	highlightedFields: string[];
	inputDebounceSetting: undefined | number;
	isDisabledField: Actions['isDisabledField'];
	isProductBundle: boolean;
	isVisibleField: Actions['isVisibleField'];
	openEditProductBundle: Actions['openEditProductBundle'];
	orderRow: EditOrderOrderRow;
	priceChange: Actions['calc']['priceChange'];
	productChange: Actions['calc']['productChange'];
	purchaseCostChange: Actions['calc']['purchaseCostChange'];
	quantityChange: Actions['calc']['quantityChange'];
	recurringInterval: number;
	rowEditable: boolean;
	rowIndex: number;
	saveDisabled: boolean;
	saving: boolean;
	sortDown: Actions['sortRowDown'];
	sortUp: Actions['sortRowUp'];
	syncedToApp: string | undefined;
	useDiscount: boolean;
};

const OrderRowComponent = (props: Props) => {
	const {
		OrderForm,
		cacheProductSearch,
		cachedProductSearches,
		categories,
		categoryTree,
		copyRow: $copyRow,
		currency,
		customFieldChange: $customFieldChange,
		decimalsPrice,
		deleteRow: $deleteRow,
		discountChange: $discountChange,
		discountPercentChange: $discountPercentChange,
		fieldsDescription,
		fieldsSyncDir,
		hasContributionMargin,
		hasCustom,
		highlightedFields,
		inputDebounceSetting,
		isDisabledField,
		isProductBundle,
		isSearch,
		isVisibleField,
		openEditProductBundle: $openEditProductBundle,
		orderRow,
		priceChange: $priceChange,
		productChange: $productChange,
		productTree,
		purchaseCostChange: $purchaseCostChange,
		quantityChange: $quantityChange,
		recurringInterval,
		rowEditable,
		rowIndex,
		saveDisabled,
		saving,
		searchCategories,
		searchProducts,
		searchableProductCustomFieldIds,
		sortDown: $sortDown,
		sortUp: $sortUp,
		syncedToApp,
		useDiscount
	} = props as Props & ProvidedProps;

	const isAgreement = false;
	const fakeProductSelectRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const wrapperRef = useRef<HTMLInputElement>(null);
	const [priceIsFocused, setPriceIsFocused] = useState(false);
	const { t } = useTranslation();

	const selectedTier = getTierFromOrderRow({ ...orderRow, tierQuantity: orderRow.quantity });

	const {
		isAdmin,
		customerId,
		salesModelOption,
		hasRecurringInterval,
		numOfDecimalsPrice,
		hasAppValidationWithOrderEdit,
		hasDiscount,
		hasFormulaVisible,
		showArticleNo,
		hasProductBundles,
		hasProductSearchOptimization,
		hasOrderRowFieldsDebounce
	} = useMemo(() => {
		const FeatureHelper = getAngularModule('FeatureHelper');
		const AppService = getAngularModule('AppService');

		const metadata = AppService.getMetadata();
		const self = AppService.getSelf();
		const roles = AppService.getRoles();
		const isAdmin = self.administrator;
		const cmWithrrOption = getCMWithRROption(metadata);
		const salesModel = metadata.params.SalesModel;
		const salesModelOption = cmWithrrOption ?? metadata.params.SalesModelOption;
		const hasRecurringInterval =
			(salesModel === 'rr' && recurringInterval && ['arr', 'mrr'].indexOf(salesModelOption) >= 0) ||
			!!cmWithrrOption;
		const numOfDecimalsPrice = metadata.params.OrderedProductPriceDecimals || 2;
		const selfRoleId = self.role?.id || null;
		const myRole = roles.find(role => role.id === selfRoleId);
		const hasDiscount = !myRole || myRole?.hasDiscount || !FeatureHelper.hasSoftDeployAccess('MAX_DISCOUNT');
		const productStandardFields = metadata.standardFields.Product;
		const showArticleNo =
			_.get(productStandardFields, 'ArticleNo.active', false) && FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		const hasProductBundles =
			FeatureHelper.hasSoftDeployAccess('PRODUCT_BUNDLE') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PRODUCT_BUNDLES);
		const hasProductSearchOptimization = FeatureHelper.hasSoftDeployAccess('SPEED_EDIT_ORDER_PRODUCT_SEARCH');
		const customerId = AppService.getCustomerId();
		const hasOrderRowFieldsDebounce = FeatureHelper.hasSoftDeployAccess('ORDER_ROW_FIELDS_DEBOUNCE');
		const hasFormulaVisible = FeatureHelper.hasSoftDeployAccess('FORMULA_VISIBLE');
		const hasAppValidationWithOrderEdit = FeatureHelper.hasSoftDeployAccess('APP_VALIDATION_WITH_ORDER_EDIT');

		return {
			isAdmin,
			customerId,
			salesModelOption,
			hasRecurringInterval,
			numOfDecimalsPrice,
			hasAppValidationWithOrderEdit,
			hasDiscount,
			hasFormulaVisible,
			showArticleNo,
			hasProductBundles,
			hasProductSearchOptimization,
			hasOrderRowFieldsDebounce
		};
	}, []);

	const isDisabledRowField = (field: string, isDisabled?: boolean) => {
		const fieldName = `orderrow.${field}`;
		const orderRowFieldName = `orderrow@${orderRow.id}.${field}`;
		const orderRowSortFieldName = `orderrow#${orderRow.sortId}.${field}`;
		return isDisabledField(fieldName, isDisabled, orderRowFieldName, orderRowSortFieldName);
	};

	const isVisibleRowField = (field: string, isVisible?: boolean, isHiddenByProductCategory?: boolean) => {
		const fieldName = `orderrow.${field}`;
		const orderRowFieldName = `orderrow@${orderRow.id}.${field}`;
		const orderRowSortFieldName = `orderrow#${orderRow.sortId}.${field}`;

		return isVisibleField(
			fieldName,
			isVisible,
			orderRowFieldName,
			isHiddenByProductCategory,
			orderRowSortFieldName
		);
	};

	useEffect(() => {
		const productSelect = $(inputRef.current!);
		const wrapper = $(wrapperRef.current!);

		let activeType: string = 'product';
		let lastSearchQuery: string | null = null;
		let selectedCategory: { id: number; name: string } | null = null;

		const isProductCacheEnabled = () =>
			selectedCategory === null && hasProductSearchOptimization && activeType === 'product';

		function searchProductsAjax(term: string, callback: (data: { results: any[] }) => void) {
			if (activeType !== 'product') {
				return searchCategories(term, callback);
			}

			// @ts-ignore
			const rb = new Tools.RequestBuilder();
			rb.limit = 500;
			rb.addFilter(Tools.Product.attr.active, rb.comparisonTypes.Equals, 1);
			rb.addExtraParam('usePriceLists', true);

			if (Tools.FeatureHelper.hasSoftDeployAccess('PRODIUCT_PATTERN_SEARCH')) {
				var termGroup = rb.groupBuilder();
				term.split(' ').forEach((splitTerm: string) => {
					var orBuilder = termGroup.orBuilder();
					orBuilder.next();
					orBuilder.addFilter(Tools.Product.attr.name, rb.comparisonTypes.Search, splitTerm);
					orBuilder.next();
					orBuilder.addFilter(Tools.Product.attr.articleNo, rb.comparisonTypes.Search, splitTerm);

					if (searchableProductCustomFieldIds.length) {
						orBuilder.next();
						var groupFilter = orBuilder.groupBuilder();
						groupFilter.addFilter(
							{ field: 'custom.fieldId' },
							rb.comparisonTypes.Equals,
							searchableProductCustomFieldIds
						);
						groupFilter.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Search, splitTerm);
						groupFilter.done();
					}
					orBuilder.done();
				});
				termGroup.done();
			} else {
				var orBuilder = rb.orBuilder();
				orBuilder.next();
				orBuilder.addFilter(Tools.Product.attr.name, rb.comparisonTypes.Search, term);
				orBuilder.next();
				orBuilder.addFilter(Tools.Product.attr.articleNo, rb.comparisonTypes.Search, term);

				if (searchableProductCustomFieldIds.length) {
					orBuilder.next();
					var groupFilter = orBuilder.groupBuilder();
					groupFilter.addFilter(
						{ field: 'custom.fieldId' },
						rb.comparisonTypes.Equals,
						searchableProductCustomFieldIds
					);
					groupFilter.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Search, term);
					groupFilter.done();
				}
				orBuilder.done();
			}

			if (selectedCategory) {
				rb.addFilter(Tools.Product.attr.categoryId, rb.comparisonTypes.Equals, selectedCategory.id);
			}

			if (
				Tools.AppService.getMetadata().params.OrderProductsImprovedSearch &&
				Tools.FeatureHelper.hasSoftDeployAccess('ORDER_PRODUCTS_IMPROVED_SEARCH')
			) {
				rb.addScore({ field: 'articleNo' }, rb.comparisonTypes.MatchExact, [term]); 
				rb.addScore(Tools.Product.attr.name, rb.comparisonTypes.WildcardEnd, term);
				rb.addScore({ field: 'custom.value' }, rb.comparisonTypes.Match, term);
			}

			Tools.Product.customer(customerId)
				.find(rb.build())
				.then(function (res) {
					var data = _.filter(res.data, function (product) {
						return product.$hasAccess;
					});
					callback({ results: data });
				})
				.catch(e => {
					logError(e, 'Failed to fetch products');
					callback({ results: [] });
				});
		}

		function query({ term, callback }: { term: string; callback: (data: { results: any[] }) => void }) {
			if (term === '') {
				if (activeType === 'product') {
					return callback({ results: productTree });
				} else {
					return callback({ results: categoryTree });
				}
			}

			if (activeType === 'product') {
				searchProducts(term, callback);
			} else {
				searchCategories(term, callback);
			}
		}

		const select2opts = {
			minimumInputLength: 0,
			placeholder: `${t('default.select')} ${t('default.product')}`,
			initSelection: _.noop,
			formatResult: function (obj: any, el: any, x: any, encode: any) {
				let data = '';
				let tier = '';
				let articleNo = '';

				if (!obj.id) {
					if (!obj.children || !obj.children.length) {
						el.parent().hide();
					} else {
						data = ` data-category-id="${obj.$id}"`;
						el.parent().addClass('select2-result-with-children');
					}
				} else {
					if (obj.tiers && obj.tiers.length) {
						tier = `<div class="order-row-select-product-type-icon Icon Icon-tiers"></div>`;
					}
					if (hasProductBundles && obj.bundle && obj.bundle.length) {
						tier = `<div class="order-row-select-product-type-icon Icon Icon-bundle"></div>`;
					}
					if (obj.articleNo && showArticleNo) {
						articleNo = `<div class="order-row-select-sub-text">${obj.articleNo}</div>`;
					}
				}
				return `<div${data} class="order-row-select">
							${encode(obj.name)}${tier}${articleNo}
						</div>`;
			},
			formatSelection: function (obj: any, x: any, encode: any) {
				let cat = '';
				if (obj.category) {
					cat = '<div class="product-category-label">' + encode(obj.category.name) + '</div>';
				}
				return cat + '<div class="product-name-label">' + encode(obj.name) + '</div>';
			},
			quietMillis: 200,
			ajax: {
				data: function (term: string) {
					return term;
				},
				transport: function (q: any) {
					if (q.data === '') {
						const speedTracker = getSpeedTracker('EditOrderProductSelect', true);
						speedTracker?.startTracking();

						const queryCacheResults = cachedProductSearches[q.data];

						if (isProductCacheEnabled()) {
							if (queryCacheResults) {
								return q.success(queryCacheResults);
							}
							// Can be extended further so it is caching the search result for all search queries
							lastSearchQuery = '';
						}
					} else {
						const speedTracker = getSpeedTracker('EditOrderProductSelect');
						speedTracker?.endTracking();
					}
					return searchProductsAjax(q.data, q.success);
				},
				results: function (res: any) {
					const speedTracker = getSpeedTracker('EditOrderProductSelect');
					speedTracker?.publishInteractable();
					speedTracker?.sendResults(hasProductSearchOptimization);

					if (isProductCacheEnabled() && lastSearchQuery !== null) {
						cacheProductSearch(lastSearchQuery, res);
						lastSearchQuery = null;
					}

					return res;
				},
				// Use query-fn to be able to change the data with the toggle buttons
				query: isSearch ? undefined : query
			}
		};

		// Setup custom buttons
		if (categories.length) {
			const mountElement = document.createElement('div');

			// eslint-disable-next-line no-inner-declarations
			function toggleActiveType(type: 'category' | 'product') {
				activeType = type;
				// @ts-expect-error Looks like our typing for select2 is not correct
				productSelect.select2('search', '');
				renderButtonGroup();
			}

			// eslint-disable-next-line no-inner-declarations
			function showCategories() {
				selectedCategory = null;
				toggleActiveType('category');
			}

			// eslint-disable-next-line no-inner-declarations
			function showProducts() {
				toggleActiveType('product');
			}

			// eslint-disable-next-line no-inner-declarations
			function renderButtonGroup() {
				const buttonGroup = (
					<div className="btn-group select2-toggle" style={{ width: '100%', padding: 5 }}>
						<button
							type="button"
							className={classNames('btn btn-sm up-btn btn-green no-shadow', {
								'btn-lined': activeType !== 'category'
							})}
							onClick={showCategories}
							style={{ width: '50%' }}
						>
							<div className="product-category-outer">
								<span className="text-span">
									{selectedCategory ? selectedCategory.name : t('default.categories')}
								</span>
								{selectedCategory && isSearch ? <i className="fa fa-times" /> : null}
							</div>
						</button>
						<button
							type="button"
							className={classNames('btn btn-sm up-btn btn-green no-shadow', {
								'btn-lined': activeType !== 'product'
							})}
							onClick={showProducts}
							style={{ width: '50%' }}
						>
							{t('default.products')}
						</button>
					</div>
				);

				ReactDOM.render(buttonGroup, mountElement);
			}

			// Listen for open event
			productSelect.on('select2-open', function () {
				const dropdown = document.querySelector('#select2-drop');
				if (dropdown) {
					dropdown.prepend(mountElement);
					renderButtonGroup();
				}
			});

			productSelect.on('select2-close', function () {
				ReactDOM.unmountComponentAtNode(mountElement);
			});

			// Listen for select event so we can prevent default befaviour if a category was selected
			productSelect.on('select2-selecting', function (event: any) {
				// If a category was clicked we want to show all products in that category
				if (event.object.category === undefined) {
					event.preventDefault();
					selectedCategory = event.object;
					showProducts();

					// Find category element in tree
					const categoryElem = $('#select2-drop').find(`[data-category-id="${event.val}"]`);

					if (categoryElem.length) {
						categoryElem[0].scrollIntoView();
					}
				}
			});
		}

		// @ts-expect-error
		$(inputRef.current!).select2(select2opts);
		wrapper.hide();
	}, []);

	function onProductChange(event: { added: OrderRow['product'] }) {
		const productSelect = $(inputRef.current!);
		// @ts-expect-error Looks like our typing for select2 is not correct
		productSelect.select2('val', null);
		$productChange(rowIndex, event.added);
	}

	function onProductClose() {
		const productSelect = $(inputRef.current!);
		const wrapper = $(wrapperRef.current!);

		wrapper.hide();

		setTimeout(function () {
			// @ts-expect-error Dont date to change this
			productSelect.off('change', onProductChange);
			// @ts-expect-error Looks like our typing for select2 is not correct
			productSelect.select2('val', null);
		});

		// Set focus to fake element
		fakeProductSelectRef.current!.focus();
	}

	function showProductSelect() {
		if (!rowEditable || isDisabledRowField('product')) {
			return;
		}

		const productSelect = $(inputRef.current!);
		const wrapper = $(wrapperRef.current!);

		// Set the value of the product on the select2 before we open
		if (orderRow.product) {
			// @ts-expect-error Looks like our typing for select2 is not correct
			productSelect.select2('data', orderRow.product);
		}

		// Set new position, show and open the select2
		wrapper.show();
		productSelect.select2('open');

		// On close we hide it
		productSelect.one('select2-close', onProductClose);
		// @ts-expect-error Dont now why this one is not working
		productSelect.one('change', onProductChange);
	}

	function showProductSelectOnKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
		// Show the real select when we click on product or enter/space/up/ner when focused
		if (event.keyCode === 32 || event.keyCode === 13 || event.keyCode === 38 || event.keyCode === 40) {
			event.preventDefault();
			showProductSelect();
		}
	}

	const getQuantity = (orderRow: EditOrderOrderRow) => {
		if (selectedTier?.tier && selectedTier?.tier?.isTotalPrice) {
			return 1;
		}
		return orderRow.quantity || 0;
	};

	const getMonthlyValue = (orderRow: EditOrderOrderRow) => {
		const isRecurring = orderRow.product?.isRecurring;

		if (!isRecurring && !isAgreement) {
			return 0;
		}

		return ((orderRow.price || 0) / recurringInterval) * getQuantity(orderRow);
	};

	const getAnnualValue = (orderRow: EditOrderOrderRow) => {
		return getMonthlyValue(orderRow) * 12;
	};

	const getOneOffValue = (orderRow: EditOrderOrderRow) => {
		const isRecurring = orderRow.product?.isRecurring;
		if (isAgreement || isRecurring) {
			return 0;
		}
		return (orderRow.price || 0) * getQuantity(orderRow);
	};

	const getRowContributionMargin = (orderRow: EditOrderOrderRow) => {
		if (isTieredTotalOrderRow(orderRow)) {
			return (orderRow.price || 0) - (orderRow.purchaseCost || 0) * orderRow.quantity;
		}
		return ((orderRow.price || 0) - (orderRow.purchaseCost || 0)) * (orderRow.quantity || 0);
	};

	const getRowContributionMarginPercentage = (orderRow: EditOrderOrderRow) => {
		// @ts-expect-error parseFloat returns the number if you pass a number
		const price = parseFloat(orderRow.price);
		const quantity = orderRow.quantity || 0;

		if (!price || Math.abs(price) < 1e-10 || !quantity) {
			return 0;
		}

		const purchaseCost = orderRow.purchaseCost || 0;
		const quantityToCalculateWith = selectedTier?.tier?.isTotalPrice ? quantity : 1;

		const per = (price - purchaseCost * quantityToCalculateWith) / price;

		return !isNaN(per) ? (per * 100).toFixed(2) : 0;
	};

	function deleteRow(event: React.MouseEvent<HTMLButtonElement>) {
		$deleteRow(rowIndex);
	}

	function copyRow(event: React.MouseEvent<HTMLButtonElement>) {
		$copyRow(rowIndex);
	}

	function sortUp(event: React.MouseEvent<HTMLButtonElement>) {
		$sortUp(rowIndex);
	}

	function sortDown(event: React.MouseEvent<HTMLButtonElement>) {
		$sortDown(rowIndex);
	}

	function discountPercentChange(event: ModifiedEvent<HTMLInputElement, number>) {
		$discountPercentChange(rowIndex, event.target.value);
	}

	function discountChange(event: ModifiedEvent<HTMLInputElement, number>) {
		$discountChange(rowIndex, event.target.value);
	}

	function quantityChange(event: ModifiedEvent<HTMLInputElement, number>) {
		$quantityChange(rowIndex, event.target.value);
	}

	function priceChange(event: ModifiedEvent<HTMLInputElement, number>) {
		$priceChange(rowIndex, event.target.value);
	}

	function customFieldChange(fieldId: number, value: EditOrderCustomField['value']) {
		$customFieldChange(rowIndex, fieldId, value);
	}

	function openEditProductBundle(event: React.MouseEvent<HTMLDivElement>) {
		if (!saving && rowEditable) {
			event.stopPropagation();
			$openEditProductBundle(rowIndex);
		}
	}

	const onWheel = useCallback(function onWheel(event) {
		event.preventDefault();
	}, []);

	function onFocus(event: React.FocusEvent<HTMLInputElement>) {
		event.target.addEventListener('wheel', onWheel, { passive: false });
	}

	function onBlur(event: React.FocusEvent<HTMLInputElement>) {
		event.target.removeEventListener('wheel', onWheel);
	}

	///////////////// purchaseCost //////////////////////
	const [purchaseCost, setPurchaseCost] = useState<string | number>('');
	const [editingPurchaseCost, setEditingPurchaseCost] = useState(false);

	function updatePurchaseCost(
		form: HTMLFormElement,
		event:
			| React.FormEvent<HTMLFormElement>
			| React.FocusEvent<HTMLInputElement>
			| React.KeyboardEvent<HTMLInputElement>
	) {
		if (form.checkValidity()) {
			setEditingPurchaseCost(false);

			// @ts-expect-error parseFloat returns the number if you pass a number
			const value = purchaseCost === '' || isNaN(parseFloat(purchaseCost)) ? 0 : parseFloat(purchaseCost);

			if (value !== orderRow.purchaseCost) {
				$purchaseCostChange(rowIndex, value);
			}
		} else {
			event.preventDefault();
			form.reportValidity();
		}
	}

	function onPurchaseCostInputChange(event: ModifiedEvent<HTMLInputElement, string | number>) {
		setPurchaseCost(event.target.value);
	}

	function onPurchaseCostInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		event.stopPropagation();

		switch (event.code) {
			case 'Escape':
				setEditingPurchaseCost(false);
				break;
			case 'Enter': {
				// @ts-expect-error weeeel check for yourself and you will see that it is there
				const form = event.target.parentElement! as HTMLFormElement;
				updatePurchaseCost(form, event);
				break;
			}
		}
	}

	function editPurchaseCost() {
		if (!orderRow.product) {
			return;
		}

		setEditingPurchaseCost(true);
		setPurchaseCost(orderRow.purchaseCost || '');
	}

	function onPurchaseCostSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.stopPropagation();
		const form = event.target as HTMLFormElement;
		updatePurchaseCost(form, event);
	}

	// I want blur to work the same way as when you press enter i.e. show the error message if invalid
	function onPurchaseCostInputBlur(event: React.FocusEvent<HTMLInputElement>) {
		event.stopPropagation();
		const form = event.target.parentElement! as HTMLFormElement;

		updatePurchaseCost(form, event);
	}

	/////////////////////////////////////////////////////

	function onPriceFocus() {
		setPriceIsFocused(true);
	}

	function onPriceBlur() {
		setPriceIsFocused(false);
	}

	const sortedCustomFields = useMemo(() => _.sortBy(orderRow.custom, 'sortId'), [orderRow.custom]);
	const filteredBundleRows = orderRow?.bundleRows?.filter(row => row.product && row.quantity > 0) ?? [];

	return (
		<tr
			className={classNames('up-order-row', {
				'up-order-row--hasContributionMargin': hasContributionMargin,
				'up-order-row--hasRecurringInterval': hasRecurringInterval,
				'up-order-row--price-locked': isProductBundle
			})}
		>
			<td colSpan={100}>
				<div className="Card Card--white">
					<div className="row field-sync-header-row hidden-xs">
						<div
							className={classNames('form-input-sync-header row-col', {
								'col-sm-4': useDiscount,
								'col-sm-6': !useDiscount
							})}
						>
							{fieldsSyncDir['orderRow.product'] ? (
								<div className="form-input-sync-direction">
									{fieldsSyncDir['orderRow.product'] === 'import' ? (
										<Tooltip title={t('integration.onlySyncedToUpsales')}>
											<b className="fa fa-long-arrow-left"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.product'] === 'export' ? (
										<Tooltip title={syncedToApp} dynamic={true}>
											<b className="fa fa-long-arrow-right"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.product'] === 'both' ? (
										<Tooltip title={t('integration.twoWaySync')}>
											<b className="fa fa-exchange"></b>
										</Tooltip>
									) : null}
								</div>
							) : null}
						</div>
						<div className="form-input-sync-header row-col col-sm-1">
							{fieldsSyncDir['orderRow.quantity'] ? (
								<div className="form-input-sync-direction">
									{fieldsSyncDir['orderRow.quantity'] === 'import' ? (
										<Tooltip title={t('integration.onlySyncedToUpsales')}>
											<b className="fa fa-long-arrow-left"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.quantity'] === 'export' ? (
										<Tooltip title={syncedToApp} dynamic={true}>
											<b className="fa fa-long-arrow-right"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.quantity'] === 'both' ? (
										<Tooltip title={t('integration.twoWaySync')}>
											<b className="fa fa-exchange"></b>
										</Tooltip>
									) : null}
								</div>
							) : null}
						</div>
						<div className="form-input-sync-header row-col col-sm-1">
							{fieldsSyncDir['orderRow.price'] ? (
								<div className="form-input-sync-direction">
									{fieldsSyncDir['orderRow.price'] === 'import' ? (
										<Tooltip title={t('integration.onlySyncedToUpsales')}>
											<b className="fa fa-long-arrow-left"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.price'] === 'export' ? (
										<Tooltip title={syncedToApp} dynamic={true}>
											<b className="fa fa-long-arrow-right"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.price'] === 'both' ? (
										<Tooltip title={t('integration.twoWaySync')}>
											<b className="fa fa-exchange"></b>
										</Tooltip>
									) : null}
								</div>
							) : null}
						</div>
						<div className="form-input-sync-header row-col col-sm-1">
							{fieldsSyncDir['orderRow.discountPercent'] ? (
								<div className="form-input-sync-direction">
									{fieldsSyncDir['orderRow.discountPercent'] === 'import' ? (
										<Tooltip title={t('integration.onlySyncedToUpsales')}>
											<b className="fa fa-long-arrow-left"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.discountPercent'] === 'export' ? (
										<Tooltip title={syncedToApp} dynamic={true}>
											<b className="fa fa-long-arrow-right"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.discountPercent'] === 'both' ? (
										<Tooltip title={t('integration.twoWaySync')}>
											<b className="fa fa-exchange"></b>
										</Tooltip>
									) : null}
								</div>
							) : null}
						</div>
						<div className="form-input-sync-header row-col col-sm-1">
							{fieldsSyncDir['orderRow.discountRaw'] ? (
								<div className="form-input-sync-direction">
									{fieldsSyncDir['orderRow.discountRaw'] === 'import' ? (
										<Tooltip title={t('integration.onlySyncedToUpsales')}>
											<b className="fa fa-long-arrow-left"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.discountRaw'] === 'export' ? (
										<Tooltip title={syncedToApp} dynamic={true}>
											<b className="fa fa-long-arrow-right"></b>
										</Tooltip>
									) : null}
									{fieldsSyncDir['orderRow.discountRaw'] === 'both' ? (
										<Tooltip title={t('integration.twoWaySync')}>
											<b className="fa fa-exchange"></b>
										</Tooltip>
									) : null}
								</div>
							) : null}
						</div>
					</div>
					<div className="row">
						{/* PRODUCT SELECT */}
						<div
							className={classNames('product row-col', {
								'col-sm-4': useDiscount && hasContributionMargin,
								'col-sm-6': !useDiscount && hasContributionMargin,
								'col-sm-5': useDiscount && !hasContributionMargin,
								'col-sm-7': !useDiscount && !hasContributionMargin,
								'has-error':
									OrderForm['product_' + rowIndex]?.$invalid &&
									(OrderForm['product_' + rowIndex]?.$dirty || OrderForm.$submitted),
								'field-sync-highlight': highlightedFields.includes('orderRow.product'),
								'field-sync-disabled':
									highlightedFields.length > 0 && !highlightedFields.includes('orderRow.product')
							})}
						>
							{isVisibleRowField('product') ? (
								<div className="form-input-sync-header">
									<label className="visible-xs">{t('default.product')}</label>
									{fieldsSyncDir['orderRow.product'] ? (
										<div className="form-input-sync-direction visible-xs">
											{fieldsSyncDir['orderRow.product'] === 'import' ? (
												<Tooltip title={t('integration.onlySyncedToUpsales')}>
													<b className="fa fa-long-arrow-left"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.product'] === 'export' ? (
												<Tooltip title={syncedToApp} dynamic={true}>
													<b className="fa fa-long-arrow-right"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.product'] === 'both' ? (
												<Tooltip title={t('integration.twoWaySync')}>
													<b className="fa fa-exchange"></b>
												</Tooltip>
											) : null}
										</div>
									) : null}
								</div>
							) : null}
							<Tooltip title={fieldsDescription['orderRow.product']} dynamic={true}>
								<div
									ref={fakeProductSelectRef}
									onClick={showProductSelect}
									onKeyDown={showProductSelectOnKeyDown}
									className={classNames(
										'input form-control fake-product-select form-high prevent-escape',
										{
											'fake-product-select--has-info': orderRow.product?.description,
											'fake-product-select--has-icon':
												isProductBundle || orderRow.product?.description,
											'fake-product-select--has-double-icon':
												isProductBundle && orderRow.product?.description,
											'hidden-field': !isVisibleRowField('product')
										}
									)}
									// @ts-expect-error .form-control[disabled] still works in css, even if "Property 'disabled' does not exist on type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>"
									disabled={isDisabledRowField('product', saving || !rowEditable)}
									tabIndex={13}
									data-test-id={`order-product-select-${rowIndex}`}
								>
									{/* Icon */}
									<ProductDescriptionTooltip
										className={'fake-product-select__icon'}
										description={orderRow.product?.description ?? ''}
									/>

									{/* Icon */}
									{isProductBundle ? (
										<span className="Icon Icon-bundle fake-product-select__icon"></span>
									) : null}

									{/* Product category */}
									{orderRow.product?.category ? (
										<div className="product-category-label">{orderRow.product.category.name}</div>
									) : null}

									{/* Selected product name */}
									<span className="product-name-label">
										{orderRow.product?.name ?? ''}
										{showArticleNo && orderRow.product?.articleNo ? (
											<span>{`(${orderRow.product?.articleNo})`}</span>
										) : null}
									</span>

									{/* Placeholder */}
									<span
										className="fake-placeholder"
										style={!orderRow.product ? {} : { display: 'none' }}
									>
										{t('default.select')} {t('default.product').toLowerCase()}
									</span>

									{/* Tier indicator */}
									{selectedTier ? (
										<div className="product-tier-indicator">
											<Tooltip
												title={`${t('product.tier')} ${selectedTier.tierNr} - ${t(
													selectedTier.tier.isTotalPrice
														? 'product.totalPricedProduct'
														: 'product.unitPricedProduct'
												)}`}
												placement="top"
												dynamic={true}
											>
												<div className="product-tier-indicator__inner">
													{selectedTier.tier.start} -{' '}
													{selectedTier.last ? '∞' : selectedTier.tier.end}
												</div>
											</Tooltip>
										</div>
									) : null}

									{/* Bundles */}
									{isProductBundle ? (
										<div
											className="fake-product-select__bundle-chip"
											onClick={openEditProductBundle}
										>
											<div className="Chip Chip--medium-blue Chip--sm Chip--default AssistChip">
												<div className="Text Text--sm Text--medium-blue">
													{filteredBundleRows.length}{' '}
													{t(
														filteredBundleRows.length === 1
															? 'default.product'
															: 'default.products'
													).toLowerCase()}
												</div>
											</div>
										</div>
									) : null}

									{/* Faked dropdown arrow */}
									<div className="fake-select-dropdown">
										<b className="fa fa-caret-down"></b>
									</div>

									{/* Hidden field to hold the model */}
									<HiddenProductInput
										name={`product_${rowIndex}`}
										value={orderRow.product}
										required={true}
									/>
								</div>
							</Tooltip>
							<div ref={wrapperRef} className="real-product-select-wrapper">
								<input ref={inputRef} className="real-product-select" type="hidden" />
							</div>
						</div>

						{/* PRICE & QUANTITY INPUT */}
						{!orderRow.calculatingField ? (
							<div className="price row-col col-sm-2">
								<div className="form-input-sync-header">
									<label className="visible-xs">
										{t('default.quantity') + '/' + t('default.price')}
									</label>
									{fieldsSyncDir['orderRow.quantity'] ? (
										<div className="form-input-sync-direction visible-xs">
											{fieldsSyncDir['orderRow.quantity'] === 'import' ? (
												<Tooltip title={t('integration.onlySyncedToUpsales')}>
													<b className="fa fa-long-arrow-left"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.quantity'] === 'export' ? (
												<Tooltip title={syncedToApp} dynamic={true}>
													<b className="fa fa-long-arrow-right"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.quantity'] === 'both' ? (
												<Tooltip title={t('integration.twoWaySync')}>
													<b className="fa fa-exchange"></b>
												</Tooltip>
											) : null}
										</div>
									) : null}
									{fieldsSyncDir['orderRow.price'] ? (
										<div className="form-input-sync-direction visible-xs">
											{fieldsSyncDir['orderRow.price'] === 'import' ? (
												<Tooltip title={t('integration.onlySyncedToUpsales')}>
													<b className="fa fa-long-arrow-left"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.price'] === 'export' ? (
												<Tooltip title={syncedToApp} dynamic={true}>
													<b className="fa fa-long-arrow-right"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.price'] === 'both' ? (
												<Tooltip title={t('integration.twoWaySync')}>
													<b className="fa fa-exchange"></b>
												</Tooltip>
											) : null}
										</div>
									) : null}
								</div>
								<div
									className={classNames('input-group input', {
										'has-error':
											(OrderForm['quantity_' + rowIndex]?.$invalid ||
												OrderForm['price_' + rowIndex]?.$invalid) &&
											(OrderForm['quantity_' + rowIndex]?.$dirty ||
												OrderForm['price_' + rowIndex]?.$dirty ||
												OrderForm.$submitted)
									})}
								>
									{/* QUANTITY */}

									{isVisibleRowField('quantity') ? (
										<Tooltip title={fieldsDescription['orderRow.quantity']} dynamic={true}>
											<NumberInput
												controllerOptions={
													hasOrderRowFieldsDebounce
														? {
																debounce: {
																	change: inputDebounceSetting || 1500,
																	blur: 0
																}
														  }
														: {}
												}
												disabled={isDisabledRowField(
													'quantity',
													saving || !orderRow.product || !rowEditable
												)}
												min="0"
												name={`quantity_${rowIndex}`}
												onBlur={onBlur}
												onChange={quantityChange}
												onFocus={onFocus}
												step="any"
												tabIndex={13}
												type="number"
												value={orderRow.quantity}
												className={classNames(
													'form-control quantity form-row-quantity form-high',
													{
														'field-sync-highlight':
															highlightedFields.includes('orderRow.quantity'),
														'field-sync-disabled':
															highlightedFields.length > 0 &&
															!highlightedFields.includes('orderRow.quantity'),
														'quantity--withoutPrice': !isVisibleRowField('price'),
														focus: priceIsFocused
													}
												)}
											/>
										</Tooltip>
									) : null}

									{/* FIX */}

									{isVisibleRowField('quantity') ? (
										<span
											className={classNames('input-group-btn multiply-fix', {
												'multiply-fix--withoutPrice': !isVisibleRowField('price'),
												focus: priceIsFocused
											})}
										>
											{selectedTier && selectedTier.tier && selectedTier.tier.isTotalPrice
												? '@'
												: 'x'}
										</span>
									) : null}

									{/* PRICE */}
									<Tooltip
										title={
											isProductBundle
												? t('orderrow.priceLockedTooltip.bundle', {
														productName: orderRow.product.name
												  })
												: ''
										}
									>
										<span>
											{isVisibleRowField('price') ? (
												<Tooltip
													title={
														isProductBundle &&
														!orderRow.bundleFixedPrice &&
														orderRow?.product?.name
															? t('orderrow.priceLockedTooltip.bundle', {
																	productName: orderRow.product?.name
															  })
															: fieldsDescription['orderRow.price']
													}
													dynamic={true}
												>
													<PriceInput
														controllerOptions={
															hasOrderRowFieldsDebounce
																? {
																		debounce: {
																			change: inputDebounceSetting || 1500,
																			blur: 0
																		}
																  }
																: {}
														}
														decimals={decimalsPrice}
														disabled={isDisabledRowField(
															'price',
															saving ||
																!orderRow.product ||
																!rowEditable ||
																(isProductBundle && !orderRow.bundleFixedPrice) ||
																(!hasDiscount && !isAdmin)
														)}
														name={`price_${rowIndex}`}
														onBlur={onPriceBlur}
														onChange={priceChange}
														onFocus={onPriceFocus}
														step="any"
														tabIndex={13}
														type="text"
														value={orderRow.price}
														className={classNames(
															'form-control price-input form-row-price form-high',
															{
																'field-sync-highlight':
																	highlightedFields.includes('orderRow.price'),
																'field-sync-disabled':
																	highlightedFields.length > 0 &&
																	!highlightedFields.includes('orderRow.price'),
																'price-input--locked':
																	isProductBundle && !orderRow.bundleFixedPrice,
																'price-input--withoutQuantity':
																	!isVisibleRowField('quantity')
															}
														)}
													/>
												</Tooltip>
											) : null}
										</span>
									</Tooltip>
								</div>
							</div>
						) : null}

						{/* CALCULATING FIELD */}
						{orderRow.calculatingField ? (
							<div
								className={classNames('price row-col col-sm-2 calc-field', {
									'hidden-field': !isVisibleRowField('price', !!orderRow.calculatingField)
								})}
							>
								<label className="visible-xs">
									{getCalculatingFieldTooltip(
										orderRow.calculatingField.formula,
										orderRow.calculatingField.name
									)}
								</label>
								<Tooltip
									title={getCalculatingFieldTooltip(
										orderRow.calculatingField.formula,
										orderRow.calculatingField.name
									)}
									placement="bottom"
									dynamic={true}
								>
									<div className="input-group input calculation-field-value">
										<span>{numberFormat(orderRow.price, true)}</span>
									</div>
								</Tooltip>
							</div>
						) : null}

						{/* DISCOUNT */}
						{useDiscount ? (
							<div
								className={classNames('discount row-col col-sm-2', {
									'hidden-field':
										!isVisibleRowField('discount') && !isVisibleRowField('discountpercent')
								})}
							>
								<div className="form-input-sync-header">
									<label className="visible-xs">{t('default.discount')}</label>
									{fieldsSyncDir['orderRow.discountPercent'] ? (
										<div className="form-input-sync-direction visible-xs">
											{fieldsSyncDir['orderRow.discountPercent'] === 'import' ? (
												<Tooltip title={t('integration.onlySyncedToUpsales')}>
													<b className="fa fa-long-arrow-left"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.discountPercent'] === 'export' ? (
												<Tooltip title={syncedToApp} dynamic={true}>
													<b className="fa fa-long-arrow-right"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.discountPercent'] === 'both' ? (
												<Tooltip title={t('integration.twoWaySync')}>
													<b className="fa fa-exchange"></b>
												</Tooltip>
											) : null}
										</div>
									) : null}

									{fieldsSyncDir['orderRow.discountRaw'] ? (
										<div className="form-input-sync-direction visible-xs">
											{fieldsSyncDir['orderRow.discountRaw'] === 'import' ? (
												<Tooltip title={t('integration.onlySyncedToUpsales')}>
													<b className="fa fa-long-arrow-left"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.discountRaw'] === 'export' ? (
												<Tooltip title={syncedToApp} dynamic={true}>
													<b className="fa fa-long-arrow-right"></b>
												</Tooltip>
											) : null}
											{fieldsSyncDir['orderRow.discountRaw'] === 'both' ? (
												<Tooltip title={t('integration.twoWaySync')}>
													<b className="fa fa-exchange"></b>
												</Tooltip>
											) : null}
										</div>
									) : null}
								</div>
								<div
									className={classNames('input-group input', {
										'has-error':
											((OrderForm['discount_' + rowIndex]?.$invalid ||
												OrderForm['discountMoney_' + rowIndex]?.$invalid) &&
												(OrderForm['discount_' + rowIndex]?.$dirty ||
													OrderForm['discountMoney_' + rowIndex]?.$dirty ||
													OrderForm.$submitted)) ||
											(orderRow.hasExcessiveDiscount && !isAdmin)
									})}
								>
									<Tooltip
										title={
											!hasDiscount && !isAdmin && orderRow.product
												? t('default.discount.toolTip')
												: ''
										}
										placement="top"
										dynamic={true}
									>
										<span>
											{/* DISCOUNT % */}
											{isVisibleRowField('discountpercent') ? (
												<Tooltip
													title={fieldsDescription['orderRow.discountPercent']}
													dynamic={true}
												>
													<NumberInput
														controllerOptions={
															hasOrderRowFieldsDebounce
																? {
																		debounce: {
																			change: inputDebounceSetting || 1500,
																			blur: 0
																		}
																  }
																: {}
														}
														disabled={isDisabledRowField(
															'discountpercent',
															saving ||
																!orderRow.product ||
																!rowEditable ||
																(!hasDiscount && !isAdmin)
														)}
														max="100"
														min="0"
														name={`discount_${rowIndex}`}
														onBlur={onBlur}
														onChange={discountPercentChange}
														onFocus={onFocus}
														placeholder={`${orderRow.$percentPlaceholder || '0'}`}
														step="any"
														tabIndex={13}
														type="number"
														value={orderRow.$discountPercent!} // The ! here is not strictly correct, but dont have time to fix NumberInput to be typed correctly for undefined
														className={classNames(
															'form-control discount-percent-input form-row-discount-percent form-high',
															{
																'field-sync-highlight':
																	highlightedFields.includes(
																		'orderRow.discountPercent'
																	),
																'field-sync-disabled':
																	highlightedFields.length > 0 &&
																	!highlightedFields.includes(
																		'orderRow.discountPercent'
																	),
																'discount-percent-input--withoutDiscount':
																	!isVisibleRowField('discount')
															}
														)}
													/>
												</Tooltip>
											) : null}

											{/* FIX */}
											{isVisibleRowField('discountpercent') ? (
												<span
													className={classNames('percent', {
														'percent--withoutDiscount': !isVisibleRowField('discount')
													})}
												>
													{'%'}
												</span>
											) : null}
											{isVisibleRowField('discount') ? (
												<span className="currency">{currency}</span>
											) : null}
											{/* DISCOUNT */}
											{isVisibleRowField('discount') ? (
												<Tooltip
													title={fieldsDescription['orderRow.discountRaw']}
													dynamic={true}
												>
													<NumberInput
														controllerOptions={
															hasOrderRowFieldsDebounce
																? {
																		debounce: {
																			change: inputDebounceSetting || 1500,
																			blur: 0
																		}
																  }
																: {}
														}
														disabled={isDisabledRowField(
															'discount',
															saving ||
																!orderRow.product ||
																!rowEditable ||
																(!hasDiscount && !isAdmin)
														)}
														min="0"
														name={`discountMoney_${rowIndex}`}
														onBlur={onBlur}
														onChange={discountChange}
														onFocus={onFocus}
														placeholder={`${orderRow.$discountRaw || '0'}`}
														step="any"
														tabIndex={13}
														type="number"
														value={orderRow.$discount!} // The ! here is not strictly correct, but dont have time to fix NumberInput to be typed correctly for undefined
														className={classNames(
															'form-control discount-input form-row-discount form-high',
															{
																'field-sync-highlight':
																	highlightedFields.includes('orderRow.discountRaw'),
																'field-sync-disabled':
																	highlightedFields.length > 0 &&
																	!highlightedFields.includes('orderRow.discountRaw'),
																'discount-input--withoutDiscountPercent':
																	!isVisibleRowField('discountpercent')
															}
														)}
													/>
												</Tooltip>
											) : null}
										</span>
									</Tooltip>
									<MaxDiscountInfo
										maxDiscount={orderRow.maxDiscount}
										hasExcessiveDiscount={orderRow.hasExcessiveDiscount}
									/>
								</div>
							</div>
						) : null}
						<div
							className={classNames('row-col last-col', {
								'break-buttons': hasContributionMargin,
								'col-sm-3': !hasContributionMargin,
								'col-sm-4': hasContributionMargin
							})}
						>
							{/* ROW TOTAL */}
							{/* Default */}
							{!hasContributionMargin && !hasRecurringInterval && isVisibleRowField('price') ? (
								<div className="total form-row-total totalWithCopy">
									<b>
										{currencyFormat(
											(orderRow.price || 0) * getQuantity(orderRow),
											currency,
											false,
											numOfDecimalsPrice,
											undefined,
											true
										)}
									</b>
								</div>
							) : null}

							{/* With recuring revenue sales model */}
							{!hasContributionMargin && hasRecurringInterval ? (
								<div className="total form-row-total totalWithCopy">
									{isVisibleRowField('price') ? (
										<div className="upOrderRow-total-row">
											<b>
												{currencyFormat(
													(orderRow.price || 0) * getQuantity(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)}
											</b>
										</div>
									) : null}

									{isVisibleRowField(
										'arr',
										salesModelOption === 'arr' && getOneOffValue(orderRow) === 0
									) ? (
										<div className="upOrderRow-total-row">
											<b>
												{currencyFormat(
													getAnnualValue(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)}{' '}
												{' (' + t('default.arr') + ')'}
											</b>
										</div>
									) : null}

									{isVisibleRowField(
										'mrr',
										salesModelOption === 'mrr' && getOneOffValue(orderRow) === 0
									) ? (
										<div className="upOrderRow-total-row">
											<b>
												{currencyFormat(
													getMonthlyValue(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)}{' '}
												{' (' + t('default.mrr') + ')'}
											</b>
										</div>
									) : null}

									{isVisibleRowField('oneoff', getOneOffValue(orderRow) > 0) ? (
										<div className="upOrderRow-total-row">
											{'(' + t('admin.products.oneOff') + ')'}
										</div>
									) : null}
								</div>
							) : null}

							{/* With Contribution margin sales model */}
							{hasContributionMargin && !hasRecurringInterval ? (
								<div className="total form-row-total totalWithCopy">
									{isVisibleRowField('price') ? (
										<div className="upOrderRow-total-row">
											<span>{t('default.netAmount')}</span>:
											<div className="total-row-right">
												{currencyFormat(
													(orderRow.price || 0) * getQuantity(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)}
											</div>
										</div>
									) : null}
									<div
										className="upOrderRow-total-row"
										style={!isVisibleRowField('purchasecost') ? { display: 'none' } : {}}
									>
										<span>{`${t('default.purchasedAtCost')}:`}</span>
										<div
											className="total-row-right edit-purchase-price clickable"
											onClick={() => {
												if (
													!(
														isDisabledRowField('purchasecost', !rowEditable) ||
														isProductBundle
													)
												) {
													editPurchaseCost();
												}
											}}
										>
											{!editingPurchaseCost ? (
												<span>
													{currencyFormat(
														(orderRow.purchaseCost || 0) * (orderRow.quantity || 0),
														currency,
														false,
														numOfDecimalsPrice,
														undefined,
														true
													)}
												</span>
											) : null}
											{!editingPurchaseCost &&
											!isDisabledRowField('purchasecost', !rowEditable) &&
											!isProductBundle ? (
												<b className="fa fa-edit pc-edit"></b>
											) : null}
											{editingPurchaseCost ? (
												// Warning: validateDOMNesting(...): <form> cannot appear as a descendant of <form>
												<form autoComplete="off" onSubmit={onPurchaseCostSubmit}>
													<PurchaseCostInput
														autoFocus={true}
														className="edit-purchase-price-input prevent-escape"
														controllerOptions={
															hasOrderRowFieldsDebounce
																? {
																		debounce: {
																			change: inputDebounceSetting || 1500,
																			blur: 0
																		}
																  }
																: {}
														}
														disabled={isDisabledRowField('purchasecost', !rowEditable)}
														onBlur={onPurchaseCostInputBlur}
														onChange={onPurchaseCostInputChange}
														onKeyDown={onPurchaseCostInputKeyDown}
														placeholder="0"
														step="0.01"
														type="number"
														value={purchaseCost}
													/>
													<span className="Text Text--dark-grey">{` ${currency}`}</span>
												</form>
											) : null}
										</div>
									</div>
									{isVisibleRowField('contributionmargin') ? (
										<div className="upOrderRow-total-row">
											<span>{t('default.contributionMarginShort')}</span>:
											<div className="total-row-right">
												{`${currencyFormat(
													getRowContributionMargin(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)} (${getRowContributionMarginPercentage(orderRow)}%)`}
											</div>
										</div>
									) : null}
								</div>
							) : null}

							{/* With both CM and RR sales model*/}
							{hasContributionMargin && hasRecurringInterval ? (
								<div className="total form-row-total totalWithCopy">
									{isVisibleRowField('price') ? (
										<div className="upOrderRow-total-row">
											<span>{t('default.netAmount')}</span>:
											<div className="total-row-right">
												{currencyFormat(
													(orderRow.price || 0) * getQuantity(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)}
											</div>
										</div>
									) : null}
									{isVisibleRowField(
										'arr',
										salesModelOption === 'arr' && getOneOffValue(orderRow) === 0
									) ? (
										<div className="upOrderRow-total-row">
											<span>{t('default.arr')}</span>:
											<div className="total-row-right">
												{currencyFormat(
													getAnnualValue(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)}
											</div>
										</div>
									) : null}
									{isVisibleRowField(
										'mrr',
										salesModelOption === 'mrr' && getOneOffValue(orderRow) === 0
									) ? (
										<div className="upOrderRow-total-row">
											<span>{t('default.mrr')}</span>:
											<div className="total-row-right">
												{currencyFormat(
													getMonthlyValue(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)}
											</div>
										</div>
									) : null}
									{isVisibleRowField('oneoff', getOneOffValue(orderRow) > 0) ? (
										<div className="upOrderRow-total-row">
											<span>{t('admin.products.oneOff')}</span>
											<div className="total-row-right">
												{currencyFormat(
													getOneOffValue(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)}
											</div>
										</div>
									) : null}
									<div
										className="upOrderRow-total-row"
										style={!isVisibleRowField('purchasecost') ? { display: 'none' } : {}}
									>
										<span>{`${t('default.purchasedAtCost')}:`}</span>
										<div
											className="total-row-right edit-purchase-price clickable"
											onClick={() => {
												if (
													!(
														isDisabledRowField('purchasecost', !rowEditable) ||
														isProductBundle
													)
												) {
													editPurchaseCost();
												}
											}}
										>
											{!editingPurchaseCost ? (
												<span>
													{currencyFormat(
														(orderRow.purchaseCost || 0) * (orderRow.quantity || 0),
														currency,
														false,
														numOfDecimalsPrice,
														undefined,
														true
													)}
												</span>
											) : null}
											{!editingPurchaseCost &&
											!isDisabledRowField('purchasecost', !rowEditable) &&
											!isProductBundle ? (
												<b className="fa fa-edit pc-edit"></b>
											) : null}
											{editingPurchaseCost ? (
												// Warning: validateDOMNesting(...): <form> cannot appear as a descendant of <form>
												<form autoComplete="off" onSubmit={onPurchaseCostSubmit}>
													<PurchaseCostInput
														autoFocus={true}
														className="edit-purchase-price-input prevent-escape"
														controllerOptions={
															hasOrderRowFieldsDebounce
																? {
																		debounce: {
																			change: inputDebounceSetting || 1500,
																			blur: 0
																		}
																  }
																: {}
														}
														disabled={isDisabledRowField('purchasecost', !rowEditable)}
														onBlur={onPurchaseCostInputBlur}
														onChange={onPurchaseCostInputChange}
														onKeyDown={onPurchaseCostInputKeyDown}
														placeholder="0"
														step="0.01"
														type="number"
														value={purchaseCost}
													/>
													<span className="Text Text--dark-grey">{` ${currency}`}</span>
												</form>
											) : null}
										</div>
									</div>
									{isVisibleRowField('contributionmargin') ? (
										<div className="upOrderRow-total-row">
											<span>{t('default.contributionMarginShort')}</span>:
											<div className="total-row-right">
												{`${currencyFormat(
													getRowContributionMargin(orderRow),
													currency,
													false,
													numOfDecimalsPrice,
													undefined,
													true
												)} (${getRowContributionMarginPercentage(orderRow)}%)`}
											</div>
										</div>
									) : null}
								</div>
							) : null}

							<div
								className={classNames('order-row-tools', {
									'with-contribution-margin': hasContributionMargin
								})}
							>
								<div className="order-row-tools__col">
									<button
										type="button"
										onClick={deleteRow}
										className="btn btn-sm up-btn btn-red btn-link no-shadow"
										disabled={saving || !rowEditable || saveDisabled}
										tabIndex={-1}
									>
										<span className="Icon Icon-trash"></span>
									</button>
									<button
										type="button"
										onClick={copyRow}
										className="btn btn-sm up-btn btn-grey btn-link no-shadow"
										disabled={saving || !rowEditable || saveDisabled}
										tabIndex={-1}
									>
										<span className="Icon Icon-copy"></span>
									</button>
								</div>
								<div className="order-row-tools__col">
									<button
										type="button"
										onClick={sortUp}
										className="btn btn-sm up-btn btn-grey btn-link no-shadow"
										disabled={saving || !rowEditable || saveDisabled}
										tabIndex={-1}
									>
										<span className="Icon Icon-angle-up"></span>
									</button>
									<button
										type="button"
										onClick={sortDown}
										className="btn btn-sm up-btn btn-grey btn-link no-shadow"
										disabled={saving || !rowEditable || saveDisabled}
										tabIndex={-1}
									>
										<span className="Icon Icon-angle-down"></span>
									</button>
								</div>
							</div>
						</div>
					</div>

					{/* CUSTOMFIELDS */}
					{hasCustom ? (
						<div className="customfields row">
							{sortedCustomFields.map((field: any) => {
								const visible = field.$hasAccess && (field.editable || field.visible);

								if (
									!isVisibleRowField(`custom.${field.id}`, visible, field.isHiddenByProductCategory)
								) {
									return null;
								}

								return (
									<div
										key={field.id}
										className={classNames('row-col col-sm-4', {
											'field-sync-disabled':
												highlightedFields.length > 0 &&
												!highlightedFields.includes('orderRow.custom.' + field.id)
										})}
									>
										<div className="form-input-sync-header">
											<label>
												<span>{field.name}</span>
												{/* eslint-disable-next-line eqeqeq */}
												{field.obligatoryField == 1 && field.datatype !== 'Calculation' ? (
													<b className="text-danger">*</b>
												) : null}
											</label>
											{fieldsSyncDir['orderRow.custom.' + field.id] ? (
												<div className="form-input-sync-direction">
													{fieldsSyncDir['orderRow.custom.' + field.id] === 'import' ? (
														<Tooltip title={t('integration.onlySyncedToUpsales')}>
															<b className="fa fa-long-arrow-left"></b>
														</Tooltip>
													) : null}
													{fieldsSyncDir['orderRow.custom.' + field.id] === 'export' ? (
														<Tooltip title={syncedToApp} dynamic={true}>
															<b className="fa fa-long-arrow-right"></b>
														</Tooltip>
													) : null}
													{fieldsSyncDir['orderRow.custom.' + field.id] === 'both' ? (
														<Tooltip title={t('integration.twoWaySync')}>
															<b className="fa fa-exchange"></b>
														</Tooltip>
													) : null}
												</div>
											) : null}
										</div>
										<div className="form-group field-sync-custom-margin-top">
											<Tooltip
												title={
													field.datatype === 'Calculation' &&
													(!hasFormulaVisible || field.formulaVisible)
														? getCalculatingFieldTooltip(field.formula, field.name)
														: ''
												}
												placement="top"
											>
												<div
													className={classNames('input', {
														'has-error':
															OrderForm['upCustomRowField_' + field.id + '_' + rowIndex]
																?.$invalid &&
															(OrderForm['upCustomRowField_' + field.id + '_' + rowIndex]
																?.$dirty ||
																OrderForm.$submitted),
														'date-icon': field.datatype === 'Date',
														'field-sync-highlight': highlightedFields.includes(
															'orderRow.custom.' + field.id
														)
													})}
												>
													{field.datatype === 'Boolean' ? (
														<label>
															<div style={{ display: 'inline-block' }}>
																<CustomFieldInput
																	disabled={isDisabledRowField(
																		'custom.' + field.id,
																		saving || !field.editable || !rowEditable
																	)}
																	entity="orderrow"
																	field={orderRow.$mappedCustom[field.id]}
																	name={`upCustomRowField_${field.id}_${rowIndex}`}
																	onChange={customFieldChange}
																	placeholder={field.name}
																	tabIndex={13}
																	useExternalDisabled={hasAppValidationWithOrderEdit}
																/>
															</div>
														</label>
													) : null}

													{field.datatype === 'Calculation' ? (
														<div className="calc-field">
															<CustomFieldInput
																disabled={saving || !field.editable || !rowEditable}
																entity="orderrow"
																field={orderRow.$mappedCustom[field.id]}
																onChange={customFieldChange}
																tabIndex={13}
															/>
														</div>
													) : null}

													{!['Calculation', 'Boolean'].includes(field.datatype) ? (
														<Tooltip
															title={fieldsDescription['orderRow.custom.' + field.id]}
															dynamic={true}
														>
															<div>
																<CustomFieldInput
																	disabled={isDisabledRowField(
																		'custom.' + field.id,
																		saving || !field.editable || !rowEditable
																	)}
																	entity="orderrow"
																	field={orderRow.$mappedCustom[field.id]}
																	inputDebounceSetting={inputDebounceSetting}
																	name={`upCustomRowField_${field.id}_${rowIndex}`}
																	onChange={customFieldChange}
																	placeholder={field.name}
																	tabIndex={13}
																	useNewTime={true}
																	useNumberInput={true}
																	useExternalDisabled={hasAppValidationWithOrderEdit}
																/>
															</div>
														</Tooltip>
													) : null}
												</div>
											</Tooltip>
										</div>
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			</td>
		</tr>
	);
};

export default OrderRowComponent;
