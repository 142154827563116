import IndustryInsightsAccount from '../IndustryInsightsAccount/IndustryInsightsAccount';
import { Drawer, DrawerHeader, Flex, Tab, Tabs, Tooltip } from '@upsales/components';
import { IndustryInsightsProvider } from '../Context/Context';
import { ModalProps } from 'App/components/Modals/Modals';
import BemClass from '@upsales/components/Utils/bemClass';
import { IndustryInsightsTabs } from '../Helpers/Helpers';
import React, { useState, useRef } from 'react';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import './IndustryInsightsDrawer.scss';

export enum IndustryInsightsDrawerView {
	List,
	Loading
}

type Props = {
	client: Client;
	defaultView?: IndustryInsightsDrawerView;
	code: string;
	description: string;
} & ModalProps;

const IndustryInsightsDrawer = ({ className, close, modalId, client, code, description }: Props) => {
	const classes = new BemClass('IndustryInsightsDrawer', className);
	const [selectedTab, setSelectedTab] = useState<IndustryInsightsTabs>(IndustryInsightsTabs.ACCOUNT_VS_INDUSTRY);
	const [importDate, setImportDate] = useState<string | null>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const lang = {
		lastUpdated: T('default.lastUpdated'),
		accountVsIndustry: T('default.accountVsIndustry'),
		aboutIndustry: T('default.aboutIndustry'),
		featureWorkInProgress: T('default.featureWorkInProgress')
	};

	const tabs = [
		{ id: 'accountVsIndustry', lang: lang.accountVsIndustry },
		{ id: 'aboutIndustry', lang: lang.aboutIndustry, disabled: true, tooltip: lang.featureWorkInProgress }
	];

	const changeTab = (tabId: string) => {
		setSelectedTab(tabId as IndustryInsightsTabs);
	};

	function renderContent() {
		switch (selectedTab) {
			case IndustryInsightsTabs.ABOUT_INDUSTRY:
				// TODO: Add "About Industry" component
				return null;
			case IndustryInsightsTabs.ACCOUNT_VS_INDUSTRY:
			default:
				return (
					<IndustryInsightsAccount client={client} code={code} setImportDate={setImportDate} close={close} />
				);
		}
	}

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader
				className={classes.elem('header').b()}
				onHide={close}
				title={description}
				subtitle={importDate ? `${lang.lastUpdated} ${importDate}` : undefined}
			/>
			<Tabs className={classes.elem('tabs').b()} onChange={changeTab} selected={selectedTab} align="center">
				{tabs.map((tab, i) => {
					const TabComponent = <Tab key={tab.id + i} id={tab.id} title={tab.lang} disabled={tab.disabled} />;

					return tab.disabled ? (
						<Tooltip key={tab.id + i} title={tab.tooltip} position="bottom" distance={20}>
							{TabComponent}
						</Tooltip>
					) : (
						TabComponent
					);
				})}
			</Tabs>

			<Flex ref={contentRef} className={classes.elem('contentWrapper').b()}>
				<IndustryInsightsProvider description={description}>{renderContent()}</IndustryInsightsProvider>
			</Flex>
		</Drawer>
	);
};

export const detached = IndustryInsightsDrawer;
export default IndustryInsightsDrawer;
