import Attribute, { Type, Attr, DisplayType } from './Attribute';

const TicketAttributes = {
	id: Attribute({
		title: 'default.id2',
		field: 'id',
		type: Type.Number,
		groupable: false,
		sortable: 'id',
		selectableColumn: true,
		filterable: false
	}),
	title: Attribute({
		title: 'support.title',
		field: 'title',
		type: Type.String,
		groupable: false,
		sortable: 'title',
		selectableColumn: true,
		filterable: false
	}),
	client: Attribute({
		title: 'support.client',
		field: 'client.id',
		type: Type.String,
		displayType: DisplayType.ClientLink,
		groupable: false,
		sortable: 'client.name',
		selectableColumn: false,
		filterable: false,
		attr: {
			id: Attribute({
				field: 'client.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.name',
				type: Type.String
			})
		}
	}),
	contact: Attribute({
		title: 'support.requester',
		field: 'contact.id',
		type: Type.String,
		displayType: DisplayType.ContactLink,
		groupable: false,
		sortable: 'contact.name',
		selectableColumn: true,
		filterable: false,
		attr: {
			id: Attribute({
				field: 'client.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'contact.name',
				type: Type.String
			})
		}
	}),
	regDate: Attribute({
		title: 'support.created',
		field: 'regDate',
		type: Type.Date,
		groupable: false,
		sortable: 'regDate',
		selectableColumn: true,
		filterable: false
	}),
	resolveDate: Attribute({
		title: 'support.closed',
		field: 'resolveDate',
		type: Type.Date,
		groupable: false,
		sortable: 'resolveDate',
		selectableColumn: true,
		filterable: false
	}),
	subaccount: Attribute({
		title: 'account.subaccount',
		field: 'subaccount',
		type: Type.String,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	user: Attribute({
		title: 'support.assignee',
		field: 'user.name',
		type: Type.String,
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	status: Attribute({
		title: 'support.status',
		field: 'status',
		type: Type.Object,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		attr: {
			closed: Attribute({
				field: 'status.closed',
				type: Type.Boolean
			})
		}
	}),
	isArchived: Attribute({
		title: 'support.archived',
		field: 'isArchived',
		type: Type.Boolean,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	lastUpdated: Attribute({
		title: 'support.updated',
		field: 'lastUpdated',
		type: Type.Date,
		groupable: false,
		sortable: 'lastUpdated',
		selectableColumn: true,
		filterable: false
	}),
	type: Attribute({
		title: 'defaultView.ticketType',
		field: 'type.name',
		type: Type.String,
		groupable: false,
		sortable: 'type.name',
		selectableColumn: true,
		filterable: false
	}),
	priority: Attribute({
		title: 'ticket.priority',
		field: 'priority',
		type: Type.Number,
		sortable: 'priority',
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	mergedWithId: Attribute({
		title: 'ticket.mergedWithId',
		field: 'mergedWithId',
		type: Type.Number,
		groupable: false,
		selectableColumn: false,
		filterable: false
	})
} satisfies { [key: string]: Attr };

export default TicketAttributes;
