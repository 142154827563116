import React from 'react';
import { dateCalendar } from 'App/helpers/DateHelpers';
import moment from 'moment';
import ProjectPlanChip from 'App/pages/ProjectBoard/ProjectPlanChip';
import UserStack from 'App/components/UserStack';
import { Flex, Text, Title } from '@upsales/components';
import { default as colorMappings } from '@upsales/components/Utils/colorMappings';
import { Pin, PinBase, PinSpecifics, ProjectPlanType, Translate } from './MapPinTypes';

export const PROJECT_TYPE_CATEGORY = {
	PROJECT: 'PROJECT',
	SERVICE: 'SERVICE'
};

const renderDetails = (pin: Pin<ProjectPlanType>, t: Translate) => {
	const projectPlan = pin.data;
	const projectName = projectPlan.name;
	const clientName = projectPlan.client?.name;
	const contactName = projectPlan.contact?.name;
	const location = projectPlan.location;
	const startDate = projectPlan.startDate ? dateCalendar(projectPlan.startDate) : undefined;
	const endDate = projectPlan.endDate ? dateCalendar(projectPlan.endDate) : undefined;
	const startTime = projectPlan.startTime ? moment(projectPlan.startTime).format('LT') : undefined;
	const endTime = projectPlan.endTime ? moment(projectPlan.endTime).format('LT') : undefined;

	const combinedStartDateTime = startDate ? `${startDate}${startTime ? ` ${startTime}` : ''}` : undefined;
	const combinedEndDateTime = endDate ? `${endDate}${endTime ? ` ${endTime}` : ''}` : undefined;

	const startDateSameAsEndDate =
		moment(projectPlan.startDate).isSame(projectPlan.endDate, 'minute') && startTime === endTime;
	const showEndDate = endDate && !startDateSameAsEndDate;

	return (
		<Flex direction="column" gap="u2">
			<Title bold size="md">
				{projectName}
			</Title>
			<Text size="sm" color="grey-11">
				{clientName}
				{contactName ? ` • ${contactName}` : ''}{' '}
			</Text>
			<Text size="sm" color="grey-11">
				{location}
			</Text>
			<Text size="sm" color="grey-11">
				{combinedStartDateTime}
				{showEndDate ? ` -> ${combinedEndDateTime}` : ''}
			</Text>
			<Flex gap="u2" justifyContent="space-between">
				<Flex gap="u2">
					<ProjectPlanChip projectPlan={projectPlan} attribute="priority" />
					<ProjectPlanChip projectPlan={projectPlan} attribute="type" />
					<ProjectPlanChip projectPlan={projectPlan} attribute="stage" />
				</Flex>
				{projectPlan.user ? <UserStack users={[projectPlan.user]} /> : null}
			</Flex>
		</Flex>
	);
};

const servicePinBase = (projectPlan: ProjectPlanType) => {
	const isService = projectPlan.projectPlanType?.category === PROJECT_TYPE_CATEGORY.SERVICE;
	const projectPlanStageColor = projectPlan.projectPlanStage?.color || colorMappings.get('info-3');
	const pinBase: PinBase<ProjectPlanType> = {
		backgroundColor: projectPlanStageColor,
		color: colorMappings.get('black'),
		border: colorMappings.get('grey-1'),
		icon: isService ? 'service' : 'project',
		iconColor: 'white',
		renderDetails
	};
	return pinBase;
};

const getSpecificsFromProjectPlan = (projectPlan: ProjectPlanType) => {
	const [address, zipcode, city] = projectPlan.location?.split?.(', ') || [];

	if (!projectPlan.locationLatitude || !projectPlan.locationLongitude) {
		return null;
	}

	const pinSpecifics: PinSpecifics<ProjectPlanType> = {
		longitude: projectPlan.locationLongitude,
		latitude: projectPlan.locationLatitude,
		address: address,
		zipcode: zipcode,
		city: city,
		name: projectPlan.name,
		data: projectPlan
	};
	return pinSpecifics;
};

const createProjectPin = (projectPlan: ProjectPlanType) => {
	const servicePinSpecifics = getSpecificsFromProjectPlan(projectPlan);
	if (!servicePinSpecifics) {
		return null;
	}

	const projectPin: Pin<ProjectPlanType> = {
		...servicePinBase(projectPlan),
		...servicePinSpecifics
	};

	return projectPin;
};

export default createProjectPin;
