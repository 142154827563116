import NotificationSettings from 'App/babel/resources/NotificationSettings';
import NotificationService from '../../NotificationService';
import T from '../../components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import { batch } from 'react-redux';
import { hasSoftDeployAccess } from 'Store/actions/FeatureHelperActions';
import _ from 'lodash';

const SET_SETTINGS = '[NotificationCenter] SET_SETTINGS';
const SET_EDIT = '[NotificationCenter] SET_EDIT';
const SET_BEFORE_EDIT = '[NotificationCenter] SET_BEFORE_EDIT';
const SET_LOADING = '[NotificationCenter] SET_LOADING';

const NOTIFICATIONS_CONSTANTS = [
	'agreement',
	'appcallback',
	'assigned',
	'esign',
	'import',
	'order',
	'submit',
	'visit',
	'ticket',
	'ticket:accountmanagers',
	'ticket:replies',
	'ticket:assigned',
	'prospecting:signals:general',
	'prospecting:signals:accounts',
	'prospecting:signals:directors',
	'prospecting:news',
	'email:projects:mentioned',
	'email:projects:assigned',
	'email:tickets:assigned',
	'email:tickets:mentioned',
	'email:tickets:accountmanagers'
];
const REMINDER_CONSTANTS = ['activity', 'appointment', 'appointmentoutcome'];
const notificationsMapper = {
	activity: {
		title: 'default.activities',
		subTitle: 'notificationCenter.subTitle.activity'
	},
	appointment: {
		title: 'default.appointments',
		subTitle: 'notificationCenter.subTitle.appointment'
	},
	appointmentoutcome: {
		title: 'default.appointmentOutcome',
		subTitle: 'notificationCenter.subTitle.appointmentoutcome'
	},
	agreement: {
		title: 'agreement',
		subTitle: 'notificationCenter.subTitle.agreement'
	},
	appcallback: {
		title: 'admin.apps',
		subTitle: 'notificationCenter.subTitle.appcallback'
	},
	assigned: {
		title: 'default.assign',
		subTitle: 'notificationCenter.subTitle.assigned'
	},
	esign: {
		title: 'default.eSign',
		subTitle: 'notificationCenter.subTitle.esign'
	},
	export: {
		title: 'default.export',
		subTitle: 'notificationCenter.subTitle.export'
	},
	import: {
		title: 'default.import',
		subTitle: 'notificationCenter.subTitle.import'
	},
	job: {
		title: 'admin.job',
		subTitle: 'notificationCenter.subTitle.job'
	},
	order: {
		title: 'default.order',
		subTitle: 'notificationCenter.subTitle.order'
	},
	submit: {
		title: 'default.formSubmits',
		subTitle: 'notificationCenter.subTitle.submit'
	},
	visit: {
		title: 'default.siteVisits',
		subTitle: 'notificationCenter.subTitle.visit'
	},
	'prospecting:signals:general': {
		title: 'notificationCenter.title.prospecting:signals:general',
		subTitle: 'notificationCenter.subTitle.prospecting:signals:general'
	},
	'prospecting:signals:accounts': {
		title: 'notificationCenter.title.prospecting:signals:accounts',
		subTitle: 'notificationCenter.subTitle.prospecting:signals:accounts'
	},
	'prospecting:signals:directors': {
		title: 'notificationCenter.title.prospecting:signals:directors',
		subTitle: 'notificationCenter.subTitle.prospecting:signals:directors'
	},
	'prospecting:news': {
		title: 'notificationCenter.title.prospecting:news',
		subTitle: 'notificationCenter.subTitle.prospecting:news'
	},
	ticket: {
		title: 'ticket.tickets',
		subTitle: 'notificationCenter.subTitle.ticket',
		hide: 'TICKET_NOTIFICATION_V2'
	},
	'ticket:accountmanagers': {
		title: 'notificationCenter.title.ticket:accountManagers',
		subTitle: 'notificationCenter.subTitle.ticket:accountManagers',
		featureFlag: 'TICKET_NOTIFICATION_V2'
	},
	'ticket:replies': {
		title: 'notificationCenter.title.ticket:replies',
		subTitle: 'notificationCenter.subTitle.ticket:replies',
		featureFlag: 'TICKET_NOTIFICATION_V2',
		userType: 'support'
	},
	'ticket:assigned': {
		title: 'notificationCenter.title.ticket:assigned',
		subTitle: 'notificationCenter.subTitle.ticket:assigned',
		featureFlag: 'TICKET_NOTIFICATION_V2',
		userType: 'support'
	},
	'email:projects:mentioned': {
		title: 'notificationCenter.title.email:projects:mentioned',
		subTitle: 'notificationCenter.subTitle.email:projects:mentioned',
		userType: ['service', 'crm', 'support']
	},
	'email:projects:assigned': {
		title: 'notificationCenter.title.email:projects:assigned',
		subTitle: 'notificationCenter.subTitle.email:projects:assigned',
		userType: 'service'
	},
	'email:tickets:assigned': {
		title: 'notificationCenter.title.email:tickets:assigned',
		subTitle: 'notificationCenter.subTitle.email:tickets:assigned',
		userType: 'support'
	},
	'email:tickets:mentioned': {
		title: 'notificationCenter.title.email:tickets:mentioned',
		subTitle: 'notificationCenter.subTitle.email:tickets:mentioned',
		userType: ['support', 'crm', 'service']
	},
	'email:tickets:accountmanagers': {
		title: 'notificationCenter.title.email:tickets:accountManagers',
		subTitle: 'notificationCenter.subTitle.email:tickets:accountManagers',
		userType: 'crm'
	}
};

export const initialState = {
	notifications: [],
	reminders: [],
	editing: false,
	editEntity: null,
	beforeEditItem: null,
	isLoading: true,
	times: {
		minutes: ['0', '5', '10', '15', '30', '45', '55'],
		hours: ['0', '1', '2', '3', '4', '5']
	},
	unit: ['minutes', 'hours']
};

const setSettings = (NOTIFICATIONS, REMINDERS) => async dispatch => {
	await Tools.AppService.loadedPromise;
	const entityToFeatureMap = {
		assigned: Tools.FeatureHelper.Feature.LEADS,
		esign: Tools.FeatureHelper.Feature.ESIGN,
		submit: Tools.FeatureHelper.Feature.FORMS,
		order: Tools.FeatureHelper.Feature.ORDERS,
		agreement:
			Tools.AppService.getMetadata().params.AgreementEnabled && Tools.FeatureHelper.Feature.RECURRING_ORDER,
		visit: Tools.FeatureHelper.Feature.VISITS,
		ticket: Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT,
		'ticket:accountmanagers': Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT,
		'ticket:replies': Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT,
		'ticket:assigned': Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT
	};

	NOTIFICATIONS = _.filter(NOTIFICATIONS, n => {
		const feature = entityToFeatureMap[n.entity];
		return !feature || Tools.FeatureHelper.isAvailable(feature, true);
	});

	return dispatch({
		type: SET_SETTINGS,
		settings: {
			notifications: NOTIFICATIONS,
			reminders: REMINDERS
		}
	});
};

function shouldSkipNotification(dispatch, entity, self) {
	const notification = notificationsMapper[entity];

	if (notification.featureFlag && !dispatch(hasSoftDeployAccess(notification.featureFlag))) {
		return true;
	}

	if (notification.hide && dispatch(hasSoftDeployAccess(notification.hide))) {
		return true;
	}

	if (dispatch(hasSoftDeployAccess('TICKET_NOTIFICATION_V2'))) {
		if (Array.isArray(notification.userType)) {
			return !notification.userType.some(type => self[type]);
		}
		return !self[notification.userType ?? 'crm'];
	}

	return false;
}

const mapSettingsWithFallback = (NOTIFICATIONS, REMINDERS) => dispatch => {
	const notificationsOut = [];
	const remindersOut = [];

	const self = Tools.AppService.getSelf();

	for (const entity of NOTIFICATIONS_CONSTANTS) {
		const [settingsObject] = NOTIFICATIONS.filter(item => item.entity === entity);

		if (shouldSkipNotification(dispatch, entity, self)) {
			continue;
		}

		if (settingsObject) {
			settingsObject.title = T(notificationsMapper[entity].title);
			settingsObject.subTitle = T(notificationsMapper[entity].subTitle);
			settingsObject.type = 'NOTIFICATION';
			notificationsOut.push(settingsObject);
		} else {
			notificationsOut.push({
				enabled: true,
				entity,
				title: T(notificationsMapper[entity].title),
				subTitle: T(notificationsMapper[entity].subTitle),
				type: 'NOTIFICATION'
			});
		}
	}

	if (self.crm) {
		for (const entity of REMINDER_CONSTANTS) {
			const [settingsObject] = REMINDERS.filter(item => item.entity === entity);

			if (settingsObject) {
				settingsObject.title = T(notificationsMapper[entity].title);
				settingsObject.subTitle = T(notificationsMapper[entity].subTitle);
				settingsObject.type = 'REMINDER';
				settingsObject.reminderTime = settingsObject.reminderTime.toString();

				remindersOut.push(settingsObject);
			} else {
				remindersOut.push({
					enabled: true,
					entity,
					title: T(notificationsMapper[entity].title),
					subTitle: T(notificationsMapper[entity].subTitle),
					type: 'REMINDER',
					reminderUnit: 'minutes',
					reminderTime: '0'
				});
			}
		}
	}
	dispatch(setSettings(notificationsOut, remindersOut));
};

export const initialize = item => async dispatch => {
	try {
		const { data } = await NotificationSettings.find();

		let found = false;
		const [NOTIFICATIONS, REMINDERS] = data.reduce(
			(memo, setting) => {
				if (setting.type === 'NOTIFICATION') {
					memo[0].push(setting);
				} else {
					if (item && item.entity === setting.entity) {
						found = true;
						memo[1].push(item);
					} else {
						memo[1].push(setting);
					}
				}

				return memo;
			},
			[[], []]
		);

		if (item && !found) {
			REMINDERS.push(item);
		}

		dispatch(mapSettingsWithFallback(NOTIFICATIONS, REMINDERS));
	} finally {
		dispatch({ type: SET_LOADING, isLoading: false });
	}
};

export const updateItem = (item, type) => (dispatch, getState) => {
	const { notifications, reminders } = getState().NotificationCenter;
	const mapper = { NOTIFICATION: notifications, REMINDER: reminders };

	const refreshed = mapper[type].map(storeItem => {
		if (item.entity === storeItem.entity) {
			return Object.assign({}, storeItem, item);
		}

		return storeItem;
	});

	return dispatch({
		type: SET_SETTINGS,
		settings: { [type === 'NOTIFICATION' ? 'notifications' : 'reminders']: refreshed }
	});
};

const setBeforeEditItem = beforeEditItem => ({
	type: SET_BEFORE_EDIT,
	beforeEditItem
});

export const startEdit = item => dispatch => {
	batch(() => {
		//want clone so it doesn't become reference
		dispatch(setBeforeEditItem({ ...item }));
		dispatch({
			type: SET_EDIT,
			data: {
				editing: true,
				editEntity: item.entity
			}
		});
	});
};

export const stopEdit = () => (dispatch, getState) => {
	const editItem = getState().NotificationCenter.beforeEditItem;
	batch(() => {
		if (editItem) {
			dispatch(updateItem(editItem, editItem.type));
			dispatch(setBeforeEditItem(null));
		}
		dispatch({
			type: SET_EDIT,
			data: {
				editing: false,
				editEntity: null
			}
		});
	});
};

export const previewNotification =
	({ entity, reminderTime, reminderUnit }) =>
	() => {
		let type = Tools.AppService.getActivityTypes()[0].name;
		if (entity === 'appointment') {
			const appointmentTypes = _.find(Tools.AppService.getActivityTypes(), item => item.hasOwnProperty('first'));
			if (appointmentTypes) {
				type = appointmentTypes.name;
			}
		}

		const body = [
			type,
			moment().add(parseInt(reminderTime), reminderUnit).calendar().toLowerCase(),
			T('default.at2').toLowerCase(),
			moment().add(parseInt(reminderTime), reminderUnit).format('HH:mm')
		];

		let title = `${T('notificationcenter.planned.in')} ${reminderTime} ${T(reminderUnit)}`;
		if (reminderTime === '0') {
			title = `${T('salesboard.planned')} ${T('date.now').toLowerCase()}`;
		}

		NotificationService.add({
			icon: 'bell',
			title: title,
			autoHide: false,
			body: body.join(' ')
		});
	};

export const saveSetting = item => dispatch => {
	NotificationSettings.save(item)
		.then(res => {
			NotificationService.add({
				style: 'success',
				title: T('notificationcenter.settingsSaved')
			});
			dispatch(initialize({ ...res.data, title: item.title }));
			dispatch({
				type: SET_EDIT,
				data: {
					editing: false,
					editEntity: null
				}
			});
		})
		.catch(e => {
			if (e) {
				logError(e);
			}
			NotificationService.add({
				style: 'error',
				title: T('notificationcenter.settingsSaveFailed')
			});
		});
};

const ACTION_HANDLERS = {
	[SET_SETTINGS]: (state, { settings }) => ({ ...state, ...settings }),
	[SET_EDIT]: (state, { data }) => ({ ...state, ...data }),
	[SET_BEFORE_EDIT]: (state, { beforeEditItem }) => ({ ...state, beforeEditItem }),
	[SET_LOADING]: (state, { isLoading }) => ({ ...state, isLoading })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
