import React, { useRef, useMemo, useEffect } from 'react';
import { TableColumn } from '@upsales/components';

type Props = {
	isDragOccurring: boolean;
	isBeingDragged: boolean;
	children: React.ReactNode;
};

// TableColumn wrapper that locks the width and height of the cell when it is being dragged.
// Uses the "Dimension locking" technique from the react-beautiful-dnd documentation:
// https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/tables.md
const LockedTableCell = ({ isDragOccurring, isBeingDragged, children }: Props) => {
	const ref = useRef<HTMLTableCellElement>(null);
	const prevIsDragOccurring = useRef(isDragOccurring);

	const snapshot = useMemo(() => {
		if (!ref.current) {
			return null;
		}

		const isDragStarting = !prevIsDragOccurring.current && isDragOccurring;

		if (!isDragStarting) {
			return null;
		}

		const { width, height } = ref.current.getBoundingClientRect();

		return { width, height };
	}, [isDragOccurring]);

	useEffect(() => {
		prevIsDragOccurring.current = isDragOccurring;
	}, [isDragOccurring]);

	useEffect(() => {
		if (!ref.current) {
			return;
		}

		if (snapshot) {
			if (parseInt(ref.current.style.width) === snapshot.width) {
				return;
			}

			ref.current.style.setProperty('width', `${snapshot.width}px`, 'important');
			ref.current.style.setProperty('height', `${snapshot.height}px`, 'important');

			if (isBeingDragged) {
				ref.current.style.setProperty('background-color', 'white', 'important');
			}
		}

		if (isDragOccurring) {
			return;
		}

		if (ref.current.style.width == null) {
			return;
		}

		ref.current.style.removeProperty('width');
		ref.current.style.removeProperty('height');
		ref.current.style.removeProperty('background-color');
	}, [isDragOccurring, isBeingDragged, snapshot]);

	return <TableColumn innerRef={ref}>{children}</TableColumn>;
};

export default LockedTableCell;
