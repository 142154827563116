import React from 'react';
import { Flex, Text } from '@upsales/components';
import { getTextColor } from 'Helpers/color';
import './ColorChip.scss';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	className?: string;
	color: string;
};

const ColorChip = ({ className, color }: Props) => {
	const classes = new BemClass('ColorChip', className);
	return (
		<Flex justifyContent="center" alignItems="center" style={{ backgroundColor: color }} className={classes.b()}>
			<Text style={{ color: getTextColor(color) }} size="sm">
				{color}
			</Text>
		</Flex>
	);
};

export default ColorChip;
