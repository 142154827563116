import { isAvailable, Feature } from 'Store/actions/FeatureHelperActions';
import { calculateMonthlyValue } from 'App/helpers/periodizationHelper';
import Periodization from 'Resources/Periodization';
import _ from 'lodash';

export const PERIODIZATION_FIELD = {
	VALUE: 'value',
	PURCHASE_COST: 'purchaseCost',
	CONTRIBUTION_MARGIN: 'contributionMargin',
	VALUE_AND_PURCHASE_COST: 'valueAndPurchaseCost'
};

/***** Helpers *****/
const isValidDate = date => {
	return date instanceof Date && !isNaN(date);
};

const getEnabledRowsToAccure = rows => {
	return _.reduce(
		rows,
		(memo, row, key) => {
			if (row) {
				memo[key] = true;
			}
			return memo;
		},
		{}
	);
};

export const initialState = {
	order: undefined,
	data: {},
	isInvalid: true,
	invalidIssue: PERIODIZATION_FIELD.VALUE_AND_PURCHASE_COST,
	startDate: undefined,
	endDate: undefined,
	startDateInput: undefined,
	endDateInput: undefined,
	pending: false,
	id: undefined,
	chartData: [],
	field: PERIODIZATION_FIELD.VALUE,
	rowsToAccure: {}
};

const RESET = '[Periodization] RESET';
const SET_INVALID = '[Periodization] SET_INVALID';
const SET_START_DATE = '[Periodization] SET_START_DATE';
const SET_END_DATE = '[Periodization] SET_END_DATE';
const SET_ORDER = '[Periodization] SET_ORDER';
const SET_DATA = '[Periodization] SET_DATA';
const SET_CHART_DATA = '[Periodization] SET_CHART_DATA';
const SET_PENDING = '[Periodization] SET_PENDING';
const SET_ID = '[Periodization] SET_ID';
const TOGGLE_ROW_TO_ACCURE = '[Periodization] TOGGLE_ROW_TO_ACCURE';
const CLEAR_ROWS_TO_ACCRUE = '[Periodization] CLEAR_ROWS_TO_ACCRUE';
const SET_FIELD = '[Periodization] SET_FIELD';

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case RESET:
			return { ...initialState };
		case SET_INVALID:
			return { ...state, invalidIssue: action.invalidIssue };
		case SET_START_DATE: {
			const nextState = { ...state, startDateInput: action.value };
			if (
				!Object.keys(getEnabledRowsToAccure(state.rowsToAccure)).length ||
				moment(state.startDate).isAfter(action.value) ||
				!state.startDate
			) {
				nextState.startDate = action.value;
			}
			return nextState;
		}
		case SET_END_DATE: {
			const nextState = { ...state, endDateInput: action.value };
			if (
				!Object.keys(getEnabledRowsToAccure(state.rowsToAccure)).length ||
				moment(state.endDate).isBefore(action.value) ||
				!state.endDate
			) {
				nextState.endDate = action.value;
			}
			return nextState;
		}
		case SET_DATA: {
			return { ...state, data: action.value, isInvalid: !action.isValid };
		}
		case SET_ORDER:
			return { ...state, order: action.value };
		case SET_CHART_DATA:
			return { ...state, chartData: action.value };
		case SET_PENDING:
			return { ...state, pending: action.value };
		case SET_ID:
			return { ...state, id: action.value };
		case SET_FIELD:
			return { ...state, field: action.value };
		case TOGGLE_ROW_TO_ACCURE:
			return {
				...state,
				rowsToAccure: { ...state.rowsToAccure, [action.value]: !state.rowsToAccure[action.value] }
			};
		case CLEAR_ROWS_TO_ACCRUE: {
			return { ...state, rowsToAccure: {}, startDateInput: state.startDate, endDateInput: state.endDate };
		}
		default:
			return state;
	}
};

export default reducer;

/*********** Actions **********/

export const reset = () => {
	return { type: RESET };
};

export const setInvalid = value => {
	return { type: SET_INVALID, value };
};

export const setStartDate = value => {
	return { type: SET_START_DATE, value };
};

export const setEndDate = value => {
	return { type: SET_END_DATE, value };
};

export const setDataAction = map => (dispatch, getState) => {
	const { order } = getState().Periodization;
	const value = { ...map };
	const isValid = dispatch(checkValidity(order, value));
	dispatch({ type: SET_DATA, value, isValid });
};

export const setOrderAction = value => {
	return { type: SET_ORDER, value };
};

export const setChartData = value => {
	return { type: SET_CHART_DATA, value };
};

export const setPending = value => {
	return { type: SET_PENDING, value };
};

export const setId = value => {
	return { type: SET_ID, value };
};

export const setField = value => {
	return { type: SET_FIELD, value };
};

export const toggleRowToAccrue = value => {
	return { type: TOGGLE_ROW_TO_ACCURE, value };
};

export const clearRowsToAccrue = () => {
	return { type: CLEAR_ROWS_TO_ACCRUE };
};

export const hasDateRange = () => (dispatch, getState) => {
	const { startDate, endDate } = getState().Periodization;
	return isValidDate(startDate) && isValidDate(endDate);
};

const initRow = (startDate, endDate, oldRow) => {
	let currentMoment = moment(startDate);
	let year = 0;
	let month = 0;
	const rowData = [];

	while (!currentMoment.isAfter(moment(endDate))) {
		const currentMonth = currentMoment.month() + 1;
		const currentYear = currentMoment.year();
		if (currentMonth !== month || currentYear !== year) {
			year = currentYear;
			month = currentMonth;
			const oldColumn = oldRow && oldRow.find(row => row.month === month && row.year === year);
			const value = (oldColumn && oldColumn.value) || 0;
			const purchaseCost = (oldColumn && oldColumn.purchaseCost) || 0;
			rowData.push({ year, month, value, purchaseCost });
		}
		currentMoment = currentMoment.add(1, 'day');
	}

	return rowData;
};

/***** Dispatched actions *****/

export const setData = data => dispatch => {
	if (!data) {
		return;
	}
	const chartData = [];
	const rowIds = Object.keys(data);
	const firstRowId = rowIds[0];

	if (firstRowId) {
		_.forEach(data[firstRowId], (entry, index) => {
			const value = _.reduce(
				rowIds,
				(result, rowId) => {
					return result + data[rowId][index].value;
				},
				0
			);

			const purchaseCost = _.reduce(
				rowIds,
				(result, rowId) => {
					return result + (data[rowId][index].purchaseCost || 0);
				},
				0
			);

			const column = {
				year: entry.year,
				month: entry.month,
				value,
				purchaseCost
			};
			chartData.push(column);
		});
	}

	dispatch(setPending(false));
	dispatch(setChartData(chartData));
	dispatch(setDataAction(data));
};

export const initData = () => (dispatch, getState) => {
	const { data, order, startDate, endDate } = getState().Periodization;
	if (!isValidDate(startDate) && !isValidDate(endDate)) {
		return;
	}
	const newData = {};
	_.forEach(order.orderRow, row => {
		const rowId = row.id;
		newData[rowId] = initRow(startDate, endDate, data[rowId]);
	});

	dispatch(setData(newData));
};

export const setOrder = newOrder => (dispatch, getState) => {
	const { order } = getState().Periodization;

	if (order && order.id !== newOrder.id) {
		dispatch(reset());
	}

	if (newOrder && newOrder.periodization) {
		const periodization = newOrder.periodization;
		dispatch(setId(periodization.id));
		dispatch(setStartDate(periodization.startDate));
		dispatch(setEndDate(periodization.endDate));

		const data = {};
		_.forEach(newOrder.orderRow, row => {
			const oldColumn = periodization.data[row.id] || null;
			data[row.id] = initRow(periodization.startDate, periodization.endDate, oldColumn);
		});

		dispatch(setData(data));
	} else {
		dispatch(setId());
		dispatch(setStartDate());
		dispatch(setEndDate());
		dispatch(setData({}));
	}

	dispatch(setPending(false));
	dispatch(setOrderAction(newOrder));
};

export const save = () => (dispatch, getState) => {
	const { id, order, startDate, endDate, data } = getState().Periodization;
	dispatch(setPending(true));

	const convertedData = {};
	const currencyRate = order.currencyRate || 1;
	Object.keys(data).forEach(key => {
		convertedData[key] = data[key].map(entry => {
			const converted = { ...entry };
			converted.value = entry.value / currencyRate;
			if (entry.purchaseCost) {
				converted.purchaseCost = entry.purchaseCost / currencyRate;
			}

			return converted;
		});
	});

	return Periodization.save({ id, orderId: order.id, startDate, endDate, data: convertedData }).then(res => {
		Object.keys(res.data.data).forEach(key => {
			res.data.data[key].forEach(entry => {
				entry.value = entry.value * currencyRate;
				if (entry.purchaseCost) {
					entry.purchaseCost = entry.purchaseCost * currencyRate;
				}
			});
		});
		order.periodization = res.data;
		dispatch(setOrder(order));
		dispatch(setPending(false));
	});
};

export const remove = () => (dispatch, getState) => {
	const { id, order } = getState().Periodization;
	dispatch(setPending(true));

	return Periodization.delete(id).then(() => {
		dispatch(setPending(false));
		delete order.periodization;
		dispatch(setOrder(order));
	});
};

export const cancel = () => (dispatch, getState) => {
	const { order } = getState().Periodization;

	dispatch(setOrder(order));
};

export const cleanFloat = num => {
	const priceDecimals = Tools.AppService.getMetadata().params.OrderedProductPriceDecimals || 2;
	return typeof num === 'number' ? parseFloat(num.toFixed(priceDecimals)) : 0;
};

export const getOrderRowValue = (orderRow, field) => {
	const isTotalTier = orderRow.product?.tiers?.[0]?.isTotalPrice;
	const quantity = isTotalTier ? 1 : parseFloat(orderRow.quantity);
	const value = field === PERIODIZATION_FIELD.VALUE ? orderRow.price : orderRow.purchaseCost;
	return quantity * parseFloat(value || 0);
};

export function checkValidity(order, data) {
	return (dispatch, getState) => {
		if (!order || !data) {
			return false;
		}
		const { metadata } = getState().App;
		const hasContributionMargin =
			dispatch(isAvailable(Feature.CONTRIBUTION_MARGIN)) && metadata.params.UseContributionMargin;

		const periodizationKeys = Object.keys(data).map(key => parseInt(key));
		const orderRowKeys = _.map(order.orderRow, 'id');
		const haveSameKeys =
			_.difference(periodizationKeys, orderRowKeys).length === 0 &&
			_.difference(orderRowKeys, periodizationKeys).length === 0;

		if (!haveSameKeys) {
			return false;
		}

		for (const [rowId, rowArray] of Object.entries(data)) {
			const orderRow = _.find(order.orderRow, { id: parseInt(rowId) });
			const orderRowValue = getOrderRowValue(orderRow, PERIODIZATION_FIELD.VALUE);
			const orderRowPurchaseCost = getOrderRowValue(orderRow, PERIODIZATION_FIELD.PURCHASE_COST);
			const periodizationValue = _.reduce(
				rowArray,
				(res, entry) => res + (entry[PERIODIZATION_FIELD.VALUE] || 0),
				0
			);
			const periodizationPurchaseCost = _.reduce(
				rowArray,
				(res, entry) => res + (entry[PERIODIZATION_FIELD.PURCHASE_COST] || 0),
				0
			);

			const invalidValue = cleanFloat(orderRowValue) !== cleanFloat(periodizationValue);
			const invalidPurchaseCost =
				hasContributionMargin && cleanFloat(orderRowPurchaseCost) !== cleanFloat(periodizationPurchaseCost);
			if (invalidValue || invalidPurchaseCost) {
				let issue = invalidValue ? PERIODIZATION_FIELD.VALUE : PERIODIZATION_FIELD.PURCHASE_COST;
				if (invalidValue && invalidPurchaseCost) {
					issue = PERIODIZATION_FIELD.VALUE_AND_PURCHASE_COST;
				}
				dispatch({ type: SET_INVALID, invalidIssue: issue });
				return false;
			}
		}
		dispatch({ type: SET_INVALID, invalidIssue: undefined });
		return true;
	};
}

export const checkValidityOrder = order => dispatch => {
	return order.periodization && order.periodization.data
		? dispatch(checkValidity(order, order.periodization.data))
		: true;
};

export const updateData = (rowId, index, value, rest) => (dispatch, getState) => {
	const { data, field } = getState().Periodization;
	const rowArray = data[rowId];

	_.forEach(rowArray, (entry, i) => {
		if (index === null || i === index || (rest && i > index)) {
			entry[field] = value;
		}
	});

	dispatch(setData(data));
};

export const distribute = () => (dispatch, getState) => {
	const { order, data, rowsToAccure, startDateInput, endDateInput, field } = getState().Periodization;

	const enabledRowsToAccrue = getEnabledRowsToAccure(rowsToAccure);
	const startDate = moment(startDateInput).startOf('month');
	const endDate = moment(endDateInput).endOf('month');

	const newOrderData = order.orderRow.reduce((acc, orderRow) => {
		const rowArray = data[orderRow.id];

		if (Object.keys(enabledRowsToAccrue).length && !enabledRowsToAccrue[orderRow.id]) {
			acc[orderRow.id] = rowArray;
			return acc;
		}

		const periodLength = Object.keys(enabledRowsToAccrue).length
			? Math.round(endDate.diff(startDate, 'months', true))
			: rowArray.length;
		const totalValue = getOrderRowValue(orderRow, field);
		const averageValue = cleanFloat(totalValue / periodLength);
		const monthlyValue = calculateMonthlyValue(startDateInput, endDateInput, totalValue);
		const objectKeysLength = !!Object.keys(enabledRowsToAccrue).length;
		const newOrderRowArray = data[orderRow.id].map(entry => {
			const entryMoment = moment(`${entry.year}-${entry.month}`, 'YYYY-MM');
			if (
				objectKeysLength &&
				(entryMoment.isBefore(startDate, 'month') || entryMoment.isAfter(endDate, 'month'))
			) {
				return { ...entry, [field]: 0 };
			}

			const matchingMonthlyValue = monthlyValue.find(mv => mv.year === entry.year && mv.month === entry.month);
			return {
				...entry,
				[field]: matchingMonthlyValue ? matchingMonthlyValue.value : averageValue
			};
		});

		const monthlyValueSum = newOrderRowArray.reduce((sum, order) => sum + order[field], 0);
		const shouldRemoveLastMonth = monthlyValueSum > 0 && newOrderRowArray.at(-1)[field] === 0;
		const filteredRowArray = shouldRemoveLastMonth ? newOrderRowArray.slice(0, -1) : newOrderRowArray;

		const totalDiff = monthlyValueSum - totalValue;
		if (totalDiff) {
			filteredRowArray[0] = {
				...filteredRowArray[0],
				[field]: cleanFloat(filteredRowArray[0][field] - cleanFloat(totalDiff))
			};
		}

		acc[orderRow.id] = filteredRowArray;
		return acc;
	}, {});

	dispatch(setData(newOrderData));
	dispatch(clearRowsToAccrue());
};

export const setDate = (field, date) => dispatch => {
	if (!isValidDate(date)) {
		return;
	}
	if (field === 'start') {
		dispatch(setStartDate(date));
	} else {
		dispatch(setEndDate(date));
	}

	dispatch(initData());
};
