import React from 'react';
import { FullscreenModal, ModalContent, ModalHeader } from '@upsales/components';
import Map, { Props } from './Map';
import { useTranslation } from 'Components/Helpers/translate';
import { ModalProps } from 'App/components/Modals/Modals';
import BemClass from '@upsales/components/Utils/bemClass';
import './MapModal.scss';

export type MapModalProps<T> = Pick<Props<T>, 'onPinSelect' | 'defaultCenter' | 'pins'> & ModalProps;

const MapModal = ({ className, close, onPinSelect, ...rest }: MapModalProps<any>) => {
	const classes = new BemClass('MapModal', className);
	const { t } = useTranslation();

	const onPinSelectOverride = onPinSelect
		? (location: any) => {
				onPinSelect?.(location);
				close();
		  }
		: undefined;

	return (
		<FullscreenModal headerAtTop className={classes.b()}>
			<ModalHeader title={t('default.findLocationOnMap')} onClose={close} />
			<ModalContent>
				<Map {...rest} onPinSelect={onPinSelectOverride} />
			</ModalContent>
		</FullscreenModal>
	);
};

export default MapModal;
