import formatWidgetValue from 'App/pages/Reportcenter/ReportcenterWidget/formatWidgetValue';

export const getMasterCurrency = (): string => {
	const currencies = Tools.AppService.getMetadata()?.customerCurrencies ?? [];
	return currencies.find(currency => currency.masterCurrency)?.iso ?? 'SEK';
};

export const formatCurrency = (value: number | undefined, compact = true): string => {
	if (value === undefined) return '-';
	const masterCurrency = getMasterCurrency();
	return formatWidgetValue(value, 'currency', masterCurrency, compact);
};

// Formats the take rate value to show the first three non-zero decimal digits
export const formatTakeRate = (value: number): string => {
	if (value === 0) return '0.00%';

	const formattedValue = (value * 100).toString();
	if (formattedValue.includes('.')) {
		const [whole, decimal] = formattedValue.split('.');
		// Find first non-zero digit in decimal
		const firstNonZeroIndex = decimal.split('').findIndex(d => d !== '0');
		if (firstNonZeroIndex === -1) {
			return `${whole}.00%`;
		}
		// Show three significant digits starting from first non-zero
		const significantDigits = decimal.substring(firstNonZeroIndex, firstNonZeroIndex + 3);
		return `${whole}.${'0'.repeat(firstNonZeroIndex)}${significantDigits}%`;
	}
	return formattedValue + '.00%';
};
