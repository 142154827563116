import createProjectPin from 'App/components/Map/mapPins/ProjectPin';
import { SupportedPinEntity } from './MapPinTypes';

export const PIN_TYPE = {
	PROJECT_PLAN: 'PROJECT_PLAN'
};

export const createPin = (entity: SupportedPinEntity, type: string) => {
	switch (type) {
		case PIN_TYPE.PROJECT_PLAN:
			return createProjectPin(entity);
		default:
			return createProjectPin(entity);
	}
};
