import React, { useEffect, useMemo } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import NewMail from '../Components/NewMail';
import SwooshScreen from '../Components/SwooshScreen';
import MailSendButton from '../Components/MailSendButton/MailSendButton';
import T from 'Components/Helpers/translate';
import { SlideFade } from 'App/components/animations';
import { DrawerHeader } from '@upsales/components';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { MailProvider, useMailContext } from '../MailContext';
import { init, send } from '../MailContextHelpers';
import MailType, {
	MailActivity,
	MailAppointment,
	MailOpportunity,
	MailRecipient,
	Attachment
} from 'App/resources/Model/Mail';
import PostponeRelatedActivity from '../Components/PostponeRelatedActivity';
import { mailIntegrationIsValid, getTooltipIfRecipientsExceedMaxSize } from 'App/helpers/mailHelpers';
import openModal from 'App/services/Modal';

import './NewSingleMail.scss';
import Mail from 'App/resources/Model/Mail';
import { OUTCOME_TYPES } from 'App/babel/enum/activityOutcome';
import CloseRelatedActivity from '../Components/CloseRelatedActivity/CloseRelatedActivity';
import { ModalProps } from 'App/components/Modals/Modals';

type Props = {
	appointment?: MailAppointment;
	activity?: MailActivity;
	opportunity?: MailOpportunity;
	attachments?: Attachment[];
	recipients?: {
		to: MailRecipient[];
		cc: MailRecipient[];
		bcc: MailRecipient[];
	};
	subject?: string;
	source?: 'activity' | 'appointment' | 'opportunity';
	onSend?: (mail: Partial<MailType> | Partial<MailType>[], metadata?: { confirmed?: boolean }) => void;
	client?: Mail['client'];
	body?: Mail['body'];
} & ModalProps;

const NewSingleMail = (props: Props) => {
	const { className, onSend = () => {}, close, modalId, ...mail } = props;
	const { self, mailIntegration } = useSelector(({ App }: RootState) => App);
	const {
		state: { id, loading },
		state,
		dispatch
	} = useMailContext();

	const initIfValidMailIntegration = async () => {
		if (mailIntegration && (await mailIntegrationIsValid(mailIntegration))) {
			init(dispatch, mail);
		} else {
			close();
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CHOOSE_MAIL_INTEGRATION')) {
				openModal('ChooseMailIntegration', { startSelectedId: mailIntegration?.id });
			} else {
				Tools.$upModal.open('chooseMailIntegration', {
					startSelectedId: mailIntegration?.id
				});
			}
		}
	};
	const mailIsSent = !!id;

	useEffect(() => {
		initIfValidMailIntegration();
	}, []);

	const sendMail = async () => {
		const sentMail = await send(dispatch, state);
		if (props.source === 'activity') return;
		if (sentMail) {
			onSend(Array.isArray(sentMail) ? sentMail : { ...sentMail });
			close?.();
		}
	};

	const closeModal = () => {
		if (!state.hasChanged) {
			return close?.();
		}

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('Alert', {
				title: 'default.unsavedChanges',
				body: 'confirm.changesWillBeLost',
				headerIcon: 'exclamation-triangle',
				confirmButtonText: 'default.discardChanges',
				onClose: confirmed => {
					if (confirmed) {
						close();
					}
				}
			});
			return;
		}

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('infoConfirm', {
				title: 'default.unsavedChanges',
				body: 'confirm.changesWillBeLost',
				icon: 'fa-exclamation-triangle',
				no: 'default.abort',
				resolveTrue: 'default.discardChanges',
				resolveTrueBtnClass: 'btn-bright-blue btn-lined'
			})
			.then(close);
	};

	const onPostponeOrCloseAnswer = (confirmed: boolean) => {
		if (!id) return;
		onSend({ id }, { confirmed });
		close?.();
	};

	const tooManyRecipientsTooltip = useMemo(
		() => getTooltipIfRecipientsExceedMaxSize(state.recipients),
		[state.recipients]
	);

	const classes = new BemClass('NewSingleMail', className);

	return (
		<div className={classes.b()}>
			{props.source === 'activity' && !!id ? null : (
				<>
					<DrawerHeader onHide={closeModal} title={T('mail.newEmail')}>
						<div className={classes.elem('rightSide').b()}>
							<MailSendButton
								onClick={() => sendMail()}
								disabled={
									!state.subject.length || !state.recipients.to.length || !!tooManyRecipientsTooltip
								}
								tooltipText={tooManyRecipientsTooltip}
								onMailSchedule={close}
								loading={loading}
							/>
						</div>
					</DrawerHeader>

					{loading || mailIsSent ? <SwooshScreen scheduled={state.mailType === 'sch'} /> : null}

					<SlideFade visible={!loading && !mailIsSent} height>
						<div>{self ? <NewMail {...props} /> : null}</div>
					</SlideFade>
				</>
			)}

			{props.source === 'activity' ? (
				props.activity?.outcomeType === OUTCOME_TYPES.ANSWER ? (
					<CloseRelatedActivity
						visible={!!id}
						onAnswer={closeActivity => onPostponeOrCloseAnswer(closeActivity)}
					/>
				) : (
					<PostponeRelatedActivity
						visible={!!id}
						onAnswer={postponeDate => onPostponeOrCloseAnswer(postponeDate)}
					/>
				)
			) : null}
		</div>
	);
};

const connectProvider = (props: Props) => (
	<MailProvider>
		<NewSingleMail {...props} />
	</MailProvider>
);

export const detached = NewSingleMail;
export default (props: Props) => connectProvider(props);
