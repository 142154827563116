import T from 'Components/Helpers/translate';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Icon, Loader, Text, Tooltip } from '@upsales/components';
import ClientResource from 'App/resources/Client';
import { renderToString } from 'react-dom/server';
import './ClientTooltip.scss';
import Client from 'App/resources/Model/Client';
import { openDrawer } from 'Services/Drawer';
import { DefaultButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import logError from 'Helpers/logError';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';

type Props = {
	id: number;
	children: JSX.Element;
	position?: 'right' | 'bottom' | 'left' | 'top';
};

const ClientTooltip = ({ id, children, position = 'right' }: Props) => {
	const uuid = useMemo(() => Math.floor(Math.random() * 10000000000000).toString(), []);
	const [data, setData] = useState<Client | null>(null);
	const tooltipRef = useRef<any>(null);
	const quickActionsRef = useRef<HTMLElement | null>(null);
	const dataPromise = useRef<CancelablePromise | null>(null);

	const getData = () => {
		if (!data) {
			dataPromise.current = makeCancelable(ClientResource.get(id));
			dataPromise.current.promise
				.then(({ data }: { data: Client }) => {
					setData(data);
				})
				.catch(e => logError(e));
		}
	};

	// hide tooltips on scroll
	const handleScroll: () => void = () => {
		for (const popper of document.querySelectorAll('.tippy-popper')) {
			//@ts-ignore
			const instance = popper._tippy;

			if (instance.state.visible) {
				instance.hide();
			}
		}
	};

	useEffect(() => {
		const clientUpdateListener = Tools.$rootScope.$on('account.updated', () => {
			setData(null);
		});

		return () => {
			if (dataPromise.current) {
				dataPromise.current.cancel();
			}
			clientUpdateListener();
		};
	}, []);

	const classes = new BemClass('ClientTooltip');

	const nameSection = (
		<div className={classes.elem('client-name').b()}>
			<Text size="md" bold ellipsis>
				{data?.name}
			</Text>
		</div>
	);

	const subtitleSection = <Text size="sm">{T('default.planA')}</Text>;
	const quickActionSection = (
		<div className={classes.elem('quick-actions').mod(uuid).b()}>
			<DefaultButton size="sm" className={classes.elem('plan-phonecall').mod(uuid).b()}>
				<Icon name="phone" />
			</DefaultButton>
			<DefaultButton size="sm" className={classes.elem('create-todo').mod(uuid).b()}>
				<Icon name="activity" />
			</DefaultButton>
			<DefaultButton size="sm" className={classes.elem('create-appointment').mod(uuid).b()}>
				<Icon name="appointment" />
			</DefaultButton>
		</div>
	);

	const TooltipContent = () => {
		return data ? (
			<div className={classes.elem('tooltip').b()}>
				{nameSection}
				{subtitleSection}
				{quickActionSection}
			</div>
		) : (
			<Loader size="xs" />
		);
	};

	const hideTooltip = () => {
		if (tooltipRef.current) {
			tooltipRef.current.hide();
		}
		// The buttons are still visible for a moment after the tooltip background disappears, this hack fixes that
		if (quickActionsRef.current) {
			quickActionsRef.current.style.opacity = '0';
		}
	};

	const onPlanPhoneCall = () => {
		openDrawer('CreateCall', { client: data });
		hideTooltip();
	};

	const onCreateTodo = () => {
		openDrawer('CreateTodo', { client: data });
		hideTooltip();
	};

	const onCreateAppointment = () => {
		openEditAppointment({
			appointment: { client: { id: data?.id } }
		});
		hideTooltip();
	};

	const initTooltipListeners = (instance: any) => {
		tooltipRef.current = instance;
		const quickActions: HTMLElement | null = document.querySelector('.ClientTooltip__quick-actions--' + uuid);
		if (quickActions) {
			quickActions.style.opacity = '1';
			quickActionsRef.current = quickActions;
		}

		const phonecall = document.querySelector('.ClientTooltip__plan-phonecall--' + uuid);
		phonecall?.addEventListener('click', onPlanPhoneCall);

		const todo = document.querySelector('.ClientTooltip__create-todo--' + uuid);
		todo?.addEventListener('click', onCreateTodo);

		const appointment = document.querySelector('.ClientTooltip__create-appointment--' + uuid);
		appointment?.addEventListener('click', onCreateAppointment);

		window.addEventListener('scroll', handleScroll);
	};

	const cleanupTooltipListeners = () => {
		const phonecall = document.querySelector('.ClientTooltip__plan-phonecall--' + uuid);
		phonecall?.removeEventListener('click', onPlanPhoneCall);

		const todo = document.querySelector('.ClientTooltip__create-todo--' + uuid);
		todo?.removeEventListener('click', onCreateTodo);

		const appointment = document.querySelector('.ClientTooltip__create-appointment--' + uuid);
		appointment?.removeEventListener('click', onCreateAppointment);

		window.removeEventListener('scroll', handleScroll);
	};

	return (
		<Tooltip
			key={data?.id}
			position={position}
			className={classes.b()}
			title={renderToString(<TooltipContent />)}
			theme="white"
			interactive={'true'}
			// @ts-ignore
			onShown={instance => {
				initTooltipListeners(instance);
				getData();
			}}
			onHide={cleanupTooltipListeners}
		>
			{children}
		</Tooltip>
	);
};

const WithFeatureFlagsClientTooltip = (props: Props) => {
	const children = props.children;
	const hasClientTooltip =
		Tools.FeatureHelper.hasSoftDeployAccess('CLIENT_TOOLTIP') &&
		Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
		Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');

	if (!hasClientTooltip) return children;

	return <ClientTooltip {...props} />;
};

export default WithFeatureFlagsClientTooltip;
