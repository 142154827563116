import InlineChangeHistory from 'App/components/InlineChangeHistory';
import { Flex, Avatar, Text, Card, Icon } from '@upsales/components';
import { currencyFormat } from 'Components/Filters/Currencies';
import { Change } from 'App/babel/resources/ARRChangesOnDate';
import { SlideFade } from '@upsales/components/animations';
import { getMetadata } from 'Store/selectors/AppSelectors';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import React, { useState } from 'react';
import RowChanged from '../RowChanged';
import ChangedRow from '../ChangedRow';
import moment from 'moment';

import './ChangesOnAgreement.scss';

type Props = {
	mrr: boolean;
	index: number;
	changes: Change[];
	currency: string;
	withDrowpDown?: boolean;
};

const ROW_HEIGHT = 44;
const HEADER_HEIGHT = 55;
const PADDING_BOTTOM = 12;

const ChangesOnAgreement = ({ mrr, index, withDrowpDown = false, changes: propChanges, currency }: Props) => {
	const [showDropDown, setShowDropDown] = useState(!withDrowpDown);
	const [changes, setChanges] = useState(propChanges);
	const hasNewRows = useSoftDeployAccess('USE_CHANGELOG_RAW_PRODUCT');
	const metadata = getMetadata();

	const classes = new BemClass('ChangesOnAgreement');

	const recurringType = T(mrr ? 'default.monthlyValue' : 'default.annualValue');

	const getUser = (userId: number) => Tools.AppService.getUsers(undefined, true).find(u => u.id === userId);
	const groupARRchangesByUser = metadata?.params?.GroupARRChangesByUser;

	const height = changes.reduce(
		(sum, change) => sum + (change.rowsChanged?.length ?? 0) * ROW_HEIGHT + HEADER_HEIGHT + PADDING_BOTTOM,
		0
	);
	const sumChanges = changes.reduce((sum, change) => sum + change.change, 0);

	const { description } = changes?.[0] ?? {};

	return (
		<div className={classes.b()} style={{ zIndex: 10000 - index }}>
			<Card
				className={classes.elem('card').mod({ clickable: withDrowpDown, open: showDropDown }).b()}
				onClick={withDrowpDown ? () => setShowDropDown(v => !v) : undefined}
			>
				<Flex
					className={classes.elem('card').b()}
					justifyContent="space-between"
					alignItems="center"
					space="pll prl"
				>
					<Flex alignItems="center" direction="column">
						<Text size="sm">{T('default.agreement')}</Text>
						<Text size="lg" bold>
							{description}
						</Text>
					</Flex>
					{withDrowpDown ? (
						<Flex gap="u3" alignItems="center">
							<Text color={sumChanges < 0 ? 'danger-5' : 'success-6'} bold>{`${currencyFormat(
								sumChanges,
								currency
							)} (${recurringType})`}</Text>
							<Icon name={showDropDown ? 'chevron-down' : 'chevron-right'} />
						</Flex>
					) : null}
				</Flex>
			</Card>
			<SlideFade visible={showDropDown} direction="top" height maxHeight={height}>
				<div>
					{changes.map((change, changeIndex) => {
						const user = getUser(change.changedBy);
						const negative = change.change < 0;
						const hidden = change.hidden;
						const isSpecificChange = true;
						return (
							<div key={'change' + changeIndex}>
								<Flex
									space="pll ptm prl pbm"
									alignItems="center"
									justifyContent="space-between"
									className={classes.elem('header').mod({ negative, hidden }).b()}
								>
									<Flex alignItems="center" gap={'u3'} flex={1}>
										<Avatar initials={user?.name ?? ''} email={user?.email ?? ''}></Avatar>
										<Flex direction="column" className={classes.elem('nameAndType').b()}>
											<Text>{`${user?.name ?? T('default.unknown')} • ${moment(
												change.regDate
											).format('L LT')}`}</Text>
											<Text size="sm" color="grey-11">
												{T(`arrchange.type.${change.type}`) +
													(hidden ? ` • ${T('default.hidden')}` : '')}
											</Text>
										</Flex>
									</Flex>
									<Flex flex={1} justifyContent="space-between">
										{change.prevCurrencyRate !== change.currencyRate ? (
											<Flex direction="column">
												<Text size="sm" color="grey-11">
													{`${T('default.currencyRate')}`}
												</Text>
												<Flex>
													<Text
														size="sm"
														className={classes.elem('header').elem('previous').b()}
													>
														{currencyFormat(change.prevCurrencyRate, currency, true, 2)}
													</Text>
													<Text size="sm">{` -> ${currencyFormat(
														change.currencyRate,
														currency,
														true,
														2
													)}`}</Text>
												</Flex>
											</Flex>
										) : (
											<Flex />
										)}
										<Flex alignItems="center" gap={'u3'}>
											<Text color={negative ? 'danger-5' : 'success-6'} bold>{`${currencyFormat(
												change.change,
												currency
											)} (${recurringType})`}</Text>
											{groupARRchangesByUser ? null : (
												<InlineChangeHistory
													change={{
														...change,
														client: {
															id: change.clientId
														},
														date: new Date(change.arrChangeDate)
													}}
													onChange={onChangeChange => {
														Tools.$rootScope.$broadcast('account.updated', {
															id: change.clientId
														});
														Tools.$rootScope.$broadcast('arrChange.updated');
														if (onChangeChange.users?.length === 1 && isSpecificChange) {
															setChanges(prev => {
																const newChanges = [...prev];
																newChanges[changeIndex].changedBy =
																	onChangeChange.users[0].id;
																return newChanges;
															});
														}
													}}
													specificChange={isSpecificChange}
												/>
											)}
										</Flex>
									</Flex>
								</Flex>

								<div
									className={classes
										.elem('rows')
										.mod({
											notLast: changeIndex !== changes.length - 1
										})
										.b()}
								>
									{hasNewRows
										? change?.changedRows?.map((row, index) => (
												<ChangedRow
													key={'row' + index}
													changeType={change.type}
													changeRow={row}
													mrr={mrr}
													currency={currency}
												/>
										  ))
										: null}
									{!hasNewRows
										? change?.rowsChanged?.map((row, index) => (
												<RowChanged
													key={'row' + index}
													changeType={change.type}
													changeRow={row}
													mrr={mrr}
													currency={currency}
												/>
										  ))
										: null}
								</div>
							</div>
						);
					})}
				</div>
			</SlideFade>
		</div>
	);
};

export default ChangesOnAgreement;
