import { useState, useEffect } from 'react';

export type Center = {
	latitude: number;
	longitude: number;
};

const useDefaultCenter = (defaultCenter?: Center, disable?: boolean) => {
	const fallbackCenter = { latitude: 59.3293, longitude: 18.0686 }; // Stockholm
	const [center, setCenter] = useState(disable ? fallbackCenter : defaultCenter);

	useEffect(() => {
		if (defaultCenter?.latitude && defaultCenter?.longitude) {
			setCenter(defaultCenter);
		}
	}, [defaultCenter]);

	const onGeoSuccess = (location: any) => {
		if (location.coords.latitude && location.coords.longitude) {
			setCenter({
				latitude: location.coords.latitude,
				longitude: location.coords.longitude
			});
		} else {
			setCenter(fallbackCenter);
		}
	};

	const onGeoError = () => {
		setCenter(fallbackCenter);
	};

	const tryToGetAccurateCenter = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(onGeoSuccess, onGeoError);
		} else {
			setCenter(fallbackCenter);
		}
	};

	useEffect(() => {
		if (!defaultCenter && !disable) {
			tryToGetAccurateCenter();
		}
	}, []);

	return center;
};

export default useDefaultCenter;
