import { Toggle, Text, Icon, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import React, { useEffect } from 'react';
import { IntegrationConfig } from 'App/resources/AllIWant';
import './InvoiceRelatedClient.scss';

type Props = {
	disabled: boolean;
	checked: boolean;
	onChange: (checked: boolean) => void;
	orderModal?: boolean;
	clientRelated?: boolean;
};

const VISMA_ID = 364;
const SUPPORTED_INTEGRATION_IDS = [
	VISMA_ID //Visma.NET
];

const SUPPORTED_INTEGRATION_NAMES = ['fortnox'];

const hasSupportedIntegration = (activeIntegrations: IntegrationConfig[]) => {
	const hasMatchingIntegration = activeIntegrations.some(i => {
		const idMatch = SUPPORTED_INTEGRATION_IDS.includes(i.id);
		const nameMatch = SUPPORTED_INTEGRATION_NAMES.some(name => i.name.toLowerCase().includes(name.toLowerCase()));
		return idMatch || nameMatch;
	});

	return hasMatchingIntegration;
};

const InvoiceRelatedClient = ({ disabled, checked, onChange, orderModal = false, clientRelated }: Props) => {
	const { metadata } = useSelector(({ App }: RootState) => App);
	const activeIntegrations = metadata?.integrations?.active;
	const isVismaActive = activeIntegrations?.some(i => i.id === VISMA_ID) ?? false;
	const showInvoiceRelatedClient = activeIntegrations && hasSupportedIntegration(activeIntegrations);

	useEffect(() => {
		if (!clientRelated && checked) {
			onChange(false);
		}
	}, [clientRelated]);

	if (!Tools.FeatureHelper.hasSoftDeployAccess('INVOICE_RELATED_CLIENT') || !showInvoiceRelatedClient) {
		return null;
	}

	const classes = new BemClass('InvoiceRelatedClient');
	return (
		<div className={classes.mod({ orderModal, isVismaActive }).b()}>
			{isVismaActive ? (
				<Text size="sm" color="grey-11">
					{T('subscription.modal.details.invoiceRelatedClientTooltip')}
				</Text>
			) : (
				<>
					<Toggle size="sm" disabled={disabled} checked={checked} onChange={onChange} />
					<Text size="sm" color="grey-11">
						{T('subscription.modal.details.invoiceRelatedClient')}
					</Text>

					<Tooltip title={T('subscription.modal.details.invoiceRelatedClientTooltip')}>
						<Icon name="question-circle" />
					</Tooltip>
				</>
			)}
		</div>
	);
};

export default InvoiceRelatedClient;
