module.exports = {
	generate: function () {
		return {
			inactive: true,
			value: {
				AddressType: { value: null },
				Zipcode: { value: { start: null, end: null } },
				Address: { value: '', comparisonType: 'Search' },
				City: { value: '', comparisonType: 'Equals' },
				Municipality: { value: '', comparisonType: 'Equals' },
				State: { value: '', comparisonType: 'Equals' },
				Country: { value: '', comparisonType: 'Equals' }
			}
		};
	},
	getText: function (val) {
		var num = 0;
		if (val.Zipcode.value.start || val.Zipcode.value.end) {
			num++;
		}
		if (val.Address.value) {
			num++;
		}
		if (val.AddressType.value) {
			num++;
		}
		if (val.City.value) {
			num++;
		}
		if (val.Municipality?.value) {
			num++;
		}
		if (val.State.value) {
			num++;
		}
		if (val.Country.value) {
			num++;
		}
		return num;
	},
	isInactive: function (filter) {
		var val = filter.value;
		var inactive = true;

		var hasAnyValue =
			(val.Address.value && val.Address.value.length) ||
			(val.AddressType.value && val.AddressType.value.length) ||
			(val.City.value && val.City.value.length) ||
			(val.Municipality?.value && val.Municipality?.value.length) ||
			(val.State.value && val.State.value.length) ||
			(val.Country.value && val.Country.value && val.Country.value.length) ||
			(val.Zipcode.value && val.Zipcode.value.start) ||
			(val.Zipcode.value && val.Zipcode.value.end);

		if (hasAnyValue) {
			inactive = false;
		}

		return inactive;
	},
	toUrl: function (filter) {
		var f = { v: {} };
		var val = filter.value;

		if (val.Zipcode.value.start || val.Zipcode.value.end) {
			f.v.zi = { s: val.Zipcode.value.start, e: val.Zipcode.value.end };
		}
		if (val.Address.value) {
			f.v.ad = val.Address.value;
			f.v.adCt = val.Address.comparisonType;
		}
		if (val.AddressType.value) {
			f.v.at = val.AddressType.value;
		}
		if (val.Municipality?.value) {
			f.v.mu = val.Municipality.value;
			f.v.muCt = val.Municipality.comparisonType;
		}
		if (val.City.value) {
			f.v.ci = val.City.value;
			f.v.ciCt = val.City.comparisonType;
		}
		if (val.State.value) {
			f.v.st = val.State.value;
			f.v.stCt = val.State.comparisonType;
		}
		if (val.Country.value) {
			f.v.co = val.Country.value;
			f.v.coCt = val.Country.comparisonType;
		}

		return f;
	},
	fromUrl: function (rawFilter) {
		var filter = this.generate();
		var val = rawFilter.v;

		if (val.zi) {
			filter.value.Zipcode = { value: { start: val.zi.s, end: val.zi.e } };
		}
		if (val.ad) {
			filter.value.Address = { value: val.ad };
			if (val.adCt) {
				filter.value.Address.comparisonType = val.adCt;
			}
		}
		if (val.at) {
			filter.value.AddressType = { value: val.at };
		}
		if (val.ci) {
			filter.value.City = { value: val.ci };
			if (val.ciCt) {
				filter.value.City.comparisonType = val.ciCt;
			}
		}
		if (val.mu) {
			filter.value.Municipality = { value: val.mu };
			if (val.muCt) {
				filter.value.Municipality.comparisonType = val.muCt;
			}
		}
		if (val.st) {
			filter.value.State = { value: val.st };
			if (val.stCt) {
				filter.value.State.comparisonType = val.stCt;
			}
		}
		if (val.co) {
			filter.value.Country = { value: val.co };
			if (val.coCt) {
				filter.value.Country.comparisonType = val.coCt;
			}
		}
		filter.inactive = this.isInactive(filter);
		return filter;
	},
	build: function (options) {
		options = options || {};

		return function (filter, rb, getField) {
			var val = filter.value;
			var hasValueEqual = false;
			var hasValueNotEqual = false;
			var field;

			// Set up group
			var groupBuilderEqual = rb.groupBuilder();
			var groupBuilderNotEqual = rb.groupBuilder();
			groupBuilderNotEqual.isNotFilter();

			if (val.AddressType.value) {
				field = 'address.type';
				if (options.fieldPrefix) {
					field = options.fieldPrefix + '.' + field;
				}
				groupBuilderEqual.addFilter(getField(field), rb.comparisonTypes.Equals, val.AddressType.value);
				groupBuilderNotEqual.addFilter(getField(field), rb.comparisonTypes.Equals, val.AddressType.value);
			}

			// Address
			if (val.Address.value.length) {
				field = 'address.address';
				if (options.fieldPrefix) {
					field = options.fieldPrefix + '.' + field;
				}
				if (val.Address.comparisonType === 'NotEquals') {
					groupBuilderNotEqual.addFilter(getField(field), rb.comparisonTypes.Equals, val.Address.value);
					hasValueNotEqual = true;
				} else {
					groupBuilderEqual.addFilter(
						getField(field),
						rb.comparisonTypes[val.Address.comparisonType],
						val.Address.value
					);
					hasValueEqual = true;
				}
			}

			// City
			if (val.City.value.length) {
				field = 'address.city';
				if (options.fieldPrefix) {
					field = options.fieldPrefix + '.' + field;
				}
				if (val.City.comparisonType === 'NotEquals') {
					groupBuilderNotEqual.addFilter(getField(field), rb.comparisonTypes.Equals, val.City.value);
					hasValueNotEqual = true;
				} else {
					groupBuilderEqual.addFilter(
						getField(field),
						rb.comparisonTypes[val.City.comparisonType],
						val.City.value
					);
					hasValueEqual = true;
				}
			}

			// Municipality
			if (val.Municipality?.value.length) {
				field = 'address.municipality';
				if (options.fieldPrefix) {
					field = options.fieldPrefix + '.' + field;
				}
				if (val.Municipality.comparisonType === 'NotEquals') {
					groupBuilderNotEqual.addFilter(getField(field), rb.comparisonTypes.Equals, val.Municipality.value);
					hasValueNotEqual = true;
				} else {
					groupBuilderEqual.addFilter(
						getField(field),
						rb.comparisonTypes[val.Municipality.comparisonType],
						val.Municipality.value
					);
					hasValueEqual = true;
				}
			}

			// State
			if (val.State.value.length) {
				field = 'address.state';
				if (options.fieldPrefix) {
					field = options.fieldPrefix + '.' + field;
				}
				if (val.State.comparisonType === 'NotEquals') {
					groupBuilderNotEqual.addFilter(getField(field), rb.comparisonTypes.Equals, val.State.value);
					hasValueNotEqual = true;
				} else {
					groupBuilderEqual.addFilter(
						getField(field),
						rb.comparisonTypes[val.State.comparisonType],
						val.State.value
					);
					hasValueEqual = true;
				}
			}

			// Country
			if (val.Country.value && val.Country.value.length) {
				field = 'address.country';
				if (options.fieldPrefix) {
					field = options.fieldPrefix + '.' + field;
				}
				if (val.Country.comparisonType === 'NotEquals') {
					groupBuilderNotEqual.addFilter(getField(field), rb.comparisonTypes.Equals, val.Country.value);
					hasValueNotEqual = true;
				} else {
					groupBuilderEqual.addFilter(
						getField(field),
						rb.comparisonTypes[val.Country.comparisonType],
						val.Country.value
					);
					hasValueEqual = true;
				}
			}

			// Zipcode start
			if (val.Zipcode.value.start) {
				field = 'address.zipcode';
				if (options.fieldPrefix) {
					field = options.fieldPrefix + '.' + field;
				}
				groupBuilderEqual.addFilter(
					getField(field),
					rb.comparisonTypes.GreaterThanEquals,
					val.Zipcode.value.start.toString()
				);
				hasValueEqual = true;
			}

			// Zipcode end
			if (val.Zipcode.value.end) {
				field = 'address.zipcode';
				if (options.fieldPrefix) {
					field = options.fieldPrefix + '.' + field;
				}
				groupBuilderEqual.addFilter(
					getField(field),
					rb.comparisonTypes.LowerThanEquals,
					val.Zipcode.value.end.toString()
				);
				hasValueEqual = true;
			}

			if (hasValueEqual) {
				groupBuilderEqual.done();
			}
			if (hasValueNotEqual) {
				groupBuilderNotEqual.done();
			}
		};
	}
};
