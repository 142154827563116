import React from 'react';
import PropTypes from 'prop-types';
import AccountRow from './AccountRow';
import {
	Tab,
	Tabs,
	Button,
	DropDownMenu,
	Table,
	TableRow,
	TableColumn,
	Icon,
	Loader,
	ButtonSelect
} from '@upsales/components';
import _ from 'lodash';

export default class Account extends React.Component {
	constructor(props) {
		super(props);

		const { name } = props;

		const t = Tools.$translate;

		this.lang = {
			loadMoreRows: t('account.loadMoreRows'),
			name: t(name),
			noResult: t('noResult.account'),
			all: t('default.all'),
			invalidSettings: t('default.invalidSettings'),
			hasError: t('integration.dataSource.error'),
			moreCountries: t('account.moreCountries'),
			headquarters: t('soliditet.headquarters'),
			branchOffices: t('account.branches')
		};

		this.loadMoreAccounts = this.loadMoreAccounts.bind(this);
		this.onTabChange = this.onTabChange.bind(this);
		this.goToSettings = this.goToSettings.bind(this);
		this.onHQBranchChange = this.onHQBranchChange.bind(this);
	}

	loadMoreAccounts() {
		const { actions, id } = this.props;
		actions.loadMoreAccounts(id);
	}

	onTabChange(countryCode) {
		const { id, actions } = this.props;
		if (this.close) {
			this.close();
		}
		actions.changeTab(id, countryCode);
	}

	onHQBranchChange(value) {
		const { id, actions } = this.props;
		actions.changeHQBranch(id, value);
	}

	goToSettings() {
		const { id, actions } = this.props;
		actions.close();
		Tools.$state.go('administration.integration', { id: id, configure: 'client' });
	}

	getCountryData(countryCode, showTotals, metadata) {
		const name = Tools.CountryCodes.get(countryCode.trim()).name;
		const flagIcon = <i className={`flag-icon flag-icon-${countryCode.toLowerCase()}`} />;
		const totalSum =
			showTotals && metadata[countryCode] ? <span className="total-sum">{metadata[countryCode]}</span> : null;

		return { name, flagIcon, totalSum };
	}

	render() {
		const {
			// Account state
			loading,
			isLoadingMore,
			data,
			metadata,
			currentTab,
			showHQ,
			invalidSettings,
			hasError,
			// Account config
			id,
			idField,
			type,
			logo,
			countryTabs,
			hqBoButton,
			pagination,
			fields,
			pricing,
			useCountryFields,
			// "global" stuff
			addingAccount,
			actions,
			companyGroup,
			setRef,
			hasRequriedFields
		} = this.props;
		let { countries } = this.props;

		const currentFields = useCountryFields ? fields[currentTab] : fields;
		let rows;

		if (loading) {
			const height = Array.isArray(data) ? Math.max(data.length, 1) * 51 : 51;

			rows = (
				<tr className="loading-row">
					<td style={{ height }} colSpan={1000}>
						<Loader />
					</td>
				</tr>
			);
		} else if (data.length) {
			rows = _.map(data, (account, index) => {
				return (
					<AccountRow
						key={index}
						account={account}
						actions={actions}
						fields={currentFields}
						idField={idField}
						addingAccount={addingAccount}
						companyGroup={companyGroup}
						dataSourceId={id}
						type={type}
						pricing={pricing}
						hasRequriedFields={hasRequriedFields}
					/>
				);
			});
		} else {
			rows = (
				<tr>
					<td colSpan={1000}>{this.lang.noResult}</td>
				</tr>
			);
		}

		const total = metadata.ALL || 0;
		const hasPagination = pagination && pagination.enabled ? true : false;
		const hasMore = metadata[currentTab] ? metadata[currentTab] > data.length : false;

		let tabs = null;
		let dropdown = null;

		const headquartersBranchOfficesButton =
			hqBoButton && hqBoButton.enabled ? (
				<ButtonSelect
					options={[
						{ value: true, title: this.lang.headquarters },
						{
							value: false,
							title: this.lang.branchOffices
						}
					]}
					value={showHQ}
					onChange={this.onHQBranchChange}
				/>
			) : null;

		if (countryTabs && countryTabs.enabled) {
			const showTotals = countryTabs.showTotals || false;

			countries = countries || countryTabs.countries || [];

			const tabsMaxLength = 6;
			const firstCountries = countries.slice(0, tabsMaxLength);
			const lastCountries = countries.slice(tabsMaxLength);

			const tabArray = _.map(firstCountries, countryCode => {
				const { name, flagIcon, totalSum } = this.getCountryData(countryCode, showTotals, metadata);
				return (
					<Tab
						key={countryCode}
						id={countryCode}
						disabled={showTotals && !metadata[countryCode] && countryCode !== currentTab}
					>
						<span className="flag-icon-wrap">{flagIcon}</span>
						{name}
						{totalSum}
					</Tab>
				);
			});

			if (countryTabs.showAll) {
				tabArray.unshift(
					<Tab key={'ALL'} id={'ALL'}>
						{this.lang.all}
						{showTotals && total ? <span className="total-sum">{total}</span> : null}
					</Tab>
				);
			}

			const dropdownArray = _.map(lastCountries, countryCode => {
				const { name, flagIcon, totalSum } = this.getCountryData(countryCode, showTotals, metadata);
				return (
					<TableRow key={countryCode}>
						<TableColumn>
							<Button
								type="link"
								onClick={() => this.onTabChange(countryCode)}
								disabled={showTotals && !totalSum}
							>
								{flagIcon} {name}
								{totalSum}
							</Button>
						</TableColumn>
					</TableRow>
				);
			});

			const foundInDropdown = lastCountries.indexOf(currentTab) !== -1;
			if (dropdownArray.length) {
				let name, flagIcon, totalSum;

				if (foundInDropdown) {
					const countryData = this.getCountryData(currentTab, showTotals, metadata);

					name = countryData.name;
					flagIcon = countryData.flagIcon;
					totalSum = countryData.totalSum;
				} else {
					name = this.lang.moreCountries;
				}

				dropdown = (
					<DropDownMenu
						align="right"
						renderTrigger={(expanded, setExpanded) => (
							<Button type="link" onClick={setExpanded}>
								{totalSum}
								{flagIcon} {name}
								<Icon name="sort-down" />
							</Button>
						)}
					>
						{close => {
							this.close = close;
							return <Table>{dropdownArray}</Table>;
						}}
					</DropDownMenu>
				);
			}

			const tabsSelected = !foundInDropdown ? currentTab : '';
			const tabsClassName = `account-tabs${!tabsSelected ? ' no-highlight' : ''}`;
			tabs = (
				<Tabs noFlex align="left" selected={tabsSelected} className={tabsClassName} onChange={this.onTabChange}>
					{tabArray}
				</Tabs>
			);
		}

		if (invalidSettings) {
			return (
				<div key={id} className="accounts-card" ref={node => setRef(`_${id}`, node)}>
					<div className="header">
						<h2>
							{logo ? <img src={logo} width="23" height="23" /> : null}
							<span>{this.lang.name}</span>
						</h2>
					</div>
					<table className="result-table">
						<tbody>
							<tr className="invalid-settings-row">
								<td colSpan="1000">
									<Button type="link" onClick={this.goToSettings}>
										{this.lang.invalidSettings}
									</Button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			);
		}

		if (hasError) {
			return (
				<div key={id} className="accounts-card" ref={node => setRef(`_${id}`, node)}>
					<div className="header">
						<h2>
							{logo ? <img src={logo} width="23" height="23" /> : null}
							<span>{this.lang.name}</span>
						</h2>
					</div>
					<table className="result-table">
						<tbody>
							<tr className="invalid-settings-row">
								<td colSpan="1000">{this.lang.hasError}</td>
							</tr>
						</tbody>
					</table>
				</div>
			);
		}

		return (
			<div key={id} className="accounts-card" ref={node => setRef(`_${id}`, node)}>
				<div className="header">
					<h2>
						{logo ? <img src={logo} width="23" height="23" /> : null}
						<span>{this.lang.name}</span>
						{total && !countryTabs ? <span className="total-sum">{total}</span> : null}
					</h2>
					{headquartersBranchOfficesButton}
					{dropdown}
				</div>
				{tabs}
				<table className="result-table" style={tabs ? { marginTop: '10px' } : null}>
					<thead>
						<tr>
							{_.map(currentFields, (field, index) => {
								return (
									<th key={index} width={field.width}>
										{Tools.$translate(field.header)}
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{rows}
						{hasPagination && hasMore ? (
							<tr className="load-more-rows-row">
								<td colSpan="1000">
									<Button
										className="btn-block"
										type="link"
										color="bright-blue"
										disabled={isLoadingMore || loading}
										onClick={this.loadMoreAccounts}
									>
										{this.lang.loadMoreRows}
									</Button>
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		);
	}
}

Account.propTypes = {
	loading: PropTypes.bool,
	data: PropTypes.arrayOf(PropTypes.object),
	addingAccount: PropTypes.object,
	idField: PropTypes.string,
	actions: PropTypes.object,
	fields: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
	companyGroup: PropTypes.object,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoadingMore: PropTypes.bool,
	countryTabs: PropTypes.object,
	hqBoButton: PropTypes.object,
	pagination: PropTypes.object,
	logo: PropTypes.string,
	name: PropTypes.string,
	countries: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
	metadata: PropTypes.object,
	currentTab: PropTypes.string,
	setRef: PropTypes.func,
	type: PropTypes.string,
	pricing: PropTypes.object,
	hasRequriedFields: PropTypes.bool,
	invalidSettings: PropTypes.bool,
	hasError: PropTypes.bool,
	useCountryFields: PropTypes.bool
};
