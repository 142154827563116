import AccountPlanSidebar from 'App/components/AccountPlanSidebar';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';
import AppsHistory from 'Components/AppsHistory';
import BEM from '@upsales/components/Utils/bemClass';
import ClientInput from './ClientInput';
import CopyableId from 'App/components/CopyableId';
import DecisionMakersSidebar from 'App/babel/components/DecisionMakersSidebar';
import DocumentTemplateDropdown from './DocumentTemplateDropdown';
import EditOrderSaveButton from 'App/components/EditOrderSaveButton';
import FieldSync from 'Components/FieldSync';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import JourneyStepDisplay from 'Components/JourneyStepDisplay';
import LostOpportunity from 'App/components/LostOpportunity';
import Notes from 'App/babel/components/Notes';
import OpportunityWinLossButtons from 'App/components/OpportunityWinLossButtons';
import OrderDashboard from './OrderDashboard';
import OrderFiles from './OrderFiles';
import OrderForm from './OrderForm';
import PeriodizationChart from 'Components/Periodization/PeriodizationChart';
import PeriodizationWrap from './PeriodizationWrap';
import ProjectPlanHandler from 'App/components/ProjectPlanOrderHandler';
import RatingLabel from './RatingLabel';
import React from 'react';
import RiskChip from 'App/components/RiskChip';
import SaveButtonDropdown from './SaveButtonDropdown';
import Select from './Select';
import TelephoneLink from 'App/components/columns/TelephoneLink';
import TextInput from './TextInput';
import TicketWidget from 'App/components/TicketWidget';
import Tooltip from './Tooltip';
import UiElements from 'Components/UiElements';
import UpFieldTranslation from 'App/upsales/common/components/react/inputs/UpFieldTranslation';
import UserSelect from './UserSelect';
import WonOpportunity from 'App/components/WonOpportunity';
import useEditOrderContext from '../hooks/useEditOrderContext';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import classNames from 'classnames';

import type Order from 'App/resources/Model/Order';
import type { EditOrderContact } from '../types';
import SimilarCustomers from 'App/components/SimilarCustomers';

type Props = {
	modalId: number;
	className: string;
};

const EditOrder = ({ className, modalId }: Props) => {
	const EditOrder = useEditOrderContext();
	const { t } = useTranslation();

	const BEMClasses = new BEM(
		'EditOrder',
		`${className} fresh up-modal notranslate form dynamic default ng-scope compiled`
	);

	if (!EditOrder.controllerDone) {
		return null;
	}

	const showWinLossButtons = EditOrder.showWinLossButtons();

	return (
		<div id="edit-order-modal" className={BEMClasses.b()} data-modal-id={modalId}>
			{/* CONDOLENCES */}
			<div>
				{/* Maby only render these if visible = true?  */}
				<LostOpportunity
					visible={EditOrder.visibleLost}
					animate={true}
					order={EditOrder.order as unknown as Order}
					closeModal={EditOrder.closeModal}
					onSaveLostReason={EditOrder.saveLostReason}
					pendingSave={EditOrder.saving}
				/>
			</div>

			{/* CELEBRATION */}
			<div>
				{/* Maby only render these if visible = true?  */}
				<WonOpportunity
					isVisible={EditOrder.visibleWon}
					animate={true}
					order={EditOrder.order as unknown as Order}
					closeModal={EditOrder.closeModal}
					changeOrderCloseDate={EditOrder.changeOrderCloseDate}
					onCreateSubscription={EditOrder.shouldShowButtonOnWonModal() ? EditOrder.createSubscription : null}
					isModal={false}
				/>
			</div>

			{EditOrder.hasPeriodization && EditOrder.isPeriodizing ? (
				<div className="periodise-root">
					<PeriodizationWrap
						order={EditOrder.order as unknown as Order}
						toggle={EditOrder.togglePeriodization}
					/>
				</div>
			) : null}

			{/* FORM */}
			<form
				autoComplete="off"
				id="form"
				name="OrderForm"
				noValidate={true}
				onSubmit={EditOrder.onFormSubmit}
				style={EditOrder.isPeriodizing ? { display: 'none' } : {}}
			>
				{/* HEADER */}
				<div className="up-modal-header white-header" style={{ zIndex: 10001 }}>
					<div className="risk-chip">
						{EditOrder.riskChipProps ? (
							<div>
								<RiskChip {...EditOrder.riskChipProps} />
							</div>
						) : null}
					</div>

					<div className="header-info-wrapper">
						<b
							className={classNames('Icon template-icon', {
								'Icon-opportunity': EditOrder.stageType !== 'order',
								'Icon-sales': EditOrder.stageType === 'order'
							})}
						></b>

						<h2 className={classNames({ 'has-subtitle': EditOrder.order.id })}>
							{t(EditOrder.edit ? 'default.edit' : 'default.create')}{' '}
							{t(EditOrder.stageType === 'order' ? 'default.order' : 'default.opportunity').toLowerCase()}
							{EditOrder.order.description ? (
								<span>
									{': '}
									<span>{EditOrder.order.description}</span>
								</span>
							) : null}
							{EditOrder.order.id ? (
								<div className="subtitle">
									<div>
										<CopyableId objectId={EditOrder.order.id} type={'order'} />
									</div>
								</div>
							) : null}
						</h2>
					</div>

					<UserSelect
						className={classNames('user-select-wrap', {
							disabled: EditOrder.saving || !EditOrder.editable
						})}
						user={EditOrder.order.user}
						disabled={!EditOrder.editable || EditOrder.promptRemoval}
						config={EditOrder.select.user}
						onChange={EditOrder.userChange}
					/>

					<a className="btn btn-link modal-close" onClick={() => EditOrder.closeModal('top')}>
						<i className="fa fa-times"></i>
					</a>
					{EditOrder.showInlineAction === 'top' ? (
						<div>
							<InlineAction
								onConfirm={EditOrder.saveChanges}
								onReject={EditOrder.rejectChanges}
								saveDisabled={EditOrder.saveDisabled}
								saveDisabledTooltip={EditOrder.saveDisabledTooltip}
								showTop={true}
								toggleInlineAction={EditOrder.closeInlineAction}
							/>
						</div>
					) : null}
				</div>

				{/* TABS */}
				{EditOrder.order.id ? (
					<ul className="tabs content-tabs flex-tabs">
						<li>
							<a
								onClick={() => EditOrder.tabs.setActive('form')}
								className={classNames('darker', { current: EditOrder.tabs.isActive('form') })}
								data-test-id="order-tabs-form"
							>
								{t('default.edit')}
							</a>
						</li>

						<li>
							<a
								onClick={() => EditOrder.tabs.setActive('dashboard')}
								className={classNames({ current: EditOrder.tabs.isActive('dashboard') })}
								data-test-id="order-tabs-dashboard"
							>
								{t(EditOrder.hasTodo ? 'default.tasksHeader' : 'default.history')}
							</a>
						</li>

						{EditOrder.showAppsSyncLogs ? (
							<li>
								<a
									onClick={() => {
										EditOrder.tabs.setActive('apps');
										EditOrder.activateSyncLogs();
									}}
									className={classNames({ current: EditOrder.tabs.isActive('apps') })}
									data-test-id="order-tabs-apps"
								>
									{t('default.sync')}
								</a>
							</li>
						) : null}

						{EditOrder.hasFiles && EditOrder.files.length && EditOrder.edit ? (
							<li>
								<a
									onClick={() => EditOrder.tabs.setActive('files')}
									className={classNames({ current: EditOrder.tabs.isActive('files') })}
								>
									<span>{`${t('file.files')} ${EditOrder.files.length}`}</span>
								</a>
							</li>
						) : null}

						{EditOrder.hasNotes ? (
							<li>
								<a
									onClick={() => EditOrder.tabs.setActive('notes')}
									className={classNames({ current: EditOrder.tabs.isActive('notes') })}
									data-test-id="order-tabs-notes"
								>
									{t('default.notes')}
								</a>
							</li>
						) : null}

						{EditOrder.productsWithProjectPlanTemplates.length > 0 &&
						EditOrder.hasProjectPlanFeature &&
						!EditOrder.isOrder ? (
							<li>
								<a
									onClick={() => EditOrder.tabs.setActive('projects')}
									className={classNames({ current: EditOrder.tabs.isActive('projects') })}
									data-test-id="order-tabs-projectPlan"
								>
									{t('default.projectPlans')}
								</a>
							</li>
						) : null}

						{EditOrder.appsWithFieldSync.length > 0 ? (
							<li className="FieldSyncLogs__dropdown">
								<div className="FieldSyncLogs__wrap">
									<FieldSync apps={EditOrder.appsWithFieldSync} toggleSync={EditOrder.toggleSync} />
								</div>
							</li>
						) : null}
					</ul>
				) : null}

				{/* CONTENT */}
				<div
					className={classNames('up-modal-content has-sidebar', {
						'account-selected': EditOrder.order.client,
						'has-tabs': EditOrder.order.id
					})}
				>
					<div className="modal-sidebar">
						{/* ACCOUNT */}
						<div
							id="select-account-section"
							className={classNames('sidebar-section form-group no-margin', {
								'has-error':
									EditOrder.OrderForm.account?.$invalid &&
									(EditOrder.OrderForm.account?.$dirty || EditOrder.OrderForm.$submitted)
							})}
							style={EditOrder.changingAccount || !EditOrder.order.id ? {} : { display: 'none' }}
						>
							<div>
								<label htmlFor="account-select">
									{t('default.account')} <b className="text-danger">{'*'}</b>
								</label>
								{EditOrder.order.id ? (
									<button
										type="button"
										className="up-btn btn-grey btn-link no-shadow btn btn-sm"
										onClick={() => EditOrder.changeAccount()}
									>
										{t('default.abort')}
									</button>
								) : null}
							</div>
							<ClientInput
								autoOpen={!EditOrder.order.client}
								className="form-control"
								id="account-select"
								name="account"
								onChange={EditOrder.accountChange}
								required={true}
								tabIndex={1}
								value={EditOrder.order.client ?? null}
							/>
						</div>

						{EditOrder.account ? (
							<div className="sidebar-section no-margin" id="select-account-section">
								<Tooltip title={`${t('change')} ${t('account').toLowerCase()}`}>
									<button
										style={
											!EditOrder.isSupportUser &&
											!EditOrder.order.closeDate &&
											!(EditOrder.changingAccount || !EditOrder.order.id)
												? {}
												: { display: 'none' }
										}
										type="button"
										className="onHover up-btn btn-grey btn-link no-shadow btn btn-sm change-company"
										onClick={() => EditOrder.changeAccount()}
									>
										<b className="fa fa-edit"></b>
									</button>
								</Tooltip>

								{EditOrder.lockedAccount ? (
									<h3
										data-test-id="activity-client-name"
										className={classNames('sidebar-account-title', {
											'field-sync-client-highlight':
												EditOrder.highlightedFields.includes('client'),
											'field-sync-client-disabled':
												EditOrder.highlightedFields.length > 0 &&
												!EditOrder.highlightedFields.includes('client')
										})}
									>
										<div className="form-client-sync-header">
											{EditOrder.fieldsSyncDir.client ? (
												<div className="form-client-sync-direction">
													{EditOrder.fieldsSyncDir.client === 'import' ? (
														<Tooltip
															title={t('integration.onlySyncedToUpsales')}
															placement="right"
														>
															<b className="fa fa-long-arrow-left"></b>
														</Tooltip>
													) : null}
													{EditOrder.fieldsSyncDir.client === 'export' ? (
														<Tooltip
															title={EditOrder.syncedToApp}
															placement="right"
															dynamic={true}
														>
															<b className="fa fa-long-arrow-right"></b>
														</Tooltip>
													) : null}
													{EditOrder.fieldsSyncDir.client === 'both' ? (
														<Tooltip title={t('integration.twoWaySync')} placement="right">
															<b className="fa fa-exchange"></b>
														</Tooltip>
													) : null}
												</div>
											) : null}
										</div>
										<div
											className={classNames({
												'field-sync-client-name': EditOrder.highlightedFields.includes('client')
											})}
										>
											<a onClick={EditOrder.goToAccountDashboard}>
												{EditOrder.order.client!.name || t('default.noName')}
											</a>
										</div>
									</h3>
								) : null}

								{EditOrder.account ? (
									<JourneyStepDisplay
										id={EditOrder.account.id}
										disableActions={!EditOrder.account.userEditable}
										journeyStep={EditOrder.account.journeyStep}
										onSetJourneyStep={EditOrder.onSetJourneyStepClient}
										isSubaccount={!!EditOrder.account.operationalAccount}
										isInactive={!EditOrder.account.active}
										type={'client'}
									/>
								) : null}

								{/* RATING LABEL */}
								<RatingLabel account={EditOrder.account} />

								{EditOrder.account.$addresses.Visit ? (
									<div className="sidebar-account-address">
										{EditOrder.account.$addresses.Visit.country ? (
											<b
												className={`icon flag-icon flag-icon-${EditOrder.account.$addresses.Visit.country.toLowerCase()}`}
											></b>
										) : null}
										{!EditOrder.account.$addresses.Visit.country &&
										EditOrder.account.$addresses.Visit.city ? (
											<b className="icon fa fa-map-marker"></b>
										) : null}
										{EditOrder.account.$addresses.Visit.city ? (
											<a onClick={EditOrder.goToAccountAddresses}>
												{` ${EditOrder.account.$addresses.Visit.city}`}
											</a>
										) : null}
									</div>
								) : null}

								{/* PHONE NUMBERS */}
								<div
									className={classNames('modal-sidebar-item', {
										'has-input': EditOrder.account.phone
									})}
								>
									<b className="fa fa-phone"></b>
									<span>{t('default.noSet')}</span> <span>{t('default.phone').toLowerCase()}</span>
									<TelephoneLink
										client={EditOrder.account}
										contact={null}
										number={EditOrder.account.phone}
										className={'ellipsis'}
									/>
								</div>

								{/* WEBPAGE */}
								<div
									className={classNames('modal-sidebar-item', {
										'has-input': EditOrder.account.webpage
									})}
								>
									<b className="fa fa-globe"></b>
									<span>{t('default.noSet')}</span> <span>{t('default.webpage').toLowerCase()}</span>
									{EditOrder.account.webpage ? (
										<a className="ellipsis" target="_blank" href={EditOrder.account.webpage}>
											{EditOrder.account.webpage}
										</a>
									) : null}
								</div>

								{/* PARENT ACCOUNT */}
								{(EditOrder.account.parent && EditOrder.account.parent.id !== 0) ||
								(EditOrder.account.soliditet?.profileData?.parentCompanyName &&
									EditOrder.account.soliditet?.profileData?.parentCompanyDunsNumber) ? (
									<div>
										<div>{`${t('default.parentCompany')}:`}</div>
										{EditOrder.account.parent ||
										EditOrder.account.soliditet?.profileData?.parentCompanyCountryCode === 'SE' ? (
											<a onClick={EditOrder.parentCompanyClick}>
												{EditOrder.account.parent?.name ||
													EditOrder.account.soliditet?.profileData?.parentCompanyName}
											</a>
										) : null}
										{!EditOrder.account.parent &&
										EditOrder.account.soliditet?.profileData?.parentCompanyCountryCode !== 'SE' ? (
											<span>{EditOrder.account.soliditet?.profileData?.parentCompanyName}</span>
										) : null}
									</div>
								) : null}

								{/* SIMILAR CUSTOMERS */}
								{EditOrder.hasAI && EditOrder.hasSimilarCustomers ? (
									<SimilarCustomers bold={false} renderTitle client={EditOrder.account} />
								) : null}
							</div>
						) : null}

						{/* END OF ACCOUNT */}

						{/* CONTACT */}

						<div
							className={classNames(
								'sidebar-section sidebar-contact-select-wrap sidebar-contact-section show-when-account',
								{ 'is-form': EditOrder.editContact }
							)}
						>
							{/* SELECT CONTACT */}
							<div
								id="select-contact-init"
								className={classNames('form-group', {
									'has-error':
										EditOrder.OrderForm.contact?.$invalid &&
										(EditOrder.OrderForm.contact?.$dirty || EditOrder.OrderForm.$submitted),
									'field-sync-disabled':
										EditOrder.highlightedFields.length > 0 &&
										!EditOrder.highlightedFields.includes('contact')
								})}
							>
								<div className="form-input-sync-header">
									<label htmlFor="contact-select">
										{t('default.contact')}
										{EditOrder.requiredFields.Contact ? <b className="text-danger">{'*'}</b> : null}
									</label>
									{EditOrder.editable ? (
										<a onClick={EditOrder.createContact} className="pull-right" tabIndex={-1}>
											{t('default.new')}
										</a>
									) : null}
									{EditOrder.fieldsSyncDir.contact ? (
										<div className="form-input-sync-direction">
											{EditOrder.fieldsSyncDir.contact === 'import' ? (
												<Tooltip title={t('integration.onlySyncedToUpsales')}>
													<b className="fa fa-long-arrow-left"></b>
												</Tooltip>
											) : null}
											{EditOrder.fieldsSyncDir.contact === 'export' ? (
												<Tooltip title={EditOrder.syncedToApp} dynamic={true}>
													<b className="fa fa-long-arrow-right"></b>
												</Tooltip>
											) : null}
											{EditOrder.fieldsSyncDir.contact === 'both' ? (
												<Tooltip title={t('integration.twoWaySync')}>
													<b className="fa fa-exchange"></b>
												</Tooltip>
											) : null}
										</div>
									) : null}
								</div>
								<Tooltip title={EditOrder.fieldsDescription.contact} placement="bottom">
									<div>
										{EditOrder.useAjaxContactSelect ? (
											<Select<EditOrderContact | null>
												disabled={
													!EditOrder.contacts.length ||
													EditOrder.saving ||
													!EditOrder.editable
												}
												focusEvent={`EditOrder.${modalId}.accountChanged`}
												id="contact-select"
												name="contact"
												onChange={EditOrder.contactChange}
												options={EditOrder.select.contactAjax}
												placeholder={`${t('default.select')} ${t(
													'default.contact'
												).toLowerCase()}`}
												required={EditOrder.requiredFields.Contact}
												tabIndex={2}
												value={EditOrder.order.contact ?? null}
												className={classNames('form-control no-transition ', {
													'field-sync-highlight':
														EditOrder.highlightedFields.includes('contact')
												})}
											/>
										) : (
											<Select<EditOrderContact | null>
												disabled={
													!EditOrder.contacts.length ||
													EditOrder.saving ||
													!EditOrder.editable
												}
												focusEvent={`EditOrder.${modalId}.accountChanged`}
												id="contact-select"
												name="contact"
												onChange={EditOrder.contactChange}
												options={EditOrder.select.contact}
												placeholder={`${t('default.select')} ${t(
													'default.contact'
												).toLowerCase()}`}
												required={EditOrder.requiredFields.Contact}
												tabIndex={2}
												value={EditOrder.order.contact ?? null}
												className={classNames('form-control no-transition ', {
													'field-sync-highlight':
														EditOrder.highlightedFields.includes('contact')
												})}
											/>
										)}
									</div>
								</Tooltip>
							</div>
							{/* DISPLAY CONTACT */}
							{EditOrder.order.contact && EditOrder.contactPerson && !EditOrder.missingContactRights ? (
								<div id="contact-display">
									<JourneyStepDisplay
										id={EditOrder.contactPerson.id}
										disableActions={!EditOrder.contactPerson.userEditable}
										journeyStep={EditOrder.contactPerson.journeyStep}
										onSetJourneyStep={EditOrder.onSetJourneyStepContact}
										isSubaccount={false}
										isInactive={!EditOrder.contactPerson.active}
										type={'contact'}
									/>

									{EditOrder.contactPerson.title &&
									(!EditOrder.contactPerson.titleCategory ||
										!EditOrder.hasSoftDeployContactTitleCategoryAccess) ? (
										<div
											className="modal-sidebar-item has-input clickable"
											onClick={() => EditOrder.showContactEdit(true)}
										>
											<span className="item-value ellipsis">
												{EditOrder.contactPerson.title.toUpperCase()}
											</span>
										</div>
									) : null}

									{EditOrder.contactPerson.titleCategory &&
									!EditOrder.contactPerson.title &&
									EditOrder.hasSoftDeployContactTitleCategoryAccess ? (
										<div
											className="modal-sidebar-item has-input clickable"
											onClick={() => EditOrder.showContactEdit(true)}
										>
											<span className="item-value ellipsis">
												{EditOrder.contactPerson.titleCategory.value.toUpperCase()}
											</span>
										</div>
									) : null}

									{EditOrder.contactPerson.titleCategory &&
									EditOrder.contactPerson.title &&
									EditOrder.hasSoftDeployContactTitleCategoryAccess ? (
										<div
											className="modal-sidebar-item has-input clickable"
											onClick={() => EditOrder.showContactEdit(true)}
										>
											<span className="item-value ellipsis">
												{(
													EditOrder.contactPerson.titleCategory.value +
													' ' +
													EditOrder.contactPerson.title
												).toUpperCase()}
											</span>
										</div>
									) : null}

									<div
										className={classNames('modal-sidebar-item', {
											'has-input': EditOrder.contactPerson.cellPhone
										})}
									>
										<b className="fa fa-mobile"></b>
										<span className="clickable" onClick={() => EditOrder.showContactEdit(true)}>
											<span>{t('default.noSet')}</span>{' '}
											<span>{t('default.cellPhone').toLowerCase()}</span>
										</span>
										<TelephoneLink
											client={EditOrder.account}
											contact={EditOrder.contactPerson}
											number={EditOrder.contactPerson.cellPhone}
											className={'ellipsis'}
										/>
									</div>

									<div
										className={classNames('modal-sidebar-item', {
											'has-input': EditOrder.contactPerson.phone
										})}
									>
										<b className="fa fa-phone"></b>
										<span className="clickable" onClick={() => EditOrder.showContactEdit(true)}>
											<span>{t('default.noSet')}</span>{' '}
											<span>{t('default.phone').toLowerCase()}</span>
										</span>
										<TelephoneLink
											client={EditOrder.account}
											contact={EditOrder.contactPerson}
											number={EditOrder.contactPerson.phone}
											className={'ellipsis'}
										/>
									</div>

									<div
										className={classNames('modal-sidebar-item', {
											'has-input': EditOrder.contactPerson.email
										})}
									>
										<b className="fa fa-envelope"></b>
										<span className="clickable" onClick={() => EditOrder.showContactEdit(true)}>
											{t('default.noEmail')}
										</span>
										<a
											className="ellipsis"
											href=""
											onClick={event => EditOrder.emailContact(event, EditOrder.contactPerson!)}
										>
											{EditOrder.contactPerson.email}
										</a>
									</div>

									<div className="modal-sidebar-item center">
										<div id="sidebar-contact-actions">
											<button
												type="button"
												className="up-btn btn-grey btn-link no-shadow btn btn-sm"
												onClick={() => EditOrder.showContactEdit(true)}
												disabled={EditOrder.saving || !EditOrder.canEditContact()}
											>
												<b className="fa fa-edit"></b> <span>{t('default.edit')}</span>
											</button>
										</div>
									</div>
								</div>
							) : null}

							{/* CONTACT QUICK EDIT/CREATE */}
							{!EditOrder.missingContactRights ? (
								<div id="edit-contact-form">
									<h3>
										<span>
											{t(EditOrder.contactFormIsEdit ? 'default.edit' : 'default.create')}
										</span>{' '}
										<span>{t('default.contact').toLowerCase()}</span>
									</h3>
									{!EditOrder.isAvailable.newFields ? (
										<div
											className={classNames('floating-label-input', {
												'has-value': EditOrder.editContactModel.name
											})}
										>
											<label>
												<span>{t('default.name')}</span> <b className="text-danger">{'*'}</b>
											</label>
											<TextInput
												type="text"
												value={EditOrder.editContactModel.name ?? ''}
												onChange={EditOrder.contactNameChanged}
												disabled={EditOrder.editContactLoading}
												onKeyDown={EditOrder.saveContactOnEnter}
												maxLength={50}
												controllerOptions={{ debounce: { change: 200, blur: 0 } }}
											/>
										</div>
									) : null}

									{EditOrder.isAvailable.newFields ? (
										<div
											className={classNames('floating-label-input', {
												'has-value': EditOrder.editContactModel.firstName
											})}
										>
											<label>
												<span>{t('default.firstName')}</span>{' '}
												<b className="text-danger">{'*'}</b>
											</label>
											<TextInput
												type="text"
												value={EditOrder.editContactModel.firstName ?? ''}
												onChange={EditOrder.contactFirstNameChanged}
												disabled={EditOrder.editContactLoading}
												onKeyDown={EditOrder.saveContactOnEnter}
												maxLength={50}
												controllerOptions={{ debounce: { change: 200, blur: 0 } }}
												autoFocus={true}
											/>
										</div>
									) : null}

									{EditOrder.isAvailable.newFields ? (
										<div
											className={classNames('floating-label-input', {
												'has-value': EditOrder.editContactModel.lastName
											})}
										>
											<label>
												<span>{t('default.lastName')}</span>{' '}
												<b className="text-danger">{'*'}</b>
											</label>
											<TextInput
												controllerOptions={{ debounce: { change: 200, blur: 0 } }}
												disabled={EditOrder.editContactLoading}
												maxLength={50}
												onChange={EditOrder.contactLastNameChanged}
												onKeyDown={EditOrder.saveContactOnEnter}
												type="text"
												value={EditOrder.editContactModel.lastName ?? ''}
											/>
										</div>
									) : null}

									<div
										className={classNames('floating-label-input', {
											'has-value': EditOrder.editContactModel.title
										})}
									>
										<label>{t('default.title').toLowerCase()}</label>
										<TextInput
											controllerOptions={{ debounce: { change: 200, blur: 0 } }}
											disabled={EditOrder.editContactLoading}
											onChange={EditOrder.contactTitleChanged}
											onKeyDown={EditOrder.saveContactOnEnter}
											type="text"
											value={EditOrder.editContactModel.title ?? ''}
										/>
									</div>
									{EditOrder.hasSoftDeployContactTitleCategoryAccess ? (
										<div
											className={classNames('title-category-wrap', {
												'has-value': EditOrder.editContactModel.titleCategory
											})}
										>
											<label>
												<span>{t('default.titlecategory')}</span>
											</label>
											{EditOrder.requiredFieldsContact.TitleCategory ? (
												<b className="text-danger">{'*'}</b>
											) : null}
											<UpFieldTranslation
												className="form-control"
												fieldType="titlecategory"
												onChange={EditOrder.contactTitleCategoryChanged}
												placeholder={t('default.titlecategory')}
												required={EditOrder.requiredFieldsContact.TitleCategory}
												value={EditOrder.editContactModel.titleCategory ?? null}
											/>
										</div>
									) : null}

									<div
										className={classNames('floating-label-input', {
											'has-value': EditOrder.editContactModel.cellPhone
										})}
									>
										<label>
											<b className="fa fa-mobile"></b> <span>{t('default.cellPhone')}</span>
										</label>
										<TextInput
											controllerOptions={{ debounce: { change: 200, blur: 0 } }}
											disabled={EditOrder.editContactLoading}
											onChange={EditOrder.contactCellPhoneChanged}
											onKeyDown={EditOrder.saveContactOnEnter}
											type="text"
											value={EditOrder.editContactModel.cellPhone ?? ''}
										/>
									</div>

									<div
										className={classNames('floating-label-input', {
											'has-value': EditOrder.editContactModel.phone
										})}
									>
										<label>
											<b className="fa fa-phone"></b> <span>{t('default.phone')}</span>
										</label>
										<TextInput
											controllerOptions={{ debounce: { change: 200, blur: 0 } }}
											disabled={EditOrder.editContactLoading}
											onChange={EditOrder.contactPhoneChanged}
											onKeyDown={EditOrder.saveContactOnEnter}
											type="text"
											value={EditOrder.editContactModel.phone ?? ''}
										/>
									</div>

									<div
										className={classNames('floating-label-input', {
											'has-value': EditOrder.editContactModel.email,
											'has-error': !EditOrder.validateEmail(EditOrder.editContactModel.email)
										})}
									>
										<label>
											<b className="fa fa-envelope-o"></b> <span>{`${t('default.email')} `}</span>
											{EditOrder.requiredFieldsContact.Email ? (
												<b className="text-danger">{'*'}</b>
											) : null}
										</label>
										<TextInput
											controllerOptions={{ debounce: { change: 200, blur: 0 } }}
											disabled={EditOrder.editContactLoading}
											onChange={EditOrder.contactEmailChanged}
											onKeyDown={EditOrder.saveContactOnEnter}
											type="text"
											value={EditOrder.editContactModel.email ?? ''}
										/>
									</div>

									<div className="pull-right">
										<button
											type="button"
											className="up-btn btn btn-sm btn-bright-blue no-shadow"
											onClick={() => EditOrder.contactEditSave()}
											disabled={EditOrder.contactEditSaveDisabled()}
										>
											{EditOrder.editContactLoading ? (
												<b className="fa fa-spin fa-refresh"></b>
											) : null}
											<span>{t('default.save')}</span>
										</button>
										<button
											type="button"
											className="up-btn btn btn-sm btn-grey btn-link"
											onClick={() => EditOrder.abortContactEdit()}
											disabled={EditOrder.editContactLoading}
										>
											{t('default.abort')}
										</button>
									</div>
								</div>
							) : null}
						</div>
						{/* END OF CONTACT */}

						{/* DECISION MAKERS */}

						{(EditOrder.oldStakeholdersEnabled || EditOrder.decisionMakersEnabled) &&
						EditOrder.hasSalesProcessProFeature ? (
							<DecisionMakersSidebar
								salesCoachDecisionMaker={EditOrder.salesCoachDecisionMaker}
								newTitleCategories={EditOrder.newTitleCategories}
								clientId={EditOrder.order.client && EditOrder.order.client.id}
								updateStakeholders={EditOrder.updateStakeholders}
								updateTitleCategories={EditOrder.updateTitleCategories}
								stakeholders={EditOrder.order.stakeholders}
								titleCategoriess={EditOrder.order.titleCategories}
								contacts={EditOrder.contacts}
								disabled={!EditOrder.editable}
							/>
						) : null}

						{/* END OF DECISION MAKERS */}

						{/* SUPPORT TICKETS WIDGET*/}
						{EditOrder.hasSupportAccess && EditOrder.order.client?.id ? (
							<div>
								<TicketWidget clientId={EditOrder.order.client.id} />
							</div>
						) : null}

						{/* END OF SUPPORT TICKET WIDGET*/}

						{/* ACCOUNT PLAN */}

						{EditOrder.account && EditOrder.hasAccountPlan ? (
							<div>
								<AccountPlanSidebar
									plans={EditOrder.account.clientPlans ?? []}
									clientName={EditOrder.account.name}
									clientId={EditOrder.account.id}
									userEditable={EditOrder.account.userEditable}
									inEditOrder={true}
								/>
							</div>
						) : null}

						{/* END OF ACCOUNT PLAN */}

						{/* ORDER DATA */}
						<div className="sidebar-section">
							<h3>{t('order.details')}</h3>

							{EditOrder.order.orderRow.map(row => {
								if (!row.product) {
									return null;
								}

								return (
									<div key={row.uuid} className="order-calc-row">
										<div className="row-left">{row.product.name}</div>
										<div className="row-right">
											{row.product.tiers && row.product.tiers.length ? (
												<div className="Icon Icon-tiers Icon--bright-blue"></div>
											) : null}
											{!(
												row.product.tiers &&
												row.product.tiers.length &&
												row.product.tiers[0].isTotalPrice
											) ? (
												<span>{`${row.quantity} x `}</span>
											) : null}
											{currencyFormat(row.price, EditOrder.selectedCurrency.iso)}
										</div>
									</div>
								);
							})}

							<div className="dashed-divider"></div>

							<div className="order-calc-row">
								<div className="row-left bold">{t('order.gross')}</div>
								<div className="row-right">
									{currencyFormat(EditOrder.calc.totalGross(), EditOrder.selectedCurrency.iso)}
								</div>
							</div>
							{EditOrder.useDiscount ? (
								<div className="order-calc-row">
									<div className="row-left bold">{t('default.discount')}</div>
									<div className="row-right">
										{EditOrder.calc.totalDiscount() ? '- ' : ''}
										{currencyFormat(
											EditOrder.calc.totalDiscount() >= 0 ? EditOrder.calc.totalDiscount() : 0,
											EditOrder.selectedCurrency.iso
										)}
									</div>
								</div>
							) : null}

							{EditOrder.hasContributionMargin ? (
								<div className="order-calc-row">
									<div className="row-left bold">{t('default.contributionMarginShort')}</div>
									<div className="row-right">
										{currencyFormat(
											EditOrder.calc.totalContributionMargin(),
											EditOrder.selectedCurrency.iso
										)}
									</div>
								</div>
							) : null}

							{EditOrder.hasRecurringInterval ? (
								<div className="order-calc-row">
									{EditOrder.salesModelOption === 'mrr' ? (
										<div className="row-left bold">{t('default.mrr')}</div>
									) : null}

									{EditOrder.salesModelOption !== 'mrr' ? (
										<div className="row-left bold">{t('default.arr')}</div>
									) : null}

									<div className="row-right">
										{currencyFormat(EditOrder.calc.totalRR(), EditOrder.selectedCurrency.iso)}
									</div>
								</div>
							) : null}

							{EditOrder.hasRecurringInterval ? (
								<div className="order-calc-row">
									<div className="row-left bold">{t('admin.products.oneOff')}</div>
									<div className="row-right">
										{currencyFormat(EditOrder.calc.totalOneOff(), EditOrder.selectedCurrency.iso)}
									</div>
								</div>
							) : null}

							<div className="order-calc-row">
								<div className="row-left bold large">{t('default.netAmount')}</div>
								<div className="row-right bold large" data-test-id="order-total">
									{currencyFormat(EditOrder.calc.totalNet() || 0, EditOrder.selectedCurrency.iso)}
								</div>
							</div>

							<div className="clearfix"></div>
						</div>
						{/* END OF ORDER DATA */}

						{/* PERIODIZATION CHART */}
						{EditOrder.hasPeriodization ? (
							<div className="periodization-chart-root">
								<PeriodizationChart
									edit={EditOrder.togglePeriodization}
									order={EditOrder.order as unknown as Order}
								/>
							</div>
						) : null}

						{/* END OF PERIODIZATION CHART */}

						{/* LINKS */}
						{EditOrder.order.id && EditOrder.links && EditOrder.links.length ? (
							<div id="links-section" className="sidebar-section">
								<h3>
									<b className="fa fa-link"></b> {t('default.links')}
								</h3>
								<ul>
									{EditOrder.links.map(link => (
										<li key={link.id}>
											<a target={link.target || '_blank'} href={link.href}>
												{link.icon && link.icon.indexOf('fa') === 0 ? (
													<b
														className={`icon fa ${link.icon} custom-icon`}
														style={link.color ? { color: `#${link.color}` } : {}}
													></b>
												) : null}
												{link.icon && link.icon.indexOf('fa') !== 0 ? (
													<img src={link.icon} />
												) : null}
												{` ${link.value}`}
											</a>
										</li>
									))}
								</ul>
							</div>
						) : null}

						{/* END OF LINKS */}

						<div className="sidebar-section edge-to-edge">
							{EditOrder.uiElements?.sidebar?.length ? (
								<div className="ui-element-edit-order-sidebar">
									<UiElements
										elements={EditOrder.uiElements.sidebar}
										object={EditOrder.order}
										type="editOrder"
										utils={EditOrder.uiElementUtils}
									/>
								</div>
							) : null}
						</div>
					</div>

					<div className="modal-center" style={EditOrder.tabs.isActive('form') ? {} : { display: 'none' }}>
						{/* TAB CONTENT GOES HERE */}
						<OrderForm />
					</div>
					<div
						className="modal-center"
						style={EditOrder.tabs.isActive('dashboard') ? {} : { display: 'none' }}
					>
						{/* TAB CONTENT GOES HERE */}
						<OrderDashboard />
					</div>
					<div className="modal-center" style={EditOrder.tabs.isActive('files') ? {} : { display: 'none' }}>
						{/* TAB CONTENT GOES HERE */}
						<OrderFiles />
					</div>
					<div
						className="modal-center"
						style={EditOrder.tabs.isActive('projects') ? {} : { display: 'none' }}
					>
						{/* TAB CONTENT GOES HERE */}
						{EditOrder.productsWithProjectPlanTemplates.length > 0 &&
						EditOrder.hasProjectPlanFeature &&
						!EditOrder.isOrder ? (
							<div>
								<div>
									<ProjectPlanHandler
										products={EditOrder.productsWithProjectPlanTemplates}
										projectPlanOptions={EditOrder.order.projectPlanOptions ?? []}
										onChange={EditOrder.onProjectPlanChange}
										orderId={EditOrder.order.id}
									/>
								</div>
							</div>
						) : null}
					</div>
					{EditOrder.renderSyncLogs ? (
						<div
							className="modal-center"
							style={EditOrder.tabs.isActive('apps') ? {} : { display: 'none' }}
						>
							<div>
								<AppsHistory order={EditOrder.order} apps={EditOrder.apps} />
							</div>
						</div>
					) : null}

					{/* TAB CONTENT GOES HERE */}
					{EditOrder.hasNotes && EditOrder.tabs.isActive('notes') ? (
						<div className="notes-root">
							<Notes orderId={EditOrder.order.id} />
						</div>
					) : null}
				</div>

				{/* Opacity style is a fix */}
				<div className="up-modal-controls">
					{/* PROMPT REMOVE ORDER */}
					{EditOrder.order.id && EditOrder.order.userRemovable ? (
						<button
							data-test-id="order-remove-btn"
							type="button"
							className="up-btn btn-lined btn-green btn-hover-red no-shadow pull-left"
							onClick={() => EditOrder.promptRemoveEntry()}
							disabled={EditOrder.saving}
						>
							<b className="fa fa-trash-o"></b>
						</button>
					) : null}

					{/* CREATE ESIGN */}
					<Tooltip
						title={
							EditOrder.saveDisabledTooltip && (EditOrder.saving || EditOrder.saveDisabled)
								? EditOrder.saveDisabledTooltip
								: ''
						}
						dynamic={true}
					>
						<div className="pull-left footer-button-container">
							{EditOrder.hasEsign && EditOrder.edit ? (
								<button
									className="btn up-btn btn-green btn-lined no-shadow pull-left"
									type="button"
									onClick={() => EditOrder.createEsign()}
								>
									<b className="fa fa-edit"></b> <span>{t('esign.createEsign')}</span>
								</button>
							) : null}
						</div>
					</Tooltip>

					{/* UPLOAD FILE */}
					<Tooltip
						title={
							EditOrder.saveDisabledTooltip && (EditOrder.saving || EditOrder.saveDisabled)
								? EditOrder.saveDisabledTooltip
								: ''
						}
						dynamic={true}
					>
						<div className="pull-left footer-button-container">
							{EditOrder.hasFiles && EditOrder.edit ? (
								<button
									className="btn up-btn btn-green btn-lined no-shadow pull-left"
									type="button"
									onClick={() => EditOrder.uploadFile()}
									disabled={EditOrder.saving || EditOrder.saveDisabled}
								>
									<b className="fa fa-cloud-upload"></b> <span>{t('default.uploadFile')}</span>
								</button>
							) : null}
						</div>
					</Tooltip>

					{/* DOCUMENTS */}
					{EditOrder.order.id && EditOrder.hasDocumentTemplates && EditOrder.hasFiles ? (
						<DocumentTemplateDropdown
							createDocument={EditOrder.createDocument}
							disabled={EditOrder.saving || EditOrder.saveDisabled}
							documentTemplates={EditOrder.documentTemplates}
							tooltip={
								EditOrder.saveDisabledTooltip && (EditOrder.saving || EditOrder.saveDisabled)
									? EditOrder.saveDisabledTooltip
									: ''
							}
						/>
					) : null}

					{/* CREATE COPY */}
					{EditOrder.copyButtonVisible && EditOrder.isVisibleField('copyButton') ? (
						<Tooltip
							title={
								EditOrder.saveDisabledTooltip && (EditOrder.saving || EditOrder.saveDisabled)
									? EditOrder.saveDisabledTooltip
									: ''
							}
							dynamic={true}
						>
							<div className="pull-left footer-button-container">
								{EditOrder.order.id && !EditOrder.isSupportUser ? (
									<button
										type="button"
										className="btn up-btn btn-green btn-lined no-shadow pull-left"
										onClick={() => EditOrder.createCopy()}
										disabled={EditOrder.saving || EditOrder.saveDisabled}
									>
										<b className="fa fa-copy"></b> <span>{t('default.createCopy')}</span>
									</button>
								) : null}
							</div>
						</Tooltip>
					) : null}

					{/* CREATE E-SIGN */}
					<Tooltip
						title={
							EditOrder.saveDisabledTooltip && (EditOrder.saving || EditOrder.saveDisabled)
								? EditOrder.saveDisabledTooltip
								: ''
						}
						dynamic={true}
					>
						<div className="pull-left footer-button-container">
							{EditOrder.order.id && EditOrder.hasEsignActivated ? (
								<button
									type="button"
									className="btn up-btn btn-green btn-lined no-shadow pull-left"
									onClick={() => EditOrder.eSign()}
									disabled={EditOrder.saving || EditOrder.saveDisabled}
								>
									<b className="fa fa-pencil"></b> <span>{t('esign.sendEsign')}</span>
								</button>
							) : null}
						</div>
					</Tooltip>

					{/* CREATE SUBSCRIPTION */}
					<Tooltip
						title={
							EditOrder.saveDisabledTooltip && (EditOrder.saving || EditOrder.saveDisabled)
								? EditOrder.saveDisabledTooltip
								: ''
						}
						dynamic={true}
					>
						<div className="pull-left footer-button-container">
							{EditOrder.order.id &&
							EditOrder.stageType === 'order' &&
							EditOrder.hasRecurringProduct() &&
							!EditOrder.order.agreement ? (
								<button
									type="button"
									className="btn up-btn btn-green btn-lined no-shadow pull-left"
									onClick={() => EditOrder.createSubscription()}
								>
									<b className="up-icon-recurring-order"></b>{' '}
									<span>{t('default.createSubscription')}</span>
								</button>
							) : null}
						</div>
					</Tooltip>
					{!EditOrder.editable ? <span className="pull-left grey">{t('noEditRights.order')}</span> : null}
					{showWinLossButtons ? (
						<div className="OpportunityWinLossButtons">
							<OpportunityWinLossButtons
								// @ts-expect-error The types were not compatible before, so will just ignore it now
								onSave={EditOrder.onWinLoss}
								onClick={EditOrder.validateForm}
								// @ts-expect-error The types were not compatible before, so will just ignore it now
								getPastDate={EditOrder.getPastDate}
								skipStageOption={EditOrder.wonOpportunityWithSubs()}
								buttonsDisabled={EditOrder.saveDisabled}
								buttonsDisabledTooltip={EditOrder.saveDisabledTooltip}
								loading={EditOrder.saving}
							/>
						</div>
					) : null}
					{showWinLossButtons ? (
						<div className="save-button-wrapper">
							<EditOrderSaveButton
								onSave={EditOrder.onSave}
								onSaveAndContinue={EditOrder.onSaveAndContinue}
								disabled={EditOrder.saving || EditOrder.saveDisabled}
								disabledTooltip={EditOrder.saveDisabledTooltip}
							/>
						</div>
					) : null}
					{!showWinLossButtons ? (
						<Tooltip
							title={
								EditOrder.saveDisabledTooltip && (EditOrder.saving || EditOrder.saveDisabled)
									? EditOrder.saveDisabledTooltip
									: ''
							}
							dynamic={true}
						>
							<div className="save-button-wrapper">
								{EditOrder.editable ? (
									<button
										type="submit"
										disabled={
											EditOrder.saving || EditOrder.saveDisabled || EditOrder.fetchingAgreements
										}
										className={classNames('btn up-btn btn-green no-shadow btn-primary-action', {
											'btn-link': showWinLossButtons,
											'main-button': !showWinLossButtons
										})}
										id="form-submit"
									>
										{t(EditOrder.saving ? 'default.saving' : 'default.save')}

										<span
											style={EditOrder.saving ? {} : { display: 'none' }}
											className={classNames('fa fa-refresh', { 'fa-spin': EditOrder.saving })}
										></span>
									</button>
								) : null}
								{EditOrder.editable ? (
									<SaveButtonDropdown
										showWinLossButtons={showWinLossButtons}
										disabled={EditOrder.saving || EditOrder.saveDisabled}
										saveAndContinue={() => EditOrder.save(true, false, true)}
									/>
								) : null}
							</div>
						</Tooltip>
					) : null}

					<button
						id="form-cancel"
						type="button"
						className="btn up-btn btn-grey btn-link"
						onClick={() => EditOrder.closeModal('bottom')}
					>
						{t('default.abort')}
					</button>

					{EditOrder.showInlineAction === 'bottom' ? (
						<div>
							<InlineAction
								onConfirm={EditOrder.saveChanges}
								onReject={EditOrder.rejectChanges}
								saveDisabled={EditOrder.saveDisabled}
								saveDisabledTooltip={EditOrder.saveDisabledTooltip}
								showTop={false}
								toggleInlineAction={EditOrder.closeInlineAction}
							/>
						</div>
					) : null}
				</div>
			</form>

			<div style={EditOrder.isPeriodizing ? { display: 'none' } : {}} className="sidebar-bg"></div>

			{/* FULL MODAL PROMPT */}
			{EditOrder.promptRemoval ? (
				<div className="fullmodal-container">
					<div className="fullscreen-content up-modal-content">
						<AlertBody
							fullModalBody={true}
							entity={EditOrder.stageType === 'order' ? 'order' : 'opportunity'}
							numSelected={1}
						/>
						<div className="fullmodal-control">
							<button
								className="btn up-btn btn-lg btn-red btn-block"
								onClick={() => EditOrder.deleteOrder()}
								disabled={EditOrder.saving}
							>
								{t('default.delete')}
							</button>
							<button
								className="btn up-btn btn-link btn-grey btn-block"
								onClick={() => EditOrder.promptRemoveEntry()}
							>
								{t('cancel')}
							</button>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default EditOrder;
