import React, { useState, useEffect, useMemo, useRef } from 'react';
import { DrawerHeader, Input, Label, Block, Text, Toggle, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import TodoTimePicker from 'Components/Inputs/TodoTimePicker';
import PlanPhonecallsDrawerCallListSelect from 'Components/PlanPhonecallsDrawer/PlanPhonecallsDrawerCallListSelect';
import './CreateCall.scss';
import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import BS from 'Services/BrowserService';
import ClientShape from 'App/babel/propTypes/Client';
import ContactShape from 'App/babel/propTypes/Contact';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import _ from 'lodash';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import Contact from 'App/resources/Model/Contact';
import Appointment from 'App/resources/Model/Appointment';
import Order from 'App/resources/Model/Order';
import moment from 'moment';
import Todo from 'App/resources/Model/Todo';
import Activity, { ActivityOnlyRequiredForSave } from 'App/resources/Model/Activity';
import Client from 'App/resources/Model/Client';
import Project from 'App/resources/Model/Project';
import RelationSelect from '../../../components/RelationSelect';
import UserSelect from 'Components/UserSelect';
import { AllIWantData } from 'App/resources/AllIWant';
import User, { BasicUserWithPermissions } from 'App/resources/Model/User';
import ClientContactFollowupSelect from 'App/components/Selectors/ClientContactFollowupSelect';
import { TYPES } from 'Components/Helpers/SourceHelper';
import { ProjectSelect } from 'App/components/EditClient/EditClientSelects';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import LZString from 'lz-string';
import openModal from 'App/services/Modal';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import Ticket from 'App/resources/Model/Ticket';
import Agreement from 'App/resources/Model/Agreement';
import history from 'App/pages/routes/history';
import { useFeatureAvailable } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';

export type Props = ModalProps & {
	client?: (Partial<Client> & Pick<Client, 'id' | 'name'>) | null;
	contact?: (Partial<Contact> & Pick<Contact, 'id' | 'name'>) | null;
	onSave?: (activity: Activity) => Promise<any> | void;
	initialDescription?: string;
	notes?: string;
	user?: BasicUserWithPermissions;
	appointment?: Appointment;
	opportunity?: PartialPick<Order, 'id' | 'description'> | null;
	activity?: Todo | Activity;
	ticket?: Ticket;
	agreement?: Agreement;
	phoneCallId?: number;
	callListId?: number;
	project?: Project;
	source?: { id: number | undefined; type: (typeof TYPES)[keyof typeof TYPES] };
	date?: Date;
	time?: string;
	shouldClose?: boolean;
	disableSaveAndCreate?: boolean;
	projectPlan?: (Partial<ProjectPlan> & Pick<ProjectPlan, 'id' | 'name'>) | null;
};

const getMappedCustom = (): CustomFieldWithValue[] => {
	const customFields = Tools.AppService.getCustomFields('activity').filter(field => field.visible);
	return customFields.map(field => ({ ...field, fieldId: field.id, value: null }));
};

const renderCustomFields = (fields: CustomFieldWithValue[], onCustomChange: (id: number, value: string) => void) => {
	const CustomFieldInput = ReactTemplates.INPUTS.customFieldInput;
	return _.chain(fields)
		.map(function (field, index) {
			if (field.$hasAccess && (field.visible || field.editable)) {
				return (
					<Block space="mbxl" key={`cfw-${index}`}>
						<Label required={!!field.obligatoryField}>{field.name}</Label>
						<CustomFieldInput
							key={'cf-' + index}
							field={field}
							name={field.name}
							disabled={!field.editable}
							multiple={false}
							className={'form-control up-input'}
							onChange={onCustomChange.bind(null, field.id)}
							usenewdate
							useNewTime
							useNumberInput
						/>
					</Block>
				);
			} else {
				return null;
			}
		})
		.sortBy('sortId')
		.value();
};

const getHash = (saveObj: ActivityOnlyRequiredForSave) => {
	const custom = (saveObj.custom ?? [])
		.filter(({ value }) => value !== null)
		.sort((a, b) => a.fieldId - b.fieldId)
		.map(({ fieldId, value }) => `${fieldId}:${value}`);
	const compareObj = {
		description: saveObj.description,
		client: saveObj.client,
		contacts: saveObj.contacts,
		opportunity: saveObj.opportunity,
		users: saveObj.users,
		date: saveObj.date,
		notes: saveObj.notes,
		priority: saveObj.priority,
		outcome: saveObj.outcome,
		project: saveObj.project,
		custom
	};
	return LZString.compressToBase64(JSON.stringify(compareObj));
};

const getClient = async (id: number): Promise<Contact['client']> => {
	const res = await Tools.Account.customer(Tools.AppService.getCustomerId()).get(id);
	return {
		id: res.data.id,
		journeyStep: res.data.journeyStep,
		name: res.data.name,
		phone: res.data.phone,
		users: res.data.users,
		active: 1,
		createRights: res.data.createRights,
		operationalAccount: res.data.operationalAccount,
		priceListId: res.data.priceListId,
		addresses: res.data.addresses
	};
};

const CreateCall = (props: Props) => {
	const {
		close,
		className,
		modalId,
		client: initialClient = null,
		contact: initialContact = null,
		opportunity: initialOpportunity,
		appointment: initialAppointment,
		user: initialUser,
		phoneCallId,
		activity: initialActivity,
		ticket: initialticket,
		agreement: initialAgreement,
		onSave = () => {},
		initialDescription,
		source,
		callListId: initialCallListId = null,
		project: initialProject = null,
		date: initialDate = new Date(),
		time: initialTime = '',
		shouldClose: initialShouldClose = false,
		disableSaveAndCreate,
		projectPlan: initialProjectPlan = null
	} = props;
	const classes = new BemClass('CreateCall', className);
	const [description, setDescription] = useState(initialDescription || '');
	const [priority, setPriority] = useState<Activity['priority']>(0);
	const [notes, setNotes] = useState(props.notes || '');
	const [date, setDate] = useState<Date | null>(initialDate);
	const [time, setTime] = useState(initialTime);
	const [callListId, setCallListId] = useState(initialCallListId);
	const [project, setProject] = useState(initialProject);
	const [contact, setContact] = useState(initialContact);
	const [saving, setSaving] = useState(false);
	const [client, setClient] = useState<typeof initialClient | null | undefined>(
		initialContact?.client || initialAppointment?.client || initialClient
	);
	const [user, setUser] = useState<User | AllIWantData['customerSelf'] | BasicUserWithPermissions>(
		initialUser ?? Tools.AppService.getSelf()
	);
	const [opportunityId, setOpportunityId] = useState<Order['id'] | null | undefined>(initialOpportunity?.id);
	const [appointmentId, setAppointmentId] = useState<Appointment['id'] | null | undefined>(initialAppointment?.id);
	const [activityId, setActivityId] = useState<Todo['id'] | Activity['id'] | null | undefined>(initialActivity?.id);
	const [projectPlanId, setProjectPlanId] = useState<ProjectPlan['id'] | null | undefined>(initialProjectPlan?.id);
	const [ticketId, setTicketId] = useState<Ticket['id'] | null | undefined>(initialticket?.id);
	const [agreementId, setAgreementId] = useState<Agreement['id'] | null | undefined>(initialAgreement?.id);
	const [customFields, setCustomFields] = useState<CustomFieldWithValue[]>([]);
	const [shouldClose, setShouldClose] = useState(initialShouldClose);
	const [reached, setReached] = useState(true);
	const inputRef = useRef<HTMLInputElement>(null);
	const mainRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const clientContactSelectInputRef = useRef<HTMLInputElement>();
	const contactSelectInputRef = useRef<HTMLInputElement>();
	const hasCallLists = Tools.FeatureHelper.hasSoftDeployAccess('CALL_LISTS');
	const hasGroupBonanza = Tools.FeatureHelper.hasSoftDeployAccess('GROUP_BONANZA');
	const hasCompanyGroupCardFeature = useFeatureAvailable(Feature.COMPANY_GROUP_CARD) && hasGroupBonanza;

	const standardFields = useMemo(() => Tools.AppService.getMetadata().standardFields.Activity, []);
	const [initialHash, setInitialHash] = useState<string>('');
	const navigateToGroup = useRef<Client['prospectingId'] | null>(null);

	const getSaveObj: () => ActivityOnlyRequiredForSave = () => {
		let fixedDate = null;
		if (date) {
			if (time) {
				fixedDate = new Date(date);
				const timeObject = moment(time, 'LT');
				const hours = timeObject.hour();
				const minutes = timeObject.minutes();
				fixedDate.setHours(hours, minutes, 0, 0);
			} else {
				fixedDate = moment(date).format('YYYY-MM-DD');
			}
		}

		return {
			description,
			contacts: contact ? [{ id: contact.id }] : [],
			client: client ? { id: client.id } : null,
			users: [user ? { id: user.id } : { id: Tools.AppService.getSelf().id }],
			activityType: { id: Tools.AppService.getTodoTypes().PHONE_CALL.id },
			date: fixedDate,
			opportunity: opportunityId ? { id: opportunityId } : null,
			parentAppointmentId: appointmentId,
			parentActivityId: activityId,
			ticketId: ticketId,
			agreementId: agreementId,
			custom: customFields,
			priority,
			notes,
			...(source ? { sourceType: source.type, sourceId: source.id } : {}),
			closeDate: shouldClose ? (fixedDate ? new Date(fixedDate) : new Date()) : null,
			callListId,
			project: project,
			phoneCallId: phoneCallId || null,
			outcome: shouldClose ? { type: reached ? 'reached' : 'not_reached', outcome: 'stop_trying' } : undefined,
			projectPlan: projectPlanId ? { id: projectPlanId } : null
		} as ActivityOnlyRequiredForSave;
	};

	const currentHash = useRef<string>(initialHash);

	useEffect(() => {
		currentHash.current = getHash(getSaveObj());
	}, [description, client, contact, date, time, priority, notes, project, customFields]);

	useEffect(() => {
		const getClientFn = async (id: number) => {
			setClient(await getClient(id));
			setInitialHash(getHash(getSaveObj()));
		};
		if (!initialClient && initialContact?.client) {
			getClientFn(initialContact.client.id);
		} else if (!initialClient && initialAppointment?.client) {
			getClientFn(initialAppointment.client.id);
		} else {
			setInitialHash(getHash(getSaveObj()));
		}

		setCustomFields(getMappedCustom());
	}, []);

	useEffect(() => {
		if (client && contact) {
			setTimeout(() => {
				inputRef.current?.focus();
			}, 300);
		}
	}, [client, contact]);

	const needToFillRequiredCustom = useMemo(() => {
		return !!customFields.find(f => f.obligatoryField && !f.value);
	}, [customFields]);

	const needToFillRequiredStandardFields = useMemo(
		() =>
			(standardFields.Project.active && standardFields.Project.required && !project) ||
			(standardFields.Notes.required && !notes),
		[project, notes]
	);

	const onCustomChange = (id: number, value: string) => {
		const fields = [...customFields];
		const index = _.findIndex(customFields, { id: id });
		if (index !== -1) {
			fields[index].value = value;
			setCustomFields(fields);
		}
	};
	const clear = () => {
		setDescription('');
		setDate(initialDate);
		setTime('');
		setOpportunityId(null);
		setAppointmentId(null);
		navigateToGroup.current = null;
		setActivityId(null);
		setProjectPlanId(null);
		setTicketId(null);
		setAgreementId(null);
		setPriority(0);
		setNotes('');
		setContact(initialContact);
		setClient(initialContact ? initialContact.client : initialClient);
		setCustomFields(
			customFields.map(c => {
				c.value = null;
				return c;
			})
		);
	};

	const save = (closeOnSave: boolean) => {
		if (!description.replace(/\n|\r/, '').trim().length || !client) {
			return;
		}
		setSaving(true);
		let fixedDate = null;
		if (date) {
			if (time) {
				fixedDate = new Date(date);
				const timeObject = moment(time, 'LT');
				const hours = timeObject.hour();
				const minutes = timeObject.minutes();
				fixedDate.setHours(hours, minutes, 0, 0);
			} else {
				fixedDate = moment(date).format('YYYY-MM-DD');
			}
		}

		return Tools.Activity.customer(Tools.AppService.getCustomerId())
			.save(getSaveObj())
			.then(async res => {
				await onSave(res.data);
				if (closeOnSave) {
					close(undefined, true);
				} else if (initialClient) {
					contactSelectInputRef.current?.focus();
				} else if (!initialContact) {
					clientContactSelectInputRef.current?.focus();
				}
			})
			.finally(() => setSaving(false))
			.catch(err => logError(err, 'Failed to create activity'));
	};
	const saveAndClose = () => save(true);
	const scrollToTop = () => {
		if (mainRef.current) {
			mainRef.current.scrollTop = 0;
		}
	};

	useModalClose(
		modalId,
		e => {
			if (initialHash !== currentHash.current) {
				e.preventDefault();

				openModal('UnsavedChangesAlert', {
					confirmButtonText: T('default.goBack'),
					onClose: async (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							navigateToGroup.current = null;
							return;
						}

						if (navigateToGroup.current) {
							history.push(`/companyGroup/${navigateToGroup.current}/`);
						}

						close(undefined, true);
					}
				});
			} else {
				if (navigateToGroup.current) {
					history.push(`/companyGroup/${navigateToGroup.current}/`);
				}
			}
		},
		[currentHash.current, navigateToGroup.current]
	);

	const canSave = !!description && !!client && !needToFillRequiredCustom && !needToFillRequiredStandardFields;
	const selfId = Tools.AppService.getSelf().id;

	return (
		<div ref={mainRef} className={classes.b()}>
			<DrawerHeader onHide={close} icon="phone" title={T('todo.planACall')} />
			<Block
				ref={contentRef}
				space="ptxl pbxl plxl prxl"
				className={classes.elem('contentWrapper').mod({ companyGroup: hasCompanyGroupCardFeature }).b()}
			>
				<UserSelect
					user={user}
					onChange={setUser}
					preText={user.id === selfId ? T('todo.whoAreXCallingPre') : T('todo.whoAreXCallingPreIs')}
					postText={T('todo.whoAreXCallingPost').toLowerCase()}
					required
				/>
				<ClientContactFollowupSelect
					clientInputRef={r => (clientContactSelectInputRef.current = r)}
					contactInputRef={r => (contactSelectInputRef.current = r)}
					onChange={value => {
						if (value.client?.id !== client?.id) {
							setAppointmentId(null);
							setOpportunityId(null);
							setActivityId(null);
							setProjectPlanId(null);
							setTicketId(null);
							setAgreementId(null);
							navigateToGroup.current = null;
						}
						setClient(value.client);
						setContact(value.contact);
					}}
					contact={contact}
					client={client}
					saving={saving}
					showCustomersInGroupWarning={true}
					autofocus
					close={(prospectingId: Client['prospectingId']) => {
						navigateToGroup.current = prospectingId;
						close();
					}}
				/>
				<RelationSelect
					label={T('todo.editPhonecall.isThisRelated')}
					onInputBlur={() => {
						setTimeout(() => inputRef.current?.focus(), 300);
					}}
					onChange={v => {
						setAppointmentId(v.appointmentId);
						setOpportunityId(v.opportunityId);
						setActivityId(v.activityId);
						setProjectPlanId(v.projectPlanId);
						setTicketId(v.ticketId);
						setAgreementId(v.agreementId);
						navigateToGroup.current = null;
					}}
					client={client}
					contact={contact || null}
					userId={user.id}
					appointmentId={appointmentId}
					opportunityId={opportunityId}
					activityId={activityId}
					projectPlanId={projectPlanId}
					ticketId={ticketId}
					agreementId={agreementId}
					disabled={saving}
				/>
				<Block space="mbxl">
					<Label required={true} maxLength={100} value={description}>
						{T('todo.editTodo.descriptionLabel')}
					</Label>
					<Input
						value={description}
						maxLength={100}
						onKeyDown={e => {
							if (BS.isEnter(e)) {
								e.preventDefault();
								saveAndClose();
							}
						}}
						onChange={e => setDescription(e.target.value)}
						placeholder={T('todo.callDescription')}
						inputRef={inputRef}
						disabled={saving}
					/>
				</Block>
				<Block onClick={() => setPriority(priority ? 0 : 3)} className={classes.elem('toggleLabel').b()}>
					<Toggle icon="flag" color="red" space="mrm" size="lg" checked={!!priority} disabled={saving} />
					<Text>{T('todo.prioritizeCall')}</Text>
				</Block>

				<TodoTimePicker
					onDateChange={e => setDate(e.target.value)}
					onTimeChange={e => setTime(e.target.value)}
					setShouldClose={setShouldClose}
					shouldClose={shouldClose}
					onClear={() => {
						setDate(null);
						setTime('');
					}}
					date={date}
					time={time}
					type={'phonecall'}
					withToggle={true}
					disabled={saving}
					setDueDateToday
				/>
				{shouldClose ? (
					<Block onClick={() => setReached(!reached)} className={classes.elem('toggleLabel').b()}>
						<Toggle icon="phone-success" space="mrm" size="lg" checked={!!reached} disabled={saving} />
						<Text>{T('todo.reachedContact')}</Text>
					</Block>
				) : null}
				{hasCallLists ? (
					<PlanPhonecallsDrawerCallListSelect
						withToggle={true}
						value={callListId}
						onChange={setCallListId}
						addSingleCall={true}
						anchor={contentRef.current}
					/>
				) : null}
				<NotesWithSignature
					onChange={setNotes}
					value={notes}
					space="mtl mbxl"
					required={standardFields.Notes.required}
					tooltipText={standardFields.Notes.tooltip || undefined}
				/>
				{standardFields.Project.active ? (
					<Block space="mbxl">
						<Label required={standardFields.Project.required}>{T('default.project')}</Label>
						<ProjectSelect
							className="form-control"
							value={project}
							onChange={event => setProject(event.target.added ?? null)}
							required={standardFields.Project.required}
						/>
					</Block>
				) : null}
				{customFields?.length ? renderCustomFields(customFields, onCustomChange) : null}
			</Block>
			<Block
				space="ptl pbl pll prl"
				backgroundColor="white"
				className={classes.elem('buttonWrapper').mod({ canSave, cannotSave: !canSave }).b()}
			>
				<Tooltip
					disabled={!(shouldClose && canSave)}
					title={reached ? T('todo.saveAndClose.reached') : T('todo.saveAndClose.notReached')}
				>
					<PrimaryButton disabled={!canSave} size="lg" loading={saving} onClick={() => saveAndClose()}>
						{T(shouldClose ? 'todo.saveAndClose' : 'todo.create')}
					</PrimaryButton>
				</Tooltip>
				{disableSaveAndCreate ? null : (
					<Tooltip
						position="bottom"
						disabled={!shouldClose}
						title={
							reached
								? T('todo.saveAndCreate.tooltip.reached')
								: T('todo.saveAndCreate.tooltip.notReached')
						}
					>
						<DefaultButton
							disabled={!canSave || saving}
							size="lg"
							onClick={() => {
								save(false);
								clear();
								scrollToTop();
							}}
						>
							{T('todo.saveAndCreate')}
						</DefaultButton>
					</Tooltip>
				)}
			</Block>
		</div>
	);
};
CreateCall.propTypes = {
	className: PropTypes.string,
	close: PropTypes.func.isRequired,
	client: ClientShape,
	contact: ContactShape,
	onSave: PropTypes.func,
	initialDescription: PropTypes.string,
	notes: PropTypes.string,
	appointment: PropTypes.object,
	opportunity: PropTypes.object,
	phoneCallId: PropTypes.number,
	date: PropTypes.instanceOf(Date),
	time: PropTypes.string,
	shouldClose: PropTypes.bool
};

export default CreateCall;
