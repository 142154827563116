module.exports = {
	ENV: 'PROD',
	URL: '/api/',
	API: 'v2/',
	SOCKET: 'https://power.upsales.com',
	PUBLICPATH: 'https://img.upsales.com/',
	FORMPATH: 'http://resource.upsales.com/forms/',
	LANDINGPAGEPATH: 'https://pages.upsales.com',
	CLASSIC_URL: 'https://crm.upsales.com/professional/application/',
	STATIC_MAPS_KEY: 'AIzaSyBz8DCi-zXpKspt_-LTIlhu-0vbLYMTNOA',
	GOOGLE_FONTS_KEY: 'AIzaSyDRwD9bJV8XncXBSWG9SQxM0sHHPso5rbw',
	STATUSPAGE: {
		pageId: 'xgxqnwgpzcyz',
		key: '91c46437-14a8-4b0b-b7f8-8e3df6ded05f',
		componentId: '1gr032v9zz1j'
	},
	PUSHER: {
		key: '0dae677b9a6736e36932',
		cluster: 'mt1'
	},
	SENTRY: {
		enable: true,
		env: 'production',
		dsn: 'https://47d0330c417c4f02b0da97429871724c@sentry.upsales.com/3',
		allowUrls: [/https?:\/\/((static|power)\.)?upsales\.com/],
		denyUrls: [/https?:\/\/static.upsales.com\/ckeditor\/.*\/ckeditor.js/]
	},
	CHECKIN_URL: 'https://checkin.upsales.com/',
	CUSTOM_DOMAIN_CNAME: 'domain.upsales.com',
	STATIC_URL: 'https://static.upsales.com/**',
	SPLIT_KEY: 'ddq3h6eiumsifs3ds5m0gnt9u4lj16r7s1aq',
	chatEnabled: true,
	GOOGLE_MAPS_KEY: 'AIzaSyBz8DCi-zXpKspt_-LTIlhu-0vbLYMTNOA'
};
