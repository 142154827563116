import store from 'Store/index';

export function topPortfolioAllowed() {
	return (
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.TOP_CUSTOMER_PORTFOLIO) &&
		Tools.FeatureHelper.hasSoftDeployAccess('TOP_CUSTOMER_PORTFOLIO')
	);
}

export function potentialTabAllowed() {
	const hasCustomerPortfolioPotential = Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO_POTENTIAL');
	const hasFullReportAccess = store.getState().App.hasFullReportAccess ?? false;

	return hasCustomerPortfolioPotential && hasFullReportAccess;
}

export default function allowed() {
	return Tools.FeatureHelper.hasSoftDeployAccess('CUSTOMER_PORTFOLIO');
}
