import { Feature, getFeatureAvailableFromState } from 'App/components/hooks/featureHelper';
import { RootState } from 'Store/index';

export const hasSoliditetMatcher = (self: RootState['App']['self'], accountSelf: RootState['App']['accountSelf']) => {
	const isAdmin = self?.administrator;
	const hasMatcherFromProspecting = getFeatureAvailableFromState(accountSelf, Feature.PROSPECTING_BASIC);
	const hasMatcherFromSoliditet = self?.userParams?.soliditetIsActive;
	const hasMultiSoliditet = self?.userParams.multiSoliditetAccess;
	const hasSoliditetMatcher = getFeatureAvailableFromState(accountSelf, Feature.SOLIDITET_MATCHER);

	return (
		(hasMatcherFromSoliditet || hasMatcherFromProspecting) && (hasMultiSoliditet || isAdmin) && hasSoliditetMatcher
	);
};
