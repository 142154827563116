const tryGoToPage = async (page: string, opts = {}) => {
	Tools.$state.go(page, opts);
};

const transformStartPage = (page: string, startPageStates: any) => {
	return startPageStates.hasOwnProperty(page) ? startPageStates[page as keyof typeof startPageStates] : page;
};

export const goToStartPage = () => {
	if (Tools.AppService.getMetadata().onboarding?.length) {
		Tools.$state.go('onboarding', { openFullScreen: true });
	} else {
		/*
			Has two-way state conversions between angular and react views just to be safe in case of releasing/unreleasing flags. 
			The start page should be set correctly in admin/profile.jsx as long as they save it. 
			When releasing a new port, we should probably update everyones startPage accordingly.
		*/
		const taskList = Tools.FeatureHelper.hasSoftDeployAccess('TASK_FILTERS')
			? 'react-root-task'
			: 'react-root-todo';

		const startPageStates = {
			todo: taskList,

			activities:
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					? taskList
					: 'activities',

			accounts: Tools.FeatureHelper.hasSoftDeployAccess('LIST_COMPANIES_REACT')
				? 'react-root-companies'
				: 'accounts',
			'react-root-companies': Tools.FeatureHelper.hasSoftDeployAccess('LIST_COMPANIES_REACT')
				? 'react-root-companies'
				: 'accounts',

			reports: 'react-root-reportcenter',

			analytics: 'analytics',

			looker: 'looker',

			groupMail: 'react-root-mail-campaigns',

			socialEvents: 'react-root-events',

			landingPages: 'react-root-landingpages',
			'react-root-landingpages': 'react-root-landingpages',

			advertising: !Tools.FeatureHelper.hasSoftDeployAccess('HIDE_ADVERTISING') ? 'listAds' : null,

			formSubmits: 'react-root-formSubmits',

			esign: 'esign',

			mailTemplates: Tools.FeatureHelper.hasSoftDeployAccess('MAIL_TEMPLATES_V2')
				? 'react-root-mailtemplates'
				: 'listMailTemplates',
			'react-root-mailtemplates': Tools.FeatureHelper.hasSoftDeployAccess('MAIL_TEMPLATES_V2')
				? 'react-root-mailtemplates'
				: 'listMailTemplates',

			contacts:
				(Tools.FeatureHelper.hasSoftDeployAccess('LIST_CONTACTS_REACT') ? 'react-root-' : '') + 'contacts',
			'react-root-contacts':
				(Tools.FeatureHelper.hasSoftDeployAccess('LIST_CONTACTS_REACT') ? 'react-root-' : '') + 'contacts',

			orders: 'react-root-orders',
			'react-root-orders': 'react-root-orders',

			salesboard: 'react-root-salesboard',
			'react-root-salesboard': 'react-root-salesboard',

			portfolio: 'react-root-portfolio',
			'react-root-portfolio': 'react-root-portfolio',

			listMail: 'react-root-mail',
			'react-root-mail': 'react-root-mail',

			agreements:
				(Tools.FeatureHelper.hasSoftDeployAccess('NEW_AGREEMENT_LIST') ? 'react-root-' : '') + 'agreements',
			'react-root-agreements':
				(Tools.FeatureHelper.hasSoftDeployAccess('NEW_AGREEMENT_LIST') ? 'react-root-' : '') + 'agreements',

			leads2: 'react-root-leads',
			'react-root-leads': 'react-root-leads',

			campaigns: 'react-root-campaigns',
			'react-root-campaigns': 'react-root-campaigns',

			listForms: 'react-root-forms',
			'react-root-forms': 'react-root-forms',

			visitors: 'react-root-visitors',
			'react-root-visitors': 'react-root-visitors',

			support: 'react-root-support',

			projectBoard: 'react-root-projectboard'
		};
		const self = Tools.AppService.getSelf();
		const accountSelf = Tools.AppService.getAccountSelf();
		const isSupportAndNotCRM = self.support && !self.crm;
		const isServiceAndNotCRM = self.service && !self.crm;
		const fallbackPages = [
			isSupportAndNotCRM
				? transformStartPage('support', startPageStates)
				: isServiceAndNotCRM
				? transformStartPage('react-root-projectBoard', startPageStates)
				: accountSelf?.products.crm
				? transformStartPage('activities', startPageStates)
				: transformStartPage('leads2', startPageStates),
			'react-root-salesboard'
		];

		let savedStartPage = self.userParams.startPage || fallbackPages[0];

		const validSupportPages = ['react-root-support', 'react-root-companies', 'react-root-contacts'];
		if (isSupportAndNotCRM && !validSupportPages.includes(savedStartPage)) {
			// Quick and ugly fix, this whole thing is a mess (fallback doesn't work as there is no error to catch from tryGoToPage)
			savedStartPage = validSupportPages[0];
		}

		const transformedStartPage = transformStartPage(savedStartPage, startPageStates);

		tryGoToPage(transformedStartPage)
			.catch(() => tryGoToPage(fallbackPages[0]))
			.catch(() => tryGoToPage(fallbackPages[1]));
	}
};
