import { numberFormat } from 'App/babel/components/Filters/Currencies';
import { StatusCategory } from 'App/services/prospectingService';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import Prospecting from 'App/babel/resources/Prospecting';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';

type Filter = { value: string[]; comparisonType: keyof typeof comparisonTypes; inactive: boolean };
type RawFilter = { v: string[]; c: keyof typeof comparisonTypes; i: boolean };

function formatName(name: string) {
	const keywordMap = new Map([
		['SA', 'SA'],
		['(SA)', '(SA)'],
		['BV', 'BV'],
		['(BV)', '(BV)'],
		['AG', 'AG'],
		['GMBH', 'GmbH'],
		['NV', 'NV'],
		['(NV)', '(NV)'],
		['(D.O.O.)', '(D.O.O.)'],
		['(SP. Z O.O.)', '(SP. Z O.O.)'],
		['(LLC)', '(LLC)'],
		['NETHERLANDS', 'Netherlands'],
		['DUTCH', 'Dutch'],
		['BELGIAN', 'Belgian'],
		['FRENCH', 'French'],
		['LUXEMBOURG', 'Luxembourg'],
		['POLISH', 'Polish'],
		['ROMANIAN', 'Romanian'],
		['SWISS', 'Swiss'],
		['SPANISH', 'Spanish'],
		['POLISH', 'Polish']
	]);

	const parts = name.split(' ');
	const fixedParts = parts.map(part => keywordMap.get(part) ?? part.toLowerCase());
	const merged = fixedParts.join(' ');
	return merged.substring(0, 1).toUpperCase() + merged.substring(1);
}

function generateFilter(countryCode: string, field: string) {
	function generate(): Filter {
		return {
			inactive: true,
			comparisonType: 'Equals',
			value: []
		};
	}

	function isInactive(filter: Filter) {
		return filter.value.length ? false : true;
	}

	function toUrl(filter: Filter) {
		return { v: filter.value, i: filter.inactive, c: filter.comparisonType };
	}

	function fromUrl(rawFilter: RawFilter) {
		const filter = generate();

		if (rawFilter.v) {
			filter.value = rawFilter.v;
		}
		if (rawFilter.c) {
			filter.comparisonType = rawFilter.c;
		}

		filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);

		return filter;
	}

	function build(filter: Filter, rb: RequestBuilder) {
		const value = filter.value;

		if (filter.inactive || !value || !value.length) {
			return;
		}

		rb.addFilter({ field }, rb.comparisonTypes[filter.comparisonType], value);
	}

	async function dataPromise() {
		const RequestBuilder = getAngularModule('RequestBuilder');

		// @ts-ignore
		const rb = new RequestBuilder();

		rb.addFilter({ field }, rb.comparisonTypes.NotEquals, null);
		rb.addFilter({ field: 'statusCategory' }, rb.comparisonTypes.Equals, StatusCategory.Active);
		rb.extraParams.push({ key: 'country', value: countryCode });
		rb.extraParams.push({ key: 'mincount', value: 100 });
		rb.limit = 1000;

		const { data, metadata } = await Prospecting.lookup(field, rb.build());
		const mappedData = data
			.map(({ count, [field]: key }: any) => ({ count, id: key, name: formatName(key) }))
			.sort(({ count: a }: { count: number }, { count: b }: { count: number }) => (a > b ? -1 : a < b ? 1 : 0));

		return { data: mappedData, metadata };
	}

	function displayText(item: { name: string; count: number }, isSelection?: boolean) {
		if (isSelection) {
			return item.name;
		}

		return `
			<div>
				${item.name}
				<div class="subtitle">
					${T('default.noOfCompanies')}: ${numberFormat(item.count)}
				</div>
			<div>
		`;
	}

	const filter = {
		filterName: 'CompanyTypeLookup',
		type: 'raw',
		displayType: 'list',
		title: 'soliditet.corporateForm',
		entity: 'account',
		showOnSegment: true,
		generate,
		toUrl,
		fromUrl,
		isInactive,
		build,
		dataPromise,
		displayText
	};

	return filter;
}

export default generateFilter;
