import Resource from 'Resources/ResourceTyped';
import ProjectPlanStatus from './Model/ProjectPlanStatus';
import { MergeData } from './ProjectPlanType';
export type ProjectPlanStatusSave = Partial<ProjectPlanStatus> & Pick<ProjectPlanStatus, 'name'>;

class ProjectPlanStatusResource extends Resource<ProjectPlanStatus, ProjectPlanStatus, ProjectPlanStatusSave> {
	eventName = 'projectPlanStatus';
	constructor() {
		super('ProjectPlanStatus');
	}

	async merge(data: MergeData) {
		return this._postRequest('merge', data);
	}
}

const resource = new ProjectPlanStatusResource();
export default resource;
