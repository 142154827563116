import { Table, TableHeader, TableRow, Icon } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState } from 'react';
import { OnDragEndResponder, DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LockedTableCell from './LockedTableCell';

export type Props = {
	columns: { key: string; title: string; render: (value: any) => React.ReactNode }[];
	rows: { [key: string]: any }[];
	className?: string;
	droppableId?: string;
	onDragEnd: OnDragEndResponder;
};

const DragDropTable = ({ className, columns, rows, droppableId, onDragEnd }: Props) => {
	const classes = new BemClass('DragDropTable', className);
	const [isDragging, setIsDragging] = useState(false);

	const onBeforeDragStart = () => {
		setIsDragging(true);
	};

	return (
		<DragDropContext
			onBeforeDragStart={onBeforeDragStart}
			onDragEnd={(result, provided) => {
				onDragEnd(result, provided);
				setIsDragging(false);
			}}
		>
			<Droppable droppableId={droppableId ?? 'DragDropTable'}>
				{provided => (
					<Table innerRef={provided.innerRef} {...provided.droppableProps} className={classes.b()}>
						<TableHeader columns={[{ title: '' }, ...columns.map(({ title }) => ({ title }))]} />

						{rows.map((row, rowIndex) => (
							<Draggable
								key={'row-' + rowIndex}
								draggableId={rowIndex.toString()}
								index={rowIndex}
								isDragDisabled={!row.active}
							>
								{(provided, snapshot) => {
									const style = {
										...provided.draggableProps.style
									};
									return (
										<TableRow
											innerRef={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											disabled={!row.active}
											style={style}
										>
											<LockedTableCell
												isDragOccurring={isDragging}
												isBeingDragged={snapshot.isDragging}
											>
												<Icon name="bars" color={row.active ? 'black' : 'grey-8'} />
											</LockedTableCell>
											{columns.map((column, colIndex) => (
												<LockedTableCell
													key={'column-' + colIndex}
													isDragOccurring={isDragging}
													isBeingDragged={snapshot.isDragging}
												>
													{column.render(row[column.key])}
												</LockedTableCell>
											))}
										</TableRow>
									);
								}}
							</Draggable>
						))}
						{provided.placeholder}
					</Table>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default DragDropTable;
