import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import Mail from 'App/resources/Model/Mail';
import { ClientActivityType } from 'App/resources/Model/Client';
import Activity from 'App/resources/Model/Activity';
import moment from 'moment';

export const openActivitiesFilter = (query: RequestBuilder) => {
	const orBuilder = query.orBuilder();

	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'time' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'date' }, ComparisonTypes.GreaterThanEquals, moment().format('YYYY-MM-DD'));

	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'time' }, ComparisonTypes.NotEquals, null);
	orBuilder.addFilter({ field: 'date' }, ComparisonTypes.GreaterThan, moment().format());

	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
	orBuilder.addFilter({ field: 'endDate' }, ComparisonTypes.GreaterThan, moment().format());
	orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.Equals, 'planned');

	//Delayed activities where closeDate is missing and date is 0000-00-00
	orBuilder.next();
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'date' }, ComparisonTypes.Equals, new Date('0000-00-00'));

	//Delayed activities where time is set (not appointments)
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'time' }, ComparisonTypes.NotEquals, null);
	orBuilder.addFilter({ field: 'date' }, ComparisonTypes.LessThan, moment().format());

	//Delayed activities where time is NOT set, only date (not appointments)
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'time' }, ComparisonTypes.Equals, null);
	orBuilder.addFilter({ field: 'date' }, ComparisonTypes.LessThan, moment().format('YYYY-MM-DD'));

	//Delayed appointments
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
	orBuilder.addFilter({ field: 'endDate' }, ComparisonTypes.LessThan, moment().format());
	orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.Equals, 'planned');

	orBuilder.done();
};

export const closedActivitiesFilter = (query: RequestBuilder) => {
	const orBuilder = query.orBuilder();

	orBuilder.next();
	orBuilder.addFilter({ field: 'isMap' }, ComparisonTypes.Equals, false);
	orBuilder.next();

	let groupBuilder = orBuilder.groupBuilder();
	groupBuilder.addFilter({ field: 'isMap' }, ComparisonTypes.Equals, true);
	groupBuilder.addFilter({ field: 'groupMailId' }, ComparisonTypes.Equals, null);
	groupBuilder.done();
	orBuilder.next();
	groupBuilder = orBuilder.groupBuilder();
	groupBuilder.addFilter({ field: 'isMap' }, ComparisonTypes.Equals, true);
	groupBuilder.addFilter({ field: 'groupMailId' }, ComparisonTypes.Equals, 0);
	groupBuilder.done();

	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.NotEquals, null);

	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
	orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.NotEquals, 'planned');

	orBuilder.done();
};

export const isMail = (activity: ClientActivityType | Activity | Mail): activity is Mail => {
	return (activity as Mail).type !== undefined && (activity as Mail).cc !== undefined;
};
