import { getCustomFieldModel, FieldModel } from '../FormObserver';
import T from 'Components/Helpers/translate';
import ValidationModel from '../FormObserver/ValidationModel';
import { StandardFieldConfig } from 'App/resources/AllIWant';
import CustomField from 'App/resources/Model/CustomField';

const fieldRequiredOnTypeMsg = (type: string, fieldStr: string) =>
	T('editClient.addressFieldIsRequiredOnAddressType', {
		field: fieldStr,
		type: T(`address.${type.toLowerCase()}`).toLocaleLowerCase()
	});

const getAddressValidationModel = (type: string, required: boolean) =>
	FieldModel.object(`address.${type.toLowerCase()}`, {
		zipcode: FieldModel.string('address.zip'),
		city: FieldModel.string('address.city'),
		municipality: FieldModel.string('address.municipality'),
		state: FieldModel.string('address.state'),
		address: FieldModel.string('address.address').required(
			required,
			fieldRequiredOnTypeMsg(type, T('address.address'))
		),
		type: FieldModel.string(''),
		country: FieldModel.string('address.country')
	}).required(required);

export default (
	requiredFields: { [key: string]: boolean },
	standardFields: { [key: string]: StandardFieldConfig },
	hasNewFields: boolean,
	customFields: CustomField[]
) => {
	const model: { [field: string]: ValidationModel } = {
		name: FieldModel.string('default.name').required().max(100),
		phone: FieldModel.string('default.phone').required(requiredFields.Phone),
		phoneCountryCode: FieldModel.string('default.phoneCountryCode'),
		webpage: FieldModel.url('default.webpage').required(requiredFields.Www).max(100),
		notes: FieldModel.string('default.notes').required(requiredFields.Notes).max(65_535),
		users: FieldModel.listOf('default.accountManager', FieldModel.keyedObject('', 'id')).required(
			requiredFields.User
		),
		parent: FieldModel.keyedObject('default.parentCompany', 'id').required(requiredFields.Parent).default(null),
		projects: FieldModel.listOf('default.campaigns', FieldModel.keyedObject('', 'id')).required(
			requiredFields.Project
		),
		categories: FieldModel.listOf('default.categories', FieldModel.keyedObject('', 'id')),
		journeyStep: FieldModel.string('default.journeyStep'),
		priceListId: FieldModel.number('priceListId'),
		addresses: FieldModel.object('default.addresses', {
			Mail: getAddressValidationModel('Mail', requiredFields.Adress),
			Visit: getAddressValidationModel('Visit', requiredFields.VisitAddress),
			Invoice: getAddressValidationModel('Invoice', requiredFields.InvoiceAddress),
			Delivery: getAddressValidationModel('Delivery', requiredFields.DeliveryAddress),
			Other: getAddressValidationModel('Other', requiredFields.OtherAddress)
		})
	};

	if (hasNewFields) {
		if (standardFields.Fax) {
			model.fax = FieldModel.string(standardFields.Fax.nameTag).required(requiredFields.Fax).max(30);
		}
		if (standardFields.Status) {
			model.status = FieldModel.string(standardFields.Status.nameTag).required(requiredFields.Status);
		}
		if (standardFields.OrgNo) {
			model.orgNo = FieldModel.string(standardFields.OrgNo.nameTag).required(requiredFields.OrgNo).max(32);
		}
		if (standardFields.RegistrationDate) {
			model.registrationDate = FieldModel.date(standardFields.RegistrationDate.nameTag).required(
				requiredFields.RegistrationDate
			);
		}
		if (standardFields.SniCode) {
			model.sniCode = FieldModel.string(standardFields.SniCode.nameTag).required(requiredFields.SniCode).max(10);
		}
		if (standardFields.CompanyForm) {
			model.companyForm = FieldModel.string(standardFields.CompanyForm.nameTag)
				.required(requiredFields.CompanyForm)
				.max(32);
		}
		if (standardFields.CreditRating) {
			model.creditRating = FieldModel.string(standardFields.CreditRating.nameTag).required(
				requiredFields.CreditRating
			);
		}
		if (standardFields.Turnover) {
			model.turnover = FieldModel.number(standardFields.Turnover.nameTag)
				.required(requiredFields.Turnover)
				.min(Number.MIN_SAFE_INTEGER)
				.max(Number.MAX_SAFE_INTEGER);
		}
		if (standardFields.Profit) {
			model.profit = FieldModel.number(standardFields.Profit.nameTag).required(requiredFields.Profit);
		}
		if (standardFields.NoEmployees) {
			model.noEmployees = FieldModel.number(standardFields.NoEmployees.nameTag)
				.required(requiredFields.NoEmployees)
				.min(0);
		}
		if (standardFields.Facebook) {
			model.facebook = FieldModel.string(standardFields.Facebook.nameTag)
				.required(requiredFields.Facebook)
				.max(256);
		}
		if (standardFields.Twitter) {
			model.twitter = FieldModel.string(standardFields.Twitter.nameTag).required(requiredFields.Twitter).max(256);
		}
		if (standardFields.LinkedIn) {
			model.linkedin = FieldModel.string(standardFields.LinkedIn.nameTag)
				.required(requiredFields.LinkedIn)
				.max(256);
		}
		if (standardFields.SicCode) {
			model.sicCode = FieldModel.string(standardFields.SicCode.nameTag).required(requiredFields.SicCode).max(6);
		}
		if (standardFields.NaicsCode) {
			model.naicsCode = FieldModel.string(standardFields.NaicsCode.nameTag)
				.required(requiredFields.NaicsCode)
				.max(6);
		}
		if (standardFields.UKSicCode) {
			model.ukSicCode = FieldModel.string(standardFields.UKSicCode.nameTag)
				.required(requiredFields.UKSicCode)
				.max(5);
		}
		if (standardFields.NaceCode) {
			model.naceCode = FieldModel.string(standardFields.NaceCode.nameTag)
				.required(requiredFields.NaceCode)
				.max(6);
		}
		if (standardFields.About) {
			model.about = FieldModel.string(standardFields.About.nameTag).required(requiredFields.About).max(65_535);
		}
		if (standardFields.Cfar) {
			model.cfar = FieldModel.string(standardFields.Cfar.nameTag).required(requiredFields.Cfar).max(32);
		}
	}

	if (customFields.length) {
		model.custom = getCustomFieldModel(customFields);
	}

	return model;
};
