import { ModalProps } from 'App/components/Modals/Modals';
import { EditTicketProvider } from './Context/Context';
import EditTicket from './EditTicket/EditTicket';
import type Ticket from 'App/resources/Model/Ticket';
import CreateTicket from './CreateTicket';
import React from 'react';

type Props = ModalProps & {
	ticketId?: number;
	client?: Ticket['client'];
	contact?: Ticket['contact'];
	description?: Ticket['description'];
	onSave?: (ticket: Ticket) => Promise<any> | void;
	isCreatedFromTicket?: boolean;
};

const OpenTicket = ({ ticketId, ...props }: Props) => {
	if (ticketId) {
		return <EditTicket {...props} ticketId={ticketId} />;
	}
	return <CreateTicket {...props} client={props.client!} onSave={props.onSave} />;
};

export default (props: Props) => (
	<EditTicketProvider initialState={props}>
		<OpenTicket {...props} />
	</EditTicketProvider>
);
