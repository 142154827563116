import Product from 'App/resources/Model/Product';
import ProjectPlan from 'App/resources/Model/ProjectPlan';

export const editProductLists = (
	productLists: ProjectPlan['productLists'],
	productListIndex: number,
	newName: string
) => {
	return productLists.map((productList, index) => {
		if (index === productListIndex) {
			return { ...productList, name: newName };
		}
		return productList;
	});
};

export const addNewProductToProductLists = (productLists: ProjectPlan['productLists'], productListIndex: number) => {
	return productLists.map((productList, index) => {
		if (index === productListIndex) {
			return { ...productList, products: [...productList.products, createNewProduct()] };
		}
		return productList;
	});
};

export const addSelectedProductToProductLists = (
	productLists: ProjectPlan['productLists'],
	newProduct: Product,
	productListIndex: number,
	productIndex: number
) => {
	const newProducts = productLists[productListIndex].products.map((product, index) => {
		if (index === productIndex) {
			return { ...product, product: newProduct };
		}
		return product;
	});
	return productLists.map((productList, index) => {
		if (index === productListIndex) {
			return {
				...productList,
				products: newProducts
			};
		}
		return productList;
	});
};

export const updateQuantityOnProductLists = (
	productLists: ProjectPlan['productLists'],
	quantity: number | undefined,
	productListIndex: number,
	productIndex: number
) => {
	const newProducts = productLists[productListIndex].products.map((product, index) => {
		if (index === productIndex) {
			return { ...product, quantity: quantity ?? 0 };
		}
		return product;
	});
	return productLists.map((productList, index) => {
		if (index === productListIndex) {
			return {
				...productList,
				products: newProducts
			};
		}
		return productList;
	});
};

export const removeProductFromProductLists = (
	productLists: ProjectPlan['productLists'],
	productListIndex: number,
	productIndex: number
) => {
	const newProducts = productLists[productListIndex].products.filter((_, index) => index !== productIndex);
	return productLists.map((productList, index) => {
		if (index === productListIndex) {
			return {
				...productList,
				products: newProducts
			};
		}
		return productList;
	});
};

export function createNewProduct() {
	return {
		quantity: 1,
		product: {
			id: 0
		} as Product
	};
}
