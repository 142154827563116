import Resource from 'Resources/ResourceTyped';
import { MergeData } from './ProjectPlanType';
import ProjectPlanPriority from './Model/ProjectPlanPriority';
export type ProjectPlanPrioritySave = Partial<ProjectPlanPriority> & Pick<ProjectPlanPriority, 'name'>;

class ProjectPlanPriorityResource extends Resource<ProjectPlanPriority, ProjectPlanPriority, ProjectPlanPrioritySave> {
	eventName = 'projectPlanPriority';
	constructor() {
		super('projectPlanPriority');
	}

	async merge(data: MergeData) {
		return this._postRequest('merge', data);
	}
}

const resource = new ProjectPlanPriorityResource();

export default resource;
