import React, { useMemo, useEffect } from 'react';
import { FullscreenModal, ModalContent, ModalHeader } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { ProjectBoardProvider, useProjectBoardContext } from 'App/pages/ProjectBoard/ProjectBoardContext';
import { createPin, PIN_TYPE } from 'App/components/Map/mapPins/MapPins';
import { ProjectPin } from 'App/components/Map/mapPins/MapPinTypes';
import ListViewFilters from 'App/components/ListView/ListViewFilters';
import Map from 'App/components/Map/Map';
import { type MapModalProps } from 'App/components/Map/MapModal';
import ProjectPlanFilters from 'App/babel/filterConfigs/ProjectPlan';
import useSelectedProjectBoardView from 'App/pages/ProjectBoard/useSelectedProjectBoardView';

import 'App/components/Map/MapModal.scss';
import './ProjectMapModal.scss';
import ProjectPlan, { ProjectPlanType } from 'App/resources/Model/ProjectPlan';
import ProjectMapQuickFilters from './ProjectMapQuickFilters';

type ProjectMapModalProps = Omit<MapModalProps<ProjectPlan>, 'pins'>;

const ProjectMapModal = ({ className, close, onPinSelect, ...rest }: ProjectMapModalProps) => {
	const classes = new BemClass('ProjectMapModal', `MapModal ${className}`);
	const { t } = useTranslation();
	const { state, setFilters, setFiltersVisible, setSelectedView } = useProjectBoardContext();
	const { filters, data, customFields, filterConfigs, selectedView } = state;

	const mapPins = useMemo(() => {
		const projects = Object.values(data)
			.map(column => column.data)
			.flat();
		return projects.map(project => createPin(project, PIN_TYPE.PROJECT_PLAN)).filter(Boolean) as ProjectPin[];
	}, [data]);

	useEffect(() => {
		if (selectedView) {
			setSelectedView(selectedView);
		}
	}, []);

	const onPinSelectOverride = onPinSelect
		? (location: any) => {
				onPinSelect?.(location);
				close();
		  }
		: undefined;

	return (
		<FullscreenModal headerAtTop className={classes.b()}>
			<ModalHeader title={t('default.findLocationOnMap')} onClose={close}>
				<ListViewFilters
					onVisibleChange={setFiltersVisible}
					filterConfigs={filterConfigs}
					activeFilters={filters}
					onChange={filters => setFilters(filters)}
					hasChanged={false} // TODO: This is always false for now, but will be used in a future PR
					hiddenFilters={['ProjectPlanStage']}
					listType="projectPlan"
					customFields={customFields}
				/>
			</ModalHeader>
			<ModalContent>
				<Map
					pins={mapPins}
					onPinSelect={onPinSelectOverride}
					renderQuickFilters={() => <ProjectMapQuickFilters />}
					{...rest}
				/>
			</ModalContent>
		</FullscreenModal>
	);
};

export default ({ type, ...rest }: MapModalProps<any> & { type: ProjectPlanType['category'] }) => {
	const filterConfigs = useMemo(() => ProjectPlanFilters(), []);
	const selectedView = useSelectedProjectBoardView({ selectedId: null, projectPlanTypeCategory: type });
	return (
		<ProjectBoardProvider initialState={{ filterConfigs, selectedView }}>
			<ProjectMapModal {...rest} />
		</ProjectBoardProvider>
	);
};
