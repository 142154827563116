import BemClass from '@upsales/components/Utils/bemClass';
import Client, { ClientAddress } from 'App/resources/Model/Client';
import React, { useMemo } from 'react';
import _ from 'lodash';

import './AddressField.scss';

const AddressTypes = ['Mail', 'Visit', 'Other', 'Invoice', 'Delivery'];
const AddressFields = ['city', 'municipality', 'address', 'country', 'state', 'zipcode'];

interface AddressFieldProps {
	column: string;
	client: Pick<Client, 'id' | 'addresses'>;
}

const AddressField: React.FC<AddressFieldProps> = ({ column = '', client }) => {
	const classes = new BemClass('AddressField');

	const [property, type, field] = column.split('_') || [];

	const isValidAddressType = useMemo(() => {
		return property && type && AddressTypes.includes(type) && field && AddressFields.includes(field);
	}, [column]);

	const address: ClientAddress | undefined = useMemo(() => {
		return client?.addresses.find(address => address.type === type);
	}, [client]);

	const showCountry = useMemo(() => {
		return property && type && AddressTypes.includes(type) && (!field || field === 'city');
	}, [column]);

	const text = useMemo(() => {
		if (isValidAddressType) {
			return (_.get(address, field) as string) || '';
		} else {
			const parts = [];
			if (address?.address?.length) {
				parts.push(address.address);
			}
			if (address?.city?.length) {
				parts.push(address.city);
			}
			return parts.join(', ');
		}
	}, [client, column]);

	return address ? (
		<div className={classes.b()}>
			{text && showCountry && address.country ? (
				<b className={'icon flag-icon flag-icon-' + address.country.toLowerCase()} />
			) : null}
			<ReactTemplates.TOOLS.accountHref
				customerId={client.id}
				id={client.id}
				text={text}
				sub="addresses"
				tools={Tools}
			/>
		</div>
	) : null;
};

export default AddressField;
