import React from 'react';
import { CardHeader, EllipsisTooltip, Flex, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import AdminCard from 'App/pages/Admin/AdminCard';
import { type ProjectPlanType } from 'App/resources/Model/ProjectPlan';
import { getTextColor } from 'Helpers/color';
import { useTranslation } from 'Components/Helpers/translate';
import './ProjectBoardPreview.scss';

type Props = {
	stages: ProjectPlanType['stages'];
	name: string;
};

const truncate = (str: string, length: number) => {
	if (str.length <= length) {
		return str;
	}
	return str.slice(0, length) + '...';
};

const ProjectBoardPreview = ({ stages, name }: Props) => {
	const classes = new BemClass('ProjectBoardPreview');
	const { t } = useTranslation();
	return (
		<AdminCard className={classes.elem('board-card').b()}>
			<CardHeader
				title={t('projectPlan.type.board.title', { name: truncate(name, 50) })}
				subtitle={t('projectPlan.type.board.subtitle', { name: truncate(name, 50) })}
			/>
			{stages.length ? (
				<Flex className={classes.elem('board-preview').b()} space="mll mrl mtl mbl pll prl ptl pbl" gap="u2">
					{stages.map(({ stage }) => (
						<Flex
							key={stage.id}
							className={classes.elem('preview-stage').b()}
							style={{ backgroundColor: stage.color }}
							space="ptl pll prl"
						>
							<EllipsisTooltip title={stage.name}>
								<Text
									size="sm"
									bold
									style={{
										color: stage.color ? getTextColor(stage.color) : undefined
									}}
								>
									{stage.name}
								</Text>
							</EllipsisTooltip>
						</Flex>
					))}
				</Flex>
			) : null}
		</AdminCard>
	);
};

export default ProjectBoardPreview;
