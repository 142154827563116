import { Address, StructuredAddress } from 'App/resources/Model/Geocode';
import { PinOnLocation } from './Map';

const addressBase = {
	address: '',
	city: '',
	country: '',
	state: '',
	zipcode: ''
};

type StateFinder = {
	state: google.maps.GeocoderAddressComponent | null;
	highestLevel: number;
};

const getStateFromAddressComponents = (components: google.maps.GeocoderAddressComponent[]) => {
	const { state } = components.reduce<StateFinder>(
		(acc, component) => {
			const administrativeAreaLevel = component.types.find(type => type.includes('administrative_area_level_'));
			if (!administrativeAreaLevel) {
				return acc;
			}
			const level = parseInt(administrativeAreaLevel.replace('administrative_area_level_', ''));
			if (level > acc.highestLevel) {
				acc.state = component;
				acc.highestLevel = level;
			}
			return acc;
		},
		{
			state: null,
			highestLevel: 0
		}
	);
	return state;
};

export const formatAddressComponents = (components: google.maps.GeocoderAddressComponent[]): Address => {
	// Reset addressBase to clear any previous data
	const formattedAddress = { ...addressBase };

	const streetNumber = components.find(component => component.types.includes('street_number'));
	const route = components.find(component => component.types.includes('route'));
	const naturalFeature = components.find(component => component.types.includes('natural_feature'));
	const establishment = components.find(component => component.types.includes('establishment'));

	const city = components.find(
		component => component.types.includes('locality') || component.types.includes('postal_town')
	);
	const state = getStateFromAddressComponents(components);
	const country = components.find(component => component.types.includes('country'));
	const postalCode = components.find(component => component.types.includes('postal_code'));

	if (route) {
		formattedAddress.address += `${route.long_name}`;
	}
	if (streetNumber) {
		if (formattedAddress.address) {
			formattedAddress.address += ` ${streetNumber.long_name}`;
		} else {
			formattedAddress.address += `${streetNumber.long_name}`;
		}
	}

	if (!formattedAddress.address && naturalFeature) {
		formattedAddress.address += `${naturalFeature.long_name}`;
	}

	if (!formattedAddress.address && establishment) {
		formattedAddress.address += `${establishment.long_name}`;
	}

	if (postalCode) {
		formattedAddress.zipcode = postalCode.long_name;
	}
	if (city) {
		formattedAddress.city = city.long_name;
	}
	if (state) {
		formattedAddress.state = state.long_name;
	}
	if (country) {
		formattedAddress.country = country.long_name;
	}

	return formattedAddress;
};

export const getLocationText = (location: PinOnLocation | Address | StructuredAddress) => {
	const { address, zipcode, city } = location;

	let locationText = address || '';

	let state;
	if ('state' in location) {
		state = location.state;
	}

	const prefix = locationText ? ', ' : '';

	const cityOrState = city || state;
	if (zipcode) {
		locationText += `${prefix}${zipcode}`;
		if (cityOrState) {
			locationText += `, ${cityOrState}`;
		}
	} else if (cityOrState) {
		locationText += `${prefix}${cityOrState}`;
	}

	return locationText;
};
