import React from 'react';
import { useProjectBoardContext } from 'App/pages/ProjectBoard/ProjectBoardContext';
import ReportcenterFilterDate, {
	type Props
} from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterDate/ReportcenterFilterDate';
import { camelCase, capitalize } from 'lodash';
import moment from 'moment';

const ProjectMapDateFilter = () => {
	const { state, setFilters } = useProjectBoardContext();
	const { filters } = state;
	const startDateValue = filters.StartDate?.value ?? [];
	const endDateValue = filters.EndDate?.value ?? [];

	const onDateChange: Props['onChange'] = (type, value) => {
		if (type === 'Date' && value !== 'Custom') {
			if (value === null) {
				const { StartDate, ...rest } = filters;
				setFilters(rest);
				return;
			}
			const { EndDate, ...rest } = filters;
			setFilters({
				...rest,
				StartDate: {
					filterName: 'StartDate',
					value: { preset: camelCase(`${value}`) }
				}
			});
		} else if (type === 'CustomDate' && value) {
			const startDate = (value as any)?.start;
			const endDate = (value as any)?.end;

			setFilters({
				...filters,
				StartDate: {
					filterName: 'StartDate',
					value: {
						preset: 'custom',
						start: startDate ? moment(startDate).startOf('day').toDate() : null
					}
				},
				EndDate: {
					filterName: 'EndDate',
					value: {
						preset: 'custom',
						start: endDate ? moment(endDate).startOf('day').toDate() : null
					}
				}
			});
		}
	};

	const updateFilterHeight = () => {};
	const values: any = {
		Date: { value: undefined },
		CustomDate: { value: undefined }
	};

	if (startDateValue.preset !== 'custom') {
		values.Date.value = capitalize(startDateValue.preset);
	} else if (startDateValue.preset === 'custom' || endDateValue.preset === 'custom') {
		values.Date.value = 'Custom';
		values.CustomDate.value = { start: startDateValue.start, end: endDateValue.start };
	}

	return (
		<ReportcenterFilterDate
			onChange={onDateChange}
			values={values}
			updateFilterHeight={updateFilterHeight}
			listFilterMode
		/>
	);
};

export default ProjectMapDateFilter;
