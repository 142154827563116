module.exports = {
	ENV: 'REPLACE_ME_ENV',
	URL: 'REPLACE_ME_URL',
	API: 'REPLACE_ME_API',
	SOCKET: '',
	PUBLICPATH: 'REPLACE_ME_PUBLICPATH',
	FORMPATH: 'REPLACE_ME_FORMPATH',
	LANDINGPAGEPATH: 'REPLACE_ME_LANDINGPAGEPATH',
	CLASSIC_URL: '',
	STATIC_MAPS_KEY: 'AIzaSyBz8DCi-zXpKspt_-LTIlhu-0vbLYMTNOA',
	GOOGLE_FONTS_KEY: 'AIzaSyDRwD9bJV8XncXBSWG9SQxM0sHHPso5rbw',
	STATUSPAGE: {
		pageId: 'xgxqnwgpzcyz',
		key: '91c46437-14a8-4b0b-b7f8-8e3df6ded05f',
		componentId: '1gr032v9zz1j'
	},
	PUSHER: {
		key: '0dae677b9a6736e36932',
		cluster: 'mt1'
	},
	SENTRY: {
		enable: true,
		env: 'awsbeta',
		dsn: 'https://47d0330c417c4f02b0da97429871724c@sentry.upsales.com/3'
	},
	CHECKIN_URL: 'https://awsbeta-checkin.upsales.com/',
	CUSTOM_DOMAIN_CNAME: 'awsbetadomain.upsales.com',
	STATIC_URL: 'https://awsbetastatic.upsales.com/**',
	SPLIT_KEY: 'rm8te0f9kmkncbb2ideqotgprv1l0rhpt9cv',
	chatEnabled: true,
	GOOGLE_MAPS_KEY: 'AIzaSyBz8DCi-zXpKspt_-LTIlhu-0vbLYMTNOA'
};
