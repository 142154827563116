import React, { useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import TextEditor from 'Components/TextEditor';
import NewMailHeader from './NewMailHeader';
import { ATTACHMENT_ADDED, ATTACHMENT_REMOVED, useMailContext } from '../../MailContext';
import { setBody } from '../../MailContextHelpers';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import MailAttachment from '../MailAttachment';
import { convertFileToAttachment } from 'Components/Helpers/MailAttachmentHelper';
import CustomerInGroupStateFrame from 'App/pages/CompanyGroupCard/Helpers/CustomerInGroupStateFrame';
import openModal from 'App/services/Modal/Modal';
import history from 'App/pages/routes/history';
import FileUploadButton from 'App/components/Buttons/FileUploadButton';
import Client from 'App/resources/Model/Client';
import t from 'Components/Helpers/translate';
import { useModalClose, ModalProps } from 'App/components/Modals/Modals';

import './NewMail.scss';

type Props = ModalProps & {
	reply?: boolean;
};

const NewMail = ({ reply, modalId, close }: Props) => {
	const {
		state: { body, attachments, mailSignatures, client, hasChanged },
		dispatch
	} = useMailContext();
	const classes = new BemClass('NewMail');
	const [config, setConfig] = React.useState<React.ComponentProps<typeof TextEditor>['config']>();
	const navigateToGroup = useRef<Client['prospectingId'] | null>(null);

	useModalClose(
		modalId,
		e => {
			e.preventDefault();

			if (!hasChanged || navigateToGroup.current === null) {
				close(undefined, true);
				return;
			}

			openModal('UnsavedChangesAlert', {
				confirmButtonText: t('default.goBack'),
				onClose: async (confirmed?: boolean) => {
					if (confirmed || confirmed === undefined) {
						navigateToGroup.current = null;
						return;
					}

					if (navigateToGroup.current) {
						history.push(`/companyGroup/${navigateToGroup.current}/`);
					}
					close(undefined, true);
				}
			});
		},
		[client, navigateToGroup.current, hasChanged]
	);

	React.useEffect(() => {
		const getConfig = async (reply: boolean | undefined) => {
			const EditorCk = getAngularModule('EditorCk');
			setConfig({
				...EditorCk.getOptions({
					height: 'auto',
					fileEntity: 'mail',
					mailSignatures
				}),
				extraPlugins: 'lineheight',
				forceSimpleAmpersand: true,
				enterMode: window.CKEDITOR.ENTER_BR,
				shiftEnterMode: window.CKEDITOR.ENTER_BR,
				removePlugins: 'liststyle,tableselection,tabletools,tableresize,contextmenu,image'
			});
		};
		getConfig(reply);
	}, [reply, mailSignatures]);

	const renderMailEditor = () => {
		return (
			<div className={classes.elem('mail-editor-wrapper').b()}>
				<FileUploadButton
					onFileUploaded={file =>
						dispatch({ type: ATTACHMENT_ADDED, attachment: convertFileToAttachment(file) })
					}
				/>
				{mailSignatures ? (
					<TextEditor
						className="MailContentEditorTextEditor"
						config={config}
						onInstanceReady={config.init}
						onChange={({ value }: { value: string }) => {
							dispatch(setBody(value));
						}}
						value={body}
					/>
				) : null}
			</div>
		);
	};
	return React.useMemo(() => {
		return (
			<div className={classes.b()}>
				<NewMailHeader />
				{config ? renderMailEditor() : null}
				<div className={classes.elem('footer').b()}>
					{attachments.map((attachment, idx) => (
						<MailAttachment
							attachment={attachment}
							key={idx}
							onRemove={attachment => {
								dispatch({ type: ATTACHMENT_REMOVED, attachment });
							}}
						></MailAttachment>
					))}
				</div>
				{client ? (
					<div className={classes.elem('customerInGroupStateFrame').b()}>
						<CustomerInGroupStateFrame
							client={client}
							close={(prospectingId: Client['prospectingId']) => {
								navigateToGroup.current = prospectingId;
								close();
							}}
						/>
					</div>
				) : null}
			</div>
		);
	}, [body, config, attachments, client, close, navigateToGroup.current]);
};

export default NewMail;
