import { FormComponent } from 'App/components/FormComponent';
import { Block, Input, Label, Select, DateInput, SelectAsync, Flex, Column, Icon, Button } from '@upsales/components';
import { PrimaryButton } from '@upsales/components/Buttons';
import {
	ProjectTabs,
	ProjectTabValues,
	fetchClients,
	fetchContacts,
	fetchOrders,
	mapToSelectItem,
	mapToSelectItemOrder
} from 'Components/EditProjectPlan/Helpers/Helpers';
import { OrderRelationRow, SubscriptionRelationRow } from './OrderSubscriptionRelationRow';
import { useEditProjectPlanContext } from 'Components/EditProjectPlan/Context';
import {
	useProjectPlanTypes,
	useProjectPlanStatuses,
	useUsers,
	useProjectPlanPriorities
} from 'App/components/hooks/appHooks';
import BemClass from '@upsales/components/Utils/bemClass';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import CustomFields from 'App/components/CustomFields';
import FormObserver, {
	FieldModel,
	getCustomFieldModel,
	mapCustomValuesToArray,
	mapCustomValuesToObject
} from 'App/components/FormObserver';
import moment from 'moment';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import ProjectPlan, { ProjectPlanForm } from 'App/resources/Model/ProjectPlan';
import React, { useEffect, useMemo, useState } from 'react';
import t from 'Components/Helpers/translate';
import type Order from 'App/resources/Model/Order';
import { AfterSelectDatePopup, AfterSelectEndDatePopup, AfterSelectUserPopup } from './AfterSelectPopup';

import { useSoftDeployAccess } from 'App/components/hooks';
import { formatWithSubAccounts } from 'App/helpers/accountsHelper';
import SelectTimeInput from 'App/components/CreateTemplateFlow/SelectTimeInput';
import ClientContactFollowupSelect from 'App/components/Selectors/ClientContactFollowupSelect';
import './ProjectDetails.scss';
import LocationPicker, { GeoLocationPicker } from 'Components/Inputs/LocationPicker';
import ClientContactSelect from 'Components/ClientContactSelect';
import openModal from 'App/services/Modal';
import { PinOnLocation } from 'App/components/Map/Map';
import { createPin, PIN_TYPE } from 'App/components/Map/mapPins/MapPins';
import { getLocationText } from 'App/components/Map/addressMappers';
import ProjectPlanRelations from './ProjectPlanRelations';

type ClientSelectType = Pick<Client, 'id' | 'name' | 'addresses' | 'operationalAccount'> & {
	title: string;
	children?: [];
};

type Props = {
	validateFormOnMount: boolean;
	setSelectedTab: (selectedTab: ProjectTabValues) => void;
	onSave?: (projectPlan: ProjectPlan) => Promise<any> | void;
};

const ProjectDetails = ({ validateFormOnMount, setSelectedTab, onSave }: Props) => {
	const classes = new BemClass('ProjectDetails');
	const users = useUsers('active', false, 'service', true).map(({ id, name }) => ({ id, name, title: name }));
	const showStatusPriority = useSoftDeployAccess('PROJECT_PLAN_STATUS_PRIORITY');
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasNewProjectFields = useSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS');
	const hasRelationSelect = useSoftDeployAccess('PROJECT_PLAN_RELATIONS');
	const hasUnifiedClientContactSelector = useSoftDeployAccess('UNIFIED_CLIENT_CONTACT_SELECTOR');
	const hasServiceMap = useSoftDeployAccess('SERVICE_MAP');

	const {
		state,
		onProjectPlanChange,
		saveNewProject,
		getClient,
		getOrder,
		setTempStartDate,
		setTempEndDate,
		setTempUser,
		setOrder,
		setAgreement
	} = useEditProjectPlanContext();

	const projectPlan = state.projectPlan!;
	const customFields = state.customFields;
	const order = state.order;
	const tasks = state.tasks;
	const tempStartDate = state.tempStartDate;
	const tempEndDate = state.tempEndDate;
	const tempUser = state.tempUser;
	const agreement = state.agreement;
	const savingProjectPlan = state.savingProjectPlan;
	const canEdit = state.canEdit;

	const projectPlanTypes = useProjectPlanTypes().map(type => mapToSelectItem(type)!);
	const projectStageOptions = (projectPlan.projectPlanType?.stages ?? []).map(({ stage }) => mapToSelectItem(stage)!);

	const lang = useMemo(
		() => ({
			cancel: t('default.cancel'),
			company: t('account'),
			contact: t('contact'),
			createProject: t('projectPlan.createProjectPlan'),
			endDate: t('default.endDate'),
			name: hasNewProjectFields ? t('default.title') : t('editProjectPlan.nameInput.label'),
			nameInputPlaceholder: t('default.enterName'),
			order: hasNewProjectFields ? t('projectPlan.relateOpportunityOrder') : t('order.order'),
			projectManager: hasNewProjectFields ? t('defaultView.assignee') : t('projectPlan.projectManager'),
			projectStage: t('projectPlan.projectStage'),
			relatedTo: t('projectPlan.relatedTo'),
			select: t('default.select'),
			startDate: t('default.startDate'),
			priority: t('default.priority'),
			status: t('default.status'),
			type: t('default.type'),
			endTime: t('default.endTime'),
			startTime: t('default.startTime'),
			location: t('default.location')
		}),
		[]
	);

	const [selectAnchor, setSelectAnchor] = useState<Element | null>(null);
	const projectStatuses = useProjectPlanStatuses().map(({ id, name }) => ({ id, name, title: name }));
	const projectPriorities = useProjectPlanPriorities().map(({ id, name }) => ({ id, name, title: name }));

	useEffect(() => {
		setSelectAnchor(document.querySelector('.ProjectDetails'));
	}, []);

	useEffect(() => {
		const onOrderEvent = (event: any, order: Order) => {
			if (order.id === projectPlan.order?.id) {
				if (event.name.split('.')[1] === 'deleted') {
					setOrder(null);
				} else {
					setOrder(order);
				}
			}
		};

		const listeners = ['order.updated', 'order.deleted', 'opportunity.updated', 'opportunity.deleted'].map(
			eventName => Tools.$rootScope.$on(eventName, onOrderEvent)
		);

		return () => {
			listeners.forEach(listener => listener());
		};
	}, []);

	const validatonModel = {
		name: FieldModel.string(hasNewProjectFields ? t('default.title') : 'default.name')
			.required()
			.max(100),
		client: FieldModel.object('default.client', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		startDate: hasNewProjectFields
			? FieldModel.date('default.startDate')
			: FieldModel.date('default.startDate').required(),
		endDate: hasNewProjectFields
			? FieldModel.date('default.whenIsIt')
			: FieldModel.date('activity.whenIsIt').required(),
		notes: FieldModel.string('default.notes').max(65535),
		projectPlanStage: FieldModel.object('projectPlan.projectStage', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		projectPlanPriority: FieldModel.object('default.priority', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		projectPlanStatus: FieldModel.object('default.status', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		projectPlanType: hasNewProjectFields
			? FieldModel.object('projectPlan.projectPlanType', {
					value: FieldModel.number('default.id'),
					label: FieldModel.string('default.name')
			  }).required()
			: FieldModel.object('projectPlan.projectPlanType', {
					value: FieldModel.number('default.id'),
					label: FieldModel.string('default.name')
			  }),
		user: FieldModel.object(hasNewProjectFields ? t('defaultView.assignee') : t('projectPlan.projectManager'), {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}),
		contact: FieldModel.object('default.contact', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}),
		order: FieldModel.object('default.order', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.description')
		}),
		custom: getCustomFieldModel(customFields)
	};

	const initialValues = {
		...projectPlan,
		custom: mapCustomValuesToObject(projectPlan.custom, customFields)
	};

	const saveAndGoToTasks = () => {
		saveNewProject(projectPlan, onSave);
		getClient(projectPlan.client?.id);

		if (projectPlan.order) {
			getOrder(projectPlan.order.id);
		}

		setTimeout(() => {
			setSelectedTab(ProjectTabs.TASKS);
		}, 1000);
	};

	const openTodos = tasks.filter(task => task.closeDate === null);

	const checkUserInOpenTodos = () => {
		return openTodos.some(todo => todo.users[0]?.id === projectPlan.user?.id);
	};

	const getClientsWithSubaccounts = (searchTerm: string) => {
		return fetchClients()(searchTerm).then((data: Client[]) => {
			return formatWithSubAccounts(data, t);
		}) as Promise<ClientSelectType[]>;
	};

	return (
		<Block className={classes.b()} space="mrxl mlxl mtxl mbxl">
			<FormObserver<ProjectPlanForm>
				onChange={(updatedProjectPlan, isValid, errorMessages) => {
					const mappedCustom = mapCustomValuesToArray(updatedProjectPlan.custom) as ProjectPlan['custom'];
					const reMappedProjectPlan = { ...updatedProjectPlan, custom: mappedCustom };
					onProjectPlanChange(reMappedProjectPlan, isValid, errorMessages);
				}}
				onSubmit={saveAndGoToTasks}
				validateOnMount={validateFormOnMount}
				model={validatonModel}
				initialValues={initialValues}
				useDebounce
			>
				{({ onFormChange, onFormChanges, inputProps, values: projectPlan, submit }) => (
					<Block className={classes.elem('container').b()}>
						{/*
            New design: https://upsales.jira.com/browse/MM-15415, currently only for Service Management
            Remove the old design when feature is released
            */}
						{hasNewProjectFields ? (
							<>
								<FormComponent label={lang.name} required>
									<Input
										placeholder={lang.nameInputPlaceholder}
										{...inputProps.name}
										disabled={!canEdit}
									/>
								</FormComponent>

								<FormComponent
									label={projectPlan.client && !projectPlan.contact ? lang.company : lang.contact}
									required={!projectPlan.id}
									className={classes.elem('ContactInput').b()}
									hideMessage
								>
									<ClientContactFollowupSelect
										onChange={value => {
											const { client, contact } = value;
											onFormChange('client', client);
											setTimeout(() => onFormChange('contact', contact), 0);
											if (state.client && client && state.client.id !== client.id) {
												if (projectPlan.order) {
													setTimeout(() => onFormChange('order', null), 0);
													setOrder(null);
													if (state.agreement) {
														setAgreement(null);
													}
												}
												if (projectPlan.activityId) {
													setTimeout(() => onFormChange('activityId', null), 0);
												}
												if (projectPlan.appointmentId) {
													setTimeout(() => onFormChange('appointmentId', null), 0);
												}
												if (projectPlan.relatedOrderId) {
													setTimeout(() => onFormChange('relatedOrderId', null), 0);
												}
												if (projectPlan.ticketId) {
													setTimeout(() => onFormChange('ticketId', null), 0);
												}
												if (projectPlan.createdFromTicketId) {
													setTimeout(() => onFormChange('createdFromTicketId', null), 0);
												}
												if (projectPlan.agreementId) {
													setTimeout(() => onFormChange('agreementId', null), 0);
												}
											}
										}}
										contact={
											projectPlan.contact
												? {
														...(projectPlan.contact as Contact),
														client: projectPlan.client as Contact['client']
												  }
												: null
										}
										client={projectPlan.client}
										disabled={!canEdit}
									/>
								</FormComponent>

								<Flex direction="column">
									<FormComponent label={lang.type} required>
										<Select
											anchor={selectAnchor}
											value={mapToSelectItem(projectPlan.projectPlanType)}
											options={projectPlanTypes}
											onChange={selectedItem => {
												onFormChange('projectPlanType', selectedItem);
											}}
											disabled={!canEdit}
										/>
									</FormComponent>
									{/* Add Template input here */}
								</Flex>
								<FormComponent
									label={lang.location}
									labelRenderRight={
										hasServiceMap
											? () => (
													<Button
														className={classes.elem('openMapButton').b()}
														type="link"
														color="bright-blue"
														onClick={() => {
															const locationPin = createPin(
																projectPlan,
																PIN_TYPE.PROJECT_PLAN
															);

															openModal('ProjectMap', {
																defaultCenter: locationPin
																	? {
																			latitude: locationPin.latitude,
																			longitude: locationPin.longitude
																	  }
																	: undefined,
																type: projectPlan.projectPlanType.category,
																onPinSelect: (location: PinOnLocation) => {
																	const locationText = getLocationText(location);
																	const { latitude, longitude } = location;
																	onFormChanges({
																		location: locationText,
																		locationLatitude: latitude,
																		locationLongitude: longitude
																	});
																}
															});
														}}
													>
														<Icon name="map-marker" space="mrm" />
														{t('default.findLocationOnMap')}
													</Button>
											  )
											: undefined
									}
								>
									{hasServiceMap ? (
										<GeoLocationPicker
											client={projectPlan.client}
											value={projectPlan.location ?? undefined}
											onChange={({ location, latitude, longitude }) => {
												const locationText = location ? getLocationText(location) : '';
												onFormChanges({
													location: locationText,
													locationLatitude: latitude,
													locationLongitude: longitude
												});
											}}
											disabled={!canEdit}
										/>
									) : (
										<LocationPicker
											client={projectPlan.client}
											value={projectPlan.location ?? undefined}
											onChange={value => onFormChange('location', value)}
											disabled={!canEdit}
										/>
									)}
								</FormComponent>
								<Flex gap="u5">
									<Column>
										<Flex
											gap="u3"
											justifyContent="space-between"
											className={classes.elem('dateinput-wrapper').b()}
										>
											<FormComponent label={lang.startDate}>
												<DateInput
													{...inputProps.startDate}
													placeholder={lang.startDate}
													icon="calendar"
													closeOnSelect={true}
													min={undefined}
													max={
														projectPlan.endDate
															? moment(projectPlan.endDate).toDate()
															: undefined
													}
													disabled={!canEdit}
													onClear={() => {
														onFormChange('startDate', null);
														if (projectPlan.startTime) {
															/**
															 * @todo  @upsales/components 2.19.25
															 * If you trigger multiple renders in this onChange, the value will not be removed
															 * @link https://github.com/upsales/ui-components/issues/586
															 */
															setTimeout(() => onFormChange('startTime', null), 0);
														}
													}}
													onChange={event => {
														if (!event.target.value) {
															onFormChange('startDate', null);
															if (projectPlan.startTime) {
																/**
																 * @todo  @upsales/components 2.19.25
																 * If you trigger multiple renders in this onChange, the value will not be removed
																 * @link https://github.com/upsales/ui-components/issues/586
																 */
																setTimeout(() => onFormChange('startTime', null), 0);
															}
														} else if (
															projectPlan.id &&
															!projectPlan.projectPlanTemplate?.offsetEndDate &&
															projectPlan.startDate
														) {
															if (
																moment(event.target.value).diff(
																	moment(projectPlan.startDate),
																	'days'
																) !== 0
															) {
																setTimeout(
																	() => setTempStartDate(event.target.value),
																	0
																);
															}
														} else {
															onFormChange('startDate', event.target.value);
															// Also adjust end date like we do in AfterSelectDatePopup
															const newStart = moment(event.target.value);
															const oldStart = moment(projectPlan.startDate);
															const businessDaysDiff = newStart.businessDiff(oldStart);
															let newEndDate: Date;

															if (newStart.isAfter(moment(projectPlan.startDate))) {
																newEndDate = moment(projectPlan.endDate)
																	.businessAdd(businessDaysDiff, 'days')
																	.toDate();
															} else {
																newEndDate = moment(projectPlan.endDate)
																	.businessSubtract(businessDaysDiff, 'days')
																	.toDate();
															}
															setTimeout(() => onFormChange('endDate', newEndDate), 0);
														}
													}}
												/>
												{tempStartDate ? (
													<AfterSelectDatePopup
														setStartDate={startDate => onFormChange('startDate', startDate)}
														setEndDate={endDate => onFormChange('endDate', endDate)}
													/>
												) : null}
											</FormComponent>
											<FormComponent label={lang.startTime}>
												<SelectTimeInput
													value={
														projectPlan.startTime
															? moment(projectPlan.startTime).format('LT')
															: null
													}
													onChange={id => {
														if (!id) {
															onFormChange('startTime', null);
														} else {
															const [hours, minutes] = id.split(':');
															onFormChange(
																'startTime',
																moment(projectPlan.startDate)
																	.hours(+hours)
																	.minutes(+minutes)
																	.toDate()
															);
														}
													}}
													max={
														moment(projectPlan.endDate).isSame(
															moment(projectPlan.startDate),
															'day'
														)
															? moment(projectPlan.endTime).format('LT')
															: '23:30'
													}
													anchor={selectAnchor}
													inline={false}
													allowEmpty
													disabled={!projectPlan.startDate || !canEdit}
												/>
											</FormComponent>
										</Flex>
									</Column>
									<Column>
										<Flex
											gap="u2"
											justifyContent="space-between"
											className={classes.elem('dateinput-wrapper').b()}
										>
											<FormComponent label={lang.endDate}>
												<DateInput
													{...inputProps.endDate}
													placeholder={lang.endDate}
													icon="calendar"
													closeOnSelect={true}
													min={
														projectPlan.startDate
															? moment(projectPlan.startDate).toDate()
															: undefined
													}
													max={undefined}
													disabled={!canEdit}
													onClear={() => {
														onFormChange('endDate', null);
														if (projectPlan.endTime) {
															/**
															 * @todo  @upsales/components 2.19.25
															 * If you trigger multiple renders in this onChange, the value will not be removed
															 * @link https://github.com/upsales/ui-components/issues/586
															 */
															setTimeout(() => onFormChange('endTime', null), 0);
														}
													}}
													onChange={event => {
														if (!event.target.value) {
															onFormChange('endDate', null);
															if (projectPlan.endTime) {
																/**
																 * @todo  @upsales/components 2.19.25
																 * If you trigger multiple renders in this onChange, the value will not be removed
																 * @link https://github.com/upsales/ui-components/issues/586
																 */
																setTimeout(() => onFormChange('endTime', null), 0);
															}
														} else if (
															projectPlan.id &&
															projectPlan.projectPlanTemplate?.offsetEndDate &&
															projectPlan.endDate
														) {
															if (
																moment(event.target.value).diff(
																	moment(projectPlan.endDate),
																	'days'
																) !== 0
															) {
																setTimeout(() => setTempEndDate(event.target.value), 0);
															}
														} else {
															onFormChange('endDate', event.target.value);
														}
													}}
												/>
												{tempEndDate ? (
													<AfterSelectEndDatePopup
														setStartDate={startDate => onFormChange('startDate', startDate)}
														setEndDate={endDate => onFormChange('endDate', endDate)}
													/>
												) : null}
											</FormComponent>
											<FormComponent label={lang.endTime}>
												{/**
												 * @todo  @upsales/components 2.19.25
												 * Using SelectTimeInput instead of TimeInput because you cannot change the TimeInput value from the outside since it stores the value in the state
												 * @link https://github.com/upsales/ui-components/issues/587
												 */}
												<SelectTimeInput
													value={
														projectPlan.endTime
															? moment(projectPlan.endTime).format('LT')
															: null
													}
													onChange={id => {
														if (!id) {
															onFormChange('endTime', null);
														} else {
															const [hours, minutes] = id.split(':');
															onFormChange(
																'endTime',
																moment(projectPlan.endDate)
																	.hours(+hours)
																	.minutes(+minutes)
																	.toDate()
															);
														}
													}}
													min={
														moment(projectPlan.endDate).isSame(
															moment(projectPlan.startDate),
															'day'
														)
															? moment(projectPlan.startTime).format('LT')
															: '00:00'
													}
													max="23:30"
													anchor={selectAnchor}
													inline={false}
													allowEmpty
													disabled={!projectPlan.endDate || !canEdit}
												/>
											</FormComponent>
										</Flex>
									</Column>
								</Flex>
								<FormComponent
									label={lang.projectManager}
									className={classes.elem('AssigneeInput').b()}
									hideMessage
								>
									<Select
										anchor={selectAnchor}
										multi={false}
										value={mapToSelectItem(projectPlan.user)}
										state={inputProps.user.state}
										required={inputProps.user.required}
										disabled={inputProps.user.disabled || savingProjectPlan || !canEdit}
										options={users}
										placeholder={lang.select}
										onChange={selectedItem => {
											if (selectedItem.id !== projectPlan.user?.id) {
												if (projectPlan.id && checkUserInOpenTodos()) {
													setTimeout(() => setTempUser(selectedItem), 0);
												} else {
													onFormChange('user', selectedItem);
												}
											}
										}}
										onClear={() => onFormChange('user', null)}
									/>
									{tempUser ? (
										<AfterSelectUserPopup setUser={user => onFormChange('user', user)} />
									) : null}
								</FormComponent>
								<Block>
									<FormComponent label={lang.projectStage} required={!projectPlan.id}>
										<Select
											anchor=".ProjectDetails"
											scrollContainer={selectAnchor}
											required={inputProps.projectPlanStage.required}
											multi={false}
											options={projectStageOptions}
											placeholder={t('default.select')}
											value={mapToSelectItem(projectPlan.projectPlanStage)}
											onChange={selectedItem =>
												onFormChange(
													'projectPlanStage',
													selectedItem as ProjectPlan['projectPlanStage']
												)
											}
											disabled={!canEdit}
										></Select>
									</FormComponent>
								</Block>
								{showStatusPriority ? (
									<Flex gap="u5">
										<Column>
											<FormComponent
												label={lang.priority}
												required={inputProps.projectPlanPriority.required}
											>
												<Select
													anchor={selectAnchor}
													scrollContainer={selectAnchor}
													required={inputProps.projectPlanPriority.required}
													multi={false}
													showSearch={false}
													options={projectPriorities}
													placeholder={t('default.select')}
													value={mapToSelectItem(projectPlan.projectPlanPriority)}
													onChange={selectedItem => {
														onFormChange('projectPlanPriority', selectedItem);
													}}
													disabled={!canEdit}
												></Select>
											</FormComponent>
										</Column>
										<Column>
											<FormComponent
												label={lang.status}
												required={inputProps.projectPlanStatus.required}
											>
												<Select
													anchor={selectAnchor}
													scrollContainer={selectAnchor}
													required={inputProps.projectPlanStatus.required}
													multi={false}
													showSearch={false}
													placeholder={t('default.select')}
													value={mapToSelectItem(projectPlan.projectPlanStatus)}
													onChange={selectedItem =>
														onFormChange('projectPlanStatus', selectedItem)
													}
													options={projectStatuses}
													disabled={!canEdit}
												></Select>
											</FormComponent>
										</Column>
									</Flex>
								) : null}
							</>
						) : (
							<>
								{projectPlan && order ? (
									<Block space="mbxl" className={classes.elem('order-relation').b()}>
										<Label>{lang.relatedTo}</Label>
										<Block>
											<OrderRelationRow order={order ?? projectPlan.order} />
										</Block>
										{agreement ? (
											<Block space="mtm">
												<SubscriptionRelationRow subscription={agreement} />
											</Block>
										) : null}
									</Block>
								) : null}
								<Block className={classes.elem('row').b()}>
									<Block className={classes.elem('column').b()}>
										<Block>
											<FormComponent label={lang.name} required={!projectPlan.id}>
												<Input placeholder={lang.nameInputPlaceholder} {...inputProps.name} />
											</FormComponent>
										</Block>
										{hasNewProjectFields ? (
											<Block>
												<FormComponent label={lang.type} required>
													<Select
														anchor={selectAnchor}
														value={mapToSelectItem(projectPlan.projectPlanType)}
														options={projectPlanTypes}
														onChange={selectedItem => {
															onFormChange('projectPlanType', selectedItem);
														}}
													/>
												</FormComponent>
											</Block>
										) : null}
										<Block>
											<FormComponent label={lang.projectManager} required={!projectPlan.id}>
												<Select
													anchor={selectAnchor}
													multi={false}
													value={mapToSelectItem(projectPlan.user)}
													state={inputProps.user.state}
													required={inputProps.user.required}
													disabled={inputProps.user.disabled || savingProjectPlan}
													options={users}
													placeholder={lang.select}
													onChange={selectedItem => {
														if (projectPlan.id && checkUserInOpenTodos()) {
															setTimeout(() => setTempUser(selectedItem), 0);
														} else {
															onFormChange('user', selectedItem);
														}
													}}
												/>
												{tempUser ? (
													<AfterSelectUserPopup
														setUser={user => onFormChange('user', user)}
													/>
												) : null}
											</FormComponent>
										</Block>
										<Flex
											gap="u2"
											justifyContent="space-between"
											className={classes.elem('dateinput-wrapper').b()}
										>
											<FormComponent label={lang.startDate} required={!projectPlan.id}>
												<DateInput
													{...inputProps.startDate}
													placeholder={lang.startDate}
													icon="calendar"
													closeOnSelect={true}
													min={undefined}
													max={
														projectPlan.endDate
															? moment(projectPlan.endDate).toDate()
															: undefined
													}
													onChange={event => {
														if (
															projectPlan.id &&
															!projectPlan.projectPlanTemplate?.offsetEndDate
														) {
															if (
																moment(event.target.value).diff(
																	moment(projectPlan.startDate),
																	'days'
																) !== 0
															) {
																setTimeout(
																	() => setTempStartDate(event.target.value),
																	0
																);
															}
														} else {
															onFormChange('startDate', event.target.value);
															if (!event.target.value && projectPlan.startTime) {
																/**
																 * @todo  @upsales/components 2.19.25
																 * If you trigger multiple renders in this onChange, the value will not be removed
																 * @link https://github.com/upsales/ui-components/issues/586
																 */
																setTimeout(() => onFormChange('startTime', null), 0);
															}
														}
													}}
												/>
												{tempStartDate ? (
													<AfterSelectDatePopup
														setStartDate={startDate => onFormChange('startDate', startDate)}
														setEndDate={endDate => onFormChange('endDate', endDate)}
													/>
												) : null}
											</FormComponent>
											{hasNewProjectFields ? (
												<FormComponent label={lang.startTime}>
													<SelectTimeInput
														value={
															projectPlan.startTime
																? moment(projectPlan.startTime).format('LT')
																: null
														}
														onChange={id => {
															if (!id) {
																onFormChange('startTime', null);
															} else {
																const [hours, minutes] = id.split(':');
																onFormChange(
																	'startTime',
																	moment(projectPlan.startDate)
																		.hours(+hours)
																		.minutes(+minutes)
																		.toDate()
																);
															}
														}}
														max={
															moment(projectPlan.endDate).isSame(
																moment(projectPlan.startDate),
																'day'
															)
																? moment(projectPlan.endTime).format('LT')
																: '23:30'
														}
														anchor={selectAnchor}
														inline={false}
														allowEmpty
														disabled={!projectPlan.startDate}
													/>
												</FormComponent>
											) : null}
										</Flex>

										<Block>
											<FormComponent label={lang.projectStage} required={!projectPlan.id}>
												<Select
													anchor=".ProjectDetails"
													scrollContainer={selectAnchor}
													required={inputProps.projectPlanStage.required}
													multi={false}
													options={projectStageOptions}
													placeholder={t('default.select')}
													value={mapToSelectItem(projectPlan.projectPlanStage)}
													onChange={selectedItem =>
														onFormChange(
															'projectPlanStage',
															selectedItem as ProjectPlan['projectPlanStage']
														)
													}
												></Select>
											</FormComponent>
										</Block>
										{showStatusPriority ? (
											<Block>
												<FormComponent
													label={lang.status}
													required={inputProps.projectPlanStatus.required}
												>
													<Select
														anchor={selectAnchor}
														scrollContainer={selectAnchor}
														required={inputProps.projectPlanStatus.required}
														multi={false}
														showSearch={false}
														placeholder={t('default.select')}
														value={mapToSelectItem(projectPlan.projectPlanStatus)}
														onChange={selectedItem =>
															onFormChange('projectPlanStatus', selectedItem)
														}
														options={projectStatuses}
													></Select>
												</FormComponent>
											</Block>
										) : null}
									</Block>

									<Block className={classes.elem('column').b()}>
										<Block>
											<FormComponent label={lang.company} required={!projectPlan.id}>
												{!hasUnifiedClientContactSelector ? (
													<SelectAsync
														key={projectPlan.client?.id ?? 0}
														state={inputProps.client.state}
														required={inputProps.client.required}
														disabled={inputProps.client.disabled || !!projectPlan.id}
														anchor={selectAnchor}
														multi={false}
														value={mapToSelectItem(projectPlan.client)}
														fetchOnMount
														fetcher={
															hasSubAccounts ? getClientsWithSubaccounts : fetchClients()
														}
														placeholder={lang.select}
														onChange={selectedItem => {
															onFormChange('client', selectedItem as unknown as Client);
															if (projectPlan.contact) {
																setTimeout(() => onFormChange('contact', null), 0);
															}
															if (projectPlan.order) {
																setTimeout(() => onFormChange('order', null), 0);
															}
														}}
													/>
												) : (
													<ClientContactSelect
														client={projectPlan.client}
														disabled={inputProps.client.disabled || !!projectPlan.id}
														mode="clients"
														selectedSkin="classic"
														onlyActive
														onChange={({ client }) => {
															onFormChange('client', client);
															if (projectPlan.contact) {
																setTimeout(() => onFormChange('contact', null), 0);
															}
															if (projectPlan.order) {
																setTimeout(() => onFormChange('order', null), 0);
															}
														}}
													/>
												)}
											</FormComponent>
										</Block>

										<Block>
											<FormComponent label={lang.contact}>
												{!hasUnifiedClientContactSelector ? (
													<SelectAsync
														key={projectPlan.client?.id ?? 0}
														anchor={selectAnchor}
														multi={false}
														disabled={inputProps.contact.disabled}
														value={mapToSelectItem(projectPlan.contact)}
														fetchOnMount
														fetcher={fetchContacts(projectPlan.client?.id)}
														placeholder={lang.select}
														onChange={selectedItem => {
															const selectedItemContact = selectedItem as Contact;
															if (!projectPlan.client) {
																onFormChange('client', selectedItemContact.client);
																setTimeout(
																	() => onFormChange('contact', selectedItemContact),
																	0
																);
															} else {
																onFormChange('contact', selectedItemContact);
															}
														}}
														onClear={() => {
															onFormChange('contact', null);
														}}
													/>
												) : (
													<ClientContactSelect
														contact={projectPlan.contact}
														disabled={inputProps.contact.disabled}
														mode="contacts"
														selectedSkin="classic"
														onChange={({ client, contact }) => {
															if (!projectPlan.client) {
																onFormChange('client', client);
															}
															setTimeout(() => onFormChange('contact', contact), 0);
														}}
													/>
												)}
											</FormComponent>
										</Block>
										{tempEndDate ? (
											<AfterSelectEndDatePopup
												setStartDate={startDate => onFormChange('startDate', startDate)}
												setEndDate={endDate => onFormChange('endDate', endDate)}
											/>
										) : null}
										<Flex
											gap="u2"
											justifyContent="space-between"
											className={classes.elem('dateinput-wrapper').b()}
										>
											<FormComponent label={lang.endDate} required={!projectPlan.id}>
												<DateInput
													{...inputProps.endDate}
													placeholder={lang.endDate}
													icon="calendar"
													closeOnSelect={true}
													min={
														projectPlan.startDate
															? moment(projectPlan.startDate).toDate()
															: undefined
													}
													max={undefined}
													onChange={event => {
														if (
															projectPlan.id &&
															projectPlan.projectPlanTemplate?.offsetEndDate
														) {
															if (
																moment(event.target.value).diff(
																	moment(projectPlan.endDate),
																	'days'
																) !== 0
															) {
																setTimeout(() => setTempEndDate(event.target.value), 0);
															}
														} else {
															onFormChange('endDate', event.target.value);
															if (!event.target.value && projectPlan.startTime) {
																/**
																 * @todo  @upsales/components 2.19.25
																 * If you trigger multiple renders in this onChange, the value will not be removed
																 * @link https://github.com/upsales/ui-components/issues/586
																 */
																setTimeout(() => onFormChange('endTime', null), 0);
															}
														}
													}}
												/>
											</FormComponent>
											{hasNewProjectFields ? (
												<FormComponent label={lang.endTime}>
													{/**
													 * @todo  @upsales/components 2.19.25
													 * Using SelectTimeInput instead of TimeInput because you cannot change the TimeInput value from the outside since it stores the value in the state
													 * @link https://github.com/upsales/ui-components/issues/587
													 */}
													<SelectTimeInput
														value={
															projectPlan.endTime
																? moment(projectPlan.endTime).format('LT')
																: null
														}
														onChange={id => {
															if (!id) {
																onFormChange('endTime', null);
															} else {
																const [hours, minutes] = id.split(':');
																onFormChange(
																	'endTime',
																	moment(projectPlan.endDate)
																		.hours(+hours)
																		.minutes(+minutes)
																		.toDate()
																);
															}
														}}
														min={
															moment(projectPlan.endDate).isSame(
																moment(projectPlan.startDate),
																'day'
															)
																? moment(projectPlan.startTime).format('LT')
																: '00:00'
														}
														max="23:30"
														anchor={selectAnchor}
														inline={false}
														allowEmpty
														disabled={!projectPlan.endDate}
													/>
												</FormComponent>
											) : null}
										</Flex>
										{!projectPlan.id ? (
											<Block>
												<FormComponent label={lang.order}>
													<SelectAsync
														key={projectPlan.client?.id ?? 0}
														anchor={selectAnchor}
														multi={false}
														disabled={inputProps.order.disabled}
														value={mapToSelectItemOrder(projectPlan.order)}
														fetchOnMount
														fetcher={fetchOrders(projectPlan.client?.id)}
														placeholder={lang.select}
														onChange={selectedItem => {
															const selectedItemOrder = selectedItem as unknown as Order;
															if (!projectPlan.client) {
																onFormChange('client', selectedItemOrder.client);
																setTimeout(
																	() => onFormChange('order', selectedItemOrder),
																	0
																);
															} else {
																onFormChange('order', selectedItemOrder);
															}
														}}
														onClear={() => {
															onFormChange('order', null);
														}}
													/>
												</FormComponent>
											</Block>
										) : null}
										{showStatusPriority ? (
											<Block>
												<FormComponent
													label={lang.priority}
													required={inputProps.projectPlanPriority.required}
												>
													<Select
														anchor={selectAnchor}
														scrollContainer={selectAnchor}
														required={inputProps.projectPlanPriority.required}
														multi={false}
														options={projectPriorities}
														value={mapToSelectItem(projectPlan.projectPlanPriority)}
														onChange={selectedItem => {
															onFormChange('projectPlanPriority', selectedItem);
														}}
														disabled={!canEdit}
													></Select>
												</FormComponent>
											</Block>
										) : null}
									</Block>
								</Block>
							</>
						)}
						<Block space="mbxl">
							<NotesWithSignature
								value={projectPlan.notes}
								onChange={value => onFormChange('notes', value)}
								disabled={!canEdit}
							/>
						</Block>
						{hasNewProjectFields && !hasRelationSelect && !projectPlan.id ? (
							<Block>
								<FormComponent label={lang.order}>
									<SelectAsync
										key={projectPlan.client?.id ?? 0}
										anchor={selectAnchor}
										multi={false}
										disabled={inputProps.order.disabled}
										value={mapToSelectItemOrder(projectPlan.order)}
										fetchOnMount
										fetcher={fetchOrders(projectPlan.client?.id)}
										placeholder={lang.select}
										onChange={selectedItem => {
											const selectedItemOrder = selectedItem as unknown as Order;
											if (!projectPlan.client) {
												onFormChange('client', selectedItemOrder.client);
												setTimeout(() => onFormChange('order', selectedItemOrder), 0);
											} else {
												onFormChange('order', selectedItemOrder);
											}
										}}
										onClear={() => {
											onFormChange('order', null);
										}}
									/>
								</FormComponent>
							</Block>
						) : null}
						{hasNewProjectFields && !hasRelationSelect && projectPlan.id && order ? (
							<Block space="mbxl" className={classes.elem('order-relation').b()}>
								<Label>{lang.relatedTo}</Label>
								<Block>
									<OrderRelationRow order={order ?? projectPlan.order} />
								</Block>
								{agreement ? (
									<Block space="mtm">
										<SubscriptionRelationRow subscription={agreement} />
									</Block>
								) : null}
							</Block>
						) : null}
						{hasRelationSelect && hasNewProjectFields ? (
							<ProjectPlanRelations onChange={onFormChange} />
						) : null}
						{customFields.length ? (
							<Block space="pbxl">
								<CustomFields
									type="projectPlan"
									inputProps={inputProps}
									onChange={(id, value) => onFormChange(`custom.Custom_${id}`, value)}
									disabled={!canEdit}
									showFormGroupName={true}
									onlyShowIds={customFields.map(({ id }) => id)}
								/>
							</Block>
						) : null}
						{!projectPlan.id ? (
							<PrimaryButton
								className={classes.elem('saveButton').b()}
								disabled={savingProjectPlan}
								shadow="high"
								size="lg"
								loading={savingProjectPlan}
								onClick={vals => {
									submit(vals);
								}}
							>
								{lang.createProject}
							</PrimaryButton>
						) : null}
					</Block>
				)}
			</FormObserver>
		</Block>
	);
};

export default ProjectDetails;
