import Resource from 'Resources/Resource';

type Credentials = {
	email: string;
	password: string;
	samlBypass?: number | null;
	skipCookie?: boolean;
};

type SessionResponse = {
	token: string;
	isTwoFactorAuth?: boolean;
};

type LoginMethodResponse = {
	url: string;
	method: 'normal' | 'saml';
};

class Session extends Resource {
	constructor() {
		super('session');
	}

	private getLogoutMethod(): Promise<LoginMethodResponse> {
		return this._deleteRequest('', {
			methodName: 'getLogoutMethod',
			_url: 'loginMethod',
			skipRejectTimeout: true
		}).then(r => r.data);
	}

	login(credentials: Credentials): Promise<{ data: SessionResponse }> {
		return this._postRequest('', credentials, { methodName: 'login' }).then(r => r.data);
	}

	loginTwoFA(validationKey: string, key: string, skipCookie?: boolean): Promise<{ data: { token: string } }> {
		return this._postRequest('twoFactorKey', { validationKey, key, skipCookie }, { methodName: 'loginTwoFA' }).then(
			r => r.data
		);
	}

	async logout(): Promise<void> {
		let ssoData: LoginMethodResponse;
		try {
			// Check for sso logout url and redirect after regular logout is done
			ssoData = await this.getLogoutMethod();
		} catch {
			// Do regular session delete regardless of sso logout url response
		}

		return this._deleteRequest('', { methodName: 'logout', skipRejectTimeout: true })
			.then(r => r.data)
			.finally(() => {
				if (ssoData?.method === 'saml') {
					window.location.href = ssoData.url;
				}
			});
	}

	getLoginMethod(
		email: string,
		samlBypass?: number | null,
		noRedirect = false,
		isMobileServiceAppLogin = false,
		rememberUser = false
	): Promise<LoginMethodResponse> {
		return this._postRequest(
			'',
			{ email, samlBypass, isMobileServiceAppLogin, rememberUser },
			{ methodName: 'getLoginMethod', _url: 'loginMethod' }
		).then(({ data }: { data: LoginMethodResponse }) => {
			if (data.method === 'saml' && !noRedirect) {
				window.location.href = data.url;
			}
			return data;
		});
	}
}

const resource = new Session();

export default resource;
