import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
	Block,
	ButtonBox,
	Card,
	Flex,
	FullscreenModal,
	Headline,
	Help,
	Icon,
	Link,
	ModalContent,
	ModalHeader,
	Text
} from '@upsales/components';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { useSoftDeployAccess } from '../hooks/featureHelper';
import { getMatches } from '../CleanMyData/Helpers/matcherHelpers';
import { formatFetchedAmount } from '../CleanMyData/Helpers/fetchHelpers';
import { useSelector } from 'react-redux';
import { useTranslation } from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import DetectedDuplicate from 'App/babel/resources/DetectedDuplicate';
import history from 'App/pages/routes/history';
import LocalStorage from 'Components/Helpers/LocalStorage';
import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';
import ClientResource from 'App/resources/Client';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import type { RootState } from 'Store/index';
import './CleanMyData.scss';
import InactivateContactsBox from './InactivateContactsBox';
import { hasSoliditetMatcher } from 'App/helpers/matcherHelper';

const useNumberOfClientsWithoutContacts = () => {
	const [numberOfClientsWithoutContacts, setNumberOfClientsWithoutContacts] = useState<number | string>(0);

	useEffect(() => {
		const rb = new RequestBuilder();
		rb.limit = 0;
		rb.addFilter({ field: 'isExternal' }, comparisonTypes.Equals, 0);
		const groupBuilder = rb.groupBuilder();
		groupBuilder.addFilter({ field: 'contact.active' }, comparisonTypes.Equals, true);
		groupBuilder.addFilter({ field: 'contact.id' }, comparisonTypes.GreaterThan, 0);
		groupBuilder.isNotFilter();
		groupBuilder.done();

		const { promise, cancel } = makeCancelable(ClientResource.find(rb.build()));
		promise
			.then(({ metadata: { total } }: { metadata: { total: number } }) =>
				setNumberOfClientsWithoutContacts(total)
			)
			.catch(error => logError(error, 'useNumberOfClientsWithoutContacts'));

		return () => cancel();
	}, []);

	return numberOfClientsWithoutContacts;
};

const CleanMyData = ({ className, close }: { className: string; close: () => void }) => {
	const classes = new bemClass('CleanMyData', className);
	const { t } = useTranslation();
	const duplicatesPromise = useRef<CancelablePromise<{ metadata: { total: number } } | void>>(
		null
	) as MutableRefObject<CancelablePromise<{ metadata: { total: number } } | void>>;
	const matchesFromProspPromise = useRef<CancelablePromise<number | void> | null>(null);
	const [duplicateClients, setDuplicateClients] = useState<number | string>(0);
	const [duplicateContacts, setDuplicateContacts] = useState<number | string>(0);
	const [matchesFromProsp, setMatchesFromProsp] = useState(0);
	const hasInactivateContacts = useSoftDeployAccess('CLEAN_INACTIVATED_CONTACTS');

	const numberOfClientsWithoutContacts = useNumberOfClientsWithoutContacts();
	const { self, accountSelf, customerId } = useSelector(({ App }: RootState) => ({
		self: App.self,
		accountSelf: App.accountSelf,
		customerId: App.customerId
	}));

	const hasMatchesFromProsp = hasSoliditetMatcher(self, accountSelf);

	const fetchMatches = async () => {
		matchesFromProspPromise.current = makeCancelable(getMatches(customerId));
		try {
			const matches = await matchesFromProspPromise.current.promise;
			if (typeof matches === 'number') {
				setMatchesFromProsp(matches);
			}
		} catch (e) {
			logError(e, 'Failed to fetch matches');
		}
	};

	const fetchDuplicatesForEntity = (
		entity: string,
		setState: React.Dispatch<React.SetStateAction<number | string>>
	) => {
		const fetchDuplicates = () => {
			const rb = new RequestBuilder();
			rb.addFilter({ field: 'ignore' }, comparisonTypes.Equals, false);
			rb.addExtraParam('groupDuplicates', true);

			rb.limit = 0;
			const filters = rb.build();
			filters.entity = entity;

			duplicatesPromise.current = makeCancelable(DetectedDuplicate.find(filters));
			duplicatesPromise.current.promise
				.then((data: void | { metadata: { total: number } }) => {
					if (data) {
						setState(data.metadata.total);
					}
				})
				.catch(e => {
					if (!e.isCanceled) {
						logError(e, 'Failed to fetch duplicates', { entity });
					}
				});
		};
		fetchDuplicates();
	};

	useEffect(() => {
		fetchDuplicatesForEntity('Client', setDuplicateClients);
		fetchDuplicatesForEntity('Contact', setDuplicateContacts);

		if (hasMatchesFromProsp) {
			fetchMatches();
		}

		return () => {
			duplicatesPromise.current?.cancel();
			matchesFromProspPromise.current?.cancel();
		};
	}, []);

	useEffect(() => {
		const matchesUpdated = Tools.$rootScope.$on('matcher.updated', () => {
			// Need time for the batch to index
			setTimeout(() => {
				fetchMatches();
			}, 5000);
		});

		return () => {
			matchesUpdated();
		};
	}, [matchesFromProsp]);

	const goToCleaningPage = (url: string) => {
		history.push(url);
		close();
	};

	const localStorage = new LocalStorage();
	const shouldDisplayModal =
		(localStorage.getValueRaw('showMatchCompaniesBeforeMerge') as string | undefined) ?? 'true';

	const allDataIsCleaned = duplicateClients === 0 && duplicateContacts === 0 && matchesFromProsp === 0;

	return (
		<FullscreenModal className={classes.b()}>
			<ModalHeader onClose={close} />
			<ModalContent>
				<Block space="ptxl mbxl">
					<Block space="pbxl">
						<Block space="pbxl">
							<Headline>{t('cleanMyData.title')} </Headline>
						</Block>
						{hasMatchesFromProsp ? (
							<ButtonBox
								icon="home-refresh"
								selectable={false}
								title={t('cleanMyData.companiesToUpdate', {
									numberOfCompanies: formatFetchedAmount(matchesFromProsp)
								})}
								subtitle={t('cleanMyData.matchCompanies')}
								onClick={() => goToCleaningPage(`/${customerId}/soliditet/matcher/`)}
							/>
						) : null}
						<Flex space="mtl" gap="u4">
							<ButtonBox
								icon="building"
								selectable={false}
								title={t('cleanMyData.duplicateCompanies', {
									numberOfCompanies: formatFetchedAmount(duplicateClients)
								})}
								subtitle={t('cleanMyData.mergeCompaniesOrContacts')}
								onClick={() => {
									if (
										matchesFromProsp > 0 &&
										shouldDisplayModal === 'true' &&
										duplicateClients !== 0
									) {
										openModal('MatchCompaniesBeforeMerge', {
											goToCleaningPage: goToCleaningPage as (path?: string | undefined) => void,
											customerId: customerId
										});
									} else {
										goToCleaningPage('/detected-duplicates-landing-page/accounts');
									}
								}}
							/>
							<ButtonBox
								icon="user"
								selectable={false}
								title={t('cleanMyData.duplicateContacts', {
									numberOfCompanies: formatFetchedAmount(duplicateContacts)
								})}
								subtitle={t('cleanMyData.mergeCompaniesOrContacts')}
								onClick={() => goToCleaningPage('/detected-duplicates-landing-page/contacts')}
							/>
						</Flex>
						<Flex space="mtl" gap="u4">
							<ButtonBox
								icon="companies-contacts"
								selectable={false}
								title={t('cleanMyData.companiesWithoutContacts.title', {
									numberOfCompanies: formatFetchedAmount(numberOfClientsWithoutContacts)
								})}
								subtitle={t('cleanMyData.companiesWithoutContacts.description')}
								onClick={() => {
									history.push('/companies?id=standard6');
									close();
								}}
							/>
							{hasInactivateContacts ? <InactivateContactsBox /> : null}
						</Flex>
					</Block>
					<Card color={allDataIsCleaned ? 'success-1' : 'super-light-blue'} space="mtxl ptl pll">
						<Flex>
							<Block>
								<Text bold color={allDataIsCleaned ? 'success-6' : 'blue'} space="mbs">
									{t('cleanMyData.cardTitle')}
								</Text>
								<Text color={allDataIsCleaned ? 'success-6' : 'blue'}>{t('cleanMyData.cardText')}</Text>
								<Flex space="mtl pbl">
									<Help articleId={1509} sidebar>
										<Icon color="dark-green" name="question-circle" space="mrm" />
										<Link color="dark-green">{t('default.readMore')}</Link>
									</Help>
								</Flex>
							</Block>
							<Block className={classes.elem('imgContaner').b()}>
								<img
									src={
										allDataIsCleaned
											? 'img/cleanMyData/Cleaning_person_success.svg'
											: 'img/cleanMyData/Cleaning_person.svg'
									}
									alt="cleaningPerson"
								/>
							</Block>
						</Flex>
					</Card>
				</Block>
			</ModalContent>
		</FullscreenModal>
	);
};

export default CleanMyData;
