import CompanyType from './CompanyType';
import generateCompanyTypeFilter from './CompanyTypeLookup';
import generateEmployeesFilter from './Employees';
import generateFinancialFilter from './Financials';
import generateLocationFilter from './Location';
import generateIndustryFilter from './Industry';
import generateIndustryCPVFilter from './IndustryCPV';
import Name from './Name';
import Keywords from './Keywords';
import Headquarters from './Headquarters';
import {
	getDefaultIndustryFieldFromCountry,
	getDefaultCurrencyFromCountry,
	getDefaultCompanyFormFieldFromCountry
} from 'App/services/prospectingService';

import type { Country } from 'App/services/prospectingService';

const getLocationDisplayTypeFromCountry = (countryCode: Country) => {
	switch (countryCode) {
		case 'SE':
			return 'address';
		default:
			return 'prospectingAddressNO';
	}
};

export default (countryCode: Country) => {
	const useRange = ['SE'].includes(countryCode);
	const locationMinCount = ['SE', 'NO', 'GB'].includes(countryCode) ? 0 : 50;
	const includeMunicipality = ['SE'].includes(countryCode) ? true : false;
	const industryField = getDefaultIndustryFieldFromCountry(countryCode);
	const companyFormField = getDefaultCompanyFormFieldFromCountry(countryCode);
	const currency = getDefaultCurrencyFromCountry(countryCode);
	const locationDisplayType = getLocationDisplayTypeFromCountry(countryCode);

	const Location = generateLocationFilter(countryCode, includeMunicipality, locationMinCount, {
		displayType: locationDisplayType
	});
	const Industry = generateIndustryFilter(industryField, countryCode);
	const IndustryCPV = generateIndustryCPVFilter('cpvCode');
	const Employees = generateEmployeesFilter(useRange);
	const Financials = generateFinancialFilter(currency, useRange);
	// Maby use this for all countries?
	const CompanyTypeLookup = generateCompanyTypeFilter(countryCode, companyFormField);

	return {
		CompanyType,
		CompanyTypeLookup,
		Location,
		Industry,
		Employees,
		Financials,
		IndustryCPV,
		Name,
		Keywords,
		Headquarters,
		RegDate: {
			filterName: 'RegDate',
			field: 'registrationDate',
			title: 'default.founded',
			inputType: 'date',
			type: 'dateRange',
			entity: 'account',
			showOnSegment: true,
			presets: [
				'whenever',
				'currentMonth',
				'lastMonth',
				'currentQuarter',
				'lastQuarter',
				'currentYear',
				'lastYear',
				'custom'
			]
		},
		NotInUpsales: {
			filterName: 'NotInUpsales',
			title: 'In upsales',
			type: 'radio',
			entity: 'account',
			field: 'notInUpsales',
			showOnSegment: false,
			displayType: 'radio',
			inputType: 'boolean',
			options: [
				{ text: 'default.all', value: null, inactive: true },
				{ text: 'soliditet.allMatches', value: false },
				{ text: 'soliditet.doesNotExistinUpales', value: true }
			]
		}
	};
};
