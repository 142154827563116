import { Block, Input, Label, Toggle, Text, Flex, Select, EllipsisTooltip, Help } from '@upsales/components';
import { ResourceMultiSelect } from 'App/components/ResourceSelect';
import { useCustomFields, useProjectPlanTypes, useStages, useUsers } from 'App/components/hooks/appHooks';
import { useForceRender, useSoftDeployAccess } from 'App/components/hooks';
import CustomFields from 'App/components/CustomFields';
import FormObserver, {
	getCustomFieldModel,
	mapCustomValuesToArray,
	mapCustomValuesToObject
} from 'App/components/FormObserver';
import Product from 'Resources/Product';
import ProductAttributes from 'App/babel/attributes/ProductAttributes';
import ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';
import React, { useMemo, useRef, useEffect, ComponentProps, useCallback } from 'react';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import { ProjectPlanType } from 'App/resources/Model/ProjectPlan';
import { mapToSelectItem } from 'Components/EditProjectPlan/Helpers/Helpers';
import { ProjectPlanCustomField } from 'App/resources/Model/CustomField';
import type ProjectPlanStage from 'App/resources/Model/ProjectPlanStage';
import BemClass from '@upsales/components/Utils/bemClass';
import './ProjectSettings.scss';
type BlockProps = ComponentProps<typeof Block>;

type Props = BlockProps & {
	projectPlanTemplate: ProjectPlanTemplate;
	onNameChange: (name: string) => void;
	onUserChange: (user: ProjectPlanTemplate['user']) => void;
	onProductsChange: (products: { id: number; name: string; title: string; isRecurring: number }[]) => void;
	onStageChange: (id: number) => void;
	onProjectPlanTypeChange: (onProjectPlanTypeChange: ProjectPlanType) => void;
	onProjectPlanStageChange: (onProjectPlanStageChange: ProjectPlanStage) => void;
	onActiveChange: (active: boolean) => void;
	onCustomChange: (custom: any) => void;
	onAssignTasksToManagerChange: (assignTasksToManager: boolean) => void;
};

const addProductFilters = (rb: RequestBuilder, templateId?: number, selectedStageProbability?: number) => {
	rb.addFilter(ProductAttributes.active, comparisonTypes.Equals, true);

	const or = rb.orBuilder();
	or.next();
	or.addFilter(ProductAttributes.projectPlanTemplate.attr.id, comparisonTypes.Equals, null);
	if (templateId) {
		or.next();
		or.addFilter(ProductAttributes.projectPlanTemplate.attr.id, comparisonTypes.Equals, templateId);
	}
	or.done();

	// Subscription products should not be selectable for stages below 100% probability
	if (selectedStageProbability && selectedStageProbability < 100) {
		rb.addFilter(ProductAttributes.isRecurring, comparisonTypes.Equals, 0);
	}
};

const extraFields = ['isRecurring'];

const ProjectSettings = ({
	projectPlanTemplate,
	onNameChange,
	onUserChange,
	onProductsChange,
	onStageChange,
	onActiveChange,
	onProjectPlanTypeChange,
	onProjectPlanStageChange,
	onCustomChange,
	onAssignTasksToManagerChange,
	...wrapperProps
}: Props) => {
	const classes = new BemClass('ProjectSettings');

	const nameInputRef = useRef<HTMLInputElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const lang = useMemo(
		() => ({
			nameInputPlaceholder: T('default.enterName'),
			nameInputLabel: T('default.title'),
			typeSelectPlaceholder: T('editProjectPlanTemplate.typeSelect.placeholder'),
			productSelectLabel: T('editProjectPlan.productSelect.label'),
			productSelectPlaceholder: T('editProjectPlan.productSelect.placeholder'),
			stageSelectPlaceholder: T('editProjectPlan.stageSelect.placeholder'),
			setAsActive: T('editProjectPlanTemplate.details.setAsActive'),
			setAsInactive: T('editProjectPlanTemplate.details.setAsInactive'),
			stageInfo: T('editProjectPlanTemplate.details.stageInfo')
		}),
		[]
	);

	const hasSpecificStageFeature = useSoftDeployAccess('START_PROJECT_AT_SPECIFIC_STAGE');
	const hasNewFields = useSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS');
	const hasStagesAndBoards = useSoftDeployAccess('SERVICE_MANAGEMENT_STAGES_AND_BOARDS');
	// Todo: Should be using 'opportunity + done' instead of 'all'
	const stages = useStages('all');
	const users = useUsers('active', false, 'service', true);

	const category = projectPlanTemplate.category ?? projectPlanTemplate.projectPlanType?.category;
	const projectPlanTypes = useProjectPlanTypes(category).map(type => mapToSelectItem(type)!);
	const projectPlanStages = (projectPlanTemplate.projectPlanType?.stages ?? [])
		.filter(({ stage }) => stage.category !== 'DONE')
		.map(({ stage }) => mapToSelectItem(stage)!);

	const projectTemplateCustomFilterFn = (field: ProjectPlanCustomField) => {
		if (!field.types?.length) {
			return true;
		}
		if (projectPlanTemplate.projectPlanType?.id) {
			return field.types.some(type => type.id === projectPlanTemplate.projectPlanType.id);
		}
		const fieldTypeIds = new Set(field.types.map(t => t.id));
		return projectPlanTypes.some(type => fieldTypeIds.has(type.id));
	};

	const customFields = (useCustomFields('projectPlan') as ProjectPlanCustomField[]).filter(
		projectTemplateCustomFilterFn
	);

	// Neither autofocus or autoFocus does not work for Input it seems like, talked to Alex and he said this was the way to do it right now
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (nameInputRef.current) {
				nameInputRef.current.focus();
			}
		}, 250);
		return () => clearTimeout(timeout);
	}, []);

	// The following two lines is needed so that the component re-renders after it is mounted, without this wrapperRef.current is null, and the dropdown will not be positioned correctly
	// You should be able to provide a ref as anchor to Select to so that we don't need this hack
	const { triggerRender } = useForceRender();
	useEffect(() => triggerRender(), []);

	const selectedProducts = useMemo(
		() => projectPlanTemplate.products.map(({ id, name, isRecurring }) => ({ id, name, title: name, isRecurring })),
		[projectPlanTemplate.products]
	);

	const selectedStage = useMemo(() => {
		const s = projectPlanTemplate.opportunityStageId
			? stages.find(stage => stage.id === projectPlanTemplate.opportunityStageId)
			: null;
		return s ? { id: s.id, title: s.name } : null;
	}, [projectPlanTemplate.opportunityStageId]);

	const validatonModel = {
		custom: getCustomFieldModel(customFields, undefined, 'projectPlan', true)
	};
	const initialCustomValues = {
		custom: mapCustomValuesToObject(projectPlanTemplate.custom ?? [], customFields)
	};

	const hasSubscriptionProduct = projectPlanTemplate.products.some(product => product.isRecurring);
	const selectedStageProbability = stages.find(stage => stage.id === selectedStage?.id)?.probability;
	const modifyRb = useCallback(
		rb => {
			addProductFilters(rb, projectPlanTemplate.id, selectedStageProbability);
		},
		[selectedStageProbability, projectPlanTemplate.id]
	);

	return (
		<Block space="ptxl" ref={wrapperRef} {...wrapperProps}>
			<Flex direction="column" gap={'u5'}>
				<Block>
					<Label
						maxLength={100}
						maxLengthReachedText={T('default.characterLimitReached')}
						value={projectPlanTemplate.name}
						required
					>
						{lang.nameInputLabel}
					</Label>
					<Input
						maxLength={100}
						inputRef={nameInputRef}
						placeholder={lang.nameInputPlaceholder}
						onChange={e => onNameChange(e.target.value)}
						state={projectPlanTemplate.name.length > 0 ? 'focus' : 'warning'}
						value={projectPlanTemplate.name}
					/>
				</Block>
				{hasNewFields ? (
					<>
						<Block>
							<Label>{T('defaultView.assignee')}</Label>
							<Select<{ id: number; title: string }>
								options={users.map(user => ({ id: user.id, title: user.name }))}
								onChange={value => {
									onUserChange(value ? { id: value.id, name: value.title } : null);
								}}
								onClear={() => onUserChange(null)}
								placeholder={T('editProjectPlanTemplate.userSelect.placeholder')}
								anchor={wrapperRef.current}
								value={
									projectPlanTemplate.user
										? { ...projectPlanTemplate.user, title: projectPlanTemplate.user.name }
										: null
								}
								openOnFocus
							/>
						</Block>
						{category === 'PROJECT' && projectPlanTemplate.user ? (
							<Block>
								<Flex alignItems="center" gap="u1">
									<Toggle
										onChange={onAssignTasksToManagerChange}
										checked={projectPlanTemplate.assignTasksToManager}
									/>
									<EllipsisTooltip title={projectPlanTemplate.user.name}>
										<Text className={classes.elem('assign-manager-text').b()}>
											{T('projectPlan.assignToManager', {
												manager: projectPlanTemplate.user.name
											})}
										</Text>
									</EllipsisTooltip>
									<Help articleId={1505} />
								</Flex>
							</Block>
						) : null}
						<Block>
							<Label required>{T('default.type')}</Label>
							<Select<ProjectPlanType & { title: string }>
								options={projectPlanTypes}
								value={mapToSelectItem(projectPlanTemplate.projectPlanType)}
								onChange={onProjectPlanTypeChange}
								anchor={wrapperRef.current}
								placeholder={lang.typeSelectPlaceholder}
								required
							/>
						</Block>

						{hasStagesAndBoards ? (
							<Block>
								<Label required>{T('projectPlan.projectStage')}</Label>
								<Select<ProjectPlanStage & { title: string }>
									options={projectPlanStages}
									value={mapToSelectItem(projectPlanTemplate.projectPlanStage)}
									onChange={onProjectPlanStageChange}
									anchor={wrapperRef.current}
									placeholder={lang.stageSelectPlaceholder}
									required
								/>
							</Block>
						) : null}

						<Block>
							<Label>{lang.stageInfo}</Label>
							<Text size="sm" color="grey-11">
								{T('editProjectPlanTemplate.details.stageInfo.subtitle', {
									category:
										category === 'SERVICE'
											? T('projectPlanType.service').toLowerCase()
											: T('projectPlanType.project').toLowerCase()
								})}
							</Text>
						</Block>
					</>
				) : null}
				<Block>
					<Label>{lang.productSelectLabel}</Label>
					<ResourceMultiSelect
						key={(selectedStageProbability === 100).toString()} // This causes the options to be reloaded the next time the select is opened after changing between opportunity and order stage
						modifyRb={modifyRb}
						limit={50}
						resource={Product}
						extraFields={extraFields}
						required={false}
						placeholder={lang.productSelectPlaceholder}
						anchor={wrapperRef.current}
						value={selectedProducts}
						onChange={products =>
							onProductsChange(
								products as { id: number; name: string; title: string; isRecurring: number }[]
							)
						}
						hideSelected
					/>
				</Block>
				{/* Todo: This select should be changed into a StageSelect select from app/components/Inputs/Selects once that exists */}
				{hasSpecificStageFeature && (
					<Block data-testid="stage-input">
						<Label required>
							{T('editProjectPlan.stageSelect.label', {
								category:
									category === 'SERVICE'
										? T('projectPlanType.service').toLowerCase()
										: T('projectPlanType.project').toLowerCase()
							})}
						</Label>
						<Select
							options={(hasSubscriptionProduct ? stages.filter(s => s.probability === 100) : stages).map(
								stage => ({ id: stage.id, title: stage.name })
							)}
							required={true}
							placeholder={lang.stageSelectPlaceholder}
							anchor={wrapperRef.current}
							value={selectedStage}
							onChange={stage => onStageChange(stage.id)}
						/>
					</Block>
				)}
				<Flex>
					<Toggle size="lg" checked={projectPlanTemplate.active} onChange={onActiveChange} />
					<Text space="mlm">{projectPlanTemplate.active ? lang.setAsActive : lang.setAsInactive}</Text>
				</Flex>
				<Block>
					<FormObserver<{ custom: { [key: string]: string | null } }>
						validateOnMount={false}
						onChange={values => {
							const mappedCustom = mapCustomValuesToArray(values.custom);
							onCustomChange(mappedCustom);
						}}
						model={validatonModel}
						initialValues={initialCustomValues}
					>
						{({ onFormChange, inputProps }) => (
							<CustomFields
								type="projectPlan"
								inputProps={inputProps}
								onChange={(id: number, value: string) => onFormChange(`custom.Custom_${id}`, value)}
								showFormGroupName={true}
								allFieldsOptional={true}
								allFieldsEditable={true}
								filterFn={projectTemplateCustomFilterFn}
							/>
						)}
					</FormObserver>
				</Block>
			</Flex>
		</Block>
	);
};

export default ProjectSettings;
