import AISignals from '../../../../resources/Model/AISignals';
import BemClass from '@upsales/components/Utils/bemClass';
import { Flex, Text } from '@upsales/components';
import React from 'react';
import { useTranslation } from 'Components/Helpers/translate';

import './MailAISidebar.scss';

type MailAISidebarProps = {
	signals: AISignals;
	className?: string;
};

const MailAISidebar = ({ signals, className }: MailAISidebarProps) => {
	const classes = new BemClass('MailAISidebar').add(className);
	const { t } = useTranslation();

	const renderFlags = (text: string, flags: string[], style: string, indicator: string) => (
		<Flex direction="column" gap="u2">
			<Flex alignItems="center" gap="u2">
				<Flex
					className={classes.elem('signalAnalysisContainer').mod(style).b()}
					alignItems="center"
					justifyContent="center"
				>
					<div>{indicator}</div>
				</Flex>
				<Text bold>{text}</Text>
			</Flex>
			{flags.map((item, index) => (
				<Text key={index} color="grey-11">
					{item}
				</Text>
			))}
		</Flex>
	);
	return (
		<Flex className={classes.b()} direction="column" space="ptl prl pbl pll" gap="u5">
			<Text bold className={classes.elem('summary').b()}>
				{signals.summary}
			</Text>
			{signals.negative && signals.redFlags?.length
				? renderFlags(t('default.negative'), signals.redFlags, 'negative', '-')
				: null}
			{signals.positive && signals.greenFlags?.length
				? renderFlags(t('default.positive'), signals.greenFlags, 'positive', '+')
				: null}
		</Flex>
	);
};

export default MailAISidebar;
