import React, { useEffect, useRef, useState } from 'react';
import { StateFrame, Button, Text, Tooltip, Flex, Loader, Block } from '@upsales/components';
import type Client from 'App/resources/Model/Client';
import { trunc } from 'lodash';
import { useTranslation } from 'Components/Helpers/translate';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { useSoftDeployAccess, useFeatureAvailable } from 'App/components/hooks';
import { Feature } from 'Store/actions/FeatureHelperActions';
import customerInCompanyGroup from 'Resources/customerInCompanyGroup';
import logError from 'App/babel/helpers/logError';
import ClientResource from 'App/resources/Client';


type Props = {
	client: Client | { id: Client['id'] };
	close?: ((prospectingId: Client['prospectingId']) => void | undefined) | undefined;
	border?: string;
	borderColor?: string;
};

const CustomerInGroupStateFrame = ({
	close,
	client: initialClientProp,
	border = '',
	borderColor = 'grey-6'
}: Props) => {
	const { t } = useTranslation();

	const lang = {
		existingCustomers: t('account.companyGroup.existingCustomers'),
		showGroup: t('account.companyGroup.showGroup')
	};

	const hasGroupBonanza = useSoftDeployAccess('GROUP_BONANZA');
	const hasCompanyGroupCardFeature = useFeatureAvailable(Feature.COMPANY_GROUP_CARD) && hasGroupBonanza;

	const customerGroupReq = useRef<CancelablePromise<
		Awaited<ReturnType<typeof customerInCompanyGroup.fetchCompaniesInGroup>>
	> | null>(null);
	const journeyStepReq = useRef<CancelablePromise<Awaited<ReturnType<typeof getClientJourneyStep>>> | null>(null);
	const [customersInGroup, setCustomersInGroup] = useState<Client[] | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [journeyStep, setJourneyStep] = useState<string | null>(null);
	const [forceReload, setForceReload] = useState<number>(0);
	const [initialClient, setInitialClient] = useState<Client | { id: Client['id'] }>(initialClientProp);

	const off = Tools.$rootScope.$on('account.updated', (e, newClient) => {
		if (newClient?.id !== initialClient?.id) return;

		setInitialClient({ ...initialClient, journeyStep: newClient?.journeyStep });
		setForceReload(forceReload + 1);
	});

	const hasJourneyStep = (client: Client | { id: Client['id'] }): client is Client => {
		return (client as Client).journeyStep !== undefined;
	};

	const getClientJourneyStep = async (clientId: Client['id']) => {
		if (hasJourneyStep(initialClient)) {
			return initialClient.journeyStep;
		}

		const { data: client } = await ClientResource.get(clientId);

		return client.journeyStep;
	};

	useEffect(() => {
		if (!initialClient?.id || !hasCompanyGroupCardFeature) return;

		journeyStepReq.current?.cancel();
		journeyStepReq.current = makeCancelable(getClientJourneyStep(initialClient.id));

		journeyStepReq.current.promise
			.then(journeyStep => {
				setJourneyStep(journeyStep);

				if (journeyStep === 'customer') return;

				customerGroupReq.current?.cancel();
				setLoading(true);
				customerGroupReq.current = makeCancelable(
					customerInCompanyGroup.fetchCompaniesInGroup(initialClient.id)
				);

				customerGroupReq.current.promise
					.then(({ data }) => {
						setCustomersInGroup(data);
					})
					.catch(err => {
						logError(err, 'Failed to fetch client data');
					})
					.finally(() => setLoading(false));
			})
			.catch(err => {
				logError(err, 'Failed to fetch client data');
			});

		return () => {
			customerGroupReq.current?.cancel();
			journeyStepReq.current?.cancel();

			off();
		};
	}, [initialClient?.id, forceReload]);

	if (!customersInGroup || customersInGroup.length === 0 || journeyStep === 'customer') {
		return null;
	}

	const [first, ...rest] = customersInGroup;
	const counter = rest.length;

	const truncDesc = trunc(rest.map(customer => customer.name).join(', '), { length: 75 });

	return loading ? (
		<Flex justifyContent="center" alignItems="center" space="pbxl">
			<Loader size="sm" />
		</Flex>
	) : (
		<Block space="mbl" border={border} borderRadius borderColor={borderColor}>
			<StateFrame
				state="warning"
				title={lang.existingCustomers}
				subtitle={
					<Text>
						{first.name}

						{counter > 0 ? (
							<>
								{' + '}
								<Tooltip title={truncDesc}>{counter}</Tooltip>
							</>
						) : null}
					</Text>
				}
			>
				{first && first?.prospectingId ? (
					<Button
						onClick={() => {
							if (close) {
								close(first.prospectingId);
							}
						}}
					>
						{lang.showGroup}
					</Button>
				) : null}
			</StateFrame>
		</Block>
	);
};

export default CustomerInGroupStateFrame;
