import {
	AssistChip,
	Block,
	Flex,
	FullscreenModal,
	Icon,
	Label,
	Loader,
	ModalContent,
	ModalHeader,
	Select,
	SelectAsync,
	Text,
	Title,
	Tooltip
} from '@upsales/components';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ModalProps } from '../Modals/Modals';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import bemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import './MergeTickets.scss';
import Ticket from 'App/resources/Model/Ticket';
import TicketResource from 'App/resources/Ticket';
import RequestBuilder from 'Resources/RequestBuilder';
import logError from 'Helpers/logError';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { MultiSelectContext } from '../MultiselectProvider/MultiselectProvider';
import { useAnimationData } from '../hooks';
import Lottie from 'react-lottie';
import ComparisonTypes from 'Resources/ComparisonTypes';
import TicketAttributes from 'App/babel/attributes/Ticket';
import _ from 'lodash';
import openModal from 'App/services/Modal';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import T from 'Components/Helpers/translate';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';

type Props = ModalProps & {
	rb: RequestBuilder;
	multiSelectContext: MultiSelectContext;
};

const formatSelectItem = (ticket: Ticket) => {
	let title = `${T('default.id2')} ${ticket.id} - ${ticket.title}`;
	if (ticket.client) {
		title += ` - ${ticket.client.name}`;
	}
	if (ticket.contact) {
		title += ` - ${ticket.contact.name}`;
	}

	return {
		id: ticket.id,
		title
	};
};

const hasMoreThanOneCompany = (mainTicket: Ticket | null, selectedTickets: Ticket[]) => {
	const companies = selectedTickets.map(ticket => ticket.client?.id);
	if (mainTicket) {
		companies.push(mainTicket.client?.id);
	}
	const uniqueCompanies = new Set(companies);
	return uniqueCompanies.size > 1;
};

const getMergeErrors = (mainTicket: Ticket | null, selectedTickets: Ticket[]) => {
	const mergeErrors: { [key: string]: string } = {};
	let hasErrors: boolean = false;
	if (!mainTicket) {
		mergeErrors.mainTicket = T('ticket.merge.error.mainTicket');
		hasErrors = true;
	}
	if (selectedTickets.length === 0) {
		mergeErrors.selectedTickets = T('ticket.merge.error.selectedTickets');
		hasErrors = true;
	}
	return { mergeErrors, hasErrors };
};

const getMostCommonCompany = (tickets: Ticket[]) => {
	const companies = tickets.map(ticket => ticket.client?.id);

	if (companies.length === 0) {
		return undefined;
	}

	const mostCommonCompany = companies.reduce((a, b, i, arr) =>
		arr.filter(v => v === a).length >= arr.filter(v => v === b).length ? a : b
	);
	return mostCommonCompany;
};

const MergeTickets = ({ className, close, rb, multiSelectContext }: Props) => {
	const [mainTicket, setMainTicket] = useState<Ticket | null>(null);
	const [selectedTickets, setSelectedTickets] = useState<Ticket[]>([]);
	const [tickets, setTickets] = useState<Ticket[]>([]);
	const [loading, setLoading] = useState(true);
	const [merging, setMerging] = useState(false);
	const classes = new bemClass('MergeTickets', className);
	const { t } = useTranslation();
	const lang = {
		cancel: t('default.cancel'),
		confirm: t('default.merge.confirm'),
		title: t('ticket.merge.title'),
		paragraph1: t('ticket.merge.text.paragraph1'),
		paragraph2: t('ticket.merge.text.paragraph2'),
		paragraph3: t('ticket.merge.text.paragraph3'),
		select: t('default.select'),
		mainTicket: t('ticket.merge.label.mainTicket'),
		ticketsToMerge: t('ticket.merge.label.mergeTickets'),
		description: t('ticket.merge.description'),
		warning: t('ticket.merge.warning'),
		merge: t('ticket.merge'),
		multipleCompaniesTitle: t('ticket.merge.multipleCompanies.title'),
		multipleCompaniesDescription: t('ticket.merge.multipleCompanies.description')
	};
	const anchor = useRef<Element | null>(null);
	const { animationData } = useAnimationData(() => import('./mergeTickets.json'));
	const selectedTicketsPromise = useRef<CancelablePromise<{
		data: Ticket[];
	}> | null>(null);
	const ticketsPromise = useRef<CancelablePromise<{
		data: Ticket[];
	}> | null>(null);

	const { mergeErrors, hasErrors } = getMergeErrors(mainTicket, selectedTickets);

	const getSelectedTickets = () => {
		const query = rb.addFilter(TicketAttributes.mergedWithId, ComparisonTypes.Equals, null).build();
		selectedTicketsPromise.current = makeCancelable(TicketResource.find(query));
		selectedTicketsPromise.current.promise
			.then(res => {
				setSelectedTickets(res.data);
				setLoading(false);
				return res.data;
			})
			.catch(err => {
				logError('Failed to get tickets', err);
			});
	};

	const fetchTickets = useCallback(
		async (searchString?: string): Promise<{ id: number; title: string }[]> => {
			const requestBuilder = new RequestBuilder();
			requestBuilder.addFilter(TicketAttributes.mergedWithId, ComparisonTypes.Equals, null);
			requestBuilder.addFilter(TicketAttributes.id, ComparisonTypes.NotEquals, mainTicket?.id);
			requestBuilder.limit = 50;

			if (searchString) {
				const searchFilter = requestBuilder.orBuilder();
				searchFilter.next();
				searchFilter.addFilter(TicketAttributes.id, ComparisonTypes.Wildcard, searchString);
				searchFilter.next();
				searchFilter.addFilter(TicketAttributes.title, ComparisonTypes.Wildcard, searchString);
				searchFilter.next();
				searchFilter.addFilter(TicketAttributes.client.attr.name, ComparisonTypes.Wildcard, searchString);
				searchFilter.next();
				searchFilter.addFilter(TicketAttributes.contact.attr.name, ComparisonTypes.Wildcard, searchString);
				searchFilter.done();
				ticketsPromise.current = makeCancelable(TicketResource.find(requestBuilder.build()));
			} else {
				requestBuilder.addSort(TicketAttributes.lastUpdated, false);
				const mostCommonCompanyId = getMostCommonCompany(selectedTickets);

				if (mainTicket) {
					requestBuilder.addFilter(
						TicketAttributes.client.attr.id,
						ComparisonTypes.Equals,
						mainTicket.client?.id
					);
					requestBuilder.addExtraParam('mostCommonCompanyId', mostCommonCompanyId);
				} else {
					if (mostCommonCompanyId) {
						requestBuilder.addFilter(
							TicketAttributes.client.attr.id,
							ComparisonTypes.Equals,
							mostCommonCompanyId
						);
					}
				}
				ticketsPromise.current = makeCancelable(
					TicketResource.findRecommendedMergeTickets(requestBuilder.build())
				);
			}

			return ticketsPromise.current.promise
				.then(({ data }) => {
					setTickets(data);
					return data.map(ticket => formatSelectItem(ticket));
				})
				.catch(err => {
					setTickets([]);
					logError('Failed to get tickets', err);
					return [];
				});
		},
		[mainTicket, selectedTickets]
	);

	useEffect(() => {
		anchor.current = document.getElementsByClassName('MergeTickets')[0];
		getSelectedTickets();

		return () => {
			selectedTicketsPromise?.current?.cancel();
			ticketsPromise?.current?.cancel();
		};
	}, []);

	const changeMainTicket = (ticket: SelectItem) => {
		const selectedTicket = selectedTickets.find(t => t.id === ticket.id);

		if (selectedTicket) {
			const filteredSelectedTickets = selectedTickets.filter(t => t.id !== selectedTicket.id);
			if (mainTicket) {
				filteredSelectedTickets.push(mainTicket);
			}
			setMainTicket(selectedTicket);
			setSelectedTickets(filteredSelectedTickets);
		}
	};

	const changeSelectedTickets = (ticketId: number, add: boolean) => {
		const isSelected = selectedTickets.find(ticket => ticket.id === ticketId);

		if (add && !isSelected) {
			const ticketToAdd: Ticket | undefined = tickets.find(t => t.id === ticketId);
			if (ticketToAdd) {
				setSelectedTickets([...selectedTickets, ticketToAdd]);
			}
		} else {
			const ticketToRemove: Ticket | undefined = selectedTickets.find(t => t.id === ticketId);
			if (ticketToRemove) {
				setSelectedTickets(selectedTickets.filter(t => t.id !== ticketToRemove.id));
			}
		}
	};

	const mergeTickets = async () => {
		setMerging(true);
		const mainTicketCopy = _.cloneDeep(mainTicket)!;
		const rb = new RequestBuilder();
		rb.addFilter(
			TicketAttributes.id,
			ComparisonTypes.Equals,
			selectedTickets.map(t => t.id)
		);

		let involvedContactsFromMergedTickets: Ticket['involved'] = [];

		selectedTickets.forEach(ticket => {
			involvedContactsFromMergedTickets = [...involvedContactsFromMergedTickets, ...ticket.involved];
		});

		if (involvedContactsFromMergedTickets) {
			involvedContactsFromMergedTickets.forEach(ic => {
				if (ic.contact) {
					const shouldbeAddedToInvolved =
						!mainTicketCopy.involved.some(i => i?.contact?.id === ic?.contact?.id) &&
						!mainTicketCopy.involved.some(i => i.email === ic.email);
					if (shouldbeAddedToInvolved) {
						mainTicketCopy.involved.push({ ...ic, type: 'CC' });
					}
				} else {
					if (!mainTicketCopy.involved.some(i => i.email === ic.email)) {
						mainTicketCopy.involved.push({ ...ic, type: 'CC' });
					}
				}
			});
		}

		await TicketResource.mergeTickets({
			to: mainTicketCopy,
			from: selectedTickets,
			name: 'ticket'
		})
			.then(() => {
				// We do this before opening the modal since the comments from the merged tickets need a second to be ready
				multiSelectContext.selectNone();
				return new Promise(resolve => {
					setTimeout(resolve, 1000);
				});
			})
			.then(() => {
				openModal('EditTicket', { ticketId: mainTicketCopy.id });
				close();
			})
			.catch(err => {
				logError('Failed to merge tickets', err);
			})
			.finally(() => {
				setMerging(false);
			});
	};

	return (
		<FullscreenModal className={classes.b()} headerAtTop>
			<ModalHeader title={lang.title} alignContent="left">
				<Flex className={classes.elem('controls').b()}>
					<EditorHeaderButton
						title={lang.cancel}
						onClick={close}
						supertitle={undefined}
						className={classes.elem('cancel').b()}
						noIcon
						next={false}
					/>
					<Tooltip title={Object.values(mergeErrors).join('\n')} disabled={!hasErrors}>
						<div>
							<InlineConfirm
								show
								keepTabPosition
								className={'ticketMerge'}
								onConfirm={() => {
									mergeTickets();
								}}
								redBtn={false}
								secondAction={{ text: lang.cancel, cancel: true, class: 'btn-link' }}
								titleText={lang.multipleCompaniesTitle}
								bodyText={lang.multipleCompaniesDescription}
								btnText={lang.merge}
								disabled={selectedTickets.length === 0 || !mainTicket || merging}
								skipConfirm={!hasMoreThanOneCompany(mainTicket, selectedTickets)}
								noResolve
							>
								<EditorHeaderButton
									title={lang.confirm}
									supertitle={undefined}
									className={undefined}
									noIcon={false}
									next
									icon={'check'}
									disabled={selectedTickets.length === 0 || !mainTicket || merging}
								/>
							</InlineConfirm>
						</div>
					</Tooltip>
				</Flex>
			</ModalHeader>
			<ModalContent color="grey-1">
				<Block
					backgroundColor="grey-1"
					border="s"
					borderColor="grey-6"
					borderRadius
					className={classes.elem('info').b()}
				>
					<Flex>
						<Block>
							<Title color="black" bold size="lg">
								{lang.title}
							</Title>
							<Flex direction="column">
								<Text space="mtm" color="grey-11">
									{lang.paragraph1}
								</Text>
								<Text space="mtl" color="grey-11">
									{lang.paragraph2}
								</Text>
								<Text space="mtl" color="grey-11">
									{lang.paragraph3}
								</Text>
								<Flex>
									<AssistChip type="alert" title={lang.warning} space="mtl" />
								</Flex>
							</Flex>
						</Block>
						<Lottie options={{ animationData }} height={168} width={120} isClickToPauseDisabled={true} />
					</Flex>
				</Block>
				<Block border="s" borderColor="grey-6" borderRadius space="mtxl" className={classes.elem('select').b()}>
					{loading ? (
						<Flex justifyContent="center">
							<Loader />
						</Flex>
					) : (
						<>
							<Label>{lang.mainTicket}</Label>
							<Select
								options={(mainTicket ? [...selectedTickets, mainTicket] : selectedTickets).map(ticket =>
									formatSelectItem(ticket)
								)}
								value={mainTicket ? formatSelectItem(mainTicket) : null}
								onChange={changeMainTicket}
								onClear={() => setMainTicket(null)}
								placeholder={lang.select}
								anchor={anchor.current}
							/>
							<Flex alignItems="center" direction="column" space="mtl mbl">
								<Icon name="arrow-up" />
								<Text space="mts">{lang.description}</Text>
							</Flex>
							<Label>{lang.ticketsToMerge}</Label>
							<SelectAsync
								key={mainTicket?.id}
								value={selectedTickets.map(ticket => formatSelectItem(ticket))}
								onChange={v => changeSelectedTickets(v.id, true)}
								onClear={() => setSelectedTickets([])}
								onRemove={v => changeSelectedTickets(typeof v === 'string' ? parseInt(v) : v, false)}
								fetcher={fetchTickets}
								fetchOnOpen
								multi
								anchor={anchor.current}
								inputMaxHeight={250}
								hideSelected
							/>
						</>
					)}
				</Block>
			</ModalContent>
		</FullscreenModal>
	);
};

export default MergeTickets;
