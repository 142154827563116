import React, { createContext, useReducer, useContext } from 'react';
import { ListViewFilter } from 'App/resources/AllIWant';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ProjectPlanAttributes from 'App/babel/attributes/ProjectPlanAttributes';
import { getConfig, parseFilters } from 'App/helpers/filterHelper';
import ProjectPlanResource from 'App/babel/resources/ProjectPlan';
import logError from 'Helpers/logError';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import type ProjectPlan from 'App/resources/Model/ProjectPlan';
import type CustomField from 'App/resources/Model/CustomField';
import type ProjectBoard from 'App/resources/Model/ProjectBoard';
import LZString from 'lz-string';
import { ProjectBoardFilter } from 'App/resources/Model/ProjectBoard';
import { ComparisonTypeEnum } from 'Resources/ComparisonTypes';
import { getSelfFromState } from 'Store/selectors/AppSelectors';
import store from 'Store/index';

// Define what fields to ask the api for
export enum Fields {
	id = 'id',
	name = 'name',
	client = 'client',
	contact = 'contact',
	startDate = 'startDate',
	endDate = 'endDate',
	startTime = 'startTime',
	endTime = 'endTime',
	finishedTasks = 'finishedTasks',
	openTasks = 'openTasks',
	user = 'user',
	projectPlanPriority = 'projectPlanPriority',
	projectPlanStatus = 'projectPlanStatus',
	projectPlanStage = 'projectPlanStage',
	projectPlanType = 'projectPlanType',
	location = 'location',
	locationLatitude = 'locationLatitude',
	locationLongitude = 'locationLongitude'
}

export type ProjectPlanPartial = Collect<Pick<ProjectPlan, keyof typeof Fields>>;

// Here we base the type on the fields we ask for, so we know  that the type reflects the data we get
type ProjectPlanResourceRes = {
	id: number;
	data: ProjectPlanPartial[];
	total: number;
	loadingMore: boolean;
};

type State = {
	filters: { [filterName: string]: ListViewFilter };
	data: Record<number, ProjectPlanResourceRes>;
	boardLoading: boolean;
	filterConfigs: { [key: string]: FilterConfig };
	filtersVisible: boolean;
	selectedView: ProjectBoard | null;
	hash: string;
	hasChanged: boolean;
	customFields: CustomField[];
};

type Dispatch = (action: any) => void;

type ProviderProps = {
	initialState?: Partial<State>;
	children: React.ReactNode;
	storageKey?: string;
};

type Actions =
	| { type: 'SET_FILTERS'; filters: State['filters'] }
	| { type: 'SET_DATA'; data: State['data'] }
	| { type: 'MOVE_ITEM'; projectPlan: ProjectPlanPartial; destinationColumnId: number; sourceColumnId: number }
	| { type: 'SET_BOARD_LOADING'; boardLoading: State['boardLoading'] }
	| { type: 'SET_FILTERS_VISIBLE'; filtersVisible: State['filtersVisible'] }
	| { type: 'SET_SELECTED_VIEW'; selectedView: State['selectedView'] }
	| { type: 'SET_HAS_CHANGED'; hasChanged: State['hasChanged'] }
	| { type: 'SET_HASH'; hash: State['hash'] };

type ProjectStage = {
	id: number;
	name: string;
};

export const getProjectStages = (projectBoard: State['selectedView']) => {
	if (!projectBoard) {
		return [];
	}
	return projectBoard.projectPlanType.stages
		.sort((a, b) => a.sortId - b.sortId)
		.map(({ stage }) => ({ id: stage.id, name: stage.name, color: stage.color }));
};

const ProjectBoardContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

// Sort the array of column data to try to match the backend sorting. This is used when data changes locally without a new request (on add/update/drop etc.)
const localDataSort = (data: ProjectPlanPartial[]) => {
	return data.sort((a, b) => {
		if (a.endDate && b.endDate) {
			return a.endDate.getTime() - b.endDate.getTime();
		}
		return 0;
	});
};

const reducer = (state: State, action: Actions) => {
	switch (action.type) {
		case 'SET_FILTERS':
			return {
				...state,
				filters: action.filters
			};
		case 'SET_DATA':
			return {
				...state,
				data: action.data
			};
		case 'MOVE_ITEM':
			return {
				...state,
				data: {
					...state.data,
					[action.destinationColumnId]: {
						...state.data[action.destinationColumnId],
						data: localDataSort([...state.data[action.destinationColumnId].data, action.projectPlan]),
						total: state.data[action.destinationColumnId].total + 1
					},
					[action.sourceColumnId]: {
						...state.data[action.sourceColumnId],
						data: localDataSort(
							state.data[action.sourceColumnId].data.filter(p => p.id !== action.projectPlan.id)
						),
						total: state.data[action.sourceColumnId].total - 1
					}
				}
			};
		case 'SET_BOARD_LOADING':
			return {
				...state,
				boardLoading: action.boardLoading
			};
		case 'SET_FILTERS_VISIBLE':
			return {
				...state,
				filtersVisible: action.filtersVisible
			};
		case 'SET_SELECTED_VIEW':
			return {
				...state,
				selectedView: action.selectedView
			};
		case 'SET_HAS_CHANGED':
			return {
				...state,
				hasChanged: action.hasChanged
			};
		case 'SET_HASH':
			return {
				...state,
				hash: action.hash
			};
		default:
			return state;
	}
};

const getHash = (filters: State['filters']) => {
	const compareObj = Object.values(filters).reduce((acc, filter) => {
		if (!filter.inactive) {
			acc[filter.filterName] = filter.value;
		}
		return acc;
	}, {} as Record<string, any>);
	return LZString.compressToBase64(JSON.stringify(compareObj));
};

export const ProjectBoardProvider = ({ children, initialState: partialState, storageKey }: ProviderProps) => {
	const initialState: State = {
		filters: {},
		data: {},
		boardLoading: true,
		filterConfigs: {},
		filtersVisible: false,
		customFields: [],
		selectedView: null,
		hash: getHash({}),
		hasChanged: false,
		...partialState
	};

	initialState.hash = getHash(initialState.filters);

	const [state, dispatch] = useReducer(reducer, initialState);

	return <ProjectBoardContext.Provider value={{ state, dispatch }}>{children}</ProjectBoardContext.Provider>;
};
const setData = (data: State['data']) => ({ type: 'SET_DATA', data });
const moveItem = (projectPlan: ProjectPlanPartial, destinationColumnId: number, sourceColumnId: number) => ({
	type: 'MOVE_ITEM',
	projectPlan,
	destinationColumnId,
	sourceColumnId
});
const setBoardLoading = (boardLoading: State['boardLoading']) => ({ type: 'SET_BOARD_LOADING', boardLoading });
const setFiltersVisible = (filtersVisible: State['filtersVisible']) => ({
	type: 'SET_FILTERS_VISIBLE',
	filtersVisible
});

/**
 * Get the request builder for the filters
 * @returns {RequestBuilder}
 */
const getRb = (state: State) => {
	return parseFilters(state.filters, '', null, null, {
		getConfig: name => getConfig(name, state.filterConfigs || {}, state.customFields) // TODO: Add customfields here in future PR
	});
};

const getColumnData = (id: number, rb: RequestBuilder, offset = 0) => {
	rb.addFilter(ProjectPlanAttributes.projectPlanStage.attr.id, comparisonTypes.Equals, id);
	rb.limit = 50;
	rb.offset = offset;
	rb.fields = Object.keys(Fields);
	rb.addSort('endDate', true);
	return ProjectPlanResource.find(rb.build()).then(({ data, metadata }) => {
		const totals = { totalProjects: 0, totalServices: 0 };
		data.forEach(projectPlan => {
			if (projectPlan.projectPlanType.category === 'PROJECT') {
				totals.totalProjects++;
			} else {
				totals.totalServices++;
			}
		});

		return {
			id,
			data,
			total: metadata.total,
			multiTotal: totals,
			loadingMore: false
		};
	});
};

/**
 * Get the board data
 * @param {boolean} silent - If true, the loading spinner will not be shown
 */
const getBoardData =
	(dispatch: Dispatch, state: State) =>
	(silent = false) => {
		if (!silent) {
			dispatch(setBoardLoading(true));
		}

		const stages = getProjectStages(state.selectedView);
		// Get data for all columns
		Promise.all(stages.map(stage => getColumnData(stage.id, getRb(state))))
			.then(res => {
				dispatch(setData(res.reduce((map, data) => ({ ...map, [data.id]: data }), {})));
				dispatch(setBoardLoading(false));
			})
			.catch(e => {
				logError(e, 'Failed to load the project board');
			});
	};

/**
 * Load more items for a column
 * @param {number} columnId
 */
const loadMore = (dispatch: Dispatch, state: State) => (columnId: number) => {
	const { data } = state;
	dispatch(setData({ ...data, [columnId]: { ...data[columnId], loadingMore: true } }));
	// Adding items to the data array could f*ck up the pagination
	getColumnData(columnId, getRb(state), data[columnId].data.length)
		.then(res => {
			dispatch(
				setData({
					...data,
					[columnId]: { ...data[columnId], data: [...data[columnId].data, ...res.data], loadingMore: false }
				})
			);
		})
		.catch(e => {
			logError(e, 'Failed to load board column data');
			dispatch(setData({ ...data, [columnId]: { ...data[columnId], loadingMore: false } }));
		});
};

let getDataTimer: NodeJS.Timeout | null = null;
const setFilters =
	(dispatch: Dispatch, state: State) =>
	(filters: State['filters'], debounce = false) => {
		const activeFilters = Object.values(filters).reduce((acc, filter) => {
			if (!filter.inactive) {
				acc[filter.filterName] = filter;
			}
			return acc;
		}, {} as State['filters']);
		dispatch({ type: 'SET_FILTERS', filters: activeFilters });
		dispatch({ type: 'SET_HAS_CHANGED', hasChanged: state.hash !== getHash(activeFilters) });
		dispatch({
			type: 'SET_SELECTED_VIEW',
			selectedView: { ...state.selectedView, filters: Object.values(activeFilters) }
		});
		if (getDataTimer) {
			clearTimeout(getDataTimer);
		}
		if (debounce) {
			getDataTimer = setTimeout(() => getBoardData(dispatch, { ...state, filters: activeFilters })(), 400);
		} else {
			getBoardData(dispatch, { ...state, filters: activeFilters })();
		}
	};

const saveOnDragEnd =
	(dispatch: Dispatch, state: State) =>
	(projectPlan: ProjectPlanPartial, destinationColumn: ProjectStage, sourceColumn: ProjectStage) => {
		// Move projectPlan to new stage visually on drop, if save fails we will revert the move
		dispatch(moveItem(projectPlan, destinationColumn.id, sourceColumn.id));

		// Save the dropped projectPlan
		ProjectPlanResource.save({ id: projectPlan.id, projectPlanStage: { id: destinationColumn.id } }).catch(e => {
			logError(e, 'Failed to save project plan on drag end');
			// Revert the move if save fails
			dispatch(moveItem(projectPlan, sourceColumn.id, destinationColumn.id));
		});
	};

const setSelectedView = (dispatch: Dispatch, state: State) => (selectedView: ProjectBoard) => {
	// Init filters
	const self = getSelfFromState(store.getState().App);
	const mappedFilters =
		selectedView.filters.reduce((res, filter) => {
			res[filter.filterName] = filter;
			return res;
		}, {} as { [key: string]: ProjectBoardFilter }) ?? {};

	if (selectedView.standard && selectedView.projectPlanType.category === 'PROJECT') {
		mappedFilters['User'] = {
			filterName: 'User',
			value: [self?.id],
			comparisonType: ComparisonTypeEnum.Equals
		};
	}
	const shouldGetBoardData = state.selectedView?.id !== selectedView.id || !Object.keys(state.data).length;

	dispatch({ type: 'SET_SELECTED_VIEW', selectedView });
	dispatch({ type: 'SET_FILTERS', filters: mappedFilters });
	dispatch({ type: 'SET_HAS_CHANGED', hasChanged: false });
	dispatch({ type: 'SET_HASH', hash: getHash(mappedFilters) });

	if (shouldGetBoardData) {
		getBoardData(dispatch, { ...state, selectedView, filters: mappedFilters })();
	}
};

export function useProjectBoardContext() {
	const context = useContext(ProjectBoardContext);

	if (typeof context === 'undefined') {
		throw new Error('useProjectBoardContext must be used within a Provider');
	}

	const { state, dispatch } = context;

	const actions = {
		setFilters: setFilters(dispatch, state),
		getBoardData: getBoardData(dispatch, state),
		loadMore: loadMore(dispatch, state),
		saveOnDragEnd: saveOnDragEnd(dispatch, state),
		setFiltersVisible: (filtersVisible: State['filtersVisible']) => dispatch(setFiltersVisible(filtersVisible)),
		setSelectedView: setSelectedView(dispatch, state)
	};

	return { state, ...actions };
}
