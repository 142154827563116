import React from 'react';

import LocationPicker, { type Props as LocationPickerProps } from './LocationPicker';
import GeocodeResource from 'App/resources/Geocode';
import { type StructuredAddress, type Address } from 'App/resources/Model/Geocode';
import { formatAddressComponents } from 'App/components/Map/addressMappers';
import { ClientAddress } from 'App/resources/Model/Client';

type OnLocationChange = {
	location: Address | StructuredAddress | null;
	latitude: number | null;
	longitude: number | null;
};

type Props = Omit<LocationPickerProps, 'onChange'> & {
	onChange?: (result: OnLocationChange) => void;
};

const GeoLocationPicker = ({ onChange, ...rest }: Props) => {
	const onLocationChange = async (
		address: string | StructuredAddress | google.maps.GeocoderResult | ClientAddress | null
	) => {
		if (!onChange) {
			return;
		}
		if (!address) {
			onChange({ location: null, latitude: null, longitude: null });
			return;
		}
		// The old way of sending it. With the feature flag SERVICE_MAP, we should always get StructuredAddress | GeoCoderResult | null
		if (typeof address === 'string') {
			const [street, zipcode, city] = address.split(',');
			const structuredAddress: StructuredAddress = {
				address: street,
				zipcode,
				city
			};
			onChange({ location: structuredAddress, latitude: null, longitude: null });
			return;
		}

		// Google Maps provides us with a GeocoderResult object with latitude and longitude
		if ('geometry' in address && address.geometry?.location) {
			const latitude = address.geometry.location.lat();
			const longitude = address.geometry.location.lng();

			if ('address_components' in address) {
				onChange({ location: formatAddressComponents(address.address_components), latitude, longitude });
				return;
			}

			// If we only get latitude and longitude, we need to do a reverse lookup to get the missing address
			const { data: addressFound } = await GeocodeResource.lookupLatLong(latitude, longitude);
			if (addressFound) {
				onChange({ location: addressFound, latitude, longitude });
				return;
			} else {
				onChange({
					location: {
						address: (address as google.maps.GeocoderResult).formatted_address,
						city: '',
						zipcode: ''
					},
					latitude,
					longitude
				});
				return;
			}
		}

		// This means that Google has provided us with an address but without latitude and longitude.
		// If Google has not been able to provide us with this, then I don't see any point with trying to externally look it up.
		if ('address_components' in address) {
			onChange({
				location: formatAddressComponents(address.address_components),
				latitude: null,
				longitude: null
			});
			return;
		}

		if ('latitude' in address && 'longitude' in address && address.latitude && address.longitude) {
			onChange({ location: address, latitude: address.latitude, longitude: address.longitude });
			return;
		}

		const { data: addressFound } = await GeocodeResource.lookupAddress(address as StructuredAddress);
		if (addressFound) {
			onChange({
				location: addressFound,
				latitude: addressFound.latitude,
				longitude: addressFound.longitude
			});
			return;
		}
		onChange({ location: address, latitude: null, longitude: null });
	};

	return <LocationPicker {...rest} onChange={onLocationChange} useStructuredAddress />;
};

export default GeoLocationPicker;
