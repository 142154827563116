import { State, getInitialState, setBucketRange, setDescription } from './Helpers';
import React, { createContext, useContext, useReducer } from 'react';
import Actions from './Actions';

type Dispatch = (action: any) => void;

interface ProviderProps {
	description: string;
	children: React.ReactNode;
}

const ACTION_HANDLERS: { [key: string]: (s: State, a: any) => State } = {
	[Actions.SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[Actions.SET_BUCKET_RANGE]: (state, { bucketRange }) => ({ ...state, bucketRange }),
	[Actions.SET_DESCRIPTION]: (state, { description }) => ({ ...state, description })
};

const reducer = (state: State, action: any) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};

const IndustryInsightsContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

export function IndustryInsightsProvider({ description, children }: ProviderProps) {
	const [state, dispatch] = useReducer(reducer, getInitialState(description));
	return <IndustryInsightsContext.Provider value={{ state, dispatch }}>{children}</IndustryInsightsContext.Provider>;
}

export function useIndustryInsights() {
	const context = useContext(IndustryInsightsContext);
	if (!context) {
		throw new Error('useIndustryInsights must be used within IndustryInsightsProvider');
	}

	const { state, dispatch } = context;

	return {
		...state,
		setBucketRange: setBucketRange(dispatch, state),
		setDescription: setDescription(dispatch, state)
	};
}
