import {
	Headline,
	Text,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Icon,
	Tooltip,
	Button,
	Link
} from '@upsales/components';
import AgreementGroupHistoryResource from 'App/babel/resources/AgreementGroupHistory';
import { useSubscriptionGroupContext } from '../Context/SubscriptionGroupContext';
import AgreementGroupHistory from 'App/resources/Model/AgreementGroupHistory';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import bemClass from '@upsales/components/Utils/bemClass';
import React, { useState, useEffect } from 'react';
import logError from 'App/babel/helpers/logError';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import Avatar from 'Components/Avatar';
import { capitalize } from 'lodash';
import moment from 'moment';

import './SubscriptionHistory.scss';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';

type Props = {
	close: () => void;
};

const SubscriptionHistory = ({ close }: Props) => {
	const classes = new bemClass('SubscriptionHistory');
	const {
		state: { id: agreementGroupId, subscriptionMap, currentUUID }
	} = useSubscriptionGroupContext();
	const { metadata, customerId } = useSelector((state: RootState) => state.App);
	const displayInMrr =
		(metadata?.params.SalesModel === 'rr' && metadata?.params.SalesModelOption === 'mrr') ||
		getCMWithRROption() === 'mrr';
	const recurringType = displayInMrr ? 'MRR' : 'ARR';
	const currencyFormat = new CurrencyFormat(metadata?.customerCurrencies?.find(c => c.masterCurrency)?.iso);
	const hasDrawer = Tools.FeatureHelper.hasSoftDeployAccess('HISTORY_TAB_DRAWER');
	let promise: CancelablePromise | undefined;

	const [historyRows, setHistoryRows] = useState<{
		[key: string]: { history: AgreementGroupHistory[]; sum: number };
	}>({});
	const [yearHidden, setYearHidden] = useState<{ [year: string]: boolean }>({});

	const fetchAgreementHistory = () => {
		if (!(agreementGroupId || subscriptionMap[currentUUID].id)) {
			return;
		}
		promise = makeCancelable<{
			data: AgreementGroupHistory[];
		}>(
			AgreementGroupHistoryResource.get((agreementGroupId || subscriptionMap[currentUUID].id) as number, {
				params: {
					isGroup: !!agreementGroupId
				}
			})
		);
		promise.promise
			.then(({ data }: { data: AgreementGroupHistory[] }) => {
				if (!data) {
					return;
				}
				const res: { [key: string]: { history: AgreementGroupHistory[]; sum: number } } = {};
				data.forEach(history => {
					const year = moment(history.arrChangeDate).format('YYYY');
					if (res[year]) {
						res[year].history = [history, ...res[year].history];
						if (history.type !== 'moved' && !isNaN(history.change as number)) {
							res[year].sum += history.change as number;
						}
					} else {
						res[year] = {
							history: [history],
							sum:
								history.type === 'moved' || isNaN(history.change as number)
									? 0
									: (history.change as number)
						};
					}
				});
				setHistoryRows(res);
			})
			.catch(err => logError(err, 'Failed to get agreement history'));
	};

	const getClientHref = (id: number) =>
		Tools.$state.href('account.dashboard', {
			id,
			customerId
		});

	const toggleHidden = async (agreementId: number) => {
		try {
			await AgreementGroupHistoryResource.toggleHidden(agreementId);
			fetchAgreementHistory();
		} catch (err) {
			logError(err, 'Failed to get agreement history');
		}
	};

	useEffect(() => {
		fetchAgreementHistory();
		return () => promise?.cancel();
	}, []);

	const renderDateColumn = (historyRow: AgreementGroupHistory) => (
		<TableColumn>
			<Text>{moment(historyRow.arrChangeDate).format('L')}</Text>
		</TableColumn>
	);

	const renderTypeColumn = (historyRow: AgreementGroupHistory) => {
		const { type, change, movedFrom } = historyRow;
		const text = T(`agreementHistory.type${capitalize(historyRow.type)}`);

		let extra;
		if (type === 'moved') {
			const account = change as { id: number; name: string };
			extra = (
				<Link
					href={getClientHref(movedFrom?.id ?? account.id)}
					onClick={e => {
						close();
						e.stopPropagation();
					}}
				>
					{movedFrom?.name ?? account.name}
				</Link>
			);
		}
		return (
			<TableColumn className={classes.elem('type').b()} size="lg">
				<Text>{text}</Text>
				{extra}
			</TableColumn>
		);
	};

	const userColumn = (historyRow: AgreementGroupHistory) => {
		const user = Tools.AppService.getUsers('active').find(user => user.id === historyRow.changedBy);
		return (
			<TableColumn>
				<div className={classes.elem('user').b()}>
					<Avatar size={28} user={user} />
					<div>
						<Text>{user?.name}</Text>
						<Text color="grey-11">{moment(historyRow.regDate).format('L')}</Text>
					</div>
				</div>
			</TableColumn>
		);
	};

	const renderChangeColumn = (historyRow: AgreementGroupHistory) => {
		let content = `${historyRow.change}`;
		let textColor: React.ComponentProps<typeof Text>['color'] | undefined;
		switch (historyRow.type) {
			case 'productChange':
				content = `${currencyFormat.short(0)} (${recurringType})`;
				break;
			case 'currencyChange': {
				const isPositive = (historyRow.change as number) >= 0;
				content = `${isPositive ? '+' : ''}${currencyFormat.short(
					historyRow.change as number
				)} (${recurringType})`;
				textColor = isPositive ? 'success-4' : 'red';
				break;
			}
			case 'moved':
				content = '';
				break;
			case 'indexIncrease':
			case 'increase':
				content = `+${currencyFormat.short(historyRow.change as number)} (${recurringType})`;
				textColor = 'success-4';
				break;
			case 'lost':
			case 'contraction':
				content = `${currencyFormat.short(historyRow.change as number)} (${recurringType})`;
				textColor = 'red';
				break;
			case 'newAgreement':
				content = `${currencyFormat.short(historyRow.change as number)} (${recurringType})`;
				textColor = 'success-4';
				break;
		}
		if (historyRow.hidden) {
			textColor = 'grey-10';
		}

		return (
			<TableColumn>
				<Text italic={historyRow.hidden} color={textColor}>
					{content}
				</Text>
			</TableColumn>
		);
	};

	const excludeColumn = (historyRow: AgreementGroupHistory) => {
		const hideButtonsForTypes = ['moved'];
		const hideToggle = hideButtonsForTypes.includes(historyRow.type);
		if (hideToggle) {
			return <TableColumn />;
		}

		const disableToggle = !historyRow.canBeHidden;

		const onClick = () => {
			toggleHidden(hasDrawer ? historyRow.agreementId : historyRow.id);
		};
		const disabled = T('agreement.history.excludeDisabled');
		const include = T('agreement.history.include');
		const exclude = T('agreement.history.exclude');
		return (
			<TableColumn align="right">
				<Tooltip title={disableToggle ? disabled : historyRow.hidden ? include : exclude} position="left">
					<InlineConfirm
						show={!disableToggle}
						keepTabPosition
						onConfirm={onClick}
						entity={`agreementHistory.type${capitalize(historyRow.type)}`}
					>
						<Button type="link" color="grey" className="row-tool">
							<Icon name="trash" color="grey-8" />
						</Button>
					</InlineConfirm>
				</Tooltip>
			</TableColumn>
		);
	};

	const renderHistoryYear = (year: string, sum: number) => {
		return (
			<TableRow
				className={classes.elem('year').b()}
				key={'year' + year}
				onClick={() => setYearHidden({ ...yearHidden, [year]: !yearHidden[year] })}
			>
				<TableColumn>
					<Text bold>{`${year} (${sum > 0 ? '+' : ''}${currencyFormat.short(sum)})`}</Text>
				</TableColumn>
				<TableColumn />
				<TableColumn />
				<TableColumn />
				<TableColumn align="right">
					<Icon
						className={classes.elem('year').elem('icon').mod({ rotate: yearHidden[year] }).b()}
						name="chevron-down"
					/>
				</TableColumn>
			</TableRow>
		);
	};

	const renderHistoryRow = (historyRow: AgreementGroupHistory) => {
		const typeIsMoved = historyRow.type === 'moved';
		const hasOnClick = hasDrawer && !typeIsMoved && !historyRow.hidden;

		return (
			<TableRow
				key={historyRow.id}
				disabled={historyRow.hidden}
				onClick={
					hasOnClick
						? () => {
								openModal('ARRChangeDrawerV2', {
									clientId: historyRow.clientId,
									agreementGroupId: historyRow.agreementGroupId,
									activeAgreementId: historyRow.activeAgreementId,
									date: historyRow.arrChangeDate,
									type: historyRow.type
								});
						  }
						: undefined
				}
			>
				{renderDateColumn(historyRow)}
				{renderTypeColumn(historyRow)}
				{userColumn(historyRow)}
				{renderChangeColumn(historyRow)}
				{excludeColumn(historyRow)}
			</TableRow>
		);
	};

	const tableContent = [];
	for (const [year, value] of Object.entries(historyRows).sort(
		([year1], [year2]) => parseInt(year1) - parseInt(year2)
	)) {
		if (!yearHidden[year]) {
			value.history.forEach(v => tableContent.unshift(renderHistoryRow(v)));
		}
		tableContent.unshift(renderHistoryYear(year, value.sum));
	}

	return (
		<div className={classes.b()}>
			<div className={classes.elem('content').b()}>
				<Headline size="sm">{T('subscription.modal.history.historicalChanges')}</Headline>
				<Table>
					<TableHeader
						columns={[
							{ title: T('subscription.modal.history.date') },
							{ title: T('subscription.modal.history.type') },
							{ title: T('subscription.modal.history.changedBy') },
							{ title: T('subscription.modal.history.change') },
							{}
						]}
					/>
					{tableContent}
				</Table>
			</div>
		</div>
	);
};

export default SubscriptionHistory;
