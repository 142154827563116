import { Card } from '@upsales/components';
import React, { ComponentProps, FC } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './AdminCard.scss';

const AdminCard: FC<ComponentProps<typeof Card>> = ({ className, ...props }) => {
	const classes = new BemClass('AdminCard', className);
	return <Card {...props} className={classes.b()} borderRadius border="small" borderColor="grey-6" />;
};

export default AdminCard;
