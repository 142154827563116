import {
	AssistChip,
	Flex,
	Link,
	Paginator,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text
} from '@upsales/components';
import { roundNumber } from 'App/pages/CompanyGroupCard/Sidebar/UpsellWidget/UpsellWidget';
import CustomerPortfolioPotential from 'App/resources/Model/CustomerPortfolioPotential';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import { useMetadata } from 'App/components/hooks/appHooks';
import { NumberFormat } from 'App/babel/utils/numberFormat';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import { DisplayValueType } from '../CustomerPortfolio';
import UserStack from 'App/components/UserStack';
import { openDrawer } from 'Services/Drawer';
import Avatar from 'Components/Avatar';

type Props = {
	salesModel: string;
	upsellData: CustomerPortfolioPotential[];
	classes: BemClass;
	valueType: DisplayValueType;
	limit: number;
	setOffset: (val: number) => void;
	offset: number;
	total: number;
	setSort: Dispatch<SetStateAction<{ field: string; asc: boolean }>>;
	sort: { field: string; asc: boolean };
};

const List = (props: Props) => {
	const { salesModel, upsellData, classes, valueType, setOffset, limit, offset, total, setSort, sort } = props;
	const { t } = useTranslation();
	const metadata = useMetadata();
	const hasUpsellDrawer = useSoftDeployAccess('CUSTOMER_PORTFOLIO_POTENTIAL_DRAWER');
	const currency = metadata?.defaultCurrency || { iso: 'SEK', rate: 1 } || 'SEK';
	const currencyString = metadata?.defaultCurrency.iso || 'SEK';

	const getValueTypeColumn = (valueType: DisplayValueType) => {
		switch (valueType) {
			case 'annualValue':
				return {
					title: t('accountGrowth.column.arr'),
					sort: 'arr',
					sortType: 'numeric'
				};
			case 'monthlyValue':
				return {
					title: t('accountGrowth.column.mrr'),
					sort: 'mrr',
					sortType: 'numeric'
				};
			case 'contributionMargin':
				return {
					title: t('accountGrowth.column.cmLast12months'),
					sort: 'cmLast12Months',
					sortType: 'numeric'
				};
			default:
				return {
					title: t('accountGrowth.column.salesLast12months'),
					sort: 'salesLast12Months',
					sortType: 'numeric'
				};
		}
	};

	const columns = useMemo(
		() => [
			{ title: t('default.account'), sort: 'name', sortType: 'string' },
			{
				title:
					valueType === 'contributionMargin'
						? t('default.potential') + ` (${t('default.contributionMarginShort')})`
						: valueType === 'value'
						? t('default.potential')
						: t('default.potential') + ` (${salesModel.toLocaleUpperCase()})`,
				sort: 'takeRatePotential',
				sortType: 'numeric'
			},
			getValueTypeColumn(valueType),
			{ title: t('default.accountManager') }
		],
		[valueType]
	);

	const goToAccount = (id: number) => {
		Tools.$state.go('account.dashboard', { id }, { inherit: false });
	};

	const mapValueTypeToGrowthKeys = () => {
		switch (valueType) {
			case 'annualValue':
			case 'monthlyValue':
				return 'arr';
			case 'contributionMargin':
				return 'cmLast12Months';
			default:
				return 'salesLast12Months';
		}
	};

	function round(num: number) {
		return Math.round(num / 10) * 10;
	}

	const row = upsellData.map((item: CustomerPortfolioPotential) => {
		const growthValue = item.client?.growth ? item.client.growth[mapValueTypeToGrowthKeys()] : 0;
		const potential = roundNumber(item.takeRatePotential) * currency.rate;
		const potentialToGrowthRatio = (potential / growthValue) * 100;
		const numberFormat = new NumberFormat();
		const users = item.client?.users;

		const potentialPercent = numberFormat.default(round(potentialToGrowthRatio));

		return (
			<TableRow
				key={item.client.id}
				onClick={
					hasUpsellDrawer
						? () =>
								openDrawer('CustomerPortfolioPotentialDrawer', {
									upsellClientData: item,
									upsellPotential: potential,
									valueType,
									salesModel
								})
						: undefined
				}
			>
				<TableColumn className={classes.elem('firstColumn').b()} size="lg">
					<Link
						onClick={e => {
							e.stopPropagation();
							goToAccount(item.client.id);
						}}
					>
						{item.client.name}
					</Link>
				</TableColumn>
				<TableColumn size="lg">
					<Flex alignItems="center" gap="u2">
						<Text>{'+' + currencyFormat(potential, currency.iso)}</Text>
						{growthValue > 0 ? <AssistChip type="success" title={'+' + potentialPercent + '%'} /> : null}
					</Flex>
				</TableColumn>
				<TableColumn size="lg">
					<Flex justifyContent="space-between" alignItems="center">
						{item.client?.growth ? (
							<Flex>
								{currencyFormat(
									valueType === 'monthlyValue' ? growthValue / 12 : growthValue,
									currencyString
								)}
							</Flex>
						) : (
							0
						)}
					</Flex>
				</TableColumn>
				<TableColumn size="lg">
					{users?.length > 1 ? (
						<UserStack users={item.client.users} />
					) : (
						<Flex alignItems="center">
							<Avatar user={users[0]} size={20} />
							<Text>{users?.length ? `${users[0].name}` : ''}</Text>
						</Flex>
					)}
				</TableColumn>
			</TableRow>
		);
	});

	return (
		<Table>
			<TableHeader columns={columns} onSortChange={setSort} sorting={sort.field} asc={sort.asc} />
			{row}
			{total > limit ? (
				<TableRow>
					<TableColumn colSpan={4} size="lg" align="center">
						<Paginator onChange={setOffset} total={total} limit={limit} offset={offset} align="center" />
					</TableColumn>
				</TableRow>
			) : null}
		</Table>
	);
};

export default List;
