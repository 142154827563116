import { Icon, ClickableItem, DropDownMenu, Tooltip } from '@upsales/components';
import { DefaultButton } from '@upsales/components/Buttons';
import { useSoftDeployAccess, useFeatureAvailable } from 'App/components/hooks';
import { useTranslation } from 'Components/Helpers/translate';
import { useEditTicketContext } from '../../../Context/Context';
import { Feature } from 'Store/actions/FeatureHelperActions';
import BemClass from '@upsales/components/Utils/bemClass';
import { useUsers } from 'App/components/hooks/appHooks';
import Activity from 'App/resources/Model/Activity';
import Client from 'App/resources/Model/Client';
import { openDrawer } from 'Services/Drawer';
import React, { useEffect, useState } from 'react';
import ClientResource from 'App/resources/Client';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import ContactResource from 'Resources/Contact';
import Order from 'App/resources/Model/Order';

import './CreateNewEntity.scss';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import Ticket from 'App/resources/Model/Ticket';
import openModal from 'App/services/Modal';
import AgreementGroup from 'App/resources/Model/AgreementGroup';

const CreateNewEntity = ({ onChange }: { onChange: (name: string, value: any, cb?: () => void) => void }) => {
	const {
		state: { ticket }
	} = useEditTicketContext();
	const { id, title, contact, client } = ticket;
	const users = useUsers('active');

	const [hasClientAccess, setHasClientAccess] = useState<boolean>(false);
	const [hasContactAccess, setHasContactAccess] = useState<boolean>(false);
	const hasNewRelations = useSoftDeployAccess('TICKET_RELATIONS_V2');

	const clientRef = React.useRef<CancelablePromise<Awaited<ReturnType<typeof ClientResource.get>>> | null>(null);
	const contactRef = React.useRef<CancelablePromise<Awaited<ReturnType<typeof ContactResource.get>>> | null>(null);

	const findFirstAccountManagerWithCRM = (accountManagers: Client['users']) => {
		for (const accountManager of accountManagers) {
			const u = users.find(u => u.id === accountManager.id);
			if (u?.crm) {
				return u;
			}
		}
	};

	useEffect(() => {
		if (ticket?.client) {
			clientRef.current = makeCancelable(ClientResource.get(ticket.client.id));
			clientRef.current.promise
				.then(() => {
					setHasClientAccess(true);
				})
				.catch(() => {
					setHasClientAccess(false);
				});
		}

		return () => {
			if (clientRef.current) {
				clientRef.current.cancel();
			}
		};
	}, [ticket.client?.id]);

	useEffect(() => {
		if (ticket?.contact) {
			contactRef.current = makeCancelable(ContactResource.get(ticket.contact.id));
			contactRef.current.promise
				.then(() => {
					setHasContactAccess(true);
				})
				.catch(() => {
					setHasContactAccess(false);
				});
		}

		return () => {
			if (contactRef.current) {
				contactRef.current.cancel();
			}
		};
	}, [ticket.client?.id]);

	const firstAccountManager = findFirstAccountManagerWithCRM(client?.users);
	const firstAccountManagerId = firstAccountManager?.id;

	const { t } = useTranslation();
	const hasActivitiesAndAppointments = useFeatureAvailable(Feature.ACTIVITIES_AND_APPOINTMENTS);
	const hasPipeline = useFeatureAvailable(Feature.PIPELINE);
	const hasOrder = useFeatureAvailable(Feature.ORDERS);
	const hasCreateEntityFromTicket = useFeatureAvailable(Feature.CREATE_ENTITY_FROM_TICKET);
	const hasTodoList = useSoftDeployAccess('TODO_LIST') && hasActivitiesAndAppointments;
	const hasRemoveActivities = useSoftDeployAccess('REMOVE_ACTIVITIES');
	const hasTicketEntityAndIsAccountManager = hasCreateEntityFromTicket && firstAccountManager;

	const classes = new BemClass('CreateNewEntity');

	const createTodo = () =>
		openDrawer('CreateTodo', {
			contact: hasContactAccess ? contact : undefined,
			client,
			ticket,
			assignedUser: firstAccountManager,
			notes: t('ticket.createdFrom', { id, title }),
			onSave: (activity: Activity) => {
				onChange('activity', activity);
			}
		});

	const createPhoneCall = () =>
		openDrawer('CreateCall', {
			contact: hasContactAccess ? contact : undefined,
			client,
			ticket,
			user: firstAccountManager,
			notes: t('ticket.createdFrom', { id, title }),
			onSave: (activity: Activity) => {
				onChange('activity', activity);
			}
		});

	const createActivity = () =>
		Tools.$upModal
			.open('editActivity', {
				activity: {
					client,
					users: firstAccountManager,
					contacts: hasContactAccess ? [{ id: contact.id }] : [],
					notes: t('ticket.createdFrom', { id, title })
				}
			})
			.then((activity: Activity) => {
				onChange('activity', activity);
			});

	const createAppointment = () =>
		Tools.$upModal
			.open('editAppointment', {
				appointment: {
					client,
					contacts: hasContactAccess ? [contact] : [],
					notes: t('ticket.createdFrom', { id, title }),
					users: firstAccountManager
				}
			})
			.then((appointment: Activity) => {
				onChange('appointment', appointment);
			});

	const createOpportunity = () =>
		Tools.$upModal
			.open('editOrder', {
				type: 'opportunity',
				contactId: hasContactAccess ? contact?.id : undefined,
				userId: firstAccountManager ? firstAccountManagerId : undefined,
				clientId: client?.id,
				notes: t('ticket.createdFrom', { id, title })
			})
			.then((opportunity: Order) => {
				onChange('opportunity', opportunity);
			});

	const createOrder = () =>
		Tools.$upModal
			.open('editOrder', {
				type: 'order',
				contactId: hasContactAccess ? contact?.id : undefined,
				userId: firstAccountManager ? firstAccountManagerId : undefined,
				clientId: client?.id,
				notes: t('ticket.createdFrom', { id, title })
			})
			.then((order: Order) => {
				onChange('opportunity', order);
			});
	const createProject = () => {
		openDrawer('EditProjectPlan', {
			client,
			contact,
			createdFromTicketId: ticket.id,
			notes: t('ticket.createdFrom', { id, title }),
			onSave: (projectPlan: ProjectPlan) => {
				onChange('projectPlan', projectPlan);
			}
		});
	};
	const createSubscription = () => {
		openModal('CreateSubscription', {
			client,
			contact,
			notes: t('ticket.createdFrom', { id, title }),
			createdFromTicketId: ticket.id,
			onSave: (agreement?: AgreementGroup) => {
				onChange('agreement', agreement?.currentAgreement);
			}
		});
	};
	const createTicket = () => {
		openDrawer('EditTicket', {
			client,
			contact,
			isCreatedFromTicket: true,
			onSave: (ticket: Ticket) => {
				onChange('relatedTicket', ticket);
			}
		});
	};
	const entities = [
		{
			hasAccess: hasNewRelations,
			title: 'default.projectPlanService',
			icon: 'project',
			onClick: createProject
		},
		{
			title: 'default.todo',
			onClick: createTodo,
			icon: 'activity',
			hasAccess: hasTodoList || hasTicketEntityAndIsAccountManager
		},
		{
			title: 'default.phonecall',
			onClick: createPhoneCall,
			icon: 'phone',
			hasAccess: hasTodoList || hasTicketEntityAndIsAccountManager
		},
		{
			hasAccess: (hasActivitiesAndAppointments || hasTicketEntityAndIsAccountManager) && !hasRemoveActivities,
			title: 'default.activity',
			icon: 'activity',
			onClick: createActivity
		},
		{
			hasAccess: hasActivitiesAndAppointments || hasTicketEntityAndIsAccountManager,
			title: 'default.appointment',
			icon: 'appointment',
			onClick: createAppointment
		},
		{
			hasAccess: hasPipeline || hasTicketEntityAndIsAccountManager,
			title: 'default.opportunity',
			icon: 'opportunity',
			onClick: createOpportunity
		},
		{
			hasAccess: hasOrder || hasTicketEntityAndIsAccountManager,
			title: 'default.order',
			icon: 'dollar',
			onClick: createOrder
		},
		{
			hasAccess: hasNewRelations,
			title: 'default.agreement',
			icon: 'subscription',
			onClick: createSubscription
		},
		{
			hasAccess: hasNewRelations,
			title: 'default.ticket',
			icon: 'customer-support',
			onClick: createTicket
		}
	] as const;

	const hasAccess = entities.filter(e => e.hasAccess);

	return (
		<div className={classes.b()}>
			<DropDownMenu
				useAnimation
				align="right"
				noMinWidth
				renderTrigger={(expanded, setExpanded) => (
					<Tooltip
						title={t(hasClientAccess ? 'ticket.noAccess' : 'ticket.noAccess.company')}
						disabled={hasClientAccess && !!hasAccess.length}
						distance={25}
					>
						<DefaultButton disabled={!hasClientAccess || !hasAccess.length} size="sm" onClick={setExpanded}>
							<Icon name="plus"></Icon>
							{' ' + t('default.create')}
						</DefaultButton>
					</Tooltip>
				)}
			>
				{close => (
					<>
						{hasAccess.map((entity, i) => (
							<ClickableItem
								block
								key={i}
								icon={entity.icon}
								title={t(entity.title)}
								onClick={e => {
									e.stopPropagation();
									entity.onClick();
									close();
								}}
							/>
						))}
					</>
				)}
			</DropDownMenu>
		</div>
	);
};

export default CreateNewEntity;
