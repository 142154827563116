// These actions are only existing so that we can get data from the store outside of react (in angular)
// When AppService is all gone we should remove this file.
import { AppThunk } from 'App/babel/store/index';
import { DocumentTemplate, ListView, ReportView, SalesboardListView, AllIWantData } from 'App/resources/AllIWant';
import Category, { CategoryType } from 'App/resources/Model/Category';
import ActivityType from 'App/resources/Model/ActivityType';
import CustomField from 'App/resources/Model/CustomField';
import JourneyStep from 'App/resources/Model/JourneyStep';
import OrderStage from 'App/resources/Model/OrderStage';
import { default as ProductType } from 'App/resources/Model/Product';
import ProductCategory from 'App/resources/Model/ProductCategory';
import { BasicUserWithPermissions } from 'App/resources/Model/User';
import _ from 'lodash';
import { AppState } from 'Store/reducers/AppReducer';
import AccessType from 'App/enum/AccessType';
import Role from 'App/resources/Model/Role';
import StaticValue from 'App/resources/Model/StaticValue';
import { getSelectedBrandIdFromState } from 'App/components/hooks/useSelectedBrandId';
import {
	getCategoriesFromState,
	getProductCategoriesFromState,
	getJourneyStepsFromState,
	getRolesFromState,
	getStagesFromState,
	getUsersFromState,
	UserType,
	applyUserTypeFilter
} from 'Store/selectors/AppSelectors';
import ProjectBoard from 'App/resources/Model/ProjectBoard';

const getter =
	<TKey extends keyof AppState>(key: TKey) =>
	(): AppThunk<AppState[TKey]> =>
	(d, getStore) => {
		return _.cloneDeep(getStore().App[key]);
	};

/**
 * @deprecated import { getAccountSelf } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getAccountSelf = getter('accountSelf');
/**
 * @deprecated import { getSelf } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getSelf = getter('self');
/**
 * @deprecated import { getAccessRights } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getAccessRights = getter('accessRights');
/**
 * @deprecated import { getUserMap } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getUserMap = getter('userMap');
/**
 * @deprecated import { getRoleMap } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getRoleMap = getter('roleMap');
/**
 * @deprecated import { getTodoTypes } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getTodoTypes = getter('todoTypes');
/**
 * @deprecated import { getAdAccount } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getAdAccount = getter('adAccount');
/**
 * @deprecated import { getBrands } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getBrands = getter('brands');
/**
 * @deprecated import { getScripts } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getScripts = getter('scripts');
/**
 * @deprecated import { getTotals } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getTotals =
	(key: keyof AppState['totals']): AppThunk<number> =>
	(d, getStore) => {
		return getStore().App.totals[key];
	};

/**
 * @deprecated import { getJourneySteps } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getJourneySteps =
	(all?: boolean): AppThunk<JourneyStep[]> =>
	(dispatch, getStore) => {
		return getJourneyStepsFromState(getStore().App, all);
	};
/**
 * @deprecated import { getProducts } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getProducts =
	(
		onlyActive?: boolean,
		skipAuth?: boolean,
		usePriceLists?: boolean,
		excludeBundles?: boolean
	): AppThunk<ProductType[]> =>
	(d, getStore) => {
		let { products } = getStore().App;
		const { productCategories, priceLists } = getStore().App;

		if (excludeBundles) {
			products = products.filter(p => p.bundlePriceAdjustment === null);
		}

		if (!usePriceLists) {
			const defaultPriceList = priceLists.find(priceList => priceList.isDefault);

			products = products.map(product => ({
				...product,
				currencies: product.currencies
					.filter(currency => currency.priceListId === (defaultPriceList?.id ?? 1))
					.map(({ priceListId, ...toKeep }) => toKeep),
				tiers: product.tiers
					.filter(tier => tier.priceListId === (defaultPriceList?.id ?? 1))
					.map(({ priceListId, ...toKeep }) => toKeep)
			}));
		}

		if (skipAuth) {
			return products;
		} else {
			const categoryAccessMap = new Map(productCategories.map(pc => [pc.id, pc.$hasAccess]));
			const hasCategoryAccess = (product: ProductType) =>
				!product.category || categoryAccessMap.get(product.category.id);

			if (onlyActive) {
				return _.filter(products, function (product) {
					return product.$hasAccess && product.active && hasCategoryAccess(product);
				});
			} else {
				return _.filter(products, function (product) {
					return product.$hasAccess && hasCategoryAccess(product);
				});
			}
		}
	};
/**
 * @deprecated import { getTopCountries } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getTopCountries = getter('topCountries');
/**
 * @deprecated import { getCustomFields } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getCustomFields =
	(type: keyof AppState['customFields'] | 'client'): AppThunk<CustomField[]> =>
	(d, getStore) => {
		const { customFields } = getStore().App;

		if (type === 'client') {
			type = 'account';
		}
		if (!customFields[type]) {
			return [];
		}
		const cf = _.cloneDeep(customFields[type]);
		if (type === 'agreement') {
			return cf.map(field => {
				field.nameType = 'Agreement';
				return field;
			});
		}
		return cf;
	};

export const getReadOnlyCustomFields =
	(type: keyof AppState['customFields'] | 'client'): AppThunk<Readonly<CustomField[]>> =>
	(d, getStore) => {
		const { customFields } = getStore().App;
		return customFields[type === 'client' ? 'account' : type] || [];
	};

/**
 * @deprecated import { getDocumentTemplates } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getDocumentTemplates =
	(type: keyof AppState['documentTemplates']): AppThunk<DocumentTemplate[]> =>
	(d, getStore) => {
		return getStore().App.documentTemplates[type] || [];
	};
/**
 * @deprecated import { getCategoryTypes } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getCategoryTypes =
	(type: keyof AppState['categoryTypes']): AppThunk<CategoryType[]> =>
	(d, getStore) => {
		return _.cloneDeep(getStore().App.categoryTypes[type] || []);
	};
/**
 * @deprecated import { getCategories } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getCategories =
	(type: string, skipAuth?: boolean, rights?: boolean): AppThunk<Category[]> =>
	(d, getStore) => {
		const App = getStore().App;
		return getCategoriesFromState(App, type, skipAuth, rights);
	};
/**
 * @deprecated import { getMetadata } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getMetadata = getter('metadata') as () => AppThunk<AllIWantData['metadata']>;
/**
 * @deprecated import { getPhoneIntegration } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getPhoneIntegration = getter('phoneIntegration');
/**
 * @deprecated import { getMailIntegration } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getMailIntegration = getter('mailIntegration');
/**
 * @deprecated import { getCustomerId } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getCustomerId = (): AppThunk<AppState['customerId']> => (d, getStore) => {
	return getStore().App.customerId;
};
/**
 * @deprecated import { getActivityTypes } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getActivityTypes =
	(type?: 'activity' | 'appointment', rights?: boolean): AppThunk<ActivityType[]> =>
	(d, getStore) => {
		const { activityTypes, self } = getStore().App;
		let types;

		if (!type) {
			types = [...(activityTypes.activity || []), ...(activityTypes.appointment || [])];
		} else {
			types = activityTypes[type] || [];
		}

		if (rights) {
			return _.cloneDeep(types).filter(type => {
				// If user is admin we can use all types OR
				// If roles is empty everyone can use this type OR
				// If this type is selected we need to se it, if this is an edit
				if (self?.administrator || !type.roles?.length) {
					return true;
				}

				if (self?.role?.id && type.roles?.find(r => r.id === self.role?.id)) {
					return true;
				}

				// If we get to this we cannot use this role, sorry
				return false;
			});
		}
		return _.cloneDeep(types);
	};
/**
 * @deprecated import { getProductCategories } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getProductCategories =
	(skipAuth?: boolean): AppThunk<ProductCategory[]> =>
	(d, getStore) => {
		const { App } = getStore();
		return getProductCategoriesFromState(App, skipAuth);
	};
/**
 * @deprecated import { getStages } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getStages =
	(type?: keyof AppState['stages'], skipAuth?: boolean): AppThunk<OrderStage[] | number[]> =>
	(d, getStore) => {
		const App = getStore().App;
		// This deprecated function still supports getting excludedIds, the new way of getting stages will never do that. If you need them they are on the redux App state
		if (type === 'excludedIds') {
			return _.cloneDeep(App.stages.excludedIds);
		}
		// The clones are still here because the old way of getting stages did allow for mutating the stages. The new way is typed as readonly so we can spare the cloning
		return _.cloneDeep(getStagesFromState(App, type, skipAuth));
	};
/**
 * @deprecated import { getReportViews } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getReportViews =
	(type?: string): AppThunk<ReportView[] | AppState['reportViews']> =>
	(d, getStore) => {
		const { reportViews } = getStore().App;

		return type ? reportViews[type] ?? [] : reportViews;
	};
/**
 * @deprecated import { getListViews } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getListViews =
	(type?: string): AppThunk<(ListView | SalesboardListView | ProjectBoard)[] | AppState['listViews']> =>
	(d, getStore) => {
		const { listViews } = getStore().App;

		return type ? listViews[type] ?? [] : listViews;
	};

/**
 * @deprecated import { getEsignIntegrations } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getEsignIntegrations = getter('esignIntegrations');
/**
 * @deprecated import { getSMSIntegrations } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getSMSIntegrations = getter('smsIntegrations');
/**
 * @deprecated import { getWebinarIntegrations } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getWebinarIntegrations = getter('webinarIntegrations');
/**
 * @deprecated import { getFileStorageIntegrations } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getFileStorageIntegrations = getter('fileStorageIntegrations');
/**
 * @deprecated import { getCalendarIntegrations } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getCalendarIntegrations = getter('calendarIntegrations');
/**
 * @deprecated import { getActiveCalendarIntegrations } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getActiveCalendarIntegrations = getter('activeCalendarIntegrations');
/**
 * @deprecated import { getSessionTimeoutMs } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getSessionTimeoutMs = getter('sessionTimeoutMs') as () => AppThunk<number>;

/**
 * @deprecated import { getSelectedBrand } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getSelectedBrand = (): AppThunk<number> => (dispatch, getStore) => {
	const { self, accountSelf } = getStore().App;
	return getSelectedBrandIdFromState(self, accountSelf);
};

/**
 * @deprecated import { getActiveUsers } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getActiveUsers =
	(userTypes: UserType | UserType[] | 'all' = 'crm'): AppThunk<BasicUserWithPermissions[]> =>
	(d, getStore) => {
		const { userMap, accountSelf } = getStore().App;
		let users = _.cloneDeep(userMap.active || []).filter(u => !u.apiUser);

		users = applyUserTypeFilter(accountSelf, users, userTypes);

		return users;
	};

/**
 * @deprecated import { getRealActiveUsers } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getRealActiveUsers =
	(userTypes: UserType | UserType[] | 'all' = 'crm'): AppThunk<BasicUserWithPermissions[]> =>
	(d, getStore) => {
		const { userMap, accountSelf } = getStore().App;
		let users = _.cloneDeep(userMap.active || []).filter(u => u.active && !u.ghost && !u.apiUser);

		users = applyUserTypeFilter(accountSelf, users, userTypes);

		return users;
	};

/**
 * @deprecated import { getUsers } from 'Store/selectors/AppSelectors' - implement if missing
 */
// Type should be required but was never required in the old implementation. New one has it set to required
export const getUsers =
	(
		type: AccessType | string = 'active',
		includeApiUsers?: boolean,
		userTypes: UserType | UserType[] | 'all' = 'crm'
	): AppThunk<BasicUserWithPermissions[]> =>
	(d, getStore) => {
		const App = getStore().App;
		return getUsersFromState(App, type ?? 'active', includeApiUsers, userTypes);
	};

/**
 * @deprecated import { getRoles } from 'Store/selectors/AppSelectors' - implement if missing
 */
// Type should be required but was never required in the old implementation. New one has it set to required
export const getRoles =
	(type: AccessType | string = 'all'): AppThunk<Role[]> =>
	(d, getStore) => {
		const App = getStore().App;

		return getRolesFromState(App, type ?? 'all');
	};

/**
 * @deprecated import { getProfileImageId } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getProfileImageId =
	(userId: number): AppThunk<number | null> =>
	(d, getStore) => {
		const { profileImageIds } = getStore().App;
		return profileImageIds[userId] || null;
	};

/**
 * @deprecated import { getStaticValues } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getStaticValues =
	(type: string): AppThunk<StaticValue[]> =>
	(d, getStore) => {
		const { staticValues } = getStore().App;
		return _.cloneDeep(staticValues[type] || []);
	};
/**
 * @deprecated import { getAcceptTerms } from 'Store/selectors/AppSelectors' - implement if missing
 */
export const getAcceptTerms = getter('acceptTerms');

/**
 * @deprecated import { getPriceLists } from 'Store/selectors/AppSelectors'
 */
export const getPriceLists = getter('priceLists');

export const getPaymentExtensions = getter('paymentExtensions');
