import Resource from 'Resources/Resource';

class ProjectPlanProductListResource extends Resource {
	eventName = 'projectPlanProductList';
	constructor() {
		super('projectPlanProductLists', null, { notificationTitle: 'editProjectPlan.ProductList' });
	}
}

const resource = new ProjectPlanProductListResource();

export default resource;
