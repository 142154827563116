import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { roundNumber } from 'App/pages/CompanyGroupCard/Sidebar/UpsellWidget/UpsellWidget';
import CustomerPortfolioPotentialResource from 'Resources/CustomerPortfolioPotential';
import { Flex, Title, Text, Loader, AssistChip } from '@upsales/components';
import type CustomerPortfolioPotential from 'App/resources/Model/CustomerPortfolioPotential';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import { useMetadata } from 'App/components/hooks/appHooks';
import React, { useEffect, useRef, useState } from 'react';
import List from './List';
import BemClass from '@upsales/components/Utils/bemClass';
import logError from 'Helpers/logError';
import { DisplayValueType, ListViewFilterMap } from '../CustomerPortfolio';
import RequestBuilder from 'Resources/RequestBuilder';
import { parseFiltersForRequest } from '../fetchHelper';
import { DefaultNoDataAsTable } from 'App/components/ListView/DefaultNoData';

import './CustomerPortfolioPotentialTab.scss';

const classes = new BemClass('CustomerPortfolioPotentialTab');
const LIMIT = 50;

type Props = {
	valueType: DisplayValueType;
	filters: ListViewFilterMap;
	aggregateSubAccounts?: boolean;
};

const CustomerPortfolioPotentialTab = ({ valueType, filters, aggregateSubAccounts }: Props): JSX.Element | null => {
	const { t } = useTranslation();

	const [potential, setPotential] = useState<{ existingPotential: number }>({
		existingPotential: 0
	});

	const metadata = useMetadata();
	const currency = metadata?.defaultCurrency || { iso: 'SEK', rate: 1 };
	const salesModelParam = metadata?.params.SalesModel ?? 'sales';
	const salesModelOption = metadata?.params.SalesModelOption ?? 'arr';
	const [numberOfClients, setNumberOfClients] = useState<number>(0);
	const [upsellData, setUpsellData] = useState<CustomerPortfolioPotential[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState({ field: 'takeRatePotential', asc: false });

	const potentialReq = useRef<CancelablePromise<
		Awaited<ReturnType<typeof CustomerPortfolioPotentialResource.find>>
	> | null>(null);

	const salesModel = salesModelParam === 'rr' ? salesModelOption : salesModelParam;

	useEffect(() => {
		setSort({ field: 'takeRatePotential', asc: false });
	}, [valueType]);

	useEffect(() => {
		potentialReq.current?.cancel();
		setLoading(true);
		const rb = new RequestBuilder();
		rb.offset = offset;
		rb.limit = LIMIT;
		rb.addSort(sort.field, sort.asc);
		const rbWithFilters = parseFiltersForRequest(filters, rb);
		rbWithFilters.addExtraParam('valueType', valueType);
		rbWithFilters.addExtraParam('aggregateSubAccounts', aggregateSubAccounts);
		potentialReq.current = makeCancelable(CustomerPortfolioPotentialResource.find(rbWithFilters.build()));

		potentialReq.current.promise
			.then(({ data, metadata }) => {
				setUpsellData(data);
				setTotal(metadata.total);
				const aggregated = data.reduce(
					(acc, curr) => {
						if (curr.hasTakeRatePotential) {
							acc.existingPotential += curr.takeRatePotential;
							acc.count++;
						}
						return acc;
					},
					{ existingPotential: 0, count: 0 }
				);

				setPotential({
					existingPotential: roundNumber(aggregated.existingPotential)
				});
				setNumberOfClients(aggregated.count);
			})
			.catch(err => {
				logError(err, 'Failed to fetch upsell data');
			})
			.finally(() => setLoading(false));

		return () => {
			potentialReq.current?.cancel();
		};
	}, [valueType, aggregateSubAccounts, filters, offset, sort]);

	if (loading) {
		return (
			<Flex justifyContent="center" alignItems="center" space="pbxl">
				<Loader size="sm" />
			</Flex>
		);
	}

	const totalPotential = currencyFormat(potential.existingPotential * currency.rate, currency.iso);

	return numberOfClients > 0 ? (
		<Flex className={classes.b()} gap="u3" direction="column">
			<Flex direction="column" space="pbm ptxl plxl prxl" gap="u3" className={classes.elem('infoBox').b()}>
				<Title bold>
					{total} {t('customerPortfolio.upsell.title')}
				</Title>
				<AssistChip
					type="success"
					title={t(
						`customerPortfolio.upsell.estimated.${
							valueType === 'contributionMargin' ? 'value' : valueType
						}`,
						{ totalPotential }
					)}
				/>
				<Text color="grey-11" size="md">
					{t('customerPortfolio.upsell.info')}
				</Text>
			</Flex>
			<List
				valueType={valueType}
				salesModel={salesModel}
				upsellData={upsellData}
				total={total}
				classes={classes}
				setOffset={setOffset}
				limit={LIMIT}
				offset={offset}
				setSort={setSort}
				sort={sort}
			/>
		</Flex>
	) : (
		<DefaultNoDataAsTable formatNoData={() => t('default.noResults')} />
	);
};

export default CustomerPortfolioPotentialTab;
