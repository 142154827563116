import React, { Dispatch, SetStateAction, useEffect } from 'react';
import FilesTabContent from 'Components/Modals/FilesTabContent/FilesTabContent';
import type File from 'App/resources/Model/File';
import { fetchFiles, PROJECT_TEMPLATE_FILE_ENTITY } from 'Components/EditProjectPlan/Helpers/Helpers';
import ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';
import { Block, Title } from '@upsales/components';
import { t } from 'i18next';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	projectPlanTemplate: ProjectPlanTemplate;
	setFiles: Dispatch<SetStateAction<File[]>>;
	setInitialFiles: Dispatch<SetStateAction<File[]>>;
	files: File[];
};

const TemplateFiles = ({ projectPlanTemplate, setFiles, setInitialFiles, files }: Props) => {
	const classes = new BemClass('TemplateFiles');

	useEffect(() => {
		if (!projectPlanTemplate?.id) {
			return;
		}

		async function getFiles() {
			const { data: fetchedFiles } = await fetchFiles(
				projectPlanTemplate.id,
				undefined,
				PROJECT_TEMPLATE_FILE_ENTITY
			);
			setFiles(fetchedFiles);
			setInitialFiles(fetchedFiles);
		}

		getFiles();
	}, [projectPlanTemplate?.id]);

	const handleFileDrop = (droppedFiles: File[]) => {
		setFiles([...files, ...droppedFiles]);
	};

	const handleFileRemoval = (file: File) => {
		setFiles(files.filter(f => f.filename !== file.filename));
	};

	return (
		<Block className={classes.b()}>
			<Block space="pbl ptl plxl">
				<Title>{t('file.files')}</Title>
			</Block>
			<FilesTabContent files={files} newFiles={[]} onDrop={handleFileDrop} onRemove={handleFileRemoval} />
		</Block>
	);
};

export default TemplateFiles;
