import Resource from './Resource';
import type Client from 'App/resources/Model/Client';

class customerInCompanyGroup extends Resource {
	constructor() {
		super('customerInCompanyGroup');
	}

	fetchCompaniesInGroup(clientId: number): Promise<{ data: Client[] }> {
		return super._getRequest(clientId).then(d => d.data);
	}
}

const resource = new customerInCompanyGroup();

export default resource;
