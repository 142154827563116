import React, { useRef, useState } from 'react';
import { Card, Drawer, DrawerHeader, Flex, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from 'App/components/Modals/Modals';
import Client from 'App/resources/Model/Client';
import AddParentCompany from './AddParentCompany/AddParentCompany';
import AddRelation from './AddRelation/AddRelation';
import AddSubaccount from './AddSubaccount/AddSubaccount';
import { useFeatureAvailable, useSoftDeployAccess } from 'App/components/hooks';
import ManualRelation from './Lotties/ManualRelation.json';
import SubaccountAdd from './Lotties/SubaccountAdd.json';
import ChangeParentCompany from './Lotties/ChangeParentCompany.json';
import Lottie from 'react-lottie';

import './CreateRelation.scss';
import { Feature } from 'Store/actions/FeatureHelperActions';

export enum CreateRelationView {
	Default,
	Relation,
	Subaccount,
	ParentCompany
}

type Props = {
	client: Client;
	defaultView?: CreateRelationView;
} & ModalProps;

export type DrawerClient = Pick<Client, 'id' | 'name'> & { hasAccess: boolean };

type Animation = {
	setDirection: (direction: -1 | 1) => void;
	setSpeed: (speed: number) => void;
	play: () => void;
	goToAndPlay: (frame: number) => void;
	currentFrame: number;
	totalFrames: number;
};

const CardWrapper = ({
	title,
	subtitle,
	lottie,
	onClick
}: {
	title: string;
	subtitle: string;
	lottie: any;
	onClick: () => void;
}) => {
	const defaultOptions = {
		autoplay: false,
		loop: false,
		animationData: lottie,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
			className: 'CreateRelation__lottie'
		}
	};
	const animationCompleted = (animation: Animation) => animation?.currentFrame >= animation?.totalFrames - 1;

	const lottieRef = useRef<Lottie & { anim: Animation }>(null);

	const resetAnimation = () => {
		const animCompleted = lottieRef?.current?.anim ? animationCompleted(lottieRef?.current?.anim) : true;
		if (!animCompleted) {
			lottieRef?.current?.anim.setDirection(-1);
			lottieRef?.current?.anim.setSpeed(5);
			lottieRef?.current?.anim.play();
		}
	};

	const playAnimation = () => {
		const animCompleted = lottieRef?.current?.anim ? animationCompleted(lottieRef?.current?.anim) : true;
		lottieRef?.current?.anim.setDirection(1);
		lottieRef?.current?.anim.setSpeed(1);
		if (animCompleted) {
			lottieRef?.current?.anim.goToAndPlay(0);
		} else {
			lottieRef?.current?.anim.play();
		}
	};

	const classes = new BemClass('CreateRelation');
	return (
		<Card
			borderRadius
			onClick={onClick}
			onMouseEnter={playAnimation}
			onMouseLeave={resetAnimation}
			className={classes.elem('content').elem('card').b()}
		>
			<Flex>
				<Flex direction="column" className={classes.elem('content').elem('card').elem('cardText').b()}>
					<Text size="lg" bold space="ptxl plxl">
						{title}
					</Text>
					<Text space="ptm plxl" color="grey-11">
						{subtitle}
					</Text>
				</Flex>
				<Lottie
					isStopped
					ref={lottieRef}
					isClickToPauseDisabled
					options={defaultOptions}
					style={{ width: '270px', height: '180px', overflow: 'inherit' }}
				/>
			</Flex>
		</Card>
	);
};

const CreateRelation = ({ className, close, client, modalId, defaultView = CreateRelationView.Default }: Props) => {
	const classes = new BemClass('CreateRelation', className);
	const { t } = useTranslation();
	const [selectedView, setSelectedView] = useState(defaultView);
	const hasSubaccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasSubaccountsV2 = useSoftDeployAccess('SUB_ACCOUNTS_V2') && hasSubaccounts;
	const hasSubAccountsFeature = useFeatureAvailable(Feature.SUB_ACCOUNTS) && hasSubaccountsV2;

	const lang = {
		headerTitle: t('createRelation.header.title'),
		cardTitleRelation: t('createRelation.card.titleRelation'),
		cardSubtitleRelation: t('createRelation.card.subtitleRelation'),
		cardTitleSubaccount: t('createRelation.card.titleSubaccount'),
		cardSubtitleSubaccount: t('createRelation.card.subtitleSubaccount'),
		cardTitleParentCompany: t('createRelation.card.titleParent'),
		cardSubtitleParentCompany: t('createRelation.card.subtitleParent')
	};

	const cardData = [
		{
			title: lang.cardTitleRelation,
			subtitle: lang.cardSubtitleRelation,
			lottie: ManualRelation,
			onClick: () => setSelectedView(CreateRelationView.Relation)
		},
		{
			title: lang.cardTitleParentCompany,
			subtitle: lang.cardSubtitleParentCompany,
			lottie: ChangeParentCompany,
			onClick: () => setSelectedView(CreateRelationView.ParentCompany)
		}
	];

	if (hasSubAccountsFeature) {
		cardData.splice(1, 0, {
			title: lang.cardTitleSubaccount,
			subtitle: lang.cardSubtitleSubaccount,
			lottie: SubaccountAdd as any,
			onClick: () => setSelectedView(CreateRelationView.Subaccount)
		});
	}

	const goBackToDefault = () => {
		setSelectedView(CreateRelationView.Default);
	};

	const closeDrawer = () => {
		close(undefined, true);
	};

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader title={lang.headerTitle} subtitle={client.name} onHide={closeDrawer} />
			{selectedView === CreateRelationView.Default ? (
				<Flex direction="column" gap="u5" className={classes.elem('content').b()}>
					{cardData.map((card, index) => (
						<CardWrapper key={'relationCard' + index} {...card} />
					))}
				</Flex>
			) : selectedView === CreateRelationView.Relation ? (
				<AddRelation goBack={goBackToDefault} client={client} modalId={modalId} close={closeDrawer} />
			) : selectedView === CreateRelationView.Subaccount ? (
				<AddSubaccount
					goBack={goBackToDefault}
					client={{ ...client, hasAccess: true }}
					modalId={modalId}
					close={closeDrawer}
				/>
			) : (
				<AddParentCompany goBack={goBackToDefault} client={client} modalId={modalId} close={closeDrawer} />
			)}
		</Drawer>
	);
};

export default CreateRelation;
