import React, { useEffect, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { loader } from 'App/helpers/googleMapsLoader';

import { renderToString } from 'react-dom/server';

import { Flex, Icon, Text, Label, Button, Loader, Card } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';

import GeocodeResource from 'App/resources/Geocode';

import './Map.scss';
import { PrimaryButton } from '@upsales/components/Buttons';
import useDefaultCenter, { Center } from './useDefaultCenter';
import { Address } from 'App/resources/Model/Geocode';
import { formatAddressComponents } from './addressMappers';
import { Pin } from './mapPins/MapPinTypes';

export type Bounds = {
	longitude: { low: number; high: number };
	latitude: { low: number; high: number };
};

export type PinOnLocation = {
	latitude: number;
	longitude: number;
	address?: string;
	zipcode?: string;
	city?: string;
};

export type Props<T> = {
	centerMapOnPins?: boolean;
	keepPinsOnUpdate?: boolean;
	allowScrollZoom?: boolean;
	defaultCenter?: Center;
	showTools?: boolean;
	pins?: Pin<T>[];
	onBoundsChange?: (bounds: Bounds) => void;
	onPinSelect?: (location: PinOnLocation) => void;
	renderQuickFilters?: () => React.ReactNode;
};

type PaintedPins = Record<string, google.maps.marker.AdvancedMarkerElement>;

const Map = <T,>({
	centerMapOnPins = false,
	keepPinsOnUpdate = false,
	allowScrollZoom = true,
	defaultCenter,
	showTools = true,
	pins = [],
	onBoundsChange,
	onPinSelect,
	renderQuickFilters
}: Props<T>) => {
	const [inited, setInited] = useState(false);
	const { t } = useTranslation();
	const classes = new BemClass('Map');
	const mapInstance = useRef<google.maps.Map | null>(null);
	const mapElem = useRef<HTMLDivElement>(null);
	const paintedPins = useRef<PaintedPins>({});
	const infoWindow = useRef<google.maps.InfoWindow | null>(null);

	const mapCenter = useDefaultCenter(defaultCenter, !!pins.length);

	const buildMarker = (pin: Pin<T>) => {
		const marker = (
			<>
				<Flex justifyContent="center" alignItems="center" className={classes.elem('markerIconWrapper').b()}>
					<div className={classes.elem('markerPin').b()} />
					<Icon className={classes.elem('markerIcon').b()} name={pin.icon as any} color={pin.iconColor} />
				</Flex>
				<Card space="ptm prm pbm plm" className={classes.elem('markerDetails').b()}>
					{pin?.renderDetails?.(pin, t)}
				</Card>
			</>
		);
		const myMarker = renderToString(marker);

		const content = document.createElement('div');
		content.classList.add(classes.elem('marker').b());
		content.style.setProperty('--backgroundColor', pin.backgroundColor);
		if (pin.color) {
			content.style.setProperty('--color', pin.color);
		}
		content.style.setProperty('--borderColor', pin.border);

		content.innerHTML = myMarker;

		return content;
	};

	const toggleHighlight = (markerView: google.maps.marker.AdvancedMarkerElement) => {
		if (!markerView?.content) {
			return;
		}

		const markerViewContent = markerView.content as HTMLElement;

		if (markerViewContent.classList.contains('highlight')) {
			markerViewContent.classList.remove('highlight');
			markerView.zIndex = null;
		} else {
			markerViewContent.classList.add('highlight');
			markerView.zIndex = 1;
		}
	};

	const getPinnedContent = (location: PinOnLocation) => {
		const { latitude, longitude, address, zipcode, city } = location;

		const latitudeLongitude = `${latitude.toFixed(6)}, ${longitude.toFixed(6)}`;
		const content = (
			<Flex className={classes.elem('pinnedContent').b()} justifyContent="space-between" alignItems="center">
				<Flex direction="column" space="mrm">
					<Label>{address?.trim?.()}</Label>
					<Text color="grey-11" size="sm" space="mbm">
						{zipcode} {city}
					</Text>
					<div className={classes.elem('divider').b()} />
					<Flex>
						<Text color="medium-blue" size="sm" space="mtm">
							{latitudeLongitude}
						</Text>
					</Flex>
				</Flex>
				<PrimaryButton id={classes.elem('pinSelect').b()}>{t('default.select')}</PrimaryButton>

				<Button
					id={classes.elem('closePinnedContent').b()}
					className={classes.elem('closeButton').b()}
					type="link"
					color="grey-11"
				>
					<Flex justifyContent="center" alignItems="center">
						<Icon name="times" />
					</Flex>
				</Button>
			</Flex>
		);
		return renderToString(content);
	};

	const setupPinnedContent = (location: PinOnLocation, latLngEvent: any) => {
		infoWindow.current?.close();

		infoWindow.current = new google.maps.InfoWindow({
			headerDisabled: true,
			position: latLngEvent
		});

		infoWindow.current.setContent(getPinnedContent(location));
		infoWindow.current.open(mapInstance.current);

		google.maps.event.addListener(infoWindow.current, 'domready', () => {
			const someButton = document.getElementById('Map__closePinnedContent');
			someButton?.addEventListener(
				'click',
				() => {
					infoWindow.current?.close();
				},
				{ once: true }
			);

			const pinSelect = document.getElementById('Map__pinSelect');
			pinSelect?.addEventListener(
				'click',
				() => {
					onPinSelect?.(location);
				},
				{ once: true }
			);
		});
	};

	const onMapClick = async (latLng: google.maps.LatLng, address?: Address) => {
		if (!mapInstance.current) {
			return;
		}

		let pinOnLocation: PinOnLocation = {
			latitude: latLng.lat(),
			longitude: latLng.lng()
		};

		if (address) {
			pinOnLocation = { ...pinOnLocation, ...address };
		} else {
			const addressFound = await GeocodeResource.lookupLatLong(pinOnLocation.latitude, pinOnLocation.longitude);
			if (addressFound) {
				pinOnLocation = {
					...pinOnLocation,
					...addressFound
				};
			}
		}
		setupPinnedContent(pinOnLocation, latLng);
	};

	const setupAddPinMode = () => {
		if (!mapInstance.current) {
			return;
		}

		mapInstance.current.addListener('click', async (mapsMouseEvent: any) => {
			onMapClick(mapsMouseEvent.latLng);
		});
	};

	const setupSearchInput = async () => {
		if (!mapInstance.current) {
			return;
		}

		const input = document.querySelector(`.${classes.elem('searchBox').b()}.controls`) as HTMLInputElement;
		if (!input) {
			return;
		}
		const searchBox = new google.maps.places.SearchBox(input);

		mapInstance.current.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
		// Bias the SearchBox results towards current map's viewport.
		mapInstance.current.addListener('bounds_changed', () => {
			if (!mapInstance.current) {
				return;
			}
			const bounds = mapInstance.current.getBounds();
			if (!bounds) {
				return;
			}
			searchBox.setBounds(bounds);
		});

		// Listen for the event fired when the user selects a prediction and retrieve
		// more details for that place.
		searchBox.addListener('places_changed', () => {
			if (!mapInstance.current) {
				return;
			}
			infoWindow.current?.close();
			const places = searchBox.getPlaces();

			if (!places || places.length === 0) {
				return;
			}

			const placeSelected = places[0];
			if (placeSelected.address_components && placeSelected.geometry?.location) {
				onMapClick(placeSelected.geometry.location, formatAddressComponents(placeSelected.address_components));
			}

			const bounds = new google.maps.LatLngBounds();
			if (placeSelected.geometry?.viewport) {
				bounds.union(placeSelected.geometry.viewport);
				mapInstance.current.fitBounds(bounds);
			} else if (placeSelected.geometry?.location) {
				bounds.extend(placeSelected.geometry?.location);
				mapInstance.current.fitBounds(bounds);
			}
		});
	};

	const getPinKey = (pin: Pin<T>) => `${pin.latitude}-${pin.longitude}`;

	const setupPins = () => {
		if (!mapInstance.current) {
			return;
		}

		for (const pin of pins) {
			const pinKey = getPinKey(pin);
			if (paintedPins.current[pinKey]) {
				continue;
			}

			const AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement({
				map: mapInstance.current,
				content: buildMarker(pin),
				position: { lat: pin.latitude, lng: pin.longitude },
				title: pin.name
			});
			paintedPins.current[pinKey] = AdvancedMarkerElement;

			AdvancedMarkerElement.addListener('click', () => {
				toggleHighlight(AdvancedMarkerElement);
			});
		}

		if (centerMapOnPins && pins.length) {
			let { north, south, west, east } = pins.reduce(
				(acc, pin) => {
					if (!acc.south || pin.latitude < acc.south) {
						acc.south = pin.latitude;
					}
					if (!acc.north || pin.latitude > acc.north) {
						acc.north = pin.latitude;
					}
					if (!acc.west || pin.longitude < acc.west) {
						acc.west = pin.longitude;
					}
					if (!acc.east || pin.longitude > acc.east) {
						acc.east = pin.longitude;
					}
					return acc;
				},
				{ north: undefined, south: undefined, west: undefined, east: undefined } as any
			);

			if (pins.length === 1) {
				// if there is only one pin, it zooms in a lot, so we need to add some padding
				north += 0.01;
				south -= 0.01;
				west -= 0.01;
				east += 0.01;
			}

			const pinBounds = { north, south, west, east };
			mapInstance.current.fitBounds(pinBounds);
		}
	};

	const setupBoundsChangedListener = () => {
		if (!onBoundsChange || !mapInstance.current) {
			return;
		}

		mapInstance.current.addListener('bounds_changed', () => {
			if (!mapInstance.current) {
				return;
			}
			const bounds = mapInstance.current.getBounds();
			if (!bounds) {
				return;
			}
			const formattedBounds: Bounds = {
				latitude: {
					low: bounds.getSouthWest().lat(),
					high: bounds.getNorthEast().lat()
				},
				longitude: {
					low: bounds.getSouthWest().lng(),
					high: bounds.getNorthEast().lng()
				}
			};

			onBoundsChange(formattedBounds);
		});
	};

	const initMap = async () => {
		const mapWrapper = mapElem.current;
		if (mapWrapper) {
			const pos = { lat: mapCenter!.latitude, lng: mapCenter!.longitude };
			const mapOpts = {
				zoom: 14,
				center: pos,
				disableDefaultUI: true,
				mapTypeControl: showTools,
				streetViewControl: showTools,
				cameraControl: showTools,
				zoomControl: allowScrollZoom,
				scrollwheel: allowScrollZoom,
				scaleControl: showTools,
				rotateControl: showTools,
				mapId: 'UPSALES_MAP'
			};

			loader
				.load()
				.then(async () => {
					mapInstance.current = new google.maps.Map(mapWrapper, mapOpts);
					mapInstance.current.setOptions({
						mapTypeControlOptions: {
							position: google.maps.ControlPosition.INLINE_START_BLOCK_START,
							mapTypeIds: [
								google.maps.MapTypeId.ROADMAP,
								google.maps.MapTypeId.SATELLITE,
								google.maps.MapTypeId.HYBRID,
								google.maps.MapTypeId.TERRAIN
							]
						}
					});
					setInited(true);
				})
				.catch(e => console.log('Failed to load Google', e));
		}
	};

	const removePins = (pins: Pin<T>[]) => {
		const pinsToKeep = pins.map(p => getPinKey(p));
		for (const [pinKey, pin] of Object.entries(paintedPins.current)) {
			if (!pinsToKeep.includes(pinKey)) {
				pin.map = null;
				delete paintedPins.current[pinKey];
			}
		}
	};

	useEffect(() => {
		if (mapCenter?.latitude && mapCenter?.longitude) {
			if (!mapInstance.current) {
				initMap();
			} else {
				mapInstance.current.setCenter({ lat: mapCenter.latitude, lng: mapCenter.longitude });
			}
		}
	}, [mapCenter]);

	useEffect(() => {
		if (!keepPinsOnUpdate) {
			removePins(pins);
		}
		setupPins();
	}, [pins]);

	useEffect(() => {
		setupPins();
		setupSearchInput();

		if (onBoundsChange) {
			setupBoundsChangedListener();
		}

		if (onPinSelect) {
			setupAddPinMode();
		}
	}, [inited]);

	return (
		<div className={classes.b()}>
			{!inited ? (
				<Flex alignItems="center" justifyContent="center" className={classes.elem('loader').b()}>
					<Loader />
				</Flex>
			) : null}

			<div className={classes.elem('map').b()} ref={mapElem} />

			{/* not able to use ui-component here since google maps take control of it and moves it out of our containers  */}
			<input
				className={`Input Input__input ${classes.elem('searchBox').b()} controls`}
				type="text"
				placeholder={t('default.searchAddress')}
			/>

			{renderQuickFilters && inited ? (
				<Flex className={classes.elem('quickFilters').b()} justifyContent="center">
					{renderQuickFilters()}
				</Flex>
			) : null}
		</div>
	);
};

export default Map;
