import React from 'react';
import md5 from 'md5';

/**
 * @todo  @upsales/components 2.15.4
 * STYLING: CalendarWeekView and CalendarDayView both have events as props.
 * These events can have a color property that is used to style the event.
 * The color property however, can not be an upsales color variable. :(
 * @link https://github.com/upsales/ui-components/issues/516
 */

import colorMappings from '@upsales/components/Utils/colorMappings';
import { Calendar, CalendarWeekView, CalendarDayView } from '@upsales/components';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { openEditAppointment } from 'Components/Modals/Appointment/EditAppointment';
import CalendarDayWrapper from './CalendarDayWrapper';
import { CalendarItem } from './CalendarItem';
import './CalendarWrapper.scss';
import openModal from 'App/services/Modal';
import logError from 'Helpers/logError';
import { getProjectPlanStagesFromState } from 'Store/selectors/AppSelectors';
import store from 'Store/index';

const moment = extendMoment(Moment);

const fallbackServiceProjectColor = '#1F5CAD';

class CalendarWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			appointmentTypes: Tools.AppService.getActivityTypes('appointment'),
			projectPlanStages: getProjectPlanStagesFromState(store.getState().App) || []
		};

		this.hasColorFeature = Tools.FeatureHelper.hasSoftDeployAccess('CALENDAR_VIEW_COLORS');
		this.currentUserId = Tools.AppService.getSelf().id;
		this.userMap = Tools.AppService.getUserMap().active.reduce((acc, user) => {
			acc[user.id] = user;
			return acc;
		}, {});

		this.validUserColors = ['danger-2', 'bright-yellow', 'success-2', 'bright-purple', 'danger-3', 'rose-3'].map(
			color => colorMappings.get(color)
		);
		this.userColorMap = {};
	}

	handleGetAppointmentColor = (appointments, appointmentTypes) => {
		appointments.forEach(appointment => {
			if (this.hasColorFeature) {
				const isCurrentUserAppointment = appointment.users?.some(user => user.id === this.currentUserId);
				const userIdArray = appointment.users?.map(user => user.id);

				if (appointment.isEditing) {
					return;
				} else if (isCurrentUserAppointment) {
					appointment.color = colorMappings.get('super-light-green');
				} else if (userIdArray?.length) {
					// find user with color already assigned
					const userId = userIdArray.find(id => this.userColorMap[id]) || userIdArray[0];

					if (!this.userColorMap[userId]) {
						this.userColorMap[userId] = this.validUserColors.shift();
					}

					appointment.color = this.userColorMap[userId];
				}

				return;
			}

			if (appointment.color) {
				return;
			}

			if (appointment.activityType) {
				const appointmentType = appointmentTypes.find(type => type.id === appointment.activityType.id);

				if (appointmentType) {
					appointment.color = appointmentType.color;
				}
			}
		});
	};

	openAppointmentModal = appointmentOrDate => {
		if (appointmentOrDate.isEvent) {
			return;
		}

		const modalParams = appointmentOrDate.id
			? { id: appointmentOrDate.id }
			: { appointment: { date: appointmentOrDate, endDate: moment(appointmentOrDate).add(1, 'hour').toDate() } };

		openEditAppointment(modalParams);
	};

	handleGetServiceProjectColor = (serviceProjects, projectPlanStages) => {
		if (!serviceProjects || !serviceProjects.length || !projectPlanStages || !projectPlanStages.length) {
			return;
		}
		serviceProjects.forEach(serviceProject => {
			if (serviceProject.color) {
				return;
			}

			if (serviceProject.projectPlanStage) {
				const projectPlanStageColor = projectPlanStages.find(
					({ id }) => id === serviceProject.projectPlanStage.id
				)?.color;
				serviceProject.color = projectPlanStageColor || fallbackServiceProjectColor;
			}
		});
	};

	openServiceProjectModal = serviceProject => {
		if (!serviceProject) {
			return;
		}

		const modalParams = serviceProject.id ? { projectPlanId: serviceProject.id } : null;
		if (modalParams) {
			openModal('EditProjectPlan', modalParams);
		} else {
			logError(JSON.stringify(serviceProject), 'No service project id found');
		}
	};

	handleEventClick = event => {
		if (event.isEvent) {
			return;
		}
		if (Tools.FeatureHelper.hasSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS') && event.projectPlanStatus) {
			this.openServiceProjectModal(event);
			return;
		}
		this.openAppointmentModal(event);
	};

	handleCalendarItemClick = (e, event = null) => {
		e.stopPropagation();

		this.handleEventClick(event);
	};

	renderAllDayAppointment(appointment) {
		return appointment.description;
	}

	renderDayForMonthView = viewDate => {
		const { appointments, handleShowFullDay, serviceProjects } = this.props;
		const { appointmentTypes, projectPlanStages } = this.state;

		const dateFilter = event =>
			moment()
				.range(moment(event.date).startOf('day'), moment(event.endDate).endOf('day'))
				.contains(moment(viewDate));
		const events = appointments.filter(dateFilter);
		const serviceProjectsEvents =
			serviceProjects &&
			serviceProjects.length &&
			Tools.FeatureHelper.hasSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS')
				? serviceProjects.filter(dateFilter)
				: [];

		this.handleGetAppointmentColor(events, appointmentTypes);
		this.handleGetServiceProjectColor(serviceProjectsEvents, projectPlanStages);

		let externalEvents = [...this.props.events];
		if (this.props.otherEvents) {
			externalEvents.push(...this.props.otherEvents);
		}

		externalEvents = this.filterEvents(externalEvents.filter(dateFilter), events);

		return (
			<CalendarDayWrapper
				appointments={[...events, ...externalEvents, ...serviceProjectsEvents]}
				date={viewDate}
				handleCalendarItemClick={this.handleCalendarItemClick}
				handleShowFullDay={handleShowFullDay}
			/>
		);
	};

	renderAppointment = appointment => {
		return <CalendarItem invisible appointment={appointment} userMap={this.userMap} />;
	};

	renderDayView = () => {
		const {
			appointment,
			appointments,
			serviceProjects,
			selectedMonth,
			onHourClick,
			dayStartHour = 0,
			dayEndHour = 23
		} = this.props;
		const { appointmentTypes, projectPlanStages } = this.state;
		const viewDate = selectedMonth instanceof moment ? selectedMonth.toDate() : selectedMonth;

		const dateFilter = event =>
			moment(event.date).isSame(moment(viewDate), 'day') ||
			moment(viewDate).isBetween(moment(event.date), moment(event.endDate), 'day', '[]');

		const events = appointments.filter(dateFilter);
		const serviceProjectsEvents =
			serviceProjects &&
			serviceProjects.length &&
			Tools.FeatureHelper.hasSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS')
				? serviceProjects?.filter(dateFilter)
				: [];

		if (appointment) {
			events.push({ ...appointment, isEditing: true });
		}
		this.handleGetAppointmentColor(events, appointmentTypes);
		this.handleGetServiceProjectColor(serviceProjectsEvents, projectPlanStages);

		let externalEvents = [...this.props.events];
		if (this.props.otherEvents) {
			externalEvents.push(...this.props.otherEvents);
		}

		externalEvents = this.filterEvents(externalEvents.filter(dateFilter), events);

		const handleHourClick = dateOrAppointment => {
			const isAppointment = !!dateOrAppointment.id;

			if (onHourClick && !isAppointment) {
				onHourClick(dateOrAppointment);
			} else {
				this.openAppointmentModal(dateOrAppointment);
			}
		};

		return (
			<div className="CalendarView__CalendarWrapper">
				<CalendarDayView
					hourClick={handleHourClick}
					eventClick={this.handleEventClick}
					events={[...events, ...externalEvents, ...serviceProjectsEvents].map(event => ({
						...event,
						endDate: moment(event.endDate).subtract(1, 'm').toDate()
					}))}
					viewDate={viewDate}
					renderAllDayEvent={this.renderAllDayAppointment}
					renderEvent={this.renderAppointment}
					dayStartHour={dayStartHour}
					dayEndHour={dayEndHour}
				/>
			</div>
		);
	};

	renderWeekView = fullWeek => {
		const { appointments, selectedMonth, serviceProjects } = this.props;
		const { appointmentTypes, projectPlanStages } = this.state;
		const startType = fullWeek ? 'week' : 'isoWeek';

		const viewDate = moment(selectedMonth).startOf(startType).toDate();
		const viewRange = moment.range(
			moment(selectedMonth).startOf(startType),
			moment(selectedMonth).endOf(startType)
		);

		const dateFilter = event =>
			moment().range(moment(event.date).startOf('day'), moment(event.endDate).endOf('day')).overlaps(viewRange);
		const events = appointments.filter(dateFilter);
		const serviceProjectsEvents =
			serviceProjects &&
			serviceProjects.length &&
			Tools.FeatureHelper.hasSoftDeployAccess('PROJECT_PLAN_NEW_FIELDS')
				? serviceProjects?.filter(dateFilter)
				: [];

		this.handleGetAppointmentColor(events, appointmentTypes);
		this.handleGetServiceProjectColor(serviceProjectsEvents, projectPlanStages);

		let externalEvents = [...this.props.events];

		if (this.props.otherEvents) {
			externalEvents.push(...this.props.otherEvents);
		}

		externalEvents = this.filterEvents(externalEvents.filter(dateFilter), events);

		return (
			<div className="CalendarView__CalendarWrapper">
				<CalendarWeekView
					hourClick={this.handleEventClick}
					eventClick={this.handleEventClick}
					events={[...events, ...externalEvents, ...serviceProjectsEvents].map(event => ({
						...event,
						endDate: moment(event.endDate).subtract(1, 'm').toDate()
					}))}
					daysInWeek={fullWeek ? 7 : 5}
					viewDate={viewDate}
					renderAllDayEvent={this.renderAllDayAppointment}
					renderEvent={this.renderAppointment}
				/>
			</div>
		);
	};

	getHeaders(fullNameHeaders) {
		const tds = [];
		const week = moment().startOf('week');

		for (let i = 0; i < 7; i++) {
			const format = week.format(fullNameHeaders ? 'dddd' : 'ddd');
			tds.push(
				<td className="Calendar__Day-header" key={`date-${format}`}>
					{format}
				</td>
			);

			week.add(1, 'day');
		}

		return <tr>{tds}</tr>;
	}

	renderMonthView() {
		const { selectedMonth } = this.props;

		return (
			<div className="CalendarView__CalendarWrapper">
				<div className="CalendarMonthView">
					<div className="CalendarMonthView__StickyHeader">{this.getHeaders(false)}</div>
					<div className="CalendarMonthView__Table">
						<Calendar
							displayDate={selectedMonth || new Date()}
							renderDay={this.renderDayForMonthView}
							hideHeaders
						/>
					</div>
				</div>
			</div>
		);
	}

	filterEvents(events, appointments) {
		const upsalesIdMap = {};
		const hashMap = {};

		const utcFormat = date => moment(date).utc().format();
		const md5Hash = ({ description, date, endDate }) => md5(description + utcFormat(date) + utcFormat(endDate));

		appointments.forEach(appointment => {
			hashMap[md5Hash(appointment)] = true;

			if (appointment.id) {
				upsalesIdMap[appointment.id + ''] = true;
			}
		});

		return events.filter(event => {
			const isUnique = !upsalesIdMap[event.upsalesId] && !hashMap[event.hash];
			if (!isUnique) {
				return false;
			}

			const isCurrentUserEvent = !!event.description;

			if (isCurrentUserEvent) {
				event.color = '#A3C6F5';
			} else if (event.userId) {
				if (!this.userColorMap[event.userId]) {
					this.userColorMap[event.userId] = this.validUserColors.shift();
				}

				event.color = this.userColorMap[event.userId];
			}

			return true;
		});
	}

	render() {
		const { view } = this.props;

		switch (view) {
			case 'month':
				return this.renderMonthView();

			case 'week':
				return this.renderWeekView(true);

			case 'workWeek':
				return this.renderWeekView(false);

			case 'day':
				return this.renderDayView();

			default:
				return null;
		}
	}
}

CalendarWrapper.propTypes = {
	view: PropTypes.string,
	selectedMonth: PropTypes.instanceOf(Date),
	dayStartHour: PropTypes.number,
	dayEndHour: PropTypes.number,
	events: PropTypes.array,
	otherEvents: PropTypes.array,
	appointment: PropTypes.object,
	appointments: PropTypes.array,
	serviceProjects: PropTypes.array,
	onHourClick: PropTypes.func,
	handleShowFullDay: PropTypes.func
};

export default CalendarWrapper;
