import {
	Block,
	Flex,
	Icon,
	Table,
	TableHeader,
	Title,
	TableRow,
	TableColumn,
	NumberInput,
	Text,
	Label,
	Input,
	Button,
	Tooltip,
	EllipsisTooltip
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useState } from 'react';

import ProductResource from 'Resources/Product';
import './ProjectProductList.scss';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'react-i18next';
import ProductCategorySelect from 'App/components/OrderRows/OrderRow/ProductCategorySelect';
import { useSelector } from 'App/components/hooks';
import { RootState } from 'Store/index';
import { useEditProjectPlanContext } from '../Context';
import Product from 'App/resources/Model/Product';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import { getTierFromQuantity } from 'Helpers/order';
import logError from 'Helpers/logError';
import Order from 'App/resources/Model/Order';
import { currencyFormat } from 'Components/Filters/Currencies';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import moment from 'moment';
import { makeCancelable } from 'Helpers/promise';
import { getProducts } from 'Store/selectors/AppSelectors';
import ProjectPlanProductListResource from 'App/resources/ProjectPlanProductList';
import {
	addNewProductToProductLists,
	addSelectedProductToProductLists,
	createNewProduct,
	editProductLists,
	removeProductFromProductLists,
	updateQuantityOnProductLists
} from '../Helpers/ProductListHelpers';

const ProjectProductList = () => {
	const classes = new BemClass('ProjectProductList');
	const { t } = useTranslation();
	const {
		totals: { products: productTotal },
		self
	} = useSelector((state: RootState) => state.App);
	const allProducts = getProducts();

	const { onProjectPlanChange, setProjectPlan, state } = useEditProjectPlanContext();
	const { projectPlan } = state as { projectPlan: ProjectPlan };
	const [inlineVisible, setInlineVisible] = useState(false);
	const [isCreatingProductList, setIsCreatingProductList] = useState(false);
	const [editingProductListName, setEditingProductListName] = useState<null | number>(null);
	const [productListName, setProductListName] = useState('');

	useEffect(() => {
		if (state.fetchedProductListData) {
			return;
		}

		const updatedProductLists = [...projectPlan.productLists]
			.sort((a, b) => b.id! - a.id!)
			.map(productList => {
				if (productList.products.length) {
					return productList;
				}
				return { ...productList, products: [createNewProduct()] };
			});

		const updatedProjectPlan = { ...projectPlan, productLists: updatedProductLists };
		setProjectPlan(updatedProjectPlan);

		const productIds = new Set(
			projectPlan.productLists.flatMap(productList => productList.products.map(product => product.product.id))
		);
		const fetchProducts =
			productTotal < 4000
				? Promise.resolve(allProducts.filter(product => productIds.has(product.id)))
				: ProductResource.find({ id: Array.from(productIds) }).then(({ data }) => data);

		const { promise, cancel } = makeCancelable(fetchProducts);
		promise
			.then(products => {
				updatedProductLists.forEach(productList => {
					productList.products = productList.products.map(productListProduct => ({
						...productListProduct,
						product:
							products.find((p: Product) => p.id === productListProduct.product.id) ??
							productListProduct.product
					}));
				});

				setProjectPlan({
					...updatedProjectPlan,
					productLists: updatedProductLists
				});
			})
			.catch(logError);

		return cancel;
	}, []);

	useEffect(() => {
		const onOrderEvent = (event: any, updatedOrder: Order) => {
			if (projectPlan.productListOrders.some(({ orderId }) => orderId === updatedOrder.id)) {
				if (event.name.split('.')[1] === 'deleted') {
					const newProductListOrders = projectPlan.productListOrders.filter(
						({ orderId }) => orderId !== updatedOrder.id
					);
					setProjectPlan({ ...projectPlan, productListOrders: newProductListOrders });
				} else {
					const newProductListOrders = projectPlan.productListOrders.map(productListOrder => {
						if (productListOrder.orderId === updatedOrder.id) {
							return { ...productListOrder, order: updatedOrder };
						}
						return productListOrder;
					});
					setProjectPlan({ ...projectPlan, productListOrders: newProductListOrders });
				}
			}
		};

		const listeners = ['order.updated', 'order.deleted', 'opportunity.updated', 'opportunity.deleted'].map(
			eventName => Tools.$rootScope.$on(eventName, onOrderEvent)
		);

		return () => {
			listeners.forEach(listener => listener());
		};
	}, [projectPlan.productListOrders.length]);

	const onAddProduct = (productListIndex: number) => {
		setProjectPlan({
			...projectPlan,
			productLists: addNewProductToProductLists(projectPlan.productLists, productListIndex)
		});
	};

	const onSetProduct = (newProduct: Product, productListIndex: number, productIndex: number) => {
		onProjectPlanChange(
			{
				...projectPlan,
				productLists: addSelectedProductToProductLists(
					projectPlan.productLists,
					newProduct,
					productListIndex,
					productIndex
				)
			},
			true
		);
	};

	const onSetQuantity = (quantity: number | undefined, productListIndex: number, productIndex: number) => {
		const hasSetProduct = !!projectPlan.productLists[productListIndex].products[productIndex].product.id;
		const productLists = updateQuantityOnProductLists(
			projectPlan.productLists,
			quantity,
			productListIndex,
			productIndex
		);

		if (hasSetProduct) {
			onProjectPlanChange({ ...projectPlan, productLists }, true);
		} else {
			setProjectPlan({ ...projectPlan, productLists });
		}
	};

	const onRemoveProduct = (productListIndex: number, productIndex: number) => {
		const productLists = removeProductFromProductLists(projectPlan.productLists, productListIndex, productIndex);

		if (projectPlan.productLists[productListIndex].products[productIndex].product.id) {
			onProjectPlanChange({ ...projectPlan, productLists }, true);
		} else {
			setProjectPlan({ ...projectPlan, productLists });
		}
	};

	const onRemoveOrder = (deletedId: number) => {
		const newProductListOrders = projectPlan.productListOrders.filter(({ orderId }) => orderId !== deletedId);
		onProjectPlanChange({ ...projectPlan, productListOrders: newProductListOrders }, true);
	};

	const onOrderSave = (order: Order, productListIndex: number) => {
		const newProductLists = projectPlan.productLists.filter((_, index) => index !== productListIndex);

		onProjectPlanChange(
			{
				...projectPlan,
				productLists: newProductLists,
				productListOrders: [...projectPlan.productListOrders, { orderId: order.id, order }]
			},
			true
		);
	};

	const onCreateProductList = async () => {
		const { data: savedProductList } = await ProjectPlanProductListResource.save({
			name: productListName,
			projectPlanId: projectPlan.id
		});

		savedProductList.products = [createNewProduct()];
		onProjectPlanChange(
			{
				...projectPlan,
				productLists: [savedProductList, ...projectPlan.productLists]
			},
			true
		);

		setProductListName('');
		setIsCreatingProductList(false);
	};

	const onEditProductList = async (productListIndex: number) => {
		onProjectPlanChange(
			{
				...projectPlan,
				productLists: editProductLists(projectPlan.productLists, productListIndex, productListName)
			},
			true
		);

		setProductListName('');
		setEditingProductListName(null);
	};

	return (
		<Block className={classes.b()} space="mrl pll ptxl prxl pbl">
			<Flex direction="row" justifyContent="space-between" alignItems="center">
				<Flex direction="column" gap="u1">
					<Title>{t('editProjectPlan.productList.productLists')}</Title>
					<Text color="grey-11">{t('editProjectPlan.productList.productLists.toolTip')}</Text>
				</Flex>
				{!isCreatingProductList ? (
					<PrimaryButton
						icon="plus"
						text={t('default.add')}
						onClick={() => {
							setIsCreatingProductList(true);
							setEditingProductListName(null);
							setProductListName('');
						}}
					/>
				) : (
					<Text
						className={classes.elem('clickable').b()}
						onClick={() => {
							setIsCreatingProductList(false);
							setProductListName('');
						}}
					>
						{t('default.cancel')}
					</Text>
				)}
			</Flex>
			{isCreatingProductList ? (
				<Block space="mtxl mbxl">
					<Label
						maxLength={128}
						value={productListName}
						maxLengthReachedText={t('default.characterLimitReached')}
						className={classes.elem('productList-input').b()}
					>
						{t('default.name')}
					</Label>
					<Flex gap="u1">
						<Input
							className={classes.elem('productList-input').b()}
							placeholder={t('default.enterName')}
							maxLength={128}
							value={productListName}
							onChange={e => setProductListName(e.target.value)}
							onKeyPress={e => {
								if (e.key === 'Enter') {
									onCreateProductList();
								}
							}}
							autofocus
						/>
						<Button
							text={t('default.create')}
							onClick={onCreateProductList}
							disabled={!productListName.trim()}
						/>
					</Flex>
				</Block>
			) : null}
			{projectPlan.productLists.map((productList, productListIndex) => (
				<Block key={productListIndex} className={classes.elem('productList-wrapper').b()} space="mtxl">
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						{editingProductListName !== productListIndex ? (
							<Flex gap="u4" alignItems="center" space="mrxl">
								<EllipsisTooltip title={productList.name}>
									<Title bold className={classes.elem('list-title').b()}>
										{productList.name}
									</Title>
								</EllipsisTooltip>
								<Tooltip title={t('default.editName')}>
									<Icon
										name="edit"
										color="grey-8"
										className={classes.elem('clickable').b()}
										onClick={() => {
											setEditingProductListName(productListIndex);
											setProductListName(productList.name);
											setIsCreatingProductList(false);
										}}
									/>
								</Tooltip>
							</Flex>
						) : (
							<Flex gap="u2" alignItems="center" flex={1} space="mrl">
								<Input
									className={classes.elem('edit-productList-input').b()}
									placeholder={t('default.enterName')}
									value={productListName}
									onChange={e => setProductListName(e.target.value)}
									autofocus
									maxLength={128}
									onKeyPress={e => {
										if (e.key === 'Enter') {
											onEditProductList(productListIndex);
										}
									}}
								/>
								<Button
									icon="check"
									color="green"
									className={classes.elem('icon-button').b()}
									onClick={() => onEditProductList(productListIndex)}
									disabled={!productListName.trim()}
								/>
								<Button
									icon="times"
									color="white"
									shadow="none"
									className={classes.elem('icon-button').b()}
									onClick={() => {
										setEditingProductListName(null);
										setProductListName('');
									}}
								/>
							</Flex>
						)}
						<Flex gap="u4" alignItems="center">
							<DefaultButton
								icon="plus"
								text={t('admin.products.addProduct')}
								onClick={() => onAddProduct(productListIndex)}
							/>
							<InlineConfirm
								key={productListIndex}
								show
								onConfirm={() => {
									onProjectPlanChange(
										{
											...projectPlan,
											productLists: projectPlan.productLists.filter(
												(_, index) => index !== productListIndex
											)
										},
										true
									);
								}}
								entity="editProjectPlan.productList"
								keepTabPosition
								noResolve
							>
								<Icon className={classes.elem('clickable').b()} name="trash" />
							</InlineConfirm>
						</Flex>
					</Flex>
					{!productList.products.length ? (
						<Block space="mtl mbl">
							<Text color="grey-11" italic>
								{t('editProjectPlan.productList.noProducts')}
							</Text>
						</Block>
					) : (
						<Block className={classes.elem('table-wrapper').b()} space="mtl mbl">
							<Table>
								<TableHeader
									columns={[
										{ title: t('default.product') },
										{ title: t('default.quantity') },
										{ title: '' }
									]}
								/>
								{productList.products.map((relProduct, productIndex) => {
									let tierObject = null;
									if (relProduct.product.tiers?.length) {
										tierObject = getTierFromQuantity(relProduct.product.tiers, relProduct.quantity);
									}
									return (
										<TableRow key={productIndex}>
											<TableColumn className={classes.elem('product-column').b()}>
												<ProductCategorySelect
													className={classes.elem('product-select').b()}
													tierObject={tierObject}
													orderRow={{ product: relProduct.product } as any}
													useDiscount={false}
													productTotal={productTotal}
													virtualized={productTotal <= 4000}
													setProduct={product =>
														onSetProduct(product, productListIndex, productIndex)
													}
													anchor={'.EditProjectPlan'}
												/>
											</TableColumn>
											<TableColumn className={classes.elem('quantity-column').b()}>
												<NumberInput
													value={relProduct.quantity}
													min={0}
													align="center"
													onChange={quantity =>
														onSetQuantity(quantity, productListIndex, productIndex)
													}
													decimals={16}
												/>
											</TableColumn>
											<TableColumn align="right">
												<Icon
													className={classes.elem('remove-row').b()}
													name="times"
													onClick={() => onRemoveProduct(productListIndex, productIndex)}
												/>
											</TableColumn>
										</TableRow>
									);
								})}
							</Table>
						</Block>
					)}
					<Flex justifyContent="end">
						<PrimaryButton
							icon="sales"
							text={t('default.createOrder')}
							onClick={() => {
								Tools.$upModal.open('editOrder', {
									customerId: self?.id,
									clientId: projectPlan.client.id,
									contactId: projectPlan.contact?.id,
									type: 'order',
									description: productList.name,
									products: productList.products
										.filter(relProduct => relProduct.product.id)
										.map(relProduct => ({
											id: relProduct.product.id,
											quantity: relProduct.quantity
										})),
									afterSave: (order: Order) => onOrderSave(order, productListIndex)
								});
							}}
						/>
					</Flex>
				</Block>
			))}
			<Block space="mtxl mbl">
				<Title>{t('editProjectPlan.productList.createdOrders')}</Title>
			</Block>
			<Block className={classes.elem('table-wrapper').mod({ inlineVisible }).b()} space="mtl mbl">
				<Table>
					<TableHeader
						columns={[
							{ title: t('default.description') },
							{ title: t('default.value') },
							{ title: t('default.wasClosed') },
							{ title: '' }
						]}
					/>
					{projectPlan.productListOrders.length ? (
						projectPlan.productListOrders.map(({ order }) => (
							<TableRow
								key={order.id}
								onClick={() => Tools.$upModal.open('editOrder', { type: order, id: order.id })}
							>
								<TableColumn className={classes.elem('order-table-name').b()}>
									<EllipsisTooltip title={order.description}>
										<Text size="sm" ellipsis>
											{order.description}
										</Text>
									</EllipsisTooltip>
								</TableColumn>
								<TableColumn
									title={order.value != null ? currencyFormat(order.value, order.currency!) : ''}
								/>
								<TableColumn
									title={moment(order.closeDate).format('YYYY-MM-DD')}
									subtitle={order.user?.name}
								/>
								<TableColumn align="right">
									<InlineConfirm
										show
										onConfirm={() => onRemoveOrder(order.id!)}
										entity="default.order"
										onVisibleChange={setInlineVisible}
										keepTabPosition
										noResolve
									>
										<Icon
											className={classes.elem('remove-row').b()}
											space="ptm prl pbm pll"
											name="trash"
										/>
									</InlineConfirm>
								</TableColumn>
							</TableRow>
						))
					) : (
						<TableRow className={classes.elem('empty-table').b()}>
							<TableColumn colSpan={4}>
								<Flex alignItems="center" justifyContent="center">
									<Text color="grey-10" italic>
										{t('salesboard.noSales')}
									</Text>
								</Flex>
							</TableColumn>
						</TableRow>
					)}
				</Table>
			</Block>
		</Block>
	);
};

export default ProjectProductList;
