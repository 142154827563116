import Resource from 'Resources/ResourceTyped';
import { ProjectPlanType } from './Model/ProjectPlan';

export type ProjectPlanTypeSave = Partial<ProjectPlanType> & Pick<ProjectPlanType, 'name'>;
export type MergeData = { from: number; to: number; name: string };

class ProjectPlanTypeResource extends Resource<ProjectPlanType, ProjectPlanType, ProjectPlanTypeSave> {
	eventName = 'projectPlanType';
	constructor() {
		super('projectPlanTypes', null, { notificationTitle: 'projectPlan.projectType' });
	}

	async merge(data: MergeData) {
		return this._postRequest('merge', data);
	}
}

const resource = new ProjectPlanTypeResource();
export default resource;
