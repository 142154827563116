import {
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	NumberInput,
	Select,
	Textarea,
	TimeInput
} from '@upsales/components';
import { ThirdButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { getDynamicUserProperties, TagEntity } from 'App/helpers/actionHelpers';
import { CustomFieldWithValue } from 'App/resources/Model/CustomField';
import ProjectResource from 'App/resources/Project';
import { useTranslation } from 'Components/Helpers/translate';
import TextInput from 'Components/Inputs/TextInput';
import ModalTagList from 'Components/Modals/ModalTagList';
import 'Components/Modals/ModalTagListStyles.scss';
import { formatDateType, prepDates } from 'Helpers/multiModals';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { mapProperties, parseCustomFields, parseProperties } from 'Services/ActionProperties';
import { useSelector } from '../hooks';
import { useAppointmentTypes } from '../hooks/appHooks';
import LabeledInput from '../Inputs/LabeledInput';
import { StaticUserSelect, useUserOptions } from '../Inputs/Selects/StaticUserSelect/StaticUserSelect';
import { ModalProps } from '../Modals/Modals';
import { PropertyArray, PropertyMap } from '../MultiActionModal/MultiActionModal';
import { getNewStartEndTime, parseDatePropToState } from '../MultiActionModal/MultiHelpers';
import { prepTriggerCategories } from '../MultiActionModal/MultiUpdateActivity/MultiUpdateActivity';
import { ResourceIdSelect } from '../ResourceSelect';
import './CreateAppointmentAction.scss';

type Props = {
	appointmentCustomFields: CustomFieldWithValue[];
	properties: PropertyArray;
	actionIndex: number;
	removeAction: (actionIndex: number) => void;
	openedFrom: string;
	tagEntity: TagEntity;
	isTrigger?: boolean;
	isAutomation?: boolean;
	onSave?: (props: PropertyArray) => Promise<void>;
} & ModalProps<PropertyArray>;

const CreateAppointmentAction = ({
	removeAction,
	actionIndex,
	openedFrom,
	className,
	tagEntity,
	isAutomation,
	onSave,
	close,
	...modalParams
}: Props) => {
	const modalRef = useRef<Element | null>(null);
	const { t } = useTranslation();
	const appointmentTypes = useAppointmentTypes();
	const isEdit = !!modalParams.properties;

	const [standardProprties, customProperties] = modalParams.properties?.reduce(
		(acc: PropertyArray[], prop) => {
			if (!prop.name.includes('custom')) {
				acc[0].push(prop);
			} else {
				acc[1].push(prop);
			}
			return acc;
		},
		[[], []]
	) || [[], []];
	const dateTypes = prepDates(tagEntity);
	const triggerDateGroups = prepTriggerCategories(dateTypes.dateTypes);

	const customDateTypes = formatDateType(dateTypes.customDateTypes) as { [key: string]: any };
	const [hideEndDateSelect, setHideDateSelect] = useState(false);
	const [tagsVisible, setTagsVisible] = useState(false);
	const users = useUserOptions();
	const userTags = useMemo(() => {
		return getDynamicUserProperties(tagEntity, isAutomation).map(p => ({
			id: p.value,
			title: p.title
		}));
	}, [tagEntity, isAutomation]);

	const { customFields: startingFields, self } = useSelector(({ App }) => ({
		customFields: App.customFields.appointment,
		self: App.self
	}));

	const [initialCustomFields, initialAvailableFileds] = parseCustomFields(
		startingFields,
		parseProperties(customProperties),
		'Appointment',
		dateTypes.dateTags
	);
	const [customFields, setCustomFields] = useState<CustomFieldWithValue[]>(initialCustomFields || []);
	const [saving, setSaving] = useState(false);

	const iaf = structuredClone(initialAvailableFileds)
		.filter(field => field.editable)
		.map(field => {
			if (customDateTypes && field.datatype === 'Date') {
				(field as any).customDateSelect = true;
			}
			return field;
		});
	const [availableFields, setAvailableFields] = useState<CustomFieldWithValue[]>(iaf || []);

	const classes = useMemo(
		() => new BemClass('CreateAppointmentAction', className).mod({ tagsVisible }),
		[className, tagsVisible]
	);

	const findSelectedUserOrTag = (value: any) => {
		const user = users.find(user => user.id === Number(value));
		if (user) {
			return user;
		}

		const tag = userTags.find(tag => tag.id === value);
		if (tag) {
			return tag;
		}

		return null;
	};

	const toggleAvailableTags = () => {
		setTagsVisible(!tagsVisible);
	};

	const parseTime = (property: string) => {
		let value = property === 'StartTime' ? '12:00' : '13:00';

		const found = standardProprties?.find(prop => prop.name === property);

		if (found?.value) {
			value = found.value as string;
		}

		const formatted = moment(value, 'HH:mm').toString();

		if (formatted === 'Invalid date' && moment(value).isValid()) {
			// The value is not in HH:mm but a valid date
			return value;
		}
		return formatted;
	};

	const [properties, setPropertyMap] = useState<PropertyMap>(
		Object.keys(standardProprties).length
			? (() => {
					const startTimeIndex = standardProprties.findIndex(prop => prop.name === 'StartTime');
					const endTimeIndex = standardProprties.findIndex(prop => prop.name === 'EndTime');
					standardProprties[startTimeIndex] = {
						...standardProprties[startTimeIndex],
						value: parseTime('StartTime')
					};
					standardProprties[endTimeIndex] = {
						...standardProprties[endTimeIndex],
						value: parseTime('EndTime')
					};

					return parseProperties(standardProprties);
			  })()
			: {
					User: self?.id,
					AppointmentType: appointmentTypes[0]?.id,
					StartTime: parseTime('StartTime'),
					EndTime: parseTime('EndTime')
			  }
	);

	const [triggerStartDate, setTriggerStartDate] = useState<{
		selectItem: SelectItem | null;
		offset: number;
	}>({ selectItem: null, offset: 0 });

	const [triggerEndDate, setTriggerEndDate] = useState<{
		selectItem: SelectItem | null;
		offset: number;
	}>({ selectItem: null, offset: 0 });

	const onTimeChange = (time: string, timeIn: 'StartTime' | 'EndTime') => {
		setPropertyMap((prev: PropertyMap) => ({
			...prev,
			...getNewStartEndTime(time, timeIn, properties.StartTime as Date, properties.EndTime as Date)
		}));
	};

	useEffect(() => {
		const today = dateTypes.dateTags.find(tag => tag.tag === 'General.Today');
		const startDateProperty = properties.StartDate as string | undefined;
		const endDateProperty = properties.EndDate as string | undefined;

		const hasStartTimeOtherThanToday = startDateProperty && startDateProperty.indexOf('{{General.Today') !== 0;
		const hasEndTimeOtherThanToday = endDateProperty && endDateProperty.indexOf('{{General.Today') !== 0;

		if (openedFrom === 'editTrigger' && today && !hasStartTimeOtherThanToday && !hasEndTimeOtherThanToday) {
			setHideDateSelect(true);
		} else {
			setHideDateSelect(false);
		}

		if (startDateProperty) {
			setTriggerStartDate(parseDatePropToState(startDateProperty, triggerDateGroups));
			setTriggerEndDate(parseDatePropToState(endDateProperty ?? startDateProperty, triggerDateGroups));
		} else if (today) {
			setTriggerStartDate({ selectItem: { id: today.tag, title: today?.name }, offset: 0 });
			setTriggerEndDate({ selectItem: { id: today.tag, title: today?.name }, offset: 0 });
		}
	}, []);

	useEffect(() => {
		modalRef.current = document.querySelector('.Modals');
	}, []);

	const save = () => {
		setSaving(true);

		const propArray: PropertyArray = [];
		const propertyMap = { ...properties };

		if (triggerStartDate.selectItem?.id) {
			propertyMap.StartDate = '{{' + triggerStartDate.selectItem.id + ':' + (triggerStartDate.offset || 0) + '}}';
		}

		if (triggerEndDate.selectItem?.id) {
			propertyMap.EndDate = '{{' + triggerEndDate.selectItem.id + ':' + (triggerEndDate.offset || 0) + '}}';
		}

		if (propertyMap.StartTime) {
			propertyMap.StartTime = moment(propertyMap.StartTime).format('HH:mm');
		}
		if (propertyMap.EndTime) {
			propertyMap.EndTime = moment(propertyMap.EndTime).format('HH:mm');
		}

		Tools.ActionProperties.mapCustomFields(propArray as any, customFields, 'Appointment');
		propArray.push(...mapProperties(propertyMap));

		if (!propArray.length) {
			return close(propArray as any);
		}

		if (onSave) {
			return onSave(propArray)
				.then(() => {
					close();
				})
				.finally(() => {
					setSaving(false);
				});
		}

		return close(propArray as any);
	};

	const handleCustomFieldChange = (value: {
		id: CustomFieldWithValue['id'];
		title: CustomFieldWithValue['name'];
	}) => {
		const selectedField = availableFields.find(field => field.id === value.id);
		if (selectedField) {
			setCustomFields(prev => [...customFields, selectedField]);
		}

		setAvailableFields(prev => prev.filter(field => field.id !== value.id));
	};

	const removeCustomField = (field: CustomFieldWithValue) => {
		const availableField = { ...field };
		if (customDateTypes && field.datatype === 'Date') {
			(availableField as any).customDateSelect = true;
		}
		setCustomFields(customFields.filter(f => f.id !== field.id));
		setAvailableFields(prev => [...prev, availableField].sort((a, b) => a.name.localeCompare(b.name)));
	};

	const handleCustomFieldValueChange = (field: CustomFieldWithValue, value: any) => {
		setCustomFields(
			customFields.map(f => {
				if (f.id === field.id) {
					let newValue = value;
					if (field.datatype === 'Date' && value?.tag === 'RegularDate') {
						(field as any).customDateSelect = false;
						newValue = '';
					}

					return { ...f, value: newValue };
				}
				return f;
			})
		);
	};

	const appointmentTypeOptions = appointmentTypes.map(type => ({
		...type,
		title: type.name
	}));

	const requiredFieldsFilled = properties.Description !== undefined && properties.Description.trim() !== '';

	return (
		<Modal size="lg" className={classes.b()}>
			<div className={classes.elem('content').b()}>
				<form
					className={'create-appointment-action-modal'}
					onSubmit={e => {
						e.preventDefault();
						e.stopPropagation();
						save();
					}}
				>
					<ModalHeader
						title={t('default.create') + ' ' + t('default.appointment').toLowerCase()}
						onClose={() => close()}
					>
						<button
							type="button"
							id="toggle-tags-btn"
							className="up-btn btn-lined no-shadow btn-bright-blue btn-sm"
							onClick={toggleAvailableTags}
						>
							{tagsVisible ? t('appointment.hideTags') : t('appointment.availableTags')}
						</button>
					</ModalHeader>
					<ModalContent>
						<fieldset>
							<div className="row">
								<div className="col-md-6">
									<div className={'form-group'}>
										<LabeledInput required title={t('default.description')}>
											<TextInput
												defaultValue={properties.Description ?? ''}
												value={properties.Description}
												disabled={saving}
												onChange={(value: string) => {
													setPropertyMap(prevProps => ({
														...prevProps,
														Description: value
													}));
												}}
												required
											/>
										</LabeledInput>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<LabeledInput title={t('default.project')}>
											<ResourceIdSelect
												anchor={modalRef.current || undefined}
												resource={ProjectResource}
												value={properties.Project ?? null}
												onChange={v => setPropertyMap({ ...properties, Project: v })}
											/>
										</LabeledInput>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<LabeledInput title={t('default.appointmentType')}>
										<Select
											options={appointmentTypeOptions}
											value={appointmentTypeOptions.find(
												type => type.id === Number(properties.AppointmentType)
											)}
											onChange={(selectedType: any) => {
												setPropertyMap(prevProps => ({
													...prevProps,
													AppointmentType: selectedType.id
												}));
											}}
											anchor={modalRef.current || undefined}
											disabled={saving}
											placeholder={t('default.select') + ' ' + t('default.appointmentType')}
										/>
									</LabeledInput>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<LabeledInput title={t('default.user')}>
											<StaticUserSelect
												extraOptions={userTags}
												value={findSelectedUserOrTag(properties.User)}
												onChange={value => {
													setPropertyMap(prevProps => ({
														...prevProps,
														User: value?.id ?? null
													}));
												}}
												anchor={modalRef.current}
											/>
										</LabeledInput>
									</div>
								</div>
							</div>
							{hideEndDateSelect ? (
								<div className="row">
									<div className="col-md-3">
										<div className="form-group">
											<LabeledInput required title={t('default.startDate')}>
												<Select
													options={triggerDateGroups}
													optionHeaderType="disabled"
													value={triggerStartDate.selectItem}
													onChange={item => {
														setTriggerStartDate(prev => ({
															...prev,
															selectItem: item ?? null
														}));
														setTriggerEndDate(prev => ({
															...prev,
															selectItem: item ?? null
														}));
													}}
													placeholder={`${t('default.select')} ${t(
														'default.date'
													).toLowerCase()}`}
													anchor={modalRef.current}
												/>
											</LabeledInput>
										</div>
									</div>
									<div className="col-md-3">
										<LabeledInput required title={t('automationAction.DateOffset')}>
											<NumberInput
												value={triggerStartDate.offset}
												disabled={saving}
												onChange={offset => {
													setTriggerStartDate(prev => ({ ...prev, offset: offset ?? 0 }));
													setTriggerEndDate(prev => ({ ...prev, offset: offset ?? 0 }));
												}}
											/>
										</LabeledInput>
									</div>
									<div className="col-md-3">
										<div className="form-group">
											<LabeledInput title={t('default.startTime')}>
												<TimeInput
													className="form-control start-time"
													disabled={saving}
													value={moment(properties.StartTime).format('HH:mm') ?? null}
													onChange={time => {
														if (time.target.value) {
															onTimeChange(time.target.value, 'StartTime');
														}
													}}
													placeholder={t('default.time')}
												/>
											</LabeledInput>
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group">
											<LabeledInput title={t('default.endTime')}>
												<TimeInput
													value={moment(properties.EndTime).format('HH:mm') ?? null}
													disabled={saving}
													onChange={time => {
														if (time.target.value) {
															onTimeChange(time.target.value, 'EndTime');
														}
													}}
													placeholder={t('default.time')}
												/>
											</LabeledInput>
										</div>
									</div>
								</div>
							) : (
								<>
									<div className="row">
										<div className="col-md-6">
											<LabeledInput required title={t('default.startDate')}>
												<Select
													options={triggerDateGroups}
													optionHeaderType="disabled"
													value={triggerStartDate.selectItem}
													onChange={item => {
														setTriggerStartDate(prev => ({
															...prev,
															selectItem: item ?? null
														}));
													}}
													placeholder={`${t('default.select')} ${t(
														'default.date'
													).toLowerCase()}`}
													anchor={modalRef.current}
												/>
											</LabeledInput>
										</div>
										<div className="col-md-3">
											<LabeledInput required title={t('automationAction.DateOffset')}>
												<NumberInput
													value={triggerStartDate.offset}
													onChange={offset => {
														setTriggerStartDate(prev => ({ ...prev, offset: offset ?? 0 }));
													}}
												/>
											</LabeledInput>
										</div>
										<div className="col-md-3">
											<LabeledInput title={t('default.startTime')}>
												<TimeInput
													value={
														properties.StartTime
															? moment(properties.StartTime).format('HH:mm')
															: null
													}
													onChange={time => {
														if (time.target.value) {
															onTimeChange(time.target.value, 'StartTime');
														}
													}}
												/>
											</LabeledInput>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<LabeledInput required title={t('default.endDate')}>
												<Select
													options={triggerDateGroups}
													optionHeaderType="disabled"
													value={triggerEndDate.selectItem}
													onChange={item => {
														setTriggerEndDate(prev => ({
															...prev,
															selectItem: item ?? null
														}));
													}}
													placeholder={`${t('default.select')} ${t(
														'default.date'
													).toLowerCase()}`}
													anchor={modalRef.current}
												/>
											</LabeledInput>
										</div>
										<div className="col-md-3">
											<LabeledInput required title={t('automationAction.DateOffset')}>
												<NumberInput
													value={triggerEndDate.offset}
													onChange={offset => {
														setTriggerEndDate(prev => ({ ...prev, offset: offset ?? 0 }));
													}}
												/>
											</LabeledInput>
										</div>
										<div className="col-md-3">
											<LabeledInput title={t('default.endTime')}>
												<TimeInput
													value={
														properties.EndTime
															? moment(properties.EndTime).format('HH:mm')
															: null
													}
													onChange={time => {
														if (time.target.value) {
															onTimeChange(time.target.value, 'EndTime');
														}
													}}
												/>
											</LabeledInput>
										</div>
									</div>
								</>
							)}
							<div className="row">
								<div className="col-md-12">
									<LabeledInput title={t('default.notes')}>
										<Textarea
											value={properties.Notes ?? ''}
											onChange={e =>
												setPropertyMap(prevProps => ({ ...prevProps, Notes: e.target.value }))
											}
											disabled={saving}
										/>
									</LabeledInput>
								</div>
							</div>
						</fieldset>
						<fieldset>
							<legend>
								<span> {t('default.otherInfo')} </span>
							</legend>
							{customFields.length ? (
								<div className="row">
									{customFields.map((field, index) => (
										<div className="col-md-6" key={field.id}>
											<div className="form-group">
												<LabeledInput
													title={field.name}
													required={!!field.obligatoryField}
													topRight={
														<ThirdButton
															className={classes.elem('remove-custom').b()}
															icon={'trash'}
															onClick={() => removeCustomField(field)}
															size="sm"
															color="blue"
														/>
													}
												>
													{customDateTypes &&
													field.datatype === 'Date' &&
													(field as any).customDateSelect ? (
														<ReactTemplates.INPUTS.upSelect
															className={classes
																.elem('custom-field-input', 'form-control')
																.b()}
															getId={customDateTypes.id}
															options={{ data: customDateTypes.data }}
															defaultValue={field.value}
															multiple={false}
															required={false}
															formatResult={customDateTypes.formatResult}
															formatSelection={customDateTypes.formatSelection}
															matcher={customDateTypes.matcher}
															onChange={value =>
																handleCustomFieldValueChange(field, value.target.added)
															}
														/>
													) : (
														<ReactTemplates.INPUTS.customFieldInput
															field={field}
															name={field.name}
															className={classes.elem('custom-field-input').b()}
															multiple
															onChange={(value: string) =>
																handleCustomFieldValueChange(field, value)
															}
															usenewdate
															useNewTime
															useNumberInput
															useNewCheckbox
														/>
													)}
												</LabeledInput>
											</div>
										</div>
									))}
								</div>
							) : null}

							{availableFields.length ? (
								<div className="row">
									<div className="col-md-6">
										<Select
											data-testid="Input_field"
											anchor={modalRef.current || undefined}
											options={availableFields
												.map(field => ({
													id: field.id,
													title: field.name
												}))
												.sort((a, b) => a.title.localeCompare(b.title))}
											value={null}
											onChange={handleCustomFieldChange}
											disabled={!availableFields.length}
											className={classes.elem('custom-field-select').b()}
											placeholder={t('default.add') + ' ' + t('default.field').toLowerCase()}
										/>
									</div>
								</div>
							) : null}
						</fieldset>
					</ModalContent>
					<ModalControls>
						{isEdit && removeAction ? (
							<button
								type="button"
								className="up-btn btn-lined btn-grey btn-hover-red no-shadow pull-left ng-scope"
								onClick={() => {
									removeAction(actionIndex);
									close();
								}}
								disabled={saving}
							>
								<b className="fa fa-trash-o"></b>
							</button>
						) : null}

						<button
							type="submit"
							className="btn up-btn btn-bright-blue no-shadow"
							disabled={saving || !requiredFieldsFilled}
							onClick={save}
						>
							{saving ? t('default.saving') : t('default.save')} {t('admin.action')}
							{saving ? <span className={saving ? 'fa-spin' : 'fa fa-refresh'}></span> : null}
						</button>

						<button type="button" className="btn up-btn btn-grey btn-link" onClick={() => close()}>
							{t('default.abort')}
						</button>
					</ModalControls>
				</form>
				<div className={classes.elem('tags').mod({ visible: tagsVisible }).b()}>
					<ModalTagList entity={tagEntity} onClose={toggleAvailableTags} anchor={modalRef} />
				</div>
			</div>
		</Modal>
	);
};

export default CreateAppointmentAction;
