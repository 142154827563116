import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { Feature } from 'Store/actions/FeatureHelperActions';
import Prospecting from 'App/babel/resources/Prospecting';

type CPVCode = string;

interface BaseNode {
	id: string;
	name: string;
	count: number;
	level?: number;
	originalCode?: string;
}

export interface CPVNode extends BaseNode {
	children?: CPVNode[];
}

interface CPVHierarchyNode extends BaseNode {
	children?: Map<string, CPVHierarchyNode>;
}

interface CPVCodeParts {
	division: CPVCode;
	group: CPVCode;
	class_: CPVCode;
	category: CPVCode;
	subcategory: CPVCode;
	fullCode: CPVCode;
	controlDigit: string | undefined;
	actualLevel: 1 | 2 | 3 | 4 | 5;
}

export interface CPVFilter {
	inactive: boolean;
	type: 'allIndustries';
	value: CPVCode[];
}

type RawFilter = {
	v: CPVCode[];
	i: boolean;
};

type CPVLookupResult = {
	cpvCode: CPVCode;
	count: number;
	description?: string;
};

function generateFilter(field: string, overrides: Record<string, any> = {}) {
	function generate(): CPVFilter {
		return {
			inactive: true,
			type: 'allIndustries',
			value: []
		};
	}

	function isInactive(filter: CPVFilter): boolean {
		return filter.value.length ? false : true;
	}

	function toUrl(filter: CPVFilter): RawFilter {
		return { v: filter.value, i: filter.inactive };
	}

	function fromUrl(rawFilter: RawFilter): CPVFilter {
		const filter = generate();
		filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);
		// Ensure we never pass non-CPV codes in the value array
		// When the table in creditsafe is fixed and only contains correct CPV-codes,
		// this is probably not needed anymore.
		filter.value = Array.isArray(rawFilter.v) ? rawFilter.v.filter(v => /^\d{8}(-\d)?$/.test(v)) : [];
		return filter;
	}

	function build(filter: CPVFilter, rb: RequestBuilder) {
		const value = filter.value;

		if (filter.inactive || !value || !value.length) {
			return;
		}

		rb.addFilter({ field }, comparisonTypes.Equals, value);
	}

	async function dataPromise() {
		const hasProspectingCpvFilterAccess = Tools.FeatureHelper.hasSoftDeployAccess('PROSPECTING_CPV_FILTER');
		const hasAI = Tools.FeatureHelper.isAvailable(Feature.AI);
		if (!(hasProspectingCpvFilterAccess && hasAI)) {
			return { data: [], metadata: { total: 0 } };
		}

		const rb = new RequestBuilder();
		let lookupType = 'company';
		rb.addFilter({ field }, comparisonTypes.NotEquals, null);
		rb.addExtraParam('lookupType', lookupType);
		//TODO: This limit will probably have to be 10000 in the worst case
		rb.limit = 1000;

		const cpvCodes: CPVLookupResult[] = await Prospecting.lookup(field, rb.build()).then(result => result.data);

		async function fetchMissingCPVDescriptions(missingCodes: CPVCode[]): Promise<Map<CPVCode, string>> {
			if (!missingCodes.length) return new Map();

			const rb = new RequestBuilder();
			rb.addFilter({ field }, comparisonTypes.Equals, missingCodes);
			rb.addExtraParam('lookupType', lookupType);
			rb.limit = 150;

			const results: CPVLookupResult[] = await Prospecting.lookup(field, rb.build()).then(result => result.data);
			return new Map(
				results
					.filter(({ description }) => description !== undefined)
					.map(({ cpvCode, description }) => [cpvCode, description as string])
			);
		}

		const groupCodesByLevel = async (cpvCodes: CPVLookupResult[]): Promise<CPVNode[]> => {
			const divisions = new Map<CPVCode, CPVHierarchyNode>();
			const groupedCpvCodes = new Map<CPVCode, number>();
			const controlDigits = new Map<CPVCode, string | undefined>();
			const descriptions = new Map<CPVCode, string>();
			const missingDescriptions = new Set<CPVCode>();

			const isValidCPVCode = (cpvCode: CPVCode): boolean => {
				// Strict CPV format validation: 8 digits followed by optional -1 to -9 (suffix)
				const isValidFormat = /^\d{8}(-[0-9])?$/.test(cpvCode);
				if (!isValidFormat) {
					return false;
				}
				return true;
			};

			const getBaseCode = (cpvCode: CPVCode): CPVCode => {
				return cpvCode.split('-')[0];
			};

			const getCodeParts = (cpvCode: CPVCode): CPVCodeParts => {
				const baseCode = getBaseCode(cpvCode);
				const controlDigit = cpvCode.split('-')[1];

				let actualLevel: 1 | 2 | 3 | 4 | 5;
				if (baseCode.substring(2).match(/^000000$/)) {
					actualLevel = 1 as const;
				} else if (baseCode.substring(3).match(/^00000$/)) {
					actualLevel = 2 as const;
				} else if (baseCode.substring(4).match(/^0000$/)) {
					actualLevel = 3 as const;
				} else if (baseCode.substring(5).match(/^000$/)) {
					actualLevel = 4 as const;
				} else {
					actualLevel = 5 as const;
				}

				return {
					division: baseCode.substring(0, 2) + '000000',
					group: baseCode.substring(0, 3) + '00000',
					class_: baseCode.substring(0, 4) + '0000',
					category: baseCode.substring(0, 5) + '000',
					subcategory: baseCode,
					fullCode: cpvCode,
					controlDigit,
					actualLevel
				};
			};

			// First, we collect all base codes and their counts and store control digits
			cpvCodes.forEach(({ cpvCode, count, description }) => {
				if (!isValidCPVCode(cpvCode)) return;
				const [baseCode, controlDigit] = cpvCode.split('-');
				groupedCpvCodes.set(baseCode, (groupedCpvCodes.get(baseCode) || 0) + count);
				controlDigits.set(baseCode, controlDigit);
				if (description) {
					descriptions.set(baseCode, description);
				}
			});

			const ensureIntermediateLevels = (parts: CPVCodeParts) => {
				const levels = [parts.division, parts.group, parts.class_, parts.category, parts.subcategory].slice(
					0,
					parts.actualLevel
				);

				levels.forEach((code, index) => {
					if (!groupedCpvCodes.has(code)) {
						groupedCpvCodes.set(code, 0);
						if (!descriptions.has(code)) {
							const controlDigit = controlDigits.get(parts.fullCode.split('-')[0]);
							const fullCode = `${code}-${controlDigit}`;
							missingDescriptions.add(fullCode);
						}
					}
				});
			};

			// Second, we ensure all intermediate levels exist
			Array.from(groupedCpvCodes.keys()).forEach(code => {
				ensureIntermediateLevels(getCodeParts(code));
			});

			// Third, we check if we have descriptions for all levels of the hierarchy
			groupedCpvCodes.forEach((count, baseCode) => {
				const parts = getCodeParts(baseCode);
				const controlDigit = controlDigits.get(baseCode);

				// Only add to missingDescriptions if we have a valid control digit
				[parts.division, parts.group, parts.class_, parts.category, parts.subcategory]
					.slice(0, parts.actualLevel)
					.forEach(levelBaseCode => {
						if (!descriptions.has(levelBaseCode) && controlDigit) {
							const fullCode = `${levelBaseCode}-${controlDigit}`;
							missingDescriptions.add(fullCode);
						}
					});
			});

			// Fourth, we fetch missing descriptions if needed
			if (missingDescriptions.size > 0) {
				lookupType = 'description';
				const additionalDescriptions = await fetchMissingCPVDescriptions([...missingDescriptions]);
				additionalDescriptions.forEach((desc, fullCode) => {
					const baseCode = getBaseCode(fullCode);
					descriptions.set(baseCode, desc);
				});
			}

			// Finally, we build the complete hierarchy
			groupedCpvCodes.forEach((count, cpvCode) => {
				const parts = getCodeParts(cpvCode);
				const maxLevel = parts.actualLevel;

				const addToHierarchy = (
					map: Map<CPVCode, CPVHierarchyNode>,
					id: CPVCode,
					level: number,
					maxLevel: number
				): CPVHierarchyNode | null => {
					if (level > maxLevel) return null;

					if (!map.has(id)) {
						const node: CPVHierarchyNode = {
							id,
							name: descriptions.get(id) || id,
							count: 0,
							children: new Map<CPVCode, CPVHierarchyNode>(),
							level
						};

						const sortedIds = Array.from(map.keys());
						const insertIndex = sortedIds.findIndex(existingId => existingId > id);

						if (insertIndex !== -1) {
							const newMap = new Map<CPVCode, CPVHierarchyNode>();
							let added = false;

							sortedIds.forEach(existingId => {
								if (!added && existingId > id) {
									newMap.set(id, node);
									added = true;
								}
								newMap.set(existingId, map.get(existingId)!);
							});

							if (!added) {
								newMap.set(id, node);
							}

							map.clear();
							newMap.forEach((value, key) => map.set(key, value));
						} else {
							map.set(id, node);
						}
					}
					return map.get(id)!;
				};

				const getNodeName = (id: CPVCode): string => descriptions.get(id) || id;

				const divisionNode = addToHierarchy(divisions, parts.division, 1, maxLevel);
				if (!divisionNode) return;
				divisionNode.name = getNodeName(parts.division);
				divisionNode.children = divisionNode.children || new Map();

				const groupNode = addToHierarchy(divisionNode.children, parts.group, 2, maxLevel);
				if (!groupNode) {
					if (maxLevel === 1) {
						divisionNode.count += count;
					}
					return;
				}
				groupNode.name = getNodeName(parts.group);
				groupNode.children = groupNode.children || new Map();

				const classNode = addToHierarchy(groupNode.children, parts.class_, 3, maxLevel);
				if (!classNode) {
					if (maxLevel === 2) {
						groupNode.count += count;
					}
					return;
				}
				classNode.name = getNodeName(parts.class_);
				classNode.children = classNode.children || new Map();

				const categoryNode = addToHierarchy(classNode.children, parts.category, 4, maxLevel);
				if (!categoryNode) {
					if (maxLevel === 3) {
						classNode.count += count;
					}
					return;
				}
				categoryNode.name = getNodeName(parts.category);
				categoryNode.children = categoryNode.children || new Map();

				if (maxLevel === 5) {
					if (!categoryNode.children?.has(parts.subcategory)) {
						categoryNode.children = categoryNode.children || new Map();
						categoryNode.children.set(parts.subcategory, {
							id: parts.subcategory,
							name: getNodeName(parts.subcategory),
							count: count,
							level: 5,
							originalCode: parts.subcategory
						});
					} else {
						categoryNode.children.get(parts.subcategory)!.count += count;
					}
				} else if (maxLevel === 4) {
					categoryNode.count += count;
				}
			});

			const aggregateCounts = (node: CPVHierarchyNode): number => {
				if (!node) return 0;
				if (!node.children) return node.count || 0;

				let totalCount = node.count || 0;
				node.children.forEach(child => {
					totalCount += aggregateCounts(child);
				});
				node.count = totalCount;
				return totalCount;
			};

			divisions.forEach(division => {
				aggregateCounts(division);
			});

			const convertToArray = (node: CPVHierarchyNode): CPVNode => {
				const converted: CPVNode = {
					id: node.id,
					name: node.name,
					count: node.count,
					level: node.level,
					originalCode: node.originalCode
				};

				const children = node.children;
				if (children && children.size > 0) {
					converted.children = Array.from(children.values()).map(convertToArray);
				}

				return converted;
			};

			const data = Array.from(divisions.values())
				.map(convertToArray)
				.filter(division => division.count > 0)
				.sort((a, b) => a.id.localeCompare(b.id));

			return data;
		};

		const data = await groupCodesByLevel(cpvCodes);

		return { data, metadata: { total: cpvCodes.length } };
	}

	const filter = {
		filterName: 'IndustryCPV',
		type: 'raw',
		displayType: 'prospectingIndustryCPV',
		title: 'default.companyBranch.cpv',
		entity: 'account',
		showOnSegment: true,
		dataPromise,
		generate,
		isInactive,
		toUrl,
		fromUrl,
		build
	};

	return Object.assign(filter, overrides);
}

export default generateFilter;
