import { Table, TableRow, TableColumn, Text, TableHeader, EllipsisTooltip, Flex } from '@upsales/components';
import { DisplayValueType } from 'App/pages/CustomerPortfolio/CustomerPortfolio';
import { formatCurrency, formatTakeRate } from './Helpers';
import BemClass from '@upsales/components/Utils/bemClass';
import { useSoftDeployAccess } from '../hooks';
import T from 'Components/Helpers/translate';
import React from 'react';

export type ToplistData = {
	id: number;
	name: string;
	sniCode: string;
	totalSales: number;
	takeRate: number;
	originalIndex?: number;
};

interface Props {
	toplistData: ToplistData[];
	loading: boolean;
	close: () => void;
	valueType: DisplayValueType;
	salesModel: string;
}

const ToplistTable = ({ toplistData, loading, close, valueType, salesModel }: Props) => {
	const hasNewClientCard = useSoftDeployAccess('REACT_CLIENT_CARD');
	const classes = new BemClass('ToplistTable');

	const lang = {
		shareOfWallet: T('default.shareOfWallet'),
		customer: T('default.customer'),
		annualValue: T('accountGrowth.column.arr'),
		monthlyValue: T('accountGrowth.column.mrr'),
		contributionMargin: T('accountGrowth.column.cmLast12months'),
		salesLast12months: T('accountGrowth.column.salesLast12months'),
		noResults: T('default.noResults')
	};

	const goToAccount = (id: number) => {
		const route = hasNewClientCard ? 'react-root-clientCard' : 'account.dashboard';
		Tools.$state.go(route, { id }, { inherit: false });
	};

	const columns = [
		{
			title: T('default.customer')
		},
		{
			title:
				valueType === 'annualValue'
					? lang.annualValue
					: valueType === 'monthlyValue'
					? lang.monthlyValue
					: valueType === 'contributionMargin'
					? lang.contributionMargin
					: lang.salesLast12months
		},
		{
			title: lang.shareOfWallet,
			elevioId: 1558
		}
	];

	const rows = (() => {
		const sortedData = [...toplistData].sort((a, b) => {
			if (valueType === 'annualValue') {
				return b.totalSales - a.totalSales;
			}
			return b.takeRate - a.takeRate;
		});

		return sortedData.map(client => ({
			id: client.id,
			name: client.name,
			values: [
				{
					value: client.name,
					isLink: true,
					onClick: () => {
						close();
						goToAccount(client.id);
					}
				},
				{
					value: formatCurrency(client.totalSales)
				},
				{
					value: formatTakeRate(client.takeRate),
					color: 'success-5' as const
				}
			]
		}));
	})();

	if (loading) {
		return (
			<Table className={classes.elem('table').b()}>
				{[...Array(3)].map((_, i) => (
					<TableRow key={`loading-${i}`}>
						{[...Array(3)].map((_, j) => (
							<TableColumn key={`loading-${i}-${j}`}>
								<Text loading size="sm" />
							</TableColumn>
						))}
					</TableRow>
				))}
			</Table>
		);
	}

	// This should never be the case but we add this here to be safe
	if (!rows.length) {
		return (
			<Table>
				<TableRow>
					<TableColumn colSpan={3}>
						<Flex justifyContent="center" alignItems="center" space="ptl pbl">
							<Text>{lang.noResults}</Text>
						</Flex>
					</TableColumn>
				</TableRow>
			</Table>
		);
	}

	return (
		<Table>
			<TableHeader className={classes.elem('header').b()} columns={columns} />
			{rows.map(row => (
				<TableRow className={classes.elem('row').b()} key={row.id}>
					{row.values.map((cell, cellIndex) => (
						<TableColumn key={`${row.id}-${cellIndex}`}>
							{cell.isLink ? (
								<EllipsisTooltip title={cell.value}>
									<Text color="bright-blue" onClick={cell.onClick} className="clickable">
										{cell.value}
									</Text>
								</EllipsisTooltip>
							) : (
								<Text color={cell.color}>{cell.value}</Text>
							)}
						</TableColumn>
					))}
				</TableRow>
			))}
		</Table>
	);
};

export default ToplistTable;
