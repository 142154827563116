export const CountryConfig = {
	// Noridc
	SE: {
		industryField: 'sniCode',
		companyFormField: 'companyForm',
		currency: 'SEK',
		supportKeywords: true,
		supportBranches: true,
		orgNumberPattern: /^\d{6}-?\d{4}$/
	},
	NO: {
		industryField: 'naceCode',
		companyFormField: 'companyForm',
		currency: 'NOK',
		supportKeywords: false,
		supportBranches: true,
		orgNumberPattern: /^\d{9}|\d{3} \d{3} \d{3}$/
	},
	DK: {
		industryField: 'naceCode',
		companyFormField: 'companyForm',
		currency: 'DKK',
		supportKeywords: false,
		supportBranches: true,
		orgNumberPattern: /^\d{8}$/
	},
	FI: {
		industryField: 'naceCode',
		companyFormField: 'companyForm',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: false,
		orgNumberPattern: /^\d{7}-?\d{1}$/
	},
	// Rest of europe
	GB: {
		industryField: 'ukSicCode',
		companyFormField: 'companyFormRaw',
		currency: 'GBP',
		supportKeywords: false,
		supportBranches: false,
		orgNumberPattern: /^[A-Z]{2}[0-9]{6}$|[0-9]{8}/
	},
	BE: {
		industryField: 'naceCode',
		companyFormField: 'companyFormRaw',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: false,
		orgNumberPattern: /^\d{9,10}$/
	},
	FR: {
		industryField: 'naceCode',
		companyFormField: 'companyFormRaw',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: true,
		orgNumberPattern: /^\d{9}$/
	},
	IE: {
		industryField: 'naceCode',
		companyFormField: 'companyFormRaw',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: false,
		orgNumberPattern: /^IE\d{6}$/
	},
	IT: {
		industryField: 'naceCode',
		companyFormField: 'companyFormRaw',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: true,
		orgNumberPattern: /^\d{11}$/
	},
	LU: {
		industryField: 'naceCode',
		companyFormField: 'companyFormRaw',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: false,
		orgNumberPattern: /^B\d{6}$/
	},
	NL: {
		industryField: 'naceCode',
		companyFormField: 'companyFormRaw',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: true,
		orgNumberPattern: /^\d{8}$/
	},
	DE: {
		industryField: 'naceCode',
		companyFormField: 'companyFormRaw',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: false,
		orgNumberPattern: /^\d{9}$|^(VR|HRA|HRB)[a-zA-Z0-9_]*$/
	},
	AT: {
		industryField: 'naceCode',
		companyFormField: 'companyFormRaw',
		currency: 'EUR',
		supportKeywords: false,
		supportBranches: false,
		orgNumberPattern: /^FN [A-Z0-9]{8}$/
	}
} as const;

export type Country = keyof typeof CountryConfig;

export const Countries = Object.keys(CountryConfig) as Country[];

export enum StatusCategory {
	InactiveOrDeregistered,
	ActiveWithWarning,
	Active
}

export function getAvailableCountries(): Country[] {
	const { isAvailable, hasSoftDeployAccess, Feature } = Tools.FeatureHelper;

	const countries: Country[] = [];
	Countries.forEach(country => {
		if (['SE', 'NO', 'FI', 'DK'].includes(country)) {
			if (isAvailable(Feature[`PROSPECTING_${country}`])) {
				countries.push(country);
			}
		} else {
			if (isAvailable(Feature[`PROSPECTING_${country}`]) && hasSoftDeployAccess(`PROSPECTING_${country}`)) {
				countries.push(country);
			}
		}
	});

	return countries;
}

export const getAllowedCountries = () => {
	// Get the allowed prospecting countries from the user params, based
	// on the countries an user is allowed to prospect in.
	let allowedCountries: Country[] = getAvailableCountries();
	const hasProspectingCountriesSettings = Tools.FeatureHelper.hasSoftDeployAccess(
		'USER_PROSPECTING_COUNTRIES_SETTINGS'
	);
	const { userParams } = Tools.AppService.getSelf();
	if (hasProspectingCountriesSettings && userParams) {
		const { allowedProspectingCountries } = userParams;
		if (allowedProspectingCountries) {
			if (allowedProspectingCountries === 'all') {
				return allowedCountries;
			} else {
				const allowedCountriesArray = allowedProspectingCountries.split(',') as Country[];
				allowedCountries = allowedCountries.filter(country => allowedCountriesArray.includes(country));
			}
		}
	}

	return allowedCountries;
};

export function getCountryFromProspectingId(prospectingId: string | null): Country | null {
	if (typeof prospectingId !== 'string') {
		return null;
	}

	if (prospectingId.startsWith('UK')) {
		return 'GB';
	}

	return (
		TypedObject.keys(CountryConfig).find(country => {
			return prospectingId.startsWith(country);
		}) || null
	);
}

export function getAvailableCountryFromProspectingId(prospectingId: string): Country | null {
	const country = getCountryFromProspectingId(prospectingId);

	if (country === null) {
		return null;
	}

	const countries = getAvailableCountries();
	return countries.includes(country) ? country : null;
}

export function getDefaultCompanyFormFieldFromCountry(country: Country) {
	return (CountryConfig[country] || CountryConfig['SE']).companyFormField;
}

export function getDefaultCompanyFormFieldFromProspectingId(prospectingId: string) {
	const country = getCountryFromProspectingId(prospectingId);
	return getDefaultCompanyFormFieldFromCountry(country ?? 'SE');
}

export function getDefaultIndustryFieldFromCountry(country: Country) {
	return (CountryConfig[country] || CountryConfig['SE']).industryField;
}

export function getDefaultIndustryFieldFromProspectingId(prospectingId: string) {
	const country = getCountryFromProspectingId(prospectingId);
	return getDefaultIndustryFieldFromCountry(country ?? 'SE');
}

export function getDefaultCurrencyFromCountry(country: Country) {
	return (CountryConfig[country] || CountryConfig['SE']).currency;
}

export function getDefaultCurrencyFromProspectingId(prospectingId: string) {
	const country = getCountryFromProspectingId(prospectingId);
	return getDefaultCurrencyFromCountry(country ?? 'SE');
}

export function validateOrgNumberForCountry(country: Country, orgNumber: string) {
	return (CountryConfig[country] || CountryConfig['SE']).orgNumberPattern.test(orgNumber);
}

export function isKeywordsSupportedForCountry(country: Country) {
	return CountryConfig[country]?.supportKeywords ?? false;
}

export function isBranchesSupportedForCountry(country: Country) {
	return CountryConfig[country]?.supportBranches ?? false;
}
