import moment, { type Moment } from 'moment-timezone';
import logError from 'App/babel/helpers/logError';

const monthDiff = (startDate: Moment | string, endDate: Moment | string) => {
	const getFormattedMonth = (date: Moment | string) => moment(moment(date).format('YYYY-MM-DD'));
	return getFormattedMonth(endDate).diff(getFormattedMonth(startDate), 'month', true);
};

type MonthlyResult = {
	year: number;
	month: number;
	value: number;
};

type MonthlyFeeResult = MonthlyResult[];

/**
 * @param startDate - Start date (YYYY-MM-DD)
 * @param endDate - End date (YYYY-MM-DD)
 * @param totalValue - Amount to distribute
 * @returns Array of {year, month, value} objects
 */
export const calculateMonthlyValue = (startDate: string, endDate: string, totalValue: number): MonthlyFeeResult => {
	const start = moment(startDate).startOf('day');
	const end = moment(endDate).startOf('day');

	if (!start.isValid() || !end.isValid()) {
		logError('Invalid date format');
		return [];
	}
	if (end.isBefore(start)) {
		logError('End date must be after start date');
		return [];
	}

	const startDateOfNextMonth = moment(startDate).add(1, 'months').startOf('month').format('YYYY-MM-DD');
	const startDatePartial = monthDiff(start, startDateOfNextMonth);

	const startDateOfEndDate = moment(endDate).startOf('month').format('YYYY-MM-DD');
	const endDatePartial = monthDiff(startDateOfEndDate, end);

	const numberOfFullMonths = monthDiff(startDateOfNextMonth, startDateOfEndDate);
	const monthsDiff = monthDiff(start, end);
	const baseMonthValue = monthsDiff < 1 ? totalValue : Math.round(totalValue / monthsDiff);

	const monthInfo = [];
	const currentDate = start.clone().startOf('month');
	const MAX_MONTHS = 120;
	let monthCount = 0;

	while (currentDate.isSameOrBefore(end) && monthCount < MAX_MONTHS) {
		monthInfo.push({
			year: currentDate.year(),
			month: currentDate.month() + 1,
			value: baseMonthValue
		});

		currentDate.add(1, 'month');
		monthCount++;

		if (monthCount >= MAX_MONTHS) {
			logError('Maximum month limit reached in periodization calculation');
			break;
		}
	}

	if (monthInfo.length <= 1) {
		return monthInfo;
	}

	const startPartialRatio = startDatePartial / (startDatePartial + endDatePartial);
	const partialValue = totalValue - baseMonthValue * numberOfFullMonths;
	const isFirstMonthFull = startDatePartial === 1;
	const firstMonthValue = isFirstMonthFull ? baseMonthValue : partialValue * startPartialRatio;
	const lastMonthValue = isFirstMonthFull ? partialValue - baseMonthValue : partialValue * (1 - startPartialRatio);

	monthInfo[0].value = Math.round(firstMonthValue);
	monthInfo[monthInfo.length - 1].value = Math.round(lastMonthValue);
	return monthInfo;
};
